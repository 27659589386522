<div class="wrapper">
  <mulo-chat-bubble anchor="bottom left" [icon]="icon()">
    <ng-container slot-chat-content>
      @if (notification.contentTitle) {
        <div>{{ notification.contentTitle | translate }}</div>
      }
      @if (notification.reason) {
        <div>
          {{ 'research.output.list.note.return.reason' | translate }} {{ notification.reason | translate }}.
        </div>
      }
      @if (notification.subject) {
        <div>{{ notification.subject }}</div>
      }
      @if (notification.body) {
        <div>
          {{ 'research.output.list.note.prefix' | translate }} {{ notification.body }}
        </div>
      }
    </ng-container>
    <ng-container slot-chat-bubble-actions>
      @for (action of actions(); track action) {
        <button
          mat-button
          color="primary"
          [class.has-icon]="action.code === 'reply'"
          class="margin-left-xs"
          (click)="onActionClick(action)"
          >
          @if (action.code === 'reply') {
            <mat-icon class="mat-icon-rtl-mirror" svgIcon="reply"></mat-icon>
          }
          <span>{{ action.name | translate }}</span>
        </button>
      }
    </ng-container>
  </mulo-chat-bubble>
</div>
