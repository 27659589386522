import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
  input
} from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';

@Component({
    selector: 'mulo-page-placeholder',
    templateUrl: './page-placeholder.component.html',
    styleUrls: ['./page-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-page-placeholder',
        // '[style.height.px]': 'this.height'
    },
    imports: [ContentLoaderModule]
})
export class PagePlaceholderComponent implements OnInit {
  readonly width = input<number>(500);
  readonly height = input<number>(580);
  readonly rtl = input(false);

  constructor() {}

  ngOnInit(): void {}
}
