import { Component, OnInit, Input, SimpleChanges, OnChanges, ElementRef, NgZone, HostBinding, inject, input, output } from '@angular/core';
import { PortionListService } from '../portion-list.service';
import { WidthOutAnimation } from '../../../animations/width.animation';
import { MatListItem } from '@angular/material/list';
import { Platform } from '@angular/cdk/platform';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';

import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'mulo-portion-list-item',
    templateUrl: './portion-list-item.component.html',
    styleUrls: ['./portion-list-item.component.scss'],
    animations: [WidthOutAnimation],
    imports: [
        MatFormField,
        MatInput,
        FormsModule,
        MatSuffix,
        MatSlider,
        MatSliderThumb,
        MatIconButton,
        MatIcon
    ]
})
export class PortionListItemComponent extends MatListItem implements OnChanges {
  service = inject(PortionListService);

  readonly hasSlider = input(true);
  readonly unit = input('%');
  readonly max = input(100);
  readonly min = input(0);
  readonly item = input(undefined);
  readonly pliDisabled = input(true);
  readonly thumbLabel = input(true);
  readonly id = input<number>(undefined);
  readonly removeBtnAriaLabel = input('Remove');
  readonly portionInputAriaLabel = input('Portion percentage');
  readonly portionSliderAriaLabel = input('Portion percentage slider');
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('role') @Input() ariaRole = 'listitem';

  readonly itemChange = output();
  readonly changeEnd = output();
  readonly remove = output();
  roundedPortion = 0;

  constructor() {
    const _element = inject<ElementRef<HTMLElement>>(ElementRef);
    const _ngZone = inject(NgZone);
    const _platform = inject(Platform);

    super(_element, _ngZone, null, _platform);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.roundedPortion = Math.floor(this.item().portion);
    }
  }

  sliderChange(event) {
    this.service.sliderChanges(this.id(), event.value);
    this.changeEnd.emit(event.value);
  }

  onInputChange(event) {
    if (event > 100) {
      event = 100;
    }
    this.changeEnd.emit(event);
  }

  removeField() {
    this.remove.emit(null);
  }
}
