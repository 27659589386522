import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { SideNavService } from './sidenav.service';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { filter, map } from 'rxjs';
import { MenuToggleButtonComponent } from '../menu-toggle/menu-toggle-button/menu-toggle-button.component';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
  selector: 'mulo-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'mulo-sidenav',
    '[class.is-fixed]': 'this.fixedInViewport()',
  },
  imports: [
    MatSidenavContainer,
    MatSidenav,
    MenuToggleButtonComponent,
    NgTemplateOutlet,
    AsyncPipe,
  ],
})
export class SidenavComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  service = inject(SideNavService);

  readonly hasBackdrop = input(true);
  readonly fixedInViewport = input(true);
  readonly autoFocus = input(true);
  readonly position = input<'start' | 'end'>('start');
  readonly mode = input<'over' | 'push' | 'side'>('over');
  readonly id = input<string>(undefined);
  readonly template = input<TemplateRef<any>>(undefined);
  readonly closeButtonText = input<string | null>('Close');
  readonly closeButtonIcon = input<string | null>('close');
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() closeButtonMode: 'absolute' | 'relative' | 'hidden' = 'relative';
  readonly closeButtonSide = input<'left' | 'right'>('right');

  readonly sidenav = viewChild('sidenav', { read: MatSidenav });
  public observer$ = this.service.content$.pipe(
    filter((_) => _?.id === this.id()),
    map((_) => _.template),
  );

  ngAfterContentInit() {
    this.sidenav()._animationEnd.subscribe((ev: any) => {
      this.service.onSidenavAnimationDone(ev?.toState);
    });
  }

  ngAfterViewInit(): void {
    this.service.setSidenav(this.sidenav(), this.id());
  }

  ngOnDestroy(): void {
    this.service.unloadSidenav(this.id());
  }

  public close() {
    this.service?.close(this.id());
  }
}
