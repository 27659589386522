import {
  Component,
  OnInit,
  inject,
  input,
  output,
  viewChild,
  contentChildren
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewDialogComponent } from '../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { MatAccordionDisplayMode } from '@angular/material/expansion';
import { CdkAccordion } from '@angular/cdk/accordion';
import { FileListItemComponent } from './file-list-item/file-list-item.component';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'exl-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
    animations: [
        trigger('scaleInOut', [
            transition(':enter', [
                style({
                    transform: 'scale(.8)',
                    opacity: 0,
                }),
                animate(
                    '600ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    style({
                        transform: 'scale(1)',
                        opacity: 1,
                    }),
                ),
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1)',
                    height: '*',
                    opacity: 1,
                }),
                animate(
                    '300ms cubic-bezier(0.895, 0.03, 0.685, 0.22)',
                    style({
                        transform: 'scale(.8)',
                        height: 0,
                        opacity: 0,
                    }),
                ),
            ]),
        ]),
    ],
    imports: [MatButton, MatIcon, CdkAccordion, CustomTranslatePipe],
})
export class FileListComponent implements OnInit {
    filePreviewDialog = inject(MatDialog);

    readonly listTitle = input<string>(undefined);
    readonly expandable = input<string>(undefined);
    readonly fileUploadProgress = input<boolean>(undefined);
    readonly expandAllLabel = input<string>(undefined);
    readonly collapseAllLabel = input<string>(undefined);
    readonly minimal = input<boolean>(undefined);

    readonly expandAllRows = input<boolean>(undefined);
    readonly expandAll = output();
    readonly collapseAll = output();
    readonly _fileList = viewChild<CdkAccordion>('fileList');
    readonly fileItems = contentChildren(FileListItemComponent);
    multiOpen = true;
    displayMode: MatAccordionDisplayMode = 'default';
    readonly removeItem = output<any>();
    readonly removeAll = output<any>();

    get isAllExpanded() {
        return !this.fileItems().some((_) => !_.expanded);
    }
    get itemIds(): string[] {
        return this.fileItems().map((_) => _._id);
    }
    ngOnInit() {
        if (this.expandAllRows()) {
            this.onExpandCollapseAll(true);
        }
    }

    onExpandCollapseAll(setTo = !this.isAllExpanded) {
        if (setTo === true) {
            this.fileItems().forEach((_) => _.open());
            this.expandAll.emit();
        } else {
            this.fileItems().forEach((_) => _.close());
            this.collapseAll.emit();
        }
    }

    onRemoveFileClick(fileName, index) {
        // event.preventDefault();
        // event.stopPropagation();
        this.removeItem.emit({ index: index, fileName: fileName });
    }

    onPreviewClick(event, file) {
        event.preventDefault();
        event.stopPropagation();
        this.filePreviewDialog.open(FilePreviewDialogComponent, {
            data: {
                file: file,
            },
        });
    }

    onRemoveAll(event) {
        this.removeAll.emit(event);
    }
}
