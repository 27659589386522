import { Directive, HostListener, inject } from '@angular/core';
import {LogoutService} from './logout.service';

@Directive({
    selector: '[espLogout]',
    standalone: true
})

export class LogoutDirective {
    private logoutService = inject(LogoutService);


    @HostListener('click', ['$event']) clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this.logoutService.logout();
    }

}

