<button
  mat-icon-button
  class="is-square"
  (click)="onClick()"
  [attr.aria-label]="isClosed ? openText() : closeText()"
  [attr.aria-expanded]="!isClosed"
>
  <mat-icon
    [svgIcon]="isClosed ? openIcon() : closeIcon()"
    muloSvgViewbox
  ></mat-icon>
</button>
