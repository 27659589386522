import {
  AfterViewInit,
  Component,
  ComponentRef,
  ContentChildren,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  inject,
  input,
  output,
  viewChild
} from '@angular/core';
import {
  DragDrop,
  DragDropModule,
  DragRef,
  DropListRef,
  Point,
} from '@angular/cdk/drag-drop';
import {
  AriaDescriber,
  FocusableOption,
  FocusKeyManager,
  FocusMonitor,
} from '@angular/cdk/a11y';
import { MatChipListbox, MatChipSet } from '@angular/material/chips';
import { merge, takeUntil, tap } from 'rxjs';
import { MatSelectionList } from '@angular/material/list';
import { componentDestroyed } from '@exl-ng/mulo-core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'mulo-drag-wrapper',
  template: ` <ng-container *ngTemplateOutlet="template"></ng-container>`,
  styles: [
    `
      :host {
        display: contents;
        flex-direction: inherit;
        flex-wrap: inherit;
      }
    `,
  ],
  imports: [NgTemplateOutlet, DragDropModule],
})
export class DragWrapperComponent implements AfterViewInit {
  elementRef = inject(ElementRef);

  readonly vcr = viewChild(TemplateRef, { read: ViewContainerRef });
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() template: TemplateRef<any>;
  public innerElem;

  ngAfterViewInit() {
    this.innerElem = (
      this.vcr().get(0) as EmbeddedViewRef<ElementRef>
    ).rootNodes[0];
  }
}

@Directive({
  selector: '[muloDrag]',
  standalone: true,
})
export class DragDirective implements FocusableOption, AfterViewInit, OnInit {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private vcr = inject(ViewContainerRef);
  private renderer = inject(Renderer2);

  readonly condition = input(true, { alias: 'muloDrag' });
  public dragWrap: ComponentRef<DragWrapperComponent>;
  public elemActual;
  private isMatChip = false;
  index: number;

  ngOnInit() {
    const evr = this.vcr.createEmbeddedView(this.templateRef);
    if (evr.rootNodes[0].tagName.toLowerCase().includes('mat-chip')) {
      this.isMatChip = true;
      this.vcr.clear();
      this.dragWrap = this.vcr.createComponent(DragWrapperComponent);
      this.dragWrap.instance.template = this.templateRef;
    }
  }

  ngAfterViewInit() {
    if (this.isMatChip) {
      this.elemActual = this.dragWrap.instance.innerElem;
    } else {
      this.elemActual = (
        this.vcr.get(0) as EmbeddedViewRef<ElementRef>
      ).rootNodes[0];
    }
  }

  focus = () => this.elemActual.focus();

  setItemTabindex = (val) =>
    this.renderer.setAttribute(this.elemActual, 'tabindex', val);
}

@Directive({
  selector: '[muloDropList]',
  host: {
    class: 'cdk-drop-list',
  },
  standalone: true,
})
export class DropListDirective implements AfterViewInit, OnInit, OnDestroy {
  private dd = inject(DragDrop);
  private elem = inject(ElementRef);
  private renderer = inject(Renderer2);
  private focusMonitor = inject(FocusMonitor);
  private matChipList = inject(MatChipSet, { optional: true });
  private matSelectionList = inject(MatSelectionList, { optional: true });
  private ariaDescriber = inject(AriaDescriber);

  readonly muloDropListEditClass = input('mulo-a11y-list-in-edit');
  readonly muloDropListMoveClass = input('mulo-a11y-item-in-transit');
  readonly muloDropListFocusClass = input('mulo-a11y-item-in-focus');
  readonly muloDropListDragClass = input<string | null>(null);

  /**
   * The WIA-ARIA role description
   *
   * @see {@link https://www.w3.org/TR/wai-aria-1.2/#aria-roledescription}
   */
  readonly muloDropListA11yRoleDesc = input('reorderable list');
  @HostBinding('attr.aria-roledescription') _a11yRoleDesc;

  readonly muloDropListA11yDesc = input(
    'Press space bar to toggle drag/drop, use the arrow keys to move selected item.',
  );

  readonly muloDropListOrientation = input<'vertical' | 'horizontal'>(
    undefined,
  );
  readonly muloDropListDirection = input<'ltr' | 'rtl'>('ltr');

  /**
   * @ignore
   */
  // TODO: Skipped for migration because:
  //  There are references to this query that cannot be migrated automatically.
  @ContentChildren(DragDirective, { descendants: true })
  items: QueryList<DragDirective>;

  @HostBinding('attr.tabindex') _tabindex = 0;

  /**
   * Emits when the user drops an item inside the container.
   */
  readonly dropped = output<{
    item: DragRef;
    currentIndex: number;
    previousIndex: number;
    container: DropListRef;
    previousContainer: DropListRef;
    isPointerOverContainer?: boolean;
    distance?: Point;
  }>({ alias: 'muloDropListDropped' });

  /**
   * Emits when the user has moved a new drag item into this container.
   */
  readonly entered = output<{
    item: DragRef;
    container: DropListRef;
    currentIndex: number;
  }>({ alias: 'muloDropListEntered' });

  /**
   * Emits when the user removes an item from the container by dragging it
   * into another container.
   */
  readonly exited = output<{
    item: DragRef;
    container: DropListRef;
  }>({ alias: 'muloDropListExited' });

  /**
   * Emits as the user is swapping items while actively dragging.
   */
  readonly sorted = output<{
    previousIndex: number;
    currentIndex: number;
    container: DropListRef;
    item: DragRef;
  }>({ alias: 'muloDropListSorted' });

  /**
   * Emits when the user drops an item inside the container.
   */
  readonly a11yEditing = output<boolean>({ alias: 'muloDropListA11yEditing' });

  /**
   * @ignore
   */
  cdkDragClass = 'cdk-drop-list-dragging';

  /**
   * @ignore
   */
  private dropLists: DropListRef[] = [];
  /**
   * @ignore
   */
  private drags: DragRef[] = [];
  /**
   * @ignore
   */
  private dragItems: DragDirective[] = [];

  /**
   * @ignore
   */
  private keyManager: FocusKeyManager<DragDirective>;
  /**
   * @ignore
   */
  private readonly isMatChipList: boolean;
  /**
   * @ignore
   */
  private readonly isMatSelectionList: boolean;
  /**
   * @ignore
   */
  private activeItemIndex = -1;
  /**
   * @ignore
   */
  private pickedItem = 0;
  /**
   * @ignore
   */
  private lastEntered = 0;
  /**
   * @ignore
   */
  private sortBuffer: 1 | 0 = null;
  /**
   * @ignore
   */
  private dragging = false;
  private focusInItem = false;

  constructor() {
    const matChipList = this.matChipList;
    const matSelectionList = this.matSelectionList;

    this.isMatChipList = matChipList != null;
    this.isMatSelectionList = matSelectionList != null;

    // if selection list, set it to not allow multiple selections (has to happen before list init)
    if (matSelectionList) {
      this.matSelectionList.multiple = false;
    }
  }

  /**
   * @ignore
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _editing = false;

  get editing(): boolean {
    return this._editing;
  }

  set editing(editing: boolean) {
    const el = this.elem?.nativeElement;

    if (editing) {
      this.renderer.addClass(el, this.muloDropListEditClass());
      this.setItemClass(this.activeItemIndex, 'active');
    } else {
      this.renderer.removeClass(el, this.muloDropListEditClass());
      this.setItemClass(this.activeItemIndex, 'focus');
    }

    if (editing !== this._editing) {
      this.a11yEditing.emit(editing);
    }
    this._editing = editing;
  }

  /**
   * @ignore
   */
  @HostListener('focusout', ['$event'])
  onFocusOut(ev: FocusEvent) {
    if (
      !this.editing &&
      (!ev.relatedTarget || (this.isMatChipList && !this.matChipList.focused))
    ) {
      // disable edit mode on tabOut for matChipList
      this.onListBlur();
    }
  }

  /**
   * @ignore
   */
  @HostListener('focus', ['$event'])
  onContainerFocus() {
    if (!this.isMatChipList) {
      // as soon as the container gets focus, we want to redirect it
      // MatChipList handles this on its own, so it's not needed there
      this.keyManager.setFirstItemActive();
    } else {
      this.matChipList.focus();
    }
  }

  /**
   * @ignore
   */
  @HostListener('keydown', ['$event'])
  onKeydown(ev) {
    if (!this.isMatChipList && this.focusInItem) {
      this.keyManager.onKeydown(ev);
    }

    let direction: -1 | 1 = 1;
    switch (ev.code) {
      case 'ArrowUp':
      case 'ArrowLeft':
        direction = -1;
      // eslint-disable-next-line no-fallthrough
      case 'ArrowDown':
      case 'ArrowRight':
        if (this.editing) {
          const oldIdx =
            (this.activeItemIndex + (this.items.length - direction)) %
            this.items.length;

          this.setItemClass(this.activeItemIndex, 'active');

          if (this.isMatChipList) {
            this.dropped.emit({
              item: this.drags[this.activeItemIndex],
              container: this.dropLists[this.activeItemIndex],
              previousContainer: this.dropLists[oldIdx],
              previousIndex: oldIdx,
              currentIndex: this.activeItemIndex,
            });
          } else {
            this.dropped.emit({
              item: this.drags[this.activeItemIndex],
              container: this.dropLists[0],
              previousContainer: this.dropLists[0],
              previousIndex: oldIdx,
              currentIndex: this.activeItemIndex,
            });
          }
        } else {
          this.setItemClass(this.activeItemIndex, 'focus');
        }
        ev.preventDefault();
        break;
      case 'Space':
        if (this.activeItemIndex !== -1) {
          this.editing = !this.editing;
        }
        ev.preventDefault();
      // eslint-disable-next-line no-fallthrough
      case 'Enter':
      case 'NumpadEnter':
        ev.target.dispatchEvent(new MouseEvent('click', { ...ev }));
        break;
    }
  }

  /**
   * @ignore
   */
  setListTabindex = (val) =>
    this.renderer.setAttribute(this.elem.nativeElement, 'tabindex', val);

  /**
   * @ignore
   */
  ngOnInit() {
    if (this.items?.length > 0) {
      this.ariaDescriber.describe(
        this.elem?.nativeElement,
        this.muloDropListA11yDesc(),
      );
      this._a11yRoleDesc = this.muloDropListA11yRoleDesc();
    }
  }

  /**
   * @ignore
   */
  ngAfterViewInit() {
    this.focusMonitor
      .monitor(this.elem, true)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((origin) => {
        if (origin == null && !this.editing) {
          this.activeItemIndex = -1;
          if (this.keyManager) {
            this.keyManager.updateActiveItem(-1);
          }
          this.renderer.removeClass(
            this.elem.nativeElement,
            this.muloDropListEditClass(),
          );
          this.setListTabindex('0');
        } else {
          this.renderer.addClass(
            this.elem.nativeElement,
            this.muloDropListEditClass(),
          );
          this.setListTabindex('-1');
        }
      });

    // Initialize drag directive to each list item
    this.items
      .filter((_) => _.condition())
      .forEach((item, idx) => {
        item.index = idx;
        this.initDrag(item);
      });

    // Subscribe to add/remove of list items
    this.items.changes
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((items) => {
        const filteredItems = items.filter((_) => _.condition);
        filteredItems.forEach((item, idx) => (item.index = idx));

        if (filteredItems.length > this.dragItems.length) {
          // add
          filteredItems.forEach((item) => {
            if (!this.dragItems.includes(item)) {
              this.initDrag(item);
            }
          });
        } else if (filteredItems.length < this.dragItems.length) {
          // remove
          const toKeep: DragDirective[] = [];
          this.dragItems.forEach((dragItem, idx) => {
            if (filteredItems.toArray().includes(dragItem)) {
              toKeep.push(dragItem);
            } else {
              this.drags.splice(idx, 1);
              if (this.isMatChipList) {
                this.dropLists.splice(idx, 1);
              }
            }
          });
          this.dragItems = toKeep;
        } else if (this.editing) {
          this.keyManager.setActiveItem(this.activeItemIndex);
          this.setItemClass(this.activeItemIndex, 'active');
        }

        this.dropLists.forEach((list) =>
          this.isMatChipList
            ? list.connectedTo(this.dropLists)
            : list.withItems(this.drags),
        );
        if (filteredItems?.length > 0) {
          this.ariaDescriber.describe(
            this.elem?.nativeElement,
            this.muloDropListA11yDesc(),
          );
          this._a11yRoleDesc = this.muloDropListA11yRoleDesc();
        } else {
          this.ariaDescriber.removeDescription(
            this.elem?.nativeElement,
            this.muloDropListA11yDesc(),
          );
          this._a11yRoleDesc = undefined;
        }
      });

    if (this.isMatChipList) {
      // When the list is a chip list
      this.dropLists.forEach((list) => {
        list.connectedTo(this.dropLists);
      });
    } else {
      // When the list is classic vertical / horizontal
      const dropList = this.dd.createDropList(this.elem).withItems(this.drags);

      merge(
        dropList.dropped.pipe(tap((ev) => this.dropped.emit(ev))),
        dropList.entered.pipe(tap((ev) => this.entered.emit(ev))),
        dropList.exited.pipe(tap((ev) => this.exited.emit(ev))),
        dropList.sorted.pipe(tap((ev) => this.sorted.emit(ev))),
      )
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();

      this.dropLists.push(dropList);
    }

    // If the list is mat-chips list, subscribe to its existing focus state
    if (this.isMatChipList) {
      const listbox = this.matChipList as MatChipListbox;
      if (listbox.selectable) {
        listbox.selectable = false;
      }
      this.matChipList.chipFocusChanges
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((ev) => {
          this.matChipList._chips.forEach((chip, idx) => {
            if (ev.chip === chip) {
              this.activeItemIndex = idx;
            }
          });
        });
    } else {
      // For all other lists, create a new key manager and subscribe to it
      this.keyManager = new FocusKeyManager(this.items)
        .withAllowedModifierKeys(['shiftKey'])
        .withHorizontalOrientation(this.muloDropListDirection())
        .withWrap();

      merge(
        this.keyManager.change.pipe(
          tap((idx) => {
            this.activeItemIndex = idx;
            this.setItemClass(idx, this._editing ? 'active' : 'focus');
          }),
        ),
        this.keyManager.tabOut.pipe(tap(() => this.onListBlur())),
      )
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();
    }
  }

  /**
   * This is required here, even as empty, to trigger all unsubscriptions
   *
   * @ignore
   */
  ngOnDestroy() {}

  /**
   * @ignore
   */
  initDrag(item: DragDirective) {
    this.dragItems.push(item);
    const drag = this.dd.createDrag(item.elemActual);
    this.drags.push(drag);
    item.setItemTabindex('0');
    this.renderer.addClass(item.elemActual, 'cdk-drag');

    if (this.isMatChipList) {
      this.renderer.addClass(item.dragWrap.instance.elementRef, 'cdk-drag');
      const dropList = this.dd.createDropList(
        item.dragWrap.instance.elementRef,
      );
      dropList.data = item.index;

      merge(
        dropList.dropped.pipe(tap((ev) => this.chipDrop(ev))),
        dropList.entered.pipe(tap(() => this.chipEntered(item.index))),
        dropList.sorted.pipe(tap((ev) => this.chipSorted(ev, item.index))),
        drag.started.pipe(tap(() => this.chipStarted(item.index))),
        drag.released.pipe(tap(() => this.chipReleased())),
      )
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();

      dropList
        .withItems([drag])
        .withOrientation(this.muloDropListOrientation())
        .withDirection(this.muloDropListDirection());

      this.dropLists.push(dropList);
    } else {
      merge(
        drag.started.pipe(tap(() => this.started())),
        drag.released.pipe(tap(() => this.released())),
      )
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();

      this.focusMonitor
        .monitor(item.elemActual, false)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe((origin) => {
          if (this.editing) {
            this.focusInItem = true;
          } else if (origin == null) {
            this.focusInItem = false;
            // item.setItemTabindex('-1');
          } else {
            this.focusInItem = true;
            this.activeItemIndex = item.index;
            if (this.keyManager) {
              this.keyManager.updateActiveItem(item.index);
            }
            // item.setItemTabindex('0');
          }
        });
    }
  }

  /**
   * @ignore
   */
  onListBlur() {
    this.activeItemIndex = -1;
    this.editing = false;
    if (this.keyManager) {
      this.keyManager.updateActiveItem(-1);
    }
    this.renderer.removeClass(
      this.elem.nativeElement,
      this.muloDropListEditClass(),
    );
  }

  /**
   * @ignore
   */
  setItemClass(idx: number, mode?: 'active' | 'focus') {
    if (this.isMatSelectionList) {
      this.matSelectionList.deselectAll();
    }

    let nextItemElem;
    this.items.forEach((item, i) => {
      this.renderer.removeClass(item.elemActual, this.muloDropListFocusClass());
      this.renderer.removeClass(item.elemActual, this.muloDropListMoveClass());
      if (i === idx) {
        nextItemElem = item.elemActual;
      }
    });

    if (idx >= 0) {
      if (mode) {
        this.renderer.addClass(nextItemElem, this.muloDropListFocusClass());
        if (mode === 'active') {
          this.renderer.addClass(nextItemElem, this.muloDropListMoveClass());
          if (this.isMatSelectionList) {
            this.matSelectionList.options.toArray()[idx].selected = true;
          }
        }
      }
    }
  }

  /**
   * @ignore
   */
  chipDrop(ev) {
    if (ev.previousContainer !== ev.container) {
      this.dropped.emit({
        ...ev,
        previousIndex: this.pickedItem,
        currentIndex: this.activeItemIndex,
      });
      this.reset();
    }
  }

  /**
   * @ignore
   */
  chipEntered(idx: number) {
    // this guard handles moving to a neighboring container
    // (the placeholder doesn't move so we don't want to confuse users)
    // sortBuffer check is to handle flinging (sortBuffer stays null)
    if (this.sortBuffer === null || Math.abs(this.activeItemIndex - idx) > 1) {
      this.activeItemIndex = idx;
    }
    this.lastEntered = idx;
  }

  /**
   * @ignore
   */
  chipSorted(ev, idx) {
    if (ev.currentIndex === ev.previousIndex) {
      // put pickedItem calculation here too to avoid bug when grabbing remove btn
      this.pickedItem = idx;
      this.activeItemIndex = idx;
    } else {
      // when we sort: if we're moving forward in the array, we have 1 too many
      this.sortBuffer = this.lastEntered > this.pickedItem ? 0 : 1;
      this.activeItemIndex = idx - ev.previousIndex + this.sortBuffer;
    }
  }

  /**
   * @ignore
   */
  chipStarted(idx: number) {
    this.toggleListDragClasses(true);
    this.dragging = true;
    this.pickedItem = idx;
    this.activeItemIndex = idx;
  }

  /**
   * @ignore
   */
  chipReleased() {
    this.toggleListDragClasses(false);
    this.dragging = false;
  }

  /**
   * @ignore
   */
  reset() {
    this.activeItemIndex = 0;
    this.pickedItem = null;
  }

  /**
   * @ignore
   */
  started() {
    this.toggleListDragClasses(true);
  }

  /**
   * @ignore
   */
  released() {
    this.toggleListDragClasses(false);
  }

  /**
   * @ignore
   */
  toggleListDragClasses(val: boolean) {
    const state = val ? 'add' : 'remove';
    this.setClass(state, this.elem.nativeElement, this.cdkDragClass);
    const muloDropListDragClass = this.muloDropListDragClass();
    if (muloDropListDragClass) {
      this.setClass(state, this.elem.nativeElement, muloDropListDragClass);
    }
  }

  /**
   * @ignore
   */
  setClass(state: 'add' | 'remove', el, cls) {
    return state === 'add'
      ? this.renderer.addClass(el, cls)
      : this.renderer.removeClass(el, cls);
  }
}
