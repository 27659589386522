import { AriaDescriber } from '@angular/cdk/a11y';
import { Directive, ElementRef, NgModule, OnInit, inject, input } from '@angular/core';

@Directive({
    selector: '[mulo-aria-describe]',
    standalone: true,
})
export class AriaDescriberDirective implements OnInit {
    private el = inject<ElementRef<HTMLElement>>(ElementRef);
    private ariaDescriber = inject(AriaDescriber);

    readonly ariaDescribe = input<string>(undefined, { alias: "mulo-aria-describe" });
  
    ngOnInit(): void {
      this.ariaDescriber.describe(this.el?.nativeElement, this.ariaDescribe());
    }
}


