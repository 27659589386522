import { Component, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {SortUtils} from '../../../shared/utils/sort.utils';
import { MatButton } from '@angular/material/button';
import { LanguageSelectComponent } from '../language-select/language-select.component';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'exl-language-select-dialog',
    templateUrl: './language-select-dialog.component.html',
    styleUrls: ['./language-select-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, LanguageSelectComponent, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class LanguageSelectDialogComponent implements OnInit {
    data = inject(MAT_DIALOG_DATA);
    private translate = inject(TranslateService);


    languages;
    selectedLanguage;

    constructor() {
        this.languages = this.data.languages;
        this.selectedLanguage = this.data.selectedLanguage;
    }

    ngOnInit() {
        this.sortLanguages();
    }

    sortLanguages(){
        this.translate.get(this.languages).subscribe((res: Object) => {
            const codeDescriptionEntries = Object.entries(res);
            codeDescriptionEntries.sort(SortUtils.labelSort);
            this.languages = codeDescriptionEntries.map((lang) => {
                return lang[0];
            });
        });
    }

}
