import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../shared/utils/url.utils';


@Injectable({
    providedIn: 'root'
})
export class ExternalOrganizationService {
    private http = inject(HttpClient);


    getExternalOrganizationAutoComplete(title) {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode)
            .set('title', title);
        return this.http.get(RestApiBase.EXTERNAL_AFFILIATION, {params:params});
    }

    addExternalOrganization(title: string, organizationType: string) {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode)
            .set('title', title).set('organizationType', organizationType);
        return this.http.get(RestApiBase.ADD_EXTERNAL_AFFILIATION, {params:params});
    }



}
