import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  contentChildren
} from '@angular/core';
import { KeywordItemComponent } from './keyword-item/keyword-item.component';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'mulo-keywords',
    templateUrl: './keywords.component.html',
    styleUrls: ['./keywords.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-keywords',
        '[class.as-separators]': 'this.flavor() === "separators"',
        '[class.as-blocks]': 'this.flavor() === "blocks"',
        '[class.as-tags]': 'this.flavor() === "tags"',
    },
    imports: [
        MatIcon,
        SvgViewboxDirective
    ]
})
export class KeywordsComponent implements AfterContentInit {
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() icon: string | 'none' = 'tag-multiple';
  readonly rtlMirrorIcon = input<boolean>(undefined);
  readonly flavor = input<'blocks' | 'separators' | 'tags'>('separators');

  readonly items = contentChildren(KeywordItemComponent);

  constructor() {
    const flavor = this.flavor();
    if (flavor === 'blocks' || flavor === 'tags') {
      this.icon = null;
    }
  }

  ngAfterContentInit() {
    this.items().forEach((keyword) => {
      const flavor = this.flavor();
      if (flavor === 'tags') {
        keyword.showIcon = true;
      }

      if (flavor === 'blocks') {
        keyword.setFlavor('block');
      }
    });
  }

  showIcon() {
    return this.flavor() !== 'tags';
  }
}
