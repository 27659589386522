<div
  class="section-anchor"
  [attr.id]="slug()"
  [attr.aria-describedby]="title() ? 'section-title-' + slug() : null"
></div>

@if (imageFullWidth() && title()) {
  <h2 id="{{ 'section-title-' + slug() }}" class="section-title">
    {{ title() }}
    @if (required()) {
      <span class="mark-required">
        <mat-icon svgIcon="asterisk"></mat-icon>
        @if (requiredText()) {
          <span>{{ requiredText() }}</span>
        }
      </span>
    }
  </h2>
}
@if (imageSrc()) {
  <img
    class="section-img"
    [class.img-on-right]="imageOnRight()"
    [class.full-width]="imageFullWidth()"
    [src]="imageSrc()"
    [alt]="imageAlt()"
  />
}
@if (!imageFullWidth()) {
  <div class="section-content">
    @if (title()) {
      <h2 id="{{ 'section-title-' + slug() }}" class="section-title">
        {{ title() }}
        @if (required()) {
          <span class="mark-required">
            <mat-icon svgIcon="asterisk"></mat-icon>
            @if (requiredText()) {
              <span>{{ requiredText() }}</span>
            }
          </span>
        }
      </h2>
    }
    @if (description()) {
      <div class="section-description">
        <span [innerHTML]="description()"></span>
      </div>
    }

    <ng-content></ng-content>
  </div>
}
<div class="page-highlight-overlay" [class.is-active]="highlightActive()">
  <div matRipple #ripple></div>
</div>
