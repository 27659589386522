import {
  Component,
  computed,
  contentChildren,
  Directive,
  effect,
  ElementRef,
  HostBinding,
  input,
  model,
  OnInit,
  signal,
  TemplateRef,
  viewChild,
  inject,
  output
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { tap } from 'rxjs';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatOption,
  MatOptionSelectionChange,
  ThemePalette,
} from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MediaService, SvgViewboxDirective } from '@exl-ng/mulo-core';

import { SearchQuery } from '../../models';
import { randomString } from '../../utils';
import { SearchbarOptionsComponent } from './searchbar-options/searchbar-options.component';
import {
  HeightInAnimation,
  HeightOutAnimation,
  OpacityInAnimation,
  OpacityOutAnimation,
} from '../../animations';
import { SearchbarAdvRootDirective } from './searchbar-adv-root.directive';
import {
  FloatLabelType,
  MatFormField,
  MatFormFieldAppearance,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { HighlightTextPipe } from '../../pipes';
import { MatCard } from '@angular/material/card';
import { AriaProgressBarDirective } from '../../directives';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface SelectOption {
  value: string;
  viewValue: string;
}
interface Selector {
  label: string;
  ariaLabel: string;
}
type SearchbarState = 'expanded' | 'collapsed';
type SearchbarSize = 'mini' | 'small' | 'normal' | 'large' | 'mega' | null;

@Directive({
  selector: '[muloSearchbarAutocompleteTemplate]',
  standalone: true,
})
export class SearchbarAutocompleteTemplateDirective {
  templateRef = inject<TemplateRef<any>>(TemplateRef);

  readonly muloSearchbarAutocompleteTemplate = input<'suggestions' | 'results' | 'scopes'>(undefined);
}

@Component({
    selector: 'mulo-searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss'],
    animations: [
        HeightInAnimation,
        HeightOutAnimation,
        OpacityInAnimation,
        OpacityOutAnimation,
    ],
    imports: [
        MatFormField,
        MatPrefix,
        MatLabel,
        MatInput,
        FormsModule,
        MatAutocompleteTrigger,
        ReactiveFormsModule,
        MatIconButton,
        MatSuffix,
        MatIcon,
        SvgViewboxDirective,
        MatProgressBar,
        AriaProgressBarDirective,
        MatButton,
        MatCard,
        MatAutocomplete,
        MatOption,
        NgTemplateOutlet,
        HighlightTextPipe,
    ]
})
export class SearchbarComponent implements OnInit {
  private focusMonitor = inject(FocusMonitor);
  private elRef = inject(ElementRef);
  media = inject(MediaService);

  /**
   * Unique ID for when there multiple concurrent instances of a searchbar in the DOM
   */
  id = input<string>(randomString());
  /**
   *  The placeholder text before typing
   */
  placeholder = input<string>();
  /**
   *  The label (if any) of the search (corresponds to Material API)
   */
  label = input<string>();
  /**
   * A floating label, when present (corresponds to Material API)
   */
  floatLabel = input<FloatLabelType>('auto');
  /**
   * Size of the searchbar
   */
  size = input<SearchbarSize>('normal');
  /**
   * Display the searchbar as outlined, or underlined
   */
  appearance = input<'outline' | 'underline'>('outline');
  /**
   * Show/hide the search button
   */
  searchButton = input(true);
  /**
   * Progress state (affects progress bar)
   */
  inProgress = input(false);
  /**
   * Show/hide the clear input button inside the input field
   */
  clearInputButton = input(true);
  clearInputButtonLabel = input('Clear input');
  /**
   * Set theme color of search elements (buttons, outline)
   */
  color = input<ThemePalette>('primary');
  /**
   * When searchbar is over dark background
   */
  overDark = input(false);
  /**
   * Set the search value from parent
   */
  inputValue = input<string>();
  private inputValueEffect = effect(() => {
    this.inputControl.setValue(this.inputValue(), { emitEvent: false });
  });

  /**
   * Customize the word that appears before the scope term (i.e "foo by bar")
   */
  autocompleteRelationWord = input('in');

  /**
   * Autocomplete result array
   */
  autocompleteResults = input<string[]>([]);

  autocompleteSuggestions = input<string[]>([]);
  autocompleteScopes = input<string[]>([]);
  autoCompleteSuggestionTemplate = input<TemplateRef<any>>;
  matAutocomplete = input<MatAutocomplete>();

  /**
   * get a hold of the autocomplete trigger, to be able to open/close it programmatically
   *
   * @internal
   */
  autocomplete = viewChild(MatAutocompleteTrigger);

  autocompleteTemplates = contentChildren(
    SearchbarAutocompleteTemplateDirective,
  );
  readonly autocompleteSelected = output<object>();
  autocompleteSearchBy = input<string[]>([]);

  /**
   * Input emitter; emit the changing value in the main input
   */
  readonly inputChanged = output<string>();

  /**
   * Search submit emitter
   */
  readonly searchSubmit = output<SearchQuery>();

  /**
   * emitter for clicks on the search trigger (placeholding button, mainly for small screen views)
   */
  readonly triggerClick = output<void>();

  /**
   * get a hold of the input
   */
  inputElement = viewChild(MatInput);
  /**
   * get a hold of the search submit button
   */
  submitButton = viewChild<MatButton>('submitButton');
  /**
   * get a hold of projected search options
   */
  options = contentChildren(SearchbarOptionsComponent);

  /**
   * Whether the host component is in focus or not, or any of its focusable children
   */
  @HostBinding('class.mulo-focused') public focused = true;

  collapseBtnLabel = input('Close');

  advSearchRoot = input<SearchbarAdvRootDirective>();
  advSearchLabel = input('Advanced Search');
  advSearchCloseAriaLabel = input('Close');
  advSearchSearchLabel = input('Search');
  advSearchStartOpen = model(false);

  private advSearchStartOpenEffect = effect(
    () => {
      const isOpen = this.advSearchStartOpen();
      const advSearchRoot = this.advSearchRoot();
      const advSearchTempl = this.advSearchTempl();
      if (isOpen) {
        if (advSearchTempl) {
          advSearchRoot?.openAdvSearch(advSearchTempl);
        }
      } else {
        advSearchRoot?.closeAdvSearch();
      }
    },
    { allowSignalWrites: true },
  );

  advSearchTempl = viewChild<TemplateRef<any>>('advSearch');
  advSearchDisableAnim = signal(true);
  /**
   * Text input form control
   *
   * @internal
   */
  inputControl = new UntypedFormControl('');

  /**
   * Observable monitoring focus on elements within the searchbar component
   */
  componentFocus$ = this.focusMonitor
    .monitor(this.elRef.nativeElement, true)
    .pipe(
      tap((source) => {
        this.focused = !!source;
        setTimeout(
          () => {
            if (this.size() === 'mini') {
              this.state.set(this.focused ? 'expanded' : 'collapsed');
            }
            // delay added to prevent sudden close/open when clicking on options
          },
          this.focused ? 0 : 300,
        );
      }),
      takeUntilDestroyed(),
    );

  emittingSubmitLock = signal(false);
  /**
   * Flags for presence of projected options
   */
  hasOptionsBefore = computed<boolean>(() =>
    this.options().some((o) => o.placement() === 'before'),
  );
  hasOptionsAfter = computed<boolean>(() =>
    this.options().some((o) => o.placement() === 'after'),
  );

  /**
   * Expand the searchbar when collapsed when available
   */
  expanding = false;

  /**
   * Searchbar focus event
   */
  focus = input<boolean>(null);
  private focusEffect = effect(
    () => {
      const focus = this.focus();
      const inputEl = this.inputElement();
      const submitBtn = this.submitButton();
      if (focus === true) {
        inputEl?.focus();
      } else if (focus === false && submitBtn) {
        submitBtn.focus();
      }
    },
    { allowSignalWrites: true },
  );
  /**
   * Set host classes according to features
   */
  @HostBinding('class') public get classList(): string {
    return [
      'mulo-searchbar',
      this.sizeClass(),
      this.labelClass(),
      this.searchButtonClass(),
      this.overDarkClass(),
    ]
      .join(' ')
      .trim();
  }
  @HostBinding('class.is-expanded') get isExpanded() {
    return this.state() === 'expanded';
  }
  @HostBinding('class.is-collapsed') get isCollapsed() {
    return this.state() === 'collapsed';
  }

  /**
   *  Applicable state, for use when space is limited, like small screens
   */
  state = model<SearchbarState>('collapsed');

  constructor() {
    this.componentFocus$.subscribe();
  }

  /**
   * Subscribe to main input value changes
   *
   * @internal
   */
  ngOnInit() {
    this.inputControl.valueChanges.subscribe(() => this.emitInput());

    // disable adv search open animation on init to prevent getting stuck
    setTimeout(() => this.advSearchDisableAnim.set(false), 500);
  }

  toggleAdvSearchOpen(toOpen: boolean) {
    const isOpen = this.advSearchStartOpen();
    if (toOpen !== isOpen) {
      this.advSearchStartOpen.set(toOpen);
    }
  }

  // Class selectors getters
  private sizeClass = computed(() => `is-${this.size()}-size`);

  private labelClass = computed(() => (this.label() ? '' : 'hasnt-label'));

  private searchButtonClass = computed(() =>
    this.searchButton() ? '' : 'hasnt-search-button',
  );

  private overDarkClass = computed(() => (this.overDark() ? 'is-on-dark' : ''));

  /**
   * Style of the entire form-field corresponds to Angular Material form field values
   */
  inputAppearance = computed<MatFormFieldAppearance>(() =>
    this.appearance() === 'underline' ? 'fill' : 'outline',
  );
  /**
   * @internal
   */
  clearInput() {
    this.inputControl.setValue('');
    this.inputControl.reset();
  }

  /**
   * @internal
   */
  submitSearch(event?) {
    if (event instanceof MatOptionSelectionChange) {
      this.emittingSubmitLock.set(true);
      this.emitSubmit(event.source.value);
      const value = event.source.value.scope
        ? this.inputControl.value
        : Object.values(event.source.value)[0];
      this.inputControl.setValue(value, { emitEvent: false });
      setTimeout(() => {
        this.emittingSubmitLock.set(false);
      }, 300);
    } else if (event instanceof MouseEvent || event instanceof KeyboardEvent) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.emittingSubmitLock()) {
        this.emitSubmit({ query: this.inputControl.value });
      }
    }

    this.autocomplete().closePanel();
  }

  /**
   * @internal
   */
  emitInput() {
    this.inputChanged.emit(this.inputControl.value);
  }

  /**
   * @internal
   */
  emitSubmit(value?) {
    this.searchSubmit.emit(value ? value : this.inputControl.value);
  }

  autocompleteTemplate(name) {
    return this.autocompleteTemplates().find(
      (t) => t.muloSearchbarAutocompleteTemplate() === name,
    );
  }

  assessedScopes = computed(() => {
    return this.autocompleteSearchBy()?.length > 0
      ? this.autocompleteSearchBy()
      : this.autocompleteScopes();
  });
}
