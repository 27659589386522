import { Directive, NgModule, HostBinding, input } from '@angular/core';

@Directive({
    selector: '[hideOnPrint]',
    host: { '[class.mulo-hide-on-print]': '_hideOnPrint' },
    standalone: true,
})
export class HideOnPrintDirective {

  readonly hideOnPrint = input<boolean>(true);
  _hideOnPrint: boolean = true

  ngOnChanges(changes){
    if (!(changes && changes.hideOnPrint)) return
    if (typeof changes.hideOnPrint.currentValue === 'boolean') {
      this._hideOnPrint = changes.hideOnPrint.currentValue
    } else {
      this._hideOnPrint = true
    }
  }
}
@Directive({
    selector: '[showOnlyOnPrint]',
    host: { class: 'mulo-show-only-on-print' },
    standalone: true,
})
export class ShowOnlyOnPrintDirective {}
@Directive({
    selector: '[keepOnPrint]',
    host: { class: 'mulo-keep-on-print' },
    standalone: true,
})
export class KeepOnPrintDirective {}


