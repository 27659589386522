import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    inject,
    input,
    viewChild,
} from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormControl,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MatAutocompleteTrigger,
    MatAutocomplete,
} from '@angular/material/autocomplete';
import { DepositKeywordsService } from './deposit-keywords.service';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { ResearchTopic } from '../../../shared/interfaces/research-topic.intreface';
import {
    SelectedResearchTopic,
    TopicType,
} from '../../../shared/interfaces/selected-research-topic.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DepositKeywordsSuggestionsService } from './deposit-keywords-suggestions.service';
import { Subject, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs';
import {
    WidthInAnimation,
    WidthOutAnimation,
    InputExpanderDirective,
    AriaProgressBarDirective,
    ElementAsHeadingDirective,
} from '@exl-ng/mulo-common';
import { ErrorsTranslatePipe } from '../../../shared/pipes/errors-translate.pipe';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../../shared/pipes/highlight-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptgroup, MatOption } from '@angular/material/core';
import { EspMatAutocompleteA11yDirective } from '../../../shared/directives/a11y/mat-autocomplete-extension.directive';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FocusDirective } from '../../../shared/directives/focus/focus.directive';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';
import { MatTooltip } from '@angular/material/tooltip';
import {
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatChipInput,
} from '@angular/material/chips';

import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-keywords',
    templateUrl: './deposit-keywords.component.html',
    styleUrls: ['./deposit-keywords.component.scss'],
    animations: [WidthInAnimation, WidthOutAnimation],
    imports: [
        MatFormField,
        InputExpanderDirective,
        MatLabel,
        MatChipGrid,
        MatChipRow,
        MatTooltip,
        BadgeAutoPopulatedComponent,
        MatIconButton,
        MatChipRemove,
        MatIcon,
        MatInput,
        FormsModule,
        MatAutocompleteTrigger,
        MatChipInput,
        ReactiveFormsModule,
        FocusDirective,
        MatError,
        MatProgressBar,
        AriaProgressBarDirective,
        MatAutocomplete,
        EspMatAutocompleteA11yDirective,
        MatOptgroup,
        ElementAsHeadingDirective,
        MatOption,
        TranslateModule,
        HighlightFilterPipe,
        CustomTranslatePipe,
        ErrorsTranslatePipe,
    ],
})
export class DepositKeywordsComponent implements OnInit, OnDestroy {
    private depositKeywordsService = inject(DepositKeywordsService);
    depositFormDataService = inject(DepositFormDataService);
    private suggestionsService = inject(DepositKeywordsSuggestionsService);

    readonly chipInput = viewChild('chipInput', {
        read: MatAutocompleteTrigger,
    });
    readonly jump = input(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() label: string;
    readonly ariaLabel = input('Keywords', { alias: 'aria-label' });
    readonly required = input<boolean>(undefined);

    topics: ResearchTopic[];
    autoCompleteChipList: UntypedFormControl = new UntypedFormControl();
    removable = true;
    addOnBlur = true;
    noValuesFound = false;
    searchInProgress = false;
    filteredSuggestions: SelectedResearchTopic[];
    private topicsDestroy = new Subject<void>();

    trackByTopic = (index: number, topic: ResearchTopic) => topic.description;

    ngOnInit() {
        this.filteredSuggestions = this.suggestionsService.getSuggestions('');
        this.listenToSearchInput();

        if (this.required()) {
            // * This hack provide error for required array controls
            // without it no error will be display if the array is pristine
            this.depositFormDataService.topics.addValidators(
                Validators.required,
            );
            this.depositFormDataService.topics.setErrors({ required: true });
        }
    }

    listenToSearchInput() {
        this.autoCompleteChipList.valueChanges
            .pipe(
                debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntil(this.topicsDestroy),
            )
            .subscribe((value) => {
                this.noValuesFound = false;
                this.filteredSuggestions =
                    this.suggestionsService.getSuggestions(value);
                if (value && value.length >= DepositForm.MIN_INPUT_LENGTH) {
                    this.searchInProgress = true;
                    this.getResearchTopics(value);
                }
            });
    }

    getResearchTopics(value) {
        this.depositKeywordsService.getResearchTopics(value).subscribe(
            (data) => {
                this.searchInProgress = false;
                if (data) {
                    this.topics = data as ResearchTopic[];
                } else {
                    this.noDataFound();
                }
            },
            (error) => {
                this.searchInProgress = false;
                this.noDataFound();
            },
        );
    }

    get topicNamesArray() {
        return this.depositFormDataService.mainForm.value.topics.map(
            (subject: ResearchTopic) => {
                return subject.description;
            },
        );
    }

    noDataFound() {
        this.noValuesFound = true;
        this.topics = [];
    }

    getHierarchyAsString(hierarchy: string[]) {
        return hierarchy.join('/');
    }

    addSuggestionChip(topic: SelectedResearchTopic, input): void {
        const code = topic.code;
        const description = topic.description;
        this.insertChip(code, description, topic.hierarchy, topic.type);
        if (input) {
            input.value = '';
            this.autoCompleteChipList.reset();
            // setTimeout(() => {
            //     this.chipInput.openPanel();
            // }, 0);
        }
    }

    addChip(topic: ResearchTopic, input): void {
        let code = topic.code;
        let description = topic.description;
        if (topic.actualCode) {
            code = topic.actualCode;
            description = topic.actualDescription;
        }
        this.insertChip(
            code,
            description,
            this.getHierarchyAsString(topic.hierarchy),
            TopicType.SUBJECT,
        );
        if (input) {
            input.value = '';
            this.autoCompleteChipList.reset();
            // setTimeout(() => {
            //     this.chipInput.openPanel();
            // }, 0);
        }
    }

    isTopicExists(code, description) {
        for (const topic of this.depositFormDataService.topics.value) {
            if (topic.code === code && topic.description === description) {
                return true;
            }
        }
        return false;
    }

    insertChip(code, description, hierarchy, type) {
        this.chipInput().closePanel();
        if (!this.isTopicExists(code, description)) {
            const selectedTopic = new SelectedResearchTopic(
                code,
                description,
                hierarchy,
                type,
            );
            this.depositFormDataService.updateTopics(selectedTopic);
            if (type === TopicType.KEYWORD) {
                this.depositFormDataService.updateKeywords(description);
            } else if (type === TopicType.SUBJECT) {
                this.depositFormDataService.updateSubjects(code);
            }
        }
    }

    removeChip(topic: SelectedResearchTopic, index): void {
        this.depositFormDataService.removeTopic(index);
        if (topic.type === TopicType.KEYWORD) {
            this.removeTopicFromArray(
                this.depositFormDataService.keywords,
                topic.description,
                'KEYWORD',
            );
        } else if (topic.type === TopicType.SUBJECT) {
            this.removeTopicFromArray(
                this.depositFormDataService.subjects,
                topic.code,
                'SUBJECT',
            );
        }
    }

    removeTopicFromArray(array: UntypedFormArray, element, flag) {
        const i = array.value.indexOf(element);
        if (i > -1) {
            array.removeAt(i);
        }
        if (flag === 'KEYWORD') {
            this.depositFormDataService.keywordsAutoPopulated.splice(i, 1);
        }
    }

    onInputEnterKey(input: any): void {
        this.noValuesFound = false;
        const value = input.value;
        if (value) {
            this.insertChip('', value, '', TopicType.KEYWORD);
            input.value = '';
            this.autoCompleteChipList.reset();
            // setTimeout(() => {
            //     this.chipInput.openPanel();
            // }, 0);
        }
    }

    isAutoPopulated(keyword) {
        return (
            this.depositFormDataService.keywordsAutoPopulated.indexOf(
                keyword.description,
            ) !== -1
        );
    }

    ngOnDestroy() {
        if (!this.topicsDestroy.isStopped) {
            this.topicsDestroy.next();
            //unsubscribe from the subject itself
            this.topicsDestroy.unsubscribe();
        }
    }
}
