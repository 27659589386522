import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Directive, HostBinding, inject, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

type ProgressMode = 'determinate' | 'indeterminate' | 'buffer' | 'query';

@Directive({
  selector: 'mat-progress-bar[mode=indeterminate]',
  standalone: true,
})
export class AriaProgressBarDirective {
  private announcer = inject(LiveAnnouncer);
  private translate = inject(TranslateService);

  readonly ariaLabel = input<string>(undefined, { alias: "aria-label" });
  @HostBinding('attr.aria-busy') busy = 'true';

  constructor() {
    const speak =
      this.ariaLabel() ||
      this.translate.instant('research.aria.autocomplete.announce.searching');
    this.announcer.announce(speak);
  }
}

@Directive({
  selector: 'mat-progress-spinner[mode=indeterminate]',
  standalone: true,
})
export class AriaProgressSpinnerDirective {
  private announcer = inject(LiveAnnouncer);
  private translate = inject(TranslateService);

  readonly ariaLabel = input<string>(undefined, { alias: "aria-label" });
  @HostBinding('attr.aria-busy') busy = 'true';

  constructor() {
    const speak =
      this.ariaLabel() ||
      this.translate.instant('research.aria.autocomplete.announce.loading');
    this.announcer.announce(speak);
  }
}
