import { Component, OnInit, inject, input, output, viewChild } from '@angular/core';
import {
    AriaProgressBarDirective,
    GrowInAnimation,
    GrowOutAnimation,
    InputExpanderDirective,
} from '@exl-ng/mulo-common';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { SharedModule } from '../../../../shared/shared.module';
import { Grant } from '../../../../shared/interfaces/grant.model';
import { UntypedFormControl } from '@angular/forms';
import {
    debounceTime,
    distinctUntilChanged,
    Subject,
    takeUntil,
    tap,
} from 'rxjs';
import { SortUtils } from '../../../../shared/utils/sort.utils';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { DepositGrantsService } from '../deposit-grants.service';

@Component({
    selector: 'esp-deposit-grants-search',
    imports: [SharedModule, AriaProgressBarDirective, InputExpanderDirective],
    templateUrl: './deposit-grants-search.component.html',
    styleUrl: './deposit-grants-search.component.scss',
    animations: [GrowOutAnimation, GrowInAnimation]
})
export class DepositGrantsSearchComponent implements OnInit {
    private grantsSvc = inject(DepositGrantsService);

    readonly showAddBtn = input(false);
    readonly addBtnClick = output();
    readonly grantSelect = output();
    readonly grantInput = viewChild('grantInput', { read: MatAutocompleteTrigger });
    readonly addNewBtn = viewChild<MatButton>('addNewBtn');

    grantsFromPivot: Grant[] = [];
    suggestedGrants: Grant[] = [];
    allGrants: Grant[] = [];
    filteredGrants: Grant[] = [];
    filteredSuggestedGrant: Grant[] = [];

    searchTerm: UntypedFormControl = new UntypedFormControl();
    noResultsMessage: string;
    searchInProgress = false;
    private grantDestroy = new Subject<void>();

    ngOnInit() {
        this.grantsSvc.getAllGrants().subscribe((data) => {
            this.allGrants = data as Grant[];
            this.allGrants = this.allGrants.sort(SortUtils.grantSortByName);
            this.filteredGrants = this.allGrants;

            this.grantsSvc.getSuggestedGrants().subscribe((data) => {
                this.suggestedGrants = data as Grant[];
                this.suggestedGrants = this.suggestedGrants.sort(
                    SortUtils.grantSortByName,
                );
                this.filteredSuggestedGrant = this.suggestedGrants;
                this.filteredGrants = this.allGrantsWithoutSuggested();
            });
        });

        this.listenToSearchInput();
    }

    allGrantsWithoutSuggested() {
        const suggestedGrantsIDs = this.suggestedGrants.map((grant) => {
            return grant.id;
        });
        return this.allGrants.filter(
            (grant) => suggestedGrantsIDs.indexOf(grant.id) === -1,
        );
    }

    listenToSearchInput() {
        this.searchTerm.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
                tap((_) => (this.searchInProgress = true)),
                takeUntil(this.grantDestroy),
            )
            .subscribe({
                next: (value) => {
                    this.filterSuggestedGrants(value);
                    this.filterGrants(value);
                    if (!value) {
                        this.autoCompleteReset();
                    }
                    if (value?.length >= DepositForm.MIN_INPUT_LENGTH) {
                        this.getGrantAutoCompleteFromPivot(value);
                    }
                },
            });
    }

    getGrantAutoCompleteFromPivot(value) {
        this.grantsSvc.searchExternalGrants(value.toLowerCase()).subscribe({
            next: (data) => {
                this.searchInProgress = false;
                if (data) {
                    this.grantsFromPivot = data as Grant[];
                    this.parseResults(
                        this.filteredGrants.length +
                            this.filteredSuggestedGrant.length +
                            this.grantsFromPivot.length,
                    );
                    this.grantsFromPivot = this.grantsFromPivot.filter(
                        (obj) =>
                            !this.allGrants
                                .map((grant) => grant.grantName)
                                .filter((obj) => Boolean(obj))
                                .some(
                                    (grant) =>
                                        grant.toLowerCase() ===
                                        obj.grantName.toLowerCase(),
                                ),
                    );
                } else {
                    this.parseResults(
                        this.filteredGrants.length +
                            this.filteredSuggestedGrant.length,
                    );
                    this.autoCompleteReset();
                }
            },
            error: (error) => {
                console.error(error);
                this.searchInProgress = false;
                this.parseResults(
                    this.filteredGrants.length +
                        this.filteredSuggestedGrant.length,
                );
                this.autoCompleteReset();
            },
        });
    }

    autoCompleteReset() {
        this.grantsFromPivot = [];
        this.searchInProgress = false;
    }

    filterGrants(text: string) {
        if (!text) {
            this.filteredGrants = this.allGrantsWithoutSuggested();
        } else {
            this.filteredGrants = this.allGrantsWithoutSuggested().filter(
                (obj) =>
                    obj.grantName
                        ?.toLowerCase()
                        .indexOf(text.toString().toLowerCase()) !== -1 ||
                    (obj.grantId &&
                        obj.grantId
                            ?.toLowerCase()
                            .indexOf(text.toString().toLowerCase()) !== -1),
            );
        }
    }

    filterSuggestedGrants(text: string) {
        if (!text) {
            this.filteredSuggestedGrant = this.suggestedGrants;
        } else {
            this.filteredSuggestedGrant = this.suggestedGrants.filter(
                (obj) =>
                    obj.grantName
                        .toLowerCase()
                        .indexOf(text.toString().toLowerCase()) !== -1 ||
                    (obj.grantId &&
                        obj.grantId
                            .toLowerCase()
                            .indexOf(text.toString().toLowerCase()) !== -1),
            );
        }
    }

    parseResults(responseLength: number) {
        this.noResultsMessage =
            responseLength > 0 ? '' : 'funding.lookup.empty.default.default';
    }

    grantSelectFinish = (grant) => {
        this.grantSelect.emit(grant);
        this.searchTerm.setValue(grant.grantName, { emitEvent: false });
        this.grantInput().closePanel();
    };

    handleGrantSelect(grant, event) {
        if (event.source.selected) {
            this.grantSelectFinish(grant);
        }
    }

    onPivotGrantSelect(grant, event) {
        if (event.source.selected) {
            this.searchTerm.setValue(grant.grantName, { emitEvent: false });
            this.grantsSvc
                .enrichAndAddGrantFromPivot(grant.grantName)
                .subscribe({
                    next: this.grantSelectFinish,
                    error: (e) => {
                        console.error(e);
                        this.grantInput().closePanel();
                        this.searchTerm.setErrors({
                            enrichment: 'funding.lookup.error.enrichment',
                        });
                    },
                });
        }
    }
}
