import {
  Component,
  HostBinding,
  HostListener,
  Input,
  input,
  output
} from '@angular/core';
import { Params } from '@angular/router';

export interface BriefActionItem {
    name: string;
    icon?: string;
    tooltip?: string;
}
export interface BriefMenuActionItem {
    name: string;
    divider?: string;
    disabled?: boolean;
}

@Component({
    selector: 'exl-brief-base',
    templateUrl: './brief-base.component.html',
    styleUrls: ['./brief-base.component.scss'],
    standalone: true,
})
export class BriefBaseComponent {
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.exl-citation-brief')
    @HostBinding('class.is-minimal')
    @Input()
    minimal = false;
    @HostBinding('class.is-indented') indentation = 0;
    @HostBinding('class.is-mouseover') mouseOver = false;
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.is-disabled') @Input() disabled = false;
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.is-hidden') @Input() hidden = false;
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.is-hoverable') @Input() hoverable = true;

    readonly type = input(undefined);
    readonly postType = input(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() doi;
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() title;
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() titleUrl;
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() authors: Array<any>;
    readonly publisher = input(undefined);
    readonly container = input(undefined);
    readonly subject = input<Array<any>>(undefined);
    readonly issue = input(undefined);
    readonly volume = input(undefined);
    readonly page = input(undefined);
    readonly date = input(undefined);
    readonly isbn = input(undefined);
    readonly abstract = input(undefined);
    readonly thumbs = input<Array<any>>(undefined);
    readonly thumbsWidth = input('50px');
    readonly lines = input<Array<any>>(undefined);
    readonly altmetric = input(false);
    readonly titleAsLink = input(false);
    readonly titleClickable = input<boolean>(undefined);
    readonly actions = input<BriefActionItem[]>(undefined);
    readonly menuActions = input<BriefMenuActionItem[]>(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() dateLine: DateLine;
    readonly highlighted = input<boolean>(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() images: Array<any>;
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() publication: string;
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() metadata: string;
    readonly status = input<string | Array<string>>(undefined);
    readonly created = input<Date>(undefined);
    readonly modified = input<Date>(undefined);
    readonly dateLabel = input<string>(undefined);
    readonly href = input<string>(undefined);
    readonly altmetricDelay = input<number>(undefined);
    readonly routerLinkUrl = input<string>(undefined, { alias: 'router' });
    readonly queryParams = input<Params>(undefined);

    highlightHover = false;

    readonly titleClick = output<any>();
    readonly actionClick = output<any>();
    readonly authorClick = output<any>();

    @HostListener('mouseover') onMouseEnter() {
        this.mouseOver = true;
    }
    @HostListener('mouseleave') onMouseLeave() {
        this.mouseOver = false;
    }

    constructor() {}

    onTitleClick(event) {
        event?.preventDefault();
        event?.stopPropagation();
        this.titleClick.emit(event);
    }

    onAction(action) {
        this.actionClick.emit(action);
    }

    onAuthorClick(event, author) {
        event.preventDefault
            ? event.preventDefault()
            : (event.returnValue = false);
        this.authorClick.emit(author);
    }

    onAltmetricCancel(event) {}

    get haveActionsOrMenuActions() {
        const actions = this.actions();
        const haveActions = actions && Object.keys(actions).length > 0;
        const menuActions = this.menuActions();
        const haveMenuActions =
            menuActions && Object.keys(menuActions).length > 0;
        return haveActions || haveMenuActions;
    }
}

export interface DateLine {
    code: string;
    value: string;
}
