import { DOCUMENT } from '@angular/common';
import { Directive, Renderer2, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * ! Accessibility
 *  This directive add more description to mat-clendar
 * It load for every datepicker in our project.
 */
@Directive({
    selector: 'mat-datepicker',
    host: { '(opened)': 'additionTodayLabel()' },
    standalone: true
})
export class AriaCalendarDirective {
    private translate = inject(TranslateService);
    private document = inject<Document>(DOCUMENT);
    private renderer = inject(Renderer2);


    /**
     * On calendar opened
     */
    additionTodayLabel() {
        setTimeout(() => { // delay for calendar elements placed in DOM
            // get the td element of today
            const todayCell = this.document.querySelector('.mat-calendar-body-today')?.parentElement;
            if (todayCell) {
                const todayDate = todayCell.getAttribute('aria-label');
                const newLabel = this.translate.instant('research.aria.date.calendar.label.today', {date: todayDate});
                this.renderer.setAttribute(todayCell, 'aria-label', newLabel);
            }
        }, 100);
    }
}
