import { Injectable, inject } from '@angular/core';
import { UrlUtils } from '../../shared/utils/url.utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JwtUtilService } from '../../core/jwt-util.service';
import { I18nService } from '../../core/i18n.service';
import { RestApiBase } from '../../shared/configurations/rest-api-base.config';
import { ClaimOutputList } from '../../shared/interfaces/claim-output-list.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClaimOutputsService {
    private http = inject(HttpClient);
    private jwtUtilService = inject(JwtUtilService);
    private i18nService = inject(I18nService);


    private notificationCountSubject = new BehaviorSubject<number>(0);
    notificationCount: Observable<number> = this.notificationCountSubject.asObservable();

    private dataRefreshSubject = new BehaviorSubject<boolean>(false);
    dataRefresh: Observable<boolean> = this.dataRefreshSubject.asObservable();

    institutionCode = UrlUtils.getParam('institution');

    updateNotificationCount(total: number){
        this.notificationCountSubject.next(total);
    }

    updateData(){
        this.dataRefreshSubject.next(true);
    }

    getResearcherClaimOutputsList(offset, limit, taskIdsToExtract, sort = 'create_date'){
        let direction = 'desc';
        if (sort === 'asset_title') {
            direction = 'asc';
        }
        let params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('offset', offset)
            .set('limit', limit)
            .set('sort', sort)
            .set('direction', direction)
            .set('language', this.i18nService.getLanguage())
            .set('userId', this.jwtUtilService.getUserID());
        for (const id of taskIdsToExtract){
            params = params.append('taskIdsToExtract', id);
        }
        return this.http.get<ClaimOutputList>(RestApiBase.GET_RESEARCHER_APPROVAL_LIST, {params});
    }

    claim(taskIds){
        const params = new HttpParams()
            .set('institution', this.institutionCode)
        return this.http.put(RestApiBase.RESEARCHER_APPROVAL_CLAIM_ACTION, taskIds,{params});
    }

    reject(taskIds){
        const params = new HttpParams()
            .set('institution', this.institutionCode)
        return this.http.put(RestApiBase.RESEARCHER_APPROVAL_REJECT_ACTION, taskIds,{params});
    }

}
