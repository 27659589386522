import { Component, OnInit, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-stepper-back-button',
    templateUrl: './deposit-stepper-back-button.component.html',
    styleUrls: ['./deposit-stepper-back-button.component.scss'],
    host: { class: 'esp-deposit-stepper-back-button' },
    imports: [MatButton, NgClass, MatIcon, TranslateModule],
})
export class DepositStepperBackButtonComponent implements OnInit {
    readonly disabled = input(true);
    readonly rounded = input(false);
    readonly back = output<any>();

    constructor() {}

    ngOnInit() {}

    onButtonClick() {
        this.back.emit(null);
    }
}
