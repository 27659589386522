import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  input,
  output,
} from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

type ButtonState = 'open' | 'closed';

@Component({
  selector: 'mulo-menu-toggle-button',
  templateUrl: './menu-toggle-button.component.html',
  styleUrls: ['./menu-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mulo-menu-toggle-button' },
  imports: [MatIconButton, MatIcon, SvgViewboxDirective],
})
export class MenuToggleButtonComponent implements OnInit {
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() state: ButtonState = 'closed';
  readonly openIcon = input('menu');
  readonly closeIcon = input('menu-open');
  readonly openText = input<string | null>('Open Menu');
  readonly closeText = input<string | null>('Close Menu');

  get isClosed() {
    return this.state === 'closed';
  }
  readonly clicked = output<ButtonState>();

  ngOnInit(): void {}

  onClick() {
    this.clicked.emit(this.state);
    // this.toggleState()
  }

  public toggleState() {
    this.state === 'open' ? (this.state = 'closed') : (this.state = 'open');
  }
}
