import {
  Directive,
  ElementRef,
  HostBinding,
  OnChanges,
  inject,
  input,
  output,
} from '@angular/core';

@Directive({
  selector: '[muloInputExpander]',
  standalone: true,
})
export class InputExpanderDirective implements OnChanges {
  private el = inject(ElementRef);

  readonly val = input<any>(undefined, { alias: 'muloInputExpander' });
  readonly buffer = input(5, { alias: 'muloInputExpanderBuffer' });
  readonly widthCalc = output();
  @HostBinding('class.is-auto-expanding') hostClass = true;

  ngOnChanges(): void {
    const val = this.val();
    if (val) {
      switch (typeof val) {
        case 'object':
          if (Array.isArray(val)) {
            this.setInputWidth(val.join(''), this.buffer() * val.length);
          }
          break;
        case 'string':
          this.setInputWidth(val);
          break;
      }
    } else {
      return;
    }
  }

  setInputWidth(value, buffer = this.buffer()) {
    const chars = (value?.length || 0) + buffer;
    this.el.nativeElement.style.width = chars + 'ch';
  }
}
