<div
  mulo-file-drop
  (filesDroppedEmitter)="onFilesDropped($event)"
  (filesInvalidEmitter)="onFileInvalids($event)"
  (dragOverEmitter)="onDragOver($event)"
  [ngClass]="{'is-minimal': minimal(), 'is-hidden': hidden() }"
  [multiple]="multiple()"
  >
  <div class="inner">
    @if (!dragOverActive) {
      <div class="label">
        {{multiple() ? multiFilesDropLabel() : singleFileDropLabel()}}
      </div>
      <button
        mat-stroked-button
        mat-raised-button
        class="has-icon has-file-input is-large"
        (click)="fileRequester.click()"
        #fileUploadButton
        >
        <span>{{multiple() ? multiSelectBtnLabel() : singleSelectBtnLabel()}}</span>
      </button>
      <input
        type="file"
        tabindex="-1"
        style="display: none"
        [accept]="allowedExtensions"
        [multiple]="multiple()"
        (change)="onFilesSelected($event)"
        #fileRequester
        (click)="$event.stopPropagation()"
        attr.aria-label="{{multiple() ? multiSelectBtnLabel() : singleSelectBtnLabel()}}"
        />
      }
      @if (dragOverActive) {
        <div class="label">
          {{multiple() ? multiDropActionLabel() : singleDropActionLabel()}}
        </div>
      }
    </div>
  </div>

  @if (fileList.length) {
    <div @scaleInOut>
      @if (showMultiFileWarning) {
        <div class="files-list-message">
          <span class="message-content mulo-color-warn">
            <mat-icon svgIcon="alert-outline"></mat-icon>
            <span>{{typeChangedLimitMsg()}}</span>
          </span>
        </div>
      }
    </div>
  }

  @if (invalidFiles.length) {
    <div class="invalid-files">
      <mat-list>
        <h3 mat-subheader>{{invalidFilesHeaderLabel()}}</h3>
        @for (file of invalidFiles; track file) {
          <mat-list-item>
            <h4 mat-line>{{ file.name }}</h4>
          </mat-list-item>
        }
      </mat-list>
    </div>
  }
