import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { PivotFundingData, PivotTabEmbeddedLink } from '../shared/interfaces/research-portal/funding/pivot-funding.interface';
import { UrlUtils } from '../shared/utils/url.utils';

@Injectable({
    providedIn: 'root',
})
export class PivotService {
    private http = inject(HttpClient);


    getHasPivot(userID: string) {
        let params = new HttpParams();
        params = params
            .set('institution', UrlUtils.getParam('institution'))
            .set('userID', userID);
        return this.http.get<boolean>(
            RestApiBase.HAS_PIVOT,
            { params: params }
        );
    }

    getPrimaryOrganizationPivotFragment(userID: string) {
        let params = new HttpParams();
        params = params
            .set('institution', UrlUtils.getParam('institution'))
            .set('userID', userID);
        return this.http.get<PivotFundingData>(
            RestApiBase.PIVOT_ESPLORO_EMBEDDED_LINK,
            { params: params }
        );
    }
}
