import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, inject, input } from '@angular/core';
import { mixinColor } from '../../common-behavior/color';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgStyle } from '@angular/common';

class InfobarComponentBase {
  constructor(public _elementRef: ElementRef) {}
}

const _InfobarComponentMixinBase = mixinColor(InfobarComponentBase);

type Width = 'auto' | 'full' | 'none' | number | string;
type Appearance = 'slim' | 'normal' | 'chunky';

@Component({
    selector: 'mulo-infobar',
    templateUrl: './infobar.component.html',
    styleUrls: ['./infobar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['color'],
    host: {
        class: 'mulo-infobar',
        '[class.has-rounded-corners]': 'this.corners() === "rounded"',
        '[class.is-slim]': 'this.appearance() === "slim"',
        '[class.is-chunky]': 'this.appearance() === "chunky"',
        '[style.--align-v]': 'horizontalAlign()',
        '[style.--align-h]': 'verticalAlign()',
    },
    imports: [
        NgClass,
        NgStyle,
        MatIcon
    ]
})
export class InfobarComponent
  extends _InfobarComponentMixinBase
  implements OnInit
{
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() icon: string | null = null;
  readonly rtlMirrorIcon = input<boolean>(undefined);
  readonly width = input<Width>('auto');
  readonly corners = input<'rounded' | 'none'>('rounded');
  readonly horizontalAlign = input<'start' | 'end' | 'center'>('start');
  readonly verticalAlign = input<'start' | 'end' | 'center'>('center');

  readonly appearance = input<Appearance>('normal');

  constructor() {
    const elementRef = inject(ElementRef);

    super(elementRef);
  }

  ngOnInit(): void {}

  get isNumber() {
    return typeof this.width() === 'number';
  }

  get widthClass() {
    return this.isNumber ? `is-custom-width` : `is-${this.width()}-width`;
  }

  get customWidth() {
    return this.isNumber ? { width: `${this.width()}px` } : null;
  }
}
