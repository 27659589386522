import { Directive, Input } from '@angular/core';

/**
 * ! Accessibility
 * This directive is a class markup for elements needed for description other elements
 * or announcment on an action;
 */

@Directive({
    selector: 'exl-description, [exlDescription]',
    standalone: true
})
export class DescriptionDirective {

    constructor() { }
}
