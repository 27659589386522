import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CodeLabelEntry } from '../shared/interfaces/code-label-entry.interface';
import { ProjectDetails } from '../shared/interfaces/project.interface';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { JwtUtilService } from '../core/jwt-util.service';
import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { finalize, map, Observable, of } from 'rxjs';
import { EntityService } from '../core/entity.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectService extends EntityService {
    private _http = inject(HttpClient);
    private _jus = inject(JwtUtilService);

    institutionCode;
    get projectId() {
        return this.entityId;
    }
    set projectId(id: string) {
        this.entityId = id;
    }

    projectTypes: CodeLabelEntry[] = [];
    loading: boolean;

    constructor() {
        super();
        this.api = RestApiBase.projectApi;
    }

    addNew(body: ProjectDetails) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userId', this._jus.getUserID());
        return this._http.post(RestApiBase.projectApi.ADD_NEW_PROJECT, body, {
            params,
        });
    }

    save(project: ProjectDetails) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this._http.post(
            `${RestApiBase.projectApi.ALL_PROJECTS}/${this.projectId}`,
            project,
            {
                params,
            },
        );
    }

    isIdentifierUnique(identifier: string) {
        const params = new HttpParams()
            .set('projectIdentifier', identifier)
            .set('institution', this.institutionCode);
        return this._http.get(
            RestApiBase.projectApi.IS_PROJECT_IDENTIFIERUNIQUE,
            { params },
        );
    }

    addSectionItem(sectionKey, item, id) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this._http.post(
            `${RestApiBase.projectApi.ALL_PROJECTS}/${this.projectId}/${sectionKey}/${id}`,
            item,
            { params },
        );
    }

    validateProjectIdentifier(loading = null): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value) {
                return of(null);
            }
            return this.isIdentifierUnique(control.value).pipe(
                finalize(() => {
                    loading = false;
                }),
                map((isUnique) => {
                    return isUnique
                        ? null
                        : {
                              isNotUnique: true,
                          };
                }),
            );
        };
    }

    deleteItemsByIds(sectionKey, listIds) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this._http.post(
            `${RestApiBase.projectApi.ALL_PROJECTS}/${this.projectId}/${sectionKey}/deleteItems`,
            listIds,
            { params },
        );
    }
}
