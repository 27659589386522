import { Directive, ElementRef, SimpleChanges, OnChanges, inject, input } from '@angular/core';

@Directive({
    selector: '[exlFocus]',
    standalone: true
})
export class FocusDirective implements OnChanges{
    private el = inject(ElementRef);


    readonly activated = input(true, { alias: "exlFocus" });


    ngOnChanges(changes: SimpleChanges){
        if (changes.activated && this.activated()) {
            this.initFocus();
        }
    }

    initFocus(){
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 700);
    }


}
