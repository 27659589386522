import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  output,
} from '@angular/core';
import {
  ImageCroppedEvent,
  ImageCropperComponent as ngxIC,
} from 'ngx-image-cropper';
import { LoaderComponent } from '../loader';

@Component({
  selector: 'mulo-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  imports: [ngxIC, LoaderComponent],
})
export class ImageCropperComponent implements OnChanges {
  readonly file = input(undefined);
  readonly labelPreview = input('Preview:');
  readonly labelCroppedImageAlt = input('Cropped image preview');
  readonly labelImageAlt = input('Cropping image');

  readonly imageOutput = output<ImageCroppedEvent>();
  readonly loadedOutput = output();

  croppedImage: any = '';
  cropperReady = false;
  base64Image = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file) {
      this.fileChangeEvent(this.file());
    }
  }

  fileChangeEvent(file: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.base64Image = event.target.result;
      this.croppedImage = this.base64Image;
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageOutput.emit(event);
  }

  imageLoaded() {
    this.cropperReady = true;
    this.loadedOutput.emit();
  }

  loadImageFailed() {
    console.error('Load failed');
  }
}
