import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JwtUtilService } from '../core/jwt-util.service';
import { UrlUtils } from '../shared/utils/url.utils';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { ResearcherAddress } from '../shared/interfaces/researcher-address.model';
import { CalendarDate } from '../shared/interfaces/calendar-date.model';
import { PersonOrganizationAffiliationDataWrapper } from '../shared/interfaces/person-organization-affiliation-data-wrapper.model';
import { ResearcherEducation } from '../shared/interfaces/researcher-education.model';
import { BehaviorSubject } from 'rxjs';
import { PersonHonorsData } from '../shared/interfaces/researcher-honors.interface';
import { ResearcherMetricDisplaySetting } from '../shared/interfaces/researcher-metric-display-setting';
import {ResearcherEngagement} from '../shared/interfaces/researcher-engagement.interface';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private http = inject(HttpClient);
    private jwtUtilService = inject(JwtUtilService);

    institutionCode: string;

    itemChange$ = new BehaviorSubject<any>('profile');

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getResearcherDetails() {
        const params = new HttpParams()
            .set('userID', this.jwtUtilService.getUserID())
            .set('institution', this.institutionCode);
        return this.http.get(RestApiBase.RESEARCHER_DETAILS, {
            params: params,
        });
    }

    getResearcherProfileImage(userID) {
        const params = new HttpParams()
            .set('userID', userID)
            .set('institution', this.institutionCode)
            .set('date', Date.now());
        return this.http.get(RestApiBase.GET_RESEARCHER_PROFILE_IMAGE, {
            params: params,
            responseType: 'blob',
        });
    }

    getResearcherProfileImageForPublicDisplay(urlIdentifier) {
        const params = new HttpParams()
            .set('urlIdentifier', urlIdentifier)
            .set('institution', this.institutionCode)
            .set('date', Date.now());
        return this.http.get(
            RestApiBase.GET_RESEARCHER_PROFILE_IMAGE_URL_IDENTIFIER,
            { params: params, responseType: 'blob' }
        );
    }

    addNameVariant(first, middle, last, suffix) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.ADD_VARIANT,
            {
                firstName: first,
                middleName: middle,
                lastName: last,
                nameSuffix: suffix,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    addEmail(email, isPreferred) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            emailAddress: email,
            isPreferred: isPreferred,
            userID: this.jwtUtilService.getUserID(),
        };
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            body['proxyUserID'] = this.jwtUtilService.getUserID(true);
        }
        return this.http.put(RestApiBase.ADD_EMAIL, body, { params: params });
    }

    addProxyByMail(email) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            emailAddress: email,
            userID: this.jwtUtilService.getUserID(),
        };
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            body['proxyUserID'] = this.jwtUtilService.getUserID(true);
        }
        return this.http.put(RestApiBase.ADD_PROXY_BY_MAIL, body, {
            params: params,
        });
    }

    getResearcherProxies(userID) {
        const params = new HttpParams()
            .set('userID', this.jwtUtilService.getUserID())
            .set('institution', this.institutionCode);
        return this.http.get(RestApiBase.RESEARCHER_PROXIES, {
            params: params,
        });
    }

    addPhone(phone, type, isPreferred) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            phone: phone,
            type: type,
            userID: this.jwtUtilService.getUserID(),
            isPreferred: isPreferred,
        };
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            body['proxyUserID'] = this.jwtUtilService.getUserID(true);
        }
        return this.http.put(RestApiBase.ADD_PHONE, body, { params: params });
    }

    addAddress(address: ResearcherAddress) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            line1: address.line1,
            line2: address.line2,
            line3: address.line3,
            line4: address.line4,
            line5: address.line5,
            city: address.city,
            postalCode: address.postalCode,
            country: address.country,
            userID: this.jwtUtilService.getUserID(),
        };
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            body['proxyUserID'] = this.jwtUtilService.getUserID(true);
        }
        return this.http.put(RestApiBase.ADD_ADDRESS, body, { params: params });
    }

    addAffiliation(bodyParams: object) {
        let _params = new HttpParams();
        _params = _params.set('institution', this.institutionCode);
        const body = bodyParams;
        body['userID'] = this.jwtUtilService.getUserID();

        return this.http.put(RestApiBase.ADD_AFFILIATION, body, {
            params: _params,
        });
    }

    deleteAffiliation(
        affiliation: PersonOrganizationAffiliationDataWrapper,
        listType
    ) {
        let params = new HttpParams();
        const position = affiliation.data.position
            ? affiliation.data.position
            : '';
        params = params
            .set('institution', this.institutionCode)
            .set('affiliationCode', affiliation.data.organizationCode)
            .set('listType', listType)
            .set('listOwnership', affiliation.category.toLowerCase())
            .set('position', position)
            .set('title', affiliation.data.title)
            .set('userID', this.jwtUtilService.getUserID());
        if (affiliation.data.startDate) {
            params = params
                .set('fromYear', affiliation.data.startDate.year.toString())
                .set(
                    'fromMonth',
                    (affiliation.data.startDate.month + 1).toString()
                )
                .set(
                    'fromDay',
                    affiliation.data.startDate.dayOfMonth.toString()
                );
        }
        if (affiliation.data.endDate) {
            params = params
                .set('toYear', affiliation.data.endDate.year.toString())
                .set('toMonth', (affiliation.data.endDate.month + 1).toString())
                .set('toDay', affiliation.data.endDate.dayOfMonth.toString());
        }
        return this.http.delete(RestApiBase.DELETE_AFFILIATION, {
            params: params,
        });
    }

    moveAffiliation(
        affiliation: PersonOrganizationAffiliationDataWrapper,
        moveTo
    ) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        const position = affiliation.data.position
            ? affiliation.data.position
            : '';
        const body = {
            affiliationCode: affiliation.data.organizationCode,
            moveTo: moveTo,
            listOwnership: affiliation.category.toLowerCase(),
            position: position,
            title: affiliation.data.title,
            userID: this.jwtUtilService.getUserID(),
        };
        if (affiliation.data.startDate) {
            body['fromYear'] = affiliation.data.startDate.year.toString();
            body['fromMonth'] = (
                affiliation.data.startDate.month + 1
            ).toString();
            body['fromDay'] = affiliation.data.startDate.dayOfMonth.toString();
        }
        if (affiliation.data.endDate) {
            body['toYear'] = affiliation.data.endDate.year.toString();
            body['toMonth'] = (affiliation.data.endDate.month + 1).toString();
            body['toDay'] = affiliation.data.endDate.dayOfMonth.toString();
        }
        return this.http.put(RestApiBase.MOVE_AFFILIATION, body, {
            params: params,
        });
    }

    updateAffiliation(
        affiliation: PersonOrganizationAffiliationDataWrapper,
        prevAffiliation: PersonOrganizationAffiliationDataWrapper,
        listType: string
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            affiliationCode: affiliation.data.organizationCode,
            position: affiliation.data.position,
            prevPosition: prevAffiliation.data.position,
            listOwnership: affiliation.category.toLowerCase(),
            listType: listType,
            title: affiliation.data.title,
            hiddenFromPublicProfile: affiliation.data.hiddenFromPublicProfile,
            userID: this.jwtUtilService.getUserID(),
        };
        if (affiliation.data.startDate) {
            body['fromYear'] = affiliation.data.startDate.year;
            body['fromMonth'] = affiliation.data.startDate.month + 1;
            body['fromDay'] = affiliation.data.startDate.dayOfMonth;
        }
        if (affiliation.data.endDate) {
            body['toYear'] = affiliation.data.endDate.year;
            body['toMonth'] = affiliation.data.endDate.month + 1;
            body['toDay'] = affiliation.data.endDate.dayOfMonth;
        }

        if (prevAffiliation.data.startDate) {
            const prevStart = prevAffiliation.data.startDate as CalendarDate;
            body['prevFromYear'] = prevStart.year;
            body['prevFromMonth'] = prevStart.month + 1;
            body['prevFromDay'] = prevStart.dayOfMonth;
        }
        return this.http.put(RestApiBase.UPDATE_AFFILIATION, body, {
            params: params,
        });
    }

    updateAffiliationAndMove(
        affiliation: PersonOrganizationAffiliationDataWrapper,
        prevAffiliation: PersonOrganizationAffiliationDataWrapper
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            affiliationCode: affiliation.data.organizationCode,
            position: affiliation.data.position,
            prevPosition: prevAffiliation.data.position,
            listOwnership: affiliation.category.toLowerCase(),
            title: affiliation.data.title,
            userID: this.jwtUtilService.getUserID(),
        };
        if (affiliation.data.startDate) {
            body['fromYear'] = affiliation.data.startDate.getYear();
            body['fromMonth'] = affiliation.data.startDate.getMonth() + 1;
            body['fromDay'] = affiliation.data.startDate.getDay();
        }
        if (affiliation.data.endDate) {
            body['toYear'] = affiliation.data.endDate.getYear();
            body['toMonth'] = affiliation.data.endDate.getMonth() + 1;
            body['toDay'] = affiliation.data.endDate.getDay();
        }

        if (prevAffiliation.data.startDate) {
            const prevStart = prevAffiliation.data.startDate as CalendarDate;
            body['prevFromYear'] = prevStart.year;
            body['prevFromMonth'] = prevStart.month + 1;
            body['prevFromDay'] = prevStart.dayOfMonth;
        }

        return this.http.put(RestApiBase.UPDATE_AFFILIATION_AND_MOVE, body, {
            params: params,
        });
    }

    editAddress(address: ResearcherAddress, prevAddress: ResearcherAddress) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_ADDRESS,
            {
                line1: address.line1,
                line2: address.line2,
                line3: address.line3,
                line4: address.line4,
                line5: address.line5,
                city: address.city,
                postalCode: address.postalCode,
                country: address.country,
                prevLine1: prevAddress.line1,
                prevLine2: prevAddress.line2,
                prevLine3: prevAddress.line3,
                prevLine4: prevAddress.line4,
                prevLine5: prevAddress.line5,
                prevCity: prevAddress.city,
                prevPostalCode: prevAddress.postalCode,
                prevCountry: prevAddress.country,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    deleteAddress(address: ResearcherAddress) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('line1', address.line1)
            .set('line2', address.line2)
            .set('line3', address.line3)
            .set('line4', address.line4)
            .set('line5', address.line5)
            .set('city', address.city)
            .set('postalCode', address.postalCode)
            .set('country', address.country)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.DELETE_ADDRESS, { params: params });
    }

    editEmail(prevEmail, email, isPreferred) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_EMAIL,
            {
                prevEmail: prevEmail,
                emailAddress: email,
                isPreferred: isPreferred,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    editPhone(prevPhone, phone, type) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_PHONE,
            {
                prevPhone: prevPhone,
                phone: phone,
                type: type,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    editNameVariant(
        first,
        middle,
        last,
        suffix,
        prevFirst,
        prevMiddle,
        prevLast,
        prevSuffix
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_VARIANT,
            {
                firstName: first,
                middleName: middle,
                lastName: last,
                nameSuffix: suffix,
                userID: this.jwtUtilService.getUserID(),
                prevFirstName: prevFirst,
                prevMiddleName: prevMiddle,
                prevLastName: prevLast,
                prevNameSuffix: prevSuffix,
            },
            { params: params }
        );
    }

    editPreferredName(first, middle, last, suffix) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_PREFERRED_NAME,
            {
                firstName: first,
                middleName: middle,
                lastName: last,
                nameSuffix: suffix,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    editGlobalID(type, value, prevType, prevValue) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_IDENTIFIER,
            {
                type: type,
                value: value,
                userID: this.jwtUtilService.getUserID(),
                prevType: prevType,
                prevValue: prevValue,
            },
            { params: params }
        );
    }

    changePreferredName(
        prevFirst,
        prevMiddle,
        prevLast,
        prevSuffix,
        curFirst,
        curMiddle,
        curLast,
        curSuffix
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.CHANGE_PREFERRED_NAME,
            {
                prevFirst: prevFirst,
                prevMiddle: prevMiddle,
                prevLast: prevLast,
                prevSuffix: prevSuffix,
                curFirst: curFirst,
                curMiddle: curMiddle,
                curLast: curLast,
                curSuffix: curSuffix,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    changePreferredEmail(curEmail) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.CHANGE_PREFERRED_EMAIL,
            {
                emailAddress: curEmail,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    changeEmailVisibilityInProfile(
        email: string,
        isPreferred: boolean,
        visible: boolean
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.CHANGE_EMAIL_VISIBILITY_IN_PROFILE,
            {
                emailAddress: email,
                userID: this.jwtUtilService.getUserID(),
                visible: visible,
                isPreferred: isPreferred,
            },
            { params: params }
        );
    }

    changePhoneVisibilityInProfile(phone: string, visible: boolean) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.CHANGE_PHONE_VISIBILITY_IN_PROFILE,
            {
                phone: phone,
                userID: this.jwtUtilService.getUserID(),
                visible: visible,
            },
            { params: params }
        );
    }

    removeNameVariant(first, middle, last, suffix) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('firstName', first)
            .set('middleName', middle)
            .set('lastName', last)
            .set('nameSuffix', suffix)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.REMOVE_VARIANT, { params: params });
    }

    addNewIdentifier(type, value) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            type: type,
            value: value,
            userID: this.jwtUtilService.getUserID(),
        };
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            body['proxyUserID'] = this.jwtUtilService.getUserID(true);
        }
        return this.http.put(RestApiBase.ADD_NEW_IDENTIFIER, body, {
            params: params,
        });
    }

    deleteIdentifier(type, value) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.DELETE_IDENTIFIER,
            {
                type: type,
                value: value,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    deleteEmail(email) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('emailAddress', email)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.DELETE_EMAIL, { params: params });
    }

    deleteProxy(researcherId, proxyId) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('researcherId', researcherId)
            .set('proxyId', proxyId);
        return this.http.delete(RestApiBase.DELETE_PROXY, { params: params });
    }

    deletePhone(phone) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('phone', phone)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.DELETE_PHONE, { params: params });
    }

    addSubject(subject) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.ADD_SUBJECT,
            {
                subject: subject,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    addKeyword(keyword) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.ADD_KEYWORD,
            {
                keyword: keyword,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    removeSubject(subject) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('subject', subject)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.REMOVE_SUBJECT, { params: params });
    }

    removeKeyword(keyword) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('keyword', keyword)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.REMOVE_KEYWORD, { params: params });
    }

    saveAreaOfInterest(description) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.SAVE_AREA_OF_INTEREST,
            {
                description: description,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    addLink(url, title, description) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.ADD_LINK,
            {
                url: url,
                title: title,
                description: description,
                userID: this.jwtUtilService.getUserID(),
            },
            { params: params }
        );
    }

    deleteLink(url, title, description) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('url', url)
            .set('title', title)
            .set('description', description)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.DELETE_LINK, { params: params });
    }

    editLink(
        url,
        title,
        description,
        hiddenFromPublicProfile,
        prevURL,
        prevTitle,
        prevDescription
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_LINK,
            {
                url: url,
                title: title,
                description: description,
                hiddenFromPublicProfile: hiddenFromPublicProfile,
                userID: this.jwtUtilService.getUserID(),
                prevURL: prevURL,
                prevTitle: prevTitle,
                prevDescription: prevDescription,
            },
            { params: params }
        );
    }

    updateLinks(list: any[]) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        const body = { links: list };
        return this.http.post(RestApiBase.UPDATE_LINKS_LIST, body, {
            params: params,
        });
    }

    updateTopicsOrder(list: string[]) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        const body = { topics: list };
        return this.http.post(RestApiBase.UPDATE_TOPICS_ORDER, body, {
            params: params,
        });
    }

    saveImageToDB(file) {
        let params = new HttpParams();
        const formData: FormData = new FormData();
        formData.append('file', file);
        params = params
            .set('institution', this.institutionCode)
            .set('userId', this.jwtUtilService.getUserID())
            .set('fileName', file['name'])
            .set('size', file['size'])
            .set('date', Date.now());
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            params = params.set(
                'proxyUserID',
                this.jwtUtilService.getUserID(true)
            );
        }
        return this.http.post(RestApiBase.PROFILE_IMAGE, formData, {
            params: params,
        });
    }

    getExternalOrganizationAutoComplete(affiliation) {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams()
            .set('institution', institutionCode)
            .set('affiliation', affiliation);
        return this.http.get(
            RestApiBase.GET_EXTERNAL_AFFILIATIION_AUTO_COMPLETE,
            { params: params }
        );
    }

    getInternalOrganizationAutoComplete(affiliation) {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams()
            .set('institution', institutionCode)
            .set('affiliation', affiliation);
        return this.http.get(
            RestApiBase.GET_INTERNAL_AFFILIATIION_AUTO_COMPLETE,
            { params: params }
        );
    }

    getCountriesLookup() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode);
        return this.http.get(RestApiBase.GET_COUNTRIES, { params: params });
    }

    getAffiliationPositionCodes() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode);
        return this.http.get(RestApiBase.GET_AFFILIATION_POSITION_CODES, {
            params: params,
        });
    }

    addEducation(education: ResearcherEducation) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            fieldOfStudy: education.fieldOfStudy,
            organizationCode: education.organizationCode,
            organizationName: education.organizationName,
            degree: education.degree,
            fromYear: education.year,
            toYear: education.toDateYear,
            additionalDetails: education.additionalDetails,
            hiddenFromPublicPortal: education.hiddenFromPublicPortal,
            userID: this.jwtUtilService.getUserID(),
        };
        return this.http.put(RestApiBase.ADD_EDUCATION, body, {
            params: params,
        });
    }

    deleteEducation(education: ResearcherEducation) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('fieldOfStudy', education.fieldOfStudy)
            .set('organizationCode', education.organizationCode)
            .set('organizationName', education.organizationName)
            .set('degree', education.degree)
            .set('fromYear', education.year ? education.year.toString() : '')
            .set(
                'toYear',
                education.toDateYear ? education.toDateYear.toString() : ''
            )
            .set('additionalDetails', education.additionalDetails)
            .set(
                'hiddenFromPublicPortal',
                education.hiddenFromPublicPortal ? 'true' : 'false'
            )
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.DELETE_EDUCATION, {
            params: params,
        });
    }

    editEducation(
        education: ResearcherEducation,
        prevEducation: ResearcherEducation
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            fieldOfStudy: education.fieldOfStudy,
            organizationCode: education.organizationCode,
            organizationName: education.organizationName,
            degree: education.degree,
            fromYear: education.year,
            toYear: education.toDateYear,
            additionalDetails: education.additionalDetails,
            hiddenFromPublicPortal: education.hiddenFromPublicPortal,
            userID: this.jwtUtilService.getUserID(),
            prevFieldOfStudy: prevEducation.fieldOfStudy,
            prevOrganizationCode: prevEducation.organizationCode,
            prevOrganizationName: prevEducation.organizationName,
            prevDegree: prevEducation.degree,
            prevFromYear: prevEducation.year,
            prevToYear: prevEducation.toDateYear,
            prevAdditionalDetails: prevEducation.additionalDetails,
            prevHiddenFromPublicPortal: prevEducation.hiddenFromPublicPortal,
        };
        return this.http.put(RestApiBase.UPDATE_EDUCATION, body, {
            params: params,
        });
    }

    updateEducations(list: any[]) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        const body = { educations: list };
        return this.http.post(RestApiBase.UPDATE_EDUCATION_LIST, body, {
            params: params,
        });
    }

    saveDisplayTitle(displayTitle: string) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        const body = { displayTitle: displayTitle };
        return this.http.post(RestApiBase.UPDATE_DISPLAY_TITLE, body, {
            params: params,
        });
    }

    isOrcidActiveDirectMode() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode);
        return this.http.get(RestApiBase.IS_ORCID_ACTIVE_DIRECT_MODE, {
            params: params,
        });
    }

    getOrcidAuthorizationUrl() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams()
            .set('userID', this.jwtUtilService.getUserID())
            .set('institution', institutionCode);
        return this.http.get(RestApiBase.GET_ORCID_AUTORIZATION_URL, {
            params: params,
        });
    }

    isResearcherHasTrustOrcid() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams()
            .set('userID', this.jwtUtilService.getUserID())
            .set('institution', institutionCode);
        return this.http.get(RestApiBase.IS_RESEARCHER_HAS_TRUST_ORCID, {
            params: params,
        });
    }

    getTrustedORCID() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams()
            .set('userID', this.jwtUtilService.getUserID())
            .set('institution', institutionCode);
        return this.http.get(RestApiBase.GET_TRUSTED_ORCID, { params: params });
    }

    addHonor(honorData: PersonHonorsData) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        // .set('userID', this.jwtUtilService.getUserID());
        const body = {
            userID: this.jwtUtilService.getUserID(),
            ...honorData,
        };
        return this.http.put(RestApiBase.ADD_HONOR, body, { params: params });
    }

    deleteHonor(honorData: PersonHonorsData) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('title', honorData.title)
            .set('organizationCode', honorData.organizationCode)
            .set('organizationName', honorData.organizationName)
            .set('timePeriod', honorData.timePeriod)
            .set('hiddenFromPublicProfile', honorData.hiddenFromPublicProfile)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.delete(RestApiBase.DELETE_HONOR, { params: params });
    }

    editHonor(honorData: PersonHonorsData, prevHonorData: PersonHonorsData) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = {
            honorData: honorData,
            prevHonorData: prevHonorData,
            userID: this.jwtUtilService.getUserID(),
        };
        return this.http.put(RestApiBase.UPDATE_HONOR, body, {
            params: params,
        });
    }

    updateHonors(list: PersonHonorsData[]) {
        let params = new HttpParams();
        params = params
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        const body = { honors: list };
        return this.http.post(RestApiBase.UPDATE_HONORS_LIST, body, {
            params: params,
        });
    }

    getMetricDisplaySettings() {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.get<ResearcherMetricDisplaySetting[]>(
            RestApiBase.GET_METRIC_DISPLAY_SETTINGS,
            {
                params,
            }
        );
    }

    updateMetricDisplaySetting(setting: ResearcherMetricDisplaySetting) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = { userID: this.jwtUtilService.getUserID(), ...setting };
        return this.http.put(RestApiBase.UPDATE_METRIC_DISPLAY, body, {
            params,
        });
    }

    updateEngagementDisplaySetting(engagement: ResearcherEngagement) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        const body = { userID: this.jwtUtilService.getUserID(), ...engagement };
        return this.http.put(RestApiBase.UPDATE_ENGAGEMENT_DISPLAY, body, {
            params,
        });
    }

    getLettersSettings() {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.get(RestApiBase.GET_LETTERS_SETTINGS, {
            params,
        });
    }

    updateLettersSetting(key: string, value: boolean) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.UPDATE_LETTERS_SETTING,
            { userID: this.jwtUtilService.getUserID(), key, value },
            { params }
        );
    }
}
