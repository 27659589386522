import { Component, input, output } from '@angular/core';
import { PortionMethod } from '../portion-list.service';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'mulo-portion-list-toolbar',
    templateUrl: './portion-list-toolbar.component.html',
    styleUrls: ['./portion-list-toolbar.component.scss'],
    host: { class: 'mulo-portion-list-toolbar' },
    imports: [
        MatIconButton,
        MatTooltip,
        MatIcon,
    ]
})
export class PortionListToolbarComponent {
  readonly labelDistribute = input('Distribute');
  readonly labelDistDesc = input('Distribute apportionments in a descending order');
  readonly labelDistEq = input('Distribute apportionments equally');
  readonly labelDistAsc = input('Distribute apportionments in an ascending order');

  readonly clicked = output<PortionMethod>();

  constructor() {}

  onClick(method: PortionMethod) {
    this.clicked.emit(method);
  }
}
