<mat-sidenav-container [hasBackdrop]="hasBackdrop()">
  <mat-sidenav #sidenav [mode]="mode()" [fixedInViewport]="fixedInViewport()" [position]="position()" [autoFocus]="true">
    <div
      class="sidenav-header"
      [class.button-on-left]="closeButtonSide() === 'left'"
      [class.button-on-right]="closeButtonSide() === 'right'"
      >
      @if (closeButtonMode != 'hidden') {
        <mulo-menu-toggle-button
          (clicked)="close()"
          [state]="'open'"
          [style.position]="closeButtonMode"
          [closeIcon]="closeButtonIcon()"
          >
        </mulo-menu-toggle-button>
      }
      <ng-content select="[slot='mulo-brand'], mulo-brand"></ng-content>
    </div>

    @if (observer$ | async; as template) {
      <ng-container *ngTemplateOutlet="template"></ng-container>
    }
  </mat-sidenav>
</mat-sidenav-container>
