import { Component, HostBinding, OnInit, input } from '@angular/core';
import { BriefBaseComponent } from '../../briefs/brief-base/brief-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'exl-super-brief',
    templateUrl: './super-brief.component.html',
    styleUrls: ['./super-brief.component.scss'],
    host: { class: 'exl-super-brief' },
    imports: [
        MatIcon,
        TranslateModule
    ]
})
export class SuperBriefComponent extends BriefBaseComponent implements OnInit {
    icon: string;
    rtlMirrorIcon: boolean;
    readonly alertType = input(undefined);
    readonly action = input(undefined);
    @HostBinding('class.exl-super-brief') true;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.assesIcon();
    }

    assesIcon() {
        const alertType = this.alertType();
        if (alertType) {
            const type = alertType;
            if (type === 'status') {
                this.icon = 'approval';
            } else if (type === 'capture') {
                this.icon = 'magnify';
                this.rtlMirrorIcon = true;
            } else if (type === 'analytics') {
                this.icon = 'pulse';
            } else if (type === 'funding') {
                this.icon = 'trophy';
            } else {
                this.icon = undefined;
            }
        }
    }
}
