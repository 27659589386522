import { Component, ElementRef, HostBinding, Input, OnInit, inject } from '@angular/core';
import { MediaService } from '@exl-ng/mulo-core';
import { ColorComponentMixinBase } from '../../shared/common-behavior/color';

@Component({
    selector: 'exl-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    host: { 'class': 'exl-footer' },
    inputs: ['color'],
    standalone: true
})
export class FooterComponent extends ColorComponentMixinBase implements OnInit {
    media = inject(MediaService);


    constructor() {
        const elementRef = inject(ElementRef);

        super(elementRef);
    }

    ngOnInit() {
    }

}
