import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../../../shared/configurations/rest-api-base.config';
import {UrlUtils} from '../../../shared/utils/url.utils';

@Injectable()
export class GrobidService {
    private http = inject(HttpClient);


    private _grobidExecuted = false;
    institutionCode: string;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    get grobidExecuted(): boolean {
        return this._grobidExecuted;
    }

    set grobidExecuted(value: boolean) {
        this._grobidExecuted = value;
    }

    grobid(file) {
        const params = new HttpParams().set('institution', this.institutionCode);
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(RestApiBase.GROBID_SERVICE,formData, {params: params});
    }
}
