import {Component, OnInit} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutDirective } from '../../logout/logout.directive';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-login-error-no-researcher-role',
    templateUrl: './login-error-no-researcher-role.component.html',
    styleUrls: ['./login-error-no-researcher-role.component.scss'],
    imports: [MatButton, LogoutDirective, TranslateModule]
})
export class LoginErrorNoResearcherRoleComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
