import './shared/patches/add-validator-abstract-control.patch';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
    withXsrfConfiguration,
} from '@angular/common/http';
import { CoreModule } from './core/core.module';
import {
    MissingTranslationHandler,
    TranslateLoader,
    TranslateModule,
} from '@ngx-translate/core';
import { EsploroMissingTranslationHandler } from './missing-translation-handler';
import { DepositFormOverlayService } from './deposit/deposit-form-overlay/deposit-form-overlay.service';
import { DepositModule } from './deposit/deposit.module';
import { DepositFormOverlayComponent } from './deposit/deposit-form-overlay/deposit-form-overlay.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService } from './config.service';
import { JwtModule } from '@auth0/angular-jwt';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RestApiBase } from './shared/configurations/rest-api-base.config';
import { UrlUtils } from './shared/utils/url.utils';
import { LayoutsModule } from './parts/layouts/layouts.module';
import { EsploroHeaderModule } from './esploro-header/esploro-header.module';
import { EsploroFooterModule } from './esploro-footer/esploro-footer.module';
import { FooterModule } from './parts/footer/footer.module';
import { DateTimeService } from './core/date-time.service';
import {
    CenteredBlockLayoutComponent,
    HeaderMainFooterLayoutComponent,
} from '@exl-ng/mulo-views';
import {
    BrandComponent,
    HtmlSanitizePipe,
    MuloMatCssVarsModule,
    SidenavComponent,
} from '@exl-ng/mulo-common';
import {
    HideOnPrintDirective,
    KeepOnPrintDirective,
    ShowOnlyOnPrintDirective,
} from '@exl-ng/mulo-core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { customTooltipDefaults } from './shared/a11y/mat-tooltip-default-option';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { BidiModule } from '@angular/cdk/bidi';
import { map, Observable } from 'rxjs';
import { TombstonePageComponent } from './tombstone-page/tombstone-page.component';
import { NgOptimizedImage } from '@angular/common';

class CustomLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        const stored = JSON.parse(
            localStorage.getItem('rvXl8n_' + lang) || '{}',
        );
        return this.http
            .get(
                `${RestApiBase.TRANSLATIONS}?institution=${UrlUtils.getParam(
                    'institution',
                )}&lang=${lang}`,
            )
            .pipe(map((remoteData) => ({ ...stored, ...remoteData })));
    }
}

export function init(config: ConfigService): Function {
    return () => config.load();
}

export function tokenGetter() {
    return localStorage.getItem('access_token')
        ? localStorage.getItem('access_token')
        : sessionStorage.getItem('access_token');
}


