<mat-form-field
    [muloInputExpander]="organizationName.value"
    [ngClass]="{ 'is-comfortably-readable': isComfortablyReadable() }"
    class="field-root"
>
    <input
        #searchInput
        [formControl]="organizationName"
        [matAutocomplete]="auto"
        [placeholder]="placeholder()"
        matInput
        maxlength="255"
    />
    <mat-label>{{ label() }}</mat-label>
    @if (organizationSearchInProgress) {
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    }
    <mat-autocomplete #auto="matAutocomplete">
        @for (organization of externalOrganizations | slice: 0 : numberOfItemsToDisplay; track organization) {
            <mat-option
                (onSelectionChange)="onExternalOrganizationSelect(organization)"
                [disabled]="organizationSearchInProgress"
                [value]="organization.code | defaultTranslate: { default: organization.name }"
            >
                <span
                    [innerHTML]="
                        organization.code
                            | defaultTranslate: { default: organization.name }
                            | highlight: organizationNameCleaned
                    "
                    class="semibold"
                ></span>
            </mat-option>
        }
        @if (
            organizationName?.value &&
            noValuesFound &&
            !organizationSearchInProgress &&
            configurationHandlerService.researcherProfileGeneralConfiguration().allowToAddNewOrganiztion
        ) {
            <mat-option
                (onSelectionChange)="onDialogCloseAttempt(organizationName.value)"
                [value]="organizationName.value"
            >
                <span class="semibold"
                    >{{ organizationName.value }}
                    {{ 'research.no.external.affiliation.auto.complete.found.add.new.organization' | translate }}</span
                >
            </mat-option>
        }
    </mat-autocomplete>
    @if (!organizationName.value) {
        <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
    }
    @if (organizationName.value) {
        <mat-error [innerHtml]="'research.settings.field.selection.required.error.message' | translate"></mat-error>
    }
</mat-form-field>
