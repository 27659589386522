@for (i of repetitions; track i) {
  <content-loader
    [rtl]="i18nService.isLangRtl()"
    [style]="{ height: 180, width: 600 }"
    backgroundColor="#dddddd"
    foregroundColor="#eeeeee"
    viewBox="0,0,600,180"
    >
    <svg:rect height="7" rx="2" ry="2" width="150" x="0" y="6" />
    <svg:rect height="11" rx="2" ry="2" width="450" x="0" y="23" />
    <svg:rect height="11" rx="2" ry="2" width="280" x="0" y="43" />
    <svg:rect height="9" rx="2" ry="2" width="320" x="0" y="63" />
    <svg:rect height="9" rx="2" ry="2" width="400" x="0" y="83" />
  </content-loader>
}
