@if (type() !== 'dot') {
  <span class="badge-text">
    @if (!count) {
      <ng-content></ng-content>
    }
    @if (count) {
      <span>{{ count }}</span>
    }
  </span>
}
<mat-icon
  class="badge-background"
  [ngClass]="color ? 'mulo-' + color : null"
  muloSvgViewbox
  [style.color]="hexColor ? color : null"
  [svgIcon]="type() === 'icon' ? 'decagram' : 'circle-dot'"
  >
</mat-icon>
@if (icon) {
  <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon" class="mulo-{{ color }}"></mat-icon>
}
