import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  input,
  output
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { componentDestroyed } from '@exl-ng/mulo-core';
import { takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';


export interface SearchbarOption {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'mulo-searchbar-options',
    templateUrl: './searchbar-options.component.html',
    styleUrls: ['./searchbar-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-searchbar-options',
        '[class.mulo-searchbar-options-before]': 'placement() === "before"',
        '[class.mulo-searchbar-options-after]': 'placement() === "after"',
        '[class.has-multiple-options]': 'options?.length > 1',
    },
    imports: [
        MatFormField,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        MatOption,
        TranslateModule
    ]
})
export class SearchbarOptionsComponent implements OnInit, OnDestroy {
  readonly placement = input<'before' | 'after'>('after');
  readonly label = input<string>(undefined);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() options: SearchbarOption[] = null;
  readonly maxWidth = input('none');
  private _value: string;

  readonly selectionChange = output<string>();

  _selectControl = new UntypedFormControl('');

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() public set value(value: string) {
    this._value = value;
    this._selectControl.setValue(value, { emitEvent: false });
  }
  public get value(): string {
    return this._value;
  }

  constructor() {}

  ngOnInit(): void {
    if (this.options.length === 1) {
      this.value = this.options[0].value;
    }
    this._selectControl.valueChanges
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((val) => {
        this.selectionChange.emit(val);
      });
  }

  ngOnDestroy() {}
}
