<mat-form-field [class.is-comfortably-readable]="isComfortablyReadable()" class="field-root">
    <mat-label>{{ label() }}</mat-label>
    <mat-chip-grid
        #chipGrid
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
        muloDropListA11y
        (muloDropListA11yEditing)="a11yEditing = $event"
    >
        @for (orgCode of orgCodeListCtrl().value; track orgCode) {
            @if (orgNameListCtrl().value[$index]; as orgName) {
                <mat-chip-row cdkDrag (removed)="remove(orgCode)">
                    <div matChipAvatar class="drag-grip"></div>
                    {{ orgName }}
                    <button
                        [attr.aria-label]="('research.aria.deposit.keywords.remove' | translate) + ' ' + orgName"
                        mat-icon-button
                        matChipRemove
                        [disabled]="a11yEditing"
                    >
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                </mat-chip-row>
            }
        }
    </mat-chip-grid>
    <input
        #orgInput
        [(ngModel)]="currentOrgName"
        [disabled]="orgNameListCtrl().value?.length >= 5"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipGrid"
        [placeholder]="'research.aria.settings.affiliations.add.new.affiliation' | translate"
        name="currentOrg"
    />
    <mat-hint [class.error]="orgNameListCtrl().value?.length >= 5" align="start"
        >{{ 'research.people.affiliations.maxCountHint' | translate }}
    </mat-hint>
    <mat-hint [class.error]="orgNameListCtrl().value?.length >= 5" align="end"
        >{{ orgNameListCtrl().value?.length }} / 5
    </mat-hint>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        @if (filteredIntOrgs().length > 0) {
            <mat-optgroup [label]="'research.portal.entityMemberOrgs.formType.internal' | translate">
                @for (org of filteredIntOrgs(); track org.organizationId) {
                    <mat-option
                        [disabled]="orgCodeListCtrl().value?.includes(org.organizationCode)"
                        [value]="org.organizationCode"
                        >{{ org.organizationName }}
                    </mat-option>
                }
            </mat-optgroup>
        }
        @if (filteredExtOrgs().length > 0 || canAddOrg()) {
            <mat-optgroup [label]="'research.portal.entityMemberOrgs.formType.external' | translate">
                @for (org of filteredExtOrgs(); track org.orgId) {
                    <mat-option [disabled]="orgCodeListCtrl().value?.includes(org.code)" [value]="org.code"
                        >{{ org.name }}
                    </mat-option>
                }
                @if (canAddOrg()) {
                    <mat-option
                        id="addNew"
                        (onSelectionChange)="addNewExtOrg(currentOrgName(), $event)"
                        [value]="currentOrgName()"
                    >
                        <span class="semibold"
                            >{{ currentOrgName() }}
                            {{
                                'research.no.external.affiliation.auto.complete.found.add.new.organization' | translate
                            }}</span
                        >
                    </mat-option>
                }
            </mat-optgroup>
        }
    </mat-autocomplete>
    @if (organizationSearchInProgress()) {
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    }
</mat-form-field>
