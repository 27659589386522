<div class="panel-buttons">
  @if (!uploadDone) {
    <button
      mat-icon-button
      (click)="togglePanelSize()"
      class="panel-button"
      attr.aria-label="{{ getAriaLabelForPanelButton() | translate }}"
      >
      <mat-icon
        class="mat-icon-rtl-mirror"
        [svgIcon]="panelCollapsed ? 'arrow-top-right' : 'arrow-bottom-left'"
      ></mat-icon>
    </button>
  }
  <!--<button mat-icon-button (click)="close()" class="panel-button" *ngIf="uploadDone && !panelCollapsed">-->
  <!--<mat-icon svgIcon="close"></mat-icon>-->
<!--</button>-->
</div>

<div mat-dialog-content>
  <exl-file-list
    listTitle="{{ uploaderTitle | customTranslate }}"
    removable="false"
    showActiveOnly="true"
    [expandable]="false"
    [minimal]="true"
    >
    <!-- Uploading items -->
    @for (file of uploadQueue; track file; let index = $index) {
      <exl-file-list-item
        [item]="file"
        [index]="index"
        [minimal]="true"
        [expandable]="false"
        [allPanelsExpanded]="false"
        >
      </exl-file-list-item>
    }
  </exl-file-list>
</div>

@if (!uploadDone && panelCollapsed) {
  <mat-progress-bar
    mode="indeterminate"
    class="collapsed-progress-bar"
  ></mat-progress-bar>
}
