<exl-date-input
    [range]="range()"
    [showHint]="dateHint()"
    [fromDate]="fromDate()"
    [toDate]="toDate()"
    [autoPopulatedFromDate]="autoPopulated()"
    [error]="error()">
</exl-date-input>








