import {
  Component,
  HostBinding,
  ElementRef,
  OnDestroy,
  DoCheck,
  input,
  output,
  viewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AriaDescriberDirective } from '../../directives/aria-describer.directive';


/**
 * Expandable container enabling cropping any type of content.
 * Dynamically maintains visible area on different screen sizes.
 */
@Component({
    selector: 'mulo-expandable',
    templateUrl: './expandable.component.html',
    styleUrls: ['./expandable.component.scss'],
    host: {
        class: 'mulo-expandable',
        '[style]': 'styleString',
    },
    imports: [
        MatButton,
        AriaDescriberDirective,
        MatIcon
    ]
})
export class ExpandableComponent implements OnDestroy, DoCheck {
  @HostBinding('class.is-expandable') expandable = false;
  @HostBinding('class.is-expanded') expanded: boolean;
  @HostBinding('class.is-expanding') expanding: boolean;
  @HostBinding('class.is-collapsing') collapsing: boolean;

  readonly ratio = input(7);
  readonly buttonColor = input<'primary' | 'accent' | 'default'>('primary');
  readonly expandLabel = input('Expand');
  readonly collapseLabel = input('Collapse');
  readonly hideButtonOnExpand = input(false);
  readonly buttonOverlap = input(true);
  readonly buttonStyle = input<'icon' | 'text' | 'full'>('full');
  readonly buttonPosition = input<'right' | 'left'>('right');
  readonly buttonDisabled = input(false);
  readonly descriptionLabel = input('For display interface');

  /** Event emitted every time the Expandable is closed. */
  readonly closed = output<void>();
  /** Event emitted every time the Expandable is opened. */
  readonly opened = output<void>();
  /**
   * Emits whenever the expanded state of the Expandable changes.
   * Primarily used to facilitate two-way binding.
   */
  readonly expandedChange = output<boolean>();

  readonly content = viewChild('content', { read: ElementRef });
  readonly toggleButton = viewChild(MatButton);
  buttonIcon = { down: 'chevron-down', up: 'chevron-up' };
  buttonHeight = 36;
  defaultHeight = 70;
  multiplier = 1.75;
  base = 10000;
  align = 3;

  viewChecked = false;

  calcHeight = 70;
  contentHeight = -1;
  transitionTimeoutID;

  get styleString() {
    return `
      --button-height: ${this.buttonHeight}px;
      --content-height: ${this.contentHeight}px;
      --calc-height: ${this.calcHeight}px;
    `;
  }

  constructor() {}

  ngDoCheck() {
    const content = this.content();
    const el = content?.nativeElement;
    const calcHeight = this.calc(el?.offsetWidth);
    this.expandable = el?.offsetHeight >= calcHeight;
    this.calcHeight = calcHeight;
    this.contentHeight = content?.nativeElement?.scrollHeight || -1;

    const toggleButton = this.toggleButton();
    if (toggleButton) {
      this.buttonHeight =
        toggleButton._elementRef.nativeElement.offsetHeight;
    }
  }

  ngOnDestroy() {}

  calc(width) {
    const area = this.multiplier * this.base * this.ratio();
    return Math.ceil(area / width) + this.align;
  }

  public toggle() {
    const willBeExpanded = this.expanded || this.expanding ? false : true;
    if (this.transitionTimeoutID) {
      clearTimeout(this.transitionTimeoutID);
    }

    if (willBeExpanded) {
      this.expanding = true;
      this.collapsing = false;
      this.transitionTimeoutID = setTimeout(() => {
        // after the animation
        this.expanded = true;
        this.expanding = false;
        this.expandedChange.emit(true);
        this.opened.emit();
      }, 500);
    } else {
      this.expanded = false;
      this.expanding = false;
      // use the collapsing class quickly to trigger the smooth transition
      this.collapsing = true;
      this.transitionTimeoutID = setTimeout(() => {
        this.collapsing = false;
        this.expandedChange.emit(false);
        this.closed.emit();
      }, 500);
    }
  }
}
