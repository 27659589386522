<div class="drag-grip"></div>
@if (hidden()) {
  <div class="hidden-indicator"></div>
}
<ng-content select="[matListItemAvatar],[matListItemIcon]"></ng-content>
<div class="mdc-list-item__content">
  @if (hidden() && hiddenLabel()) {
    <mulo-status-tag [description]="hiddenDescription()">{{ hiddenLabel() }}</mulo-status-tag>
  }
  <ng-content select="[matListItemTitle]"></ng-content>
  <ng-content select="[mat-line], [matLine], [matListItemLine]"></ng-content>
  <span #unscopedContent class="mat-mdc-list-item-unscoped-content" (cdkObserveContent)="updateItemLines(true)">
    <ng-content></ng-content>
  </span>
</div>
<ng-template matMenuContent #keepActionsBlock>
  <ng-content select="mulo-action-list-item-action[keepVisible]"></ng-content>
</ng-template>
<ng-template matMenuContent #actionsBlock>
  <ng-content select="mulo-action-list-item-action"></ng-content>
</ng-template>

<ng-container *ngTemplateOutlet="keepActionsBlock"></ng-container>
@if (!useMenu()) {
  <ng-container *ngTemplateOutlet="actionsBlock"></ng-container>
}

@if (!disabled && mainActionCount() > 0 && useMenu()) {
  <button mat-icon-button [matMenuTriggerFor]="actionsMenu" [attr.aria-label]="moreBtnLabel()">
    <mat-icon svgIcon="dots-vertical"></mat-icon>
  </button>
  <mat-menu #actionsMenu="matMenu" xPosition="before">
    <ng-container *ngTemplateOutlet="actionsBlock"></ng-container>
  </mat-menu>
}
<ng-content select="[matListItemMeta]"></ng-content>

<ng-content select="mat-divider"></ng-content>

<!--
  Strong focus indicator element. MDC uses the `::before` pseudo element for the default
  focus/hover/selected state, so we need a separate element.
-->
<div class="mat-mdc-focus-indicator"></div>
