import {
  Component,
  OnInit,
  inject,
  input,
  output,
  viewChild
} from '@angular/core';
import {
    UntypedFormControl,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { Subject, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { SettingsDataService } from '../../settings/settings-data.service';
import { Settings } from '../../shared/configurations/settings.constant';
import { ResearcherOrganization } from '../../shared/interfaces/researcher-organization.interface';
import { InternalOrganizationService } from './internal-organization.service';
import { CustomTranslatePipe } from '../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../shared/pipes/highlight-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'esp-internal-organization-autocomplete-input',
    templateUrl: './internal-organization-autocomplete-input.component.html',
    styleUrls: ['./internal-organization-autocomplete-input.component.scss'],
    imports: [
        MatFormField,
        NgClass,
        MatLabel,
        MatError,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        MatOption,
        NgxMatSelectSearchModule,
        MatIcon,
        AsyncPipe,
        TranslateModule,
        HighlightFilterPipe,
        CustomTranslatePipe,
    ],
})
export class InternalOrganizationAutocompleteInputComponent implements OnInit {
    settingsDataService = inject(SettingsDataService);
    private internalOrganizationService = inject(InternalOrganizationService);

    readonly internalOrgControl = input(new UntypedFormControl());
    readonly label = input<string>(undefined);
    readonly placeholder = input<string>(undefined);
    readonly required = input<boolean>(undefined);
    readonly classField = input<string>(undefined);
    readonly errorMessage = input('research.field.required.error.message');

    readonly departmentChange = output<ResearcherOrganization>();

    departmentSearchInProgress = false;
    displayDepartmentInput = true;
    organizationsFilterCtrl = new UntypedFormControl('');
    filteredOrganizations$;
    readonly departmentInputElem = viewChild('departmentInput');

    private destroy = new Subject();

    ngOnInit(): void {
        this.internalOrganizationService.internalOrg.subscribe((data) => {
            this.filteredOrganizations$ =
                this.organizationsFilterCtrl.valueChanges.pipe(
                    startWith(data),
                    distinctUntilChanged(),
                    takeUntil(this.destroy),
                    map(() => this.filterOrganizations()),
                );
        });
    }

    filterOrganizations() {
        if (!this.internalOrganizationService.departments) {
            return;
        }

        const search: string = this.organizationsFilterCtrl.value
            ?.toString()
            .toLowerCase();
        if (typeof search !== 'string') {
            return;
        }

        return this.internalOrganizationService.departments?.filter((option) =>
            option.organizationName?.toLowerCase()?.includes(search),
        );
    }

    get numberOfItemsToDisplay() {
        return Settings.MAX_DISPLAYED_ITEMS_IN_DROP_DOWN;
    }

    onDepartmentSelect(department: ResearcherOrganization, event: any) {
        const internalOrgControl = this.internalOrgControl();
        if (
            !event.isUserInput ||
            internalOrgControl.value === department?.organizationCode ||
            internalOrgControl.value === department?.organizationName
        ) {
            return;
        }
        this.departmentChange.emit(department);
    }
}
