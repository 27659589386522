import { Component, HostBinding, Input, OnInit, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';


export interface Position {
  x: number;
  y: number;
}

/**
 *  Avatars are used to show a visual representation of an individual
 */
@Component({
    selector: 'mulo-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    imports: [
        MatIcon,
        SvgViewboxDirective
    ]
})
export class AvatarComponent implements OnInit {
  private sanitizer = inject(DomSanitizer);

  /**
   * @internal
   */
  _image;

  /**
   * @internal
   */
  @HostBinding('class.mulo-avatar') get() {
    return true;
  }

  /**
   *  The url for the image, can be base64 too
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input()
  get image() {
    return this._image;
  }
  set image(val: string) {
    if (val == null || val === '') {
      val = null;
      this.imageLoaded = false;
    }
    this._image = this.sanitizer.bypassSecurityTrustResourceUrl(val);
  }

  /**
   * square or round image
   */
  readonly shape = input<'square' | 'round'>('square');
  /**
   * The description to go into the `alt` attribute
   */
  readonly description = input<string>(undefined);
  /**
   * Image size (1:1 ratio)
   */
  readonly size = input<string | number>(undefined);
  /**
   * Rounded border radius
   */
  readonly radius = input<number>(4);
  /**
   * Image position override; helps when the image shouldn’t be centered
   */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() position: Position;
  /**
   * Inititals to show in the absence of image
   */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() initials: string;
  /**
   * Image overlay (optional) state
   * @internal
   */
  public overlayVisible: boolean = false;
  /**
   * Flag when avatar image loaded
   * @internal
   */
  imageLoaded: boolean = false;

  /**
   * @internal
   */
  ngOnInit() {}

  get sizeString() {
    // check if size input is a number, or a string (with or without a unit)
    const size = this.size();
    if (typeof size === 'number' || /^\d+$/.test(size)) {
      return `${size}px`;
    } else {
      return size;
    }
  }

  /**
   * @internal
   */
  calcFontSize() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `calc((${this.size()} * 0.025) * 1rem)`
    );
  }

  /**
   * @internal
   */
  showOverlay() {
    this.overlayVisible = true;
  }

  /**
   * @internal
   */
  hideOverlay() {
    this.overlayVisible = false;
  }
}
