import { Injectable, inject } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from './configuration-handler.service';
import { RouterService } from './router.service';

@Injectable()
export class PivotGuard {
    private router = inject(Router);
    private configurationService = inject(ConfigurationHandlerService);
    private routerService = inject(RouterService);

    institutionCode: string;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }
    canLoad(
        route: Route,
        segments: UrlSegment[],
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        // return true;
        const isPivotEnabled =
            this.configurationService.portalConfigurationHedearIncludePivot();
        if (!isPivotEnabled) {
            this.routerService.goToHomepage();
        }
        return isPivotEnabled;
    }
}
