import {
    Component,
    Input,
    OnInit,
    SimpleChanges,
    OnChanges,
    input,
    output as output_1,
    output,
    viewChild,
    ElementRef,
} from '@angular/core';
import {
    UntypedFormControl,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CustomTranslatePipe } from '../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { FocusDirective } from '../../shared/directives/focus/focus.directive';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '@exl-ng/mulo-common';
import { NgClass } from '@angular/common';
import {
    MatFormField,
    MatLabel,
    MatPrefix,
    MatSuffix,
    MatHint,
    MatError,
} from '@angular/material/form-field';

@Component({
    selector: 'exl-plain-text-input',
    templateUrl: './plain-text-input.component.html',
    styleUrls: ['./plain-text-input.component.scss'],
    host: { class: 'exl-plain-text-input' },
    imports: [
        MatFormField,
        NgClass,
        InputExpanderDirective,
        MatLabel,
        MatInput,
        FormsModule,
        ReactiveFormsModule,
        FocusDirective,
        CdkTextareaAutosize,
        MatIcon,
        MatPrefix,
        MatTooltip,
        MatIconButton,
        MatSuffix,
        MatHint,
        MatError,
        TranslateModule,
        CustomTranslatePipe,
    ],
})
export class PlainTextInputComponent implements OnInit, OnChanges {
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() input: UntypedFormControl;
    readonly jump = input(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() label: string;
    readonly placeholder = input<string>(undefined);
    readonly autoPopulated = input(false);
    readonly multipleLines = input(false);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() lengthLimit: number;
    readonly focus = input(false);
    readonly expanded = input(false);
    readonly autocompleteLabel = input<string>(undefined, {
        alias: 'autocomplete',
    });
    readonly output = output<string>();

    readonly autosize = viewChild<CdkTextareaAutosize>('autosize');

    readonly inputElement = viewChild<ElementRef>('inputElement');

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.focus && changes.focus.currentValue) {
            setTimeout(() => {
                this.focusOnInput();
            }, 0);
        }
    }

    focusOnInput() {
        this.inputElement().nativeElement.focus();
    }

    clearInput() {
        this.input.reset();
    }
}
