@if (menuStyle() === 'menu') {
  <button mat-button [matMenuTriggerFor]="menu" [attr.aria-label]="menuA11yLabel">
    <ng-container *ngTemplateOutlet="btnContent"></ng-container>
  </button>
}
@if (menuStyle() !== 'menu') {
  <button
    mat-button
    (click)="showBottomSheet()"
    [attr.aria-expanded]="menuOpen"
    [attr.aria-label]="menuA11yLabel"
    >
    <ng-container *ngTemplateOutlet="btnContent"></ng-container>
  </button>
}

<ng-template #btnContent>
  @if (hasProxy) {
    <mulo-avatar
      class="proxy-avatar"
      [image]="proxyImage()"
      [initials]="proxyInitials()"
      [description]="'research.alt.profile.img.with.name' | translate : { value: proxyName() }"
      size="30"
      [radius]="4"
    ></mulo-avatar>
  }

  <span [class.bracketed-avatar]="hasProxy">
    @if (hasProxy) {
      <span class="avatar-bracket">&#123;</span>
    }
    <mulo-avatar
      [image]="userImage()"
      [initials]="userInitials()"
      [description]="'research.alt.profile.img.with.name' | translate : { value: userName() }"
      [size]="hasProxy ? '24' : '30'"
      [radius]="4"
      [shape]="'square'"
    ></mulo-avatar>
  </span>
  @if (hasProxy) {
    <span class="avatar-bracket">&#125;</span>
  }
  @if (showArrow()) {
    <mat-icon svgIcon="menu-down" class="menu-icon"></mat-icon>
  }
</ng-template>

<mat-menu #menu="matMenu" class="user-menu" yPosition="below">
  <exl-user-menu-panel
    #userMenuPanel
    [userName]="userName()"
    [proxyName]="proxyName()"
    [menuItems]="menuItems()"
    (signOut)="doSignOut()"
    (action)="onMenuAction($event)"
    >
  </exl-user-menu-panel>
</mat-menu>
