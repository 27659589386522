import { CdkTextareaAutosize, CdkAutofill } from '@angular/cdk/text-field';
import {
  Component,
  HostBinding,
  Input,
  NgZone,
  SimpleChanges,
  HostListener,
  inject,
  input,
  output as output_1,
  output,
  OnChanges,
  viewChild,
  ElementRef,
} from '@angular/core';
import { ChatService } from '../chat.service';
import { take } from 'rxjs';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '../../../directives/input-expander.directive';
import { NgClass } from '@angular/common';
import {
  MatFormField,
  MatLabel,
  MatPrefix,
  MatSuffix,
  MatHint,
  MatError,
} from '@angular/material/form-field';

@Component({
  selector: 'mulo-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  host: { class: 'mulo-chat-input' },
  imports: [
    MatFormField,
    NgClass,
    InputExpanderDirective,
    MatLabel,
    MatInput,
    FormsModule,
    CdkTextareaAutosize,
    CdkAutofill,
    MatIcon,
    MatPrefix,
    MatTooltip,
    MatIconButton,
    MatSuffix,
    MatHint,
    MatError,
  ],
})
export class ChatInputComponent implements OnChanges {
  private service = inject(ChatService);
  private ngZone = inject(NgZone);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() input: string;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() inputLabel = 'Your reply';
  readonly placeholder = input<string>(undefined);
  readonly autopopulateDisabled = input(false);
  readonly autoPopulatedInfo = input(
    'The title was auto-populated from your selected asset',
  );
  readonly multipleLines = input(true);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() focus = false;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() lengthLimit = 1000;
  readonly expanded = input(true);
  readonly showClearButton = input(true);
  readonly clearButtonLabel = input('Clear input');
  readonly required = input(false);
  readonly errorMsgHtml = input('This field is <strong>required</strong>');
  readonly output = output();

  readonly autosize = viewChild<CdkTextareaAutosize>('autosize');
  readonly inputElement = viewChild<ElementRef>('inputElement');

  model;
  autoPopulated = false;
  valid;

  readonly userEntry = output();
  @HostBinding('class.is-expanded') inputExpanded = true;
  reply;

  constructor() {
    this.service.userInput$.subscribe((input) => {
      this.input = input;
      this.model = this.input;
    });
  }

  onInput(val) {
    this.reply = val;
    this.service.input = val;
  }

  @HostListener('keyup.enter', ['$event'])
  onKeyup(event) {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (this.reply) {
        this.userEntry.emit(this.reply);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input && changes.input.currentValue) {
      const val = changes.input.currentValue;
      const firstChange = changes.input.firstChange;
      // autopopulate
      if (val !== this.model && !firstChange) {
        this.autoPopulated = val;
        if (!this.expanded()) {
          this.triggerResize();
        }
      }
      this.model = this.input;
    } else if (changes.input) {
      this.model = this.input;
    }
    if (changes && changes.focus) {
      setTimeout(() => {
        this.focusOnInput();
      }, 0);
    }
  }

  onInputChange(val) {
    if (val != this.autoPopulated) {
      this.autoPopulated = null;
    }
    if (!this.expanded()) {
      this.triggerResize();
    }
    this.onInput(this.model);
  }

  clearInput() {
    this.model = '';
    this.autoPopulated = null;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      setTimeout(() => {
        // this.autosize.reset()
        this.autosize().resizeToFitContent(true);
      }, 0);
    });
  }

  focusOnInput() {
    this.inputElement().nativeElement.focus();
    setTimeout(() => {
      this.focus = false;
    }, 0);
  }

  onAutoFill(event) {
    console.log(event);
  }
}
