import { Component, input } from '@angular/core';
import { PickerItemComponent } from './picker-item/picker-item.component';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMiniFabButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'exl-picker',
    templateUrl: './picker.component.html',
    styleUrls: ['./picker.component.scss'],
    imports: [
        MatMiniFabButton,
        MatMenuTrigger,
        MatIcon,
        PickerItemComponent,
        MatButton
    ]
})
export class PickerComponent {
    readonly items = input(undefined);
    readonly pickerIcon = input(undefined);
    readonly rtlMirrorIcon = input<boolean>(undefined);
    public navItems = [];

    constructor() {
        const list = {};
        setTimeout(() => {
            list['name'] = 'All';
            list['children'] = this.items();
            this.navItems.push(list);
        }, 1000);
    }
}
