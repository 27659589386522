import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Renderer2,
  inject,
  input,
  viewChild
} from '@angular/core';
import {
    UntypedFormControl,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MatDatepicker,
    MatDatepickerToggle,
    MatDatepickerInput,
} from '@angular/material/datepicker';
import { DateUtils } from '../../shared/utils/date.utils';
import moment from 'moment';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { preventOnKeyboardOrigin } from '../../shared/annotations/prevent-on-keyboard-origin.annotation';
import {
    MatFormField,
    MatLabel,
    MatHint,
    MatPrefix,
    MatSuffix,
    MatError,
} from '@angular/material/form-field';
import { CustomTranslatePipe } from '../../shared/pipes/custom-translate.pipe';
import { AriaCalendarDirective } from '../../shared/directives/a11y/aria-calendar.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AutomationClassDirective } from '../../shared/directives/tests/automation-tests-class.directive';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'exl-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    imports: [
        MatFormField,
        MatInput,
        FormsModule,
        MatDatepickerInput,
        AutomationClassDirective,
        ReactiveFormsModule,
        MatLabel,
        MatSelect,
        MatOption,
        MatHint,
        TranslateModule,
        MatIcon,
        MatPrefix,
        MatTooltip,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        AriaCalendarDirective,
        MatError,
        CustomTranslatePipe,
    ],
})
export class DateInputComponent implements OnInit, AfterViewInit {
    private systemDateFormatService = inject(SystemDateFormatService);
    private renderer = inject(Renderer2);

    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() fromDate: UntypedFormControl;
    readonly toDate = input<UntypedFormControl>(undefined);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() fromDateLabel: string;
    readonly toDateLabel = input('research.status.date.todate');
    readonly range = input(false);
    readonly error = input(false);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() rangeError = false;
    readonly showHint = input(false);
    readonly autoPopulatedFromDate = input(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() usePicker = true;
    readonly dateStyle = input<'full' | 'year'>('full');
    readonly fromYear = input(1950);

    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() fromDateTranslation: string;
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() toDateTranslation: string;

    currentDate: string;
    years: number[];

    readonly fromDatePickerRef = viewChild<MatDatepicker<any>>('fromDatePicker');
    readonly toDatePickerRef = viewChild<MatDatepicker<any>>('toDatePicker');

    readonly fromDateFormfield = viewChild<MatFormField>('fromDateFormfield');
    readonly fromDateToggle = viewChild<MatDatepickerToggle<any>>('fromDateToggle');
    readonly toDateFormfield = viewChild<MatFormField>('toDateFormfield');
    readonly toDateToggle = viewChild<MatDatepickerToggle<any>>('toDateToggle');

    ngOnInit() {
        this.fromDateLabel =
            this.toDate() || this.range()
                ? 'research.status.date.fromdate'
                : 'research.status.date';
        this.currentDate = moment().format(
            this.systemDateFormatService.getSystemDateFormat(),
        );
        this.generateYears(this.fromYear());

        this.fromDate.addValidators([this.dateRangeError.bind(this)]);
        this.fromDate.updateValueAndValidity();
        this.toDate()?.valueChanges?.subscribe(() =>
            this.fromDate.updateValueAndValidity(),
        );
    }

    dateRangeError() {
        const start = this.fromDate?.value;
        const end = this.toDate()?.value;
        if (!start || !end) return null;
        if (
            !moment(start, 'YYYYMMDD').isSameOrBefore(moment(end, 'YYYYMMDD'))
        ) {
            this.rangeError = true;
            return {
                rangeError: true,
            };
        }
        return null;
    }

    ngAfterViewInit() {
        this.addDescriptionToggleButton(
            this.fromDateFormfield(),
            this.fromDateToggle(),
        );
        this.addDescriptionToggleButton(
            this.toDateFormfield(),
            this.toDateToggle(),
        );
    }

    /**
     * ! Accessibility
     */
    addDescriptionToggleButton(
        formField: MatFormField,
        datepickerToggle: MatDatepickerToggle<any>,
    ) {
        if (formField?.getLabelId() && datepickerToggle) {
            this.renderer.setAttribute(
                datepickerToggle._button._elementRef.nativeElement,
                'aria-describedby',
                formField.getLabelId(),
            );
        }
    }

    @preventOnKeyboardOrigin
    onDateChange() {
        if (this.range() && this.fromDate.value) {
            this.toDatePickerRef()?.open();
        }
    }

    generateYears(startYear) {
        const currentYear = new Date().getFullYear();
        this.years = [];
        startYear = startYear || this.fromYear();
        while (startYear <= currentYear) {
            this.years.push(startYear++);
        }
        return this.years.reverse();
    }
}
