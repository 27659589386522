import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../../../shared/configurations/rest-api-base.config';
import {DepositFormService} from '../../deposit-form.service';
import {UrlUtils} from '../../../shared/utils/url.utils';

@Injectable()
export class DepositPublicationDetailsService {
    private http = inject(HttpClient);


    /* Save the last issn and title in order to prevent a new search when the title is filled from the issn search
    and vise versa */
    private _lastPartOfIssn = '';
    private _lastPartOfTitle = '';

    institutionCode: string;

    issnRegex = new RegExp('^[0-9]{4}-?[0-9]{3}[0-9|X|x]$');

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    enrichFromIssn(issn){
        const params = new HttpParams().set('issn', issn)
            .set('institution', this.institutionCode);
        return this.http.get(RestApiBase.ISSN_ENRICHMENT, {params:params});
    }

    enrichFromJournalName(journalName){
        journalName = journalName.concat('*');
        const params = new HttpParams().set('journalName', journalName)
            .set('institution', this.institutionCode);
        return this.http.get(RestApiBase.JOURNAL_NAME_ENRICHMENT, {params:params});
    }

    get lastPartOfTitle(): string {
        return this._lastPartOfTitle;
    }

    set lastPartOfTitle(value: string) {
        this._lastPartOfTitle = value;
    }

    get lastPartOfIssn(): string {
        return this._lastPartOfIssn;
    }

    set lastPartOfIssn(value: string) {
        this._lastPartOfIssn = value;
    }

    isEmptyObject(o) {
        return Object.keys(o).every(function(x) {
            return o[x].length === 0 || o[x] === 'UNKNOWN';
        });
    }

}
