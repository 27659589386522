import { Component, OnInit, ChangeDetectionStrategy, SimpleChanges, OnChanges, ViewChild, input, contentChildren } from '@angular/core';
import { FacetSectionComponent } from './facet-section/facet-section.component';
import { FacetItemComponent } from './facet-item/facet-item.component';
import { MatAccordion } from '@angular/material/expansion';

@Component({
    selector: 'mulo-facets',
    templateUrl: './facets.component.html',
    styleUrls: ['./facets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        'class': 'mulo-facets',
        '[class.has-checkboxes]': 'showCheckboxes()',
        '[class.is-disabled]': 'disabled()'
    },
    standalone: true
})
export class FacetsComponent implements OnInit, OnChanges {

  readonly showCheckboxes = input<boolean>(false);
  readonly disabled = input<boolean>(false
// @ViewChild(MatAccordion) accordion: MatAccordion
);

  // @ViewChild(MatAccordion) accordion: MatAccordion

  readonly items = contentChildren(FacetItemComponent, { descendants: true });

  // @ContentChildren(FacetSectionComponent, { descendants: true }) sections: QueryList<FacetSectionComponent>

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return
    if (changes.showCheckboxes) {
      if (this.items()) {
        this.setCheckboxes()
      }
    }
  }

  setCheckboxes() {
    const val = this.showCheckboxes()
    const items = this.items();
    if (items) {
      items.forEach(item => item.hasCheckbox = val)
    }
  }
}
