import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from '../../../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../../../shared/utils/url.utils';
import { I18nService } from '../../../core/i18n.service';

@Injectable()
export class DepositAuthorsService {
    private http = inject(HttpClient);
    private i18nService = inject(I18nService);

    // Needed for check if user exist when the users stored in separate lists
    existsUsersList = [];
    private _lastChosenAffiliation = '';
    institutionCode: string;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getCreatorsContributors(creatorsContributorsLookup) {
        let params = new HttpParams().set(
            'creatorContributorLookup',
            creatorsContributorsLookup,
        );
        params = params.set('institution', this.institutionCode);
        params = params.set('lang', this.i18nService.getLanguage());
        return this.http.get(RestApiBase.CREATORS_CONTRIBUTORS_LOOKUP, {
            params,
        });
    }

    getCreatorsContributorsForETD(creatorsContributorsLookup) {
        let params = new HttpParams().set(
            'creatorContributorLookup',
            creatorsContributorsLookup,
        );
        params = params.set('institution', this.institutionCode);
        params = params.set('lang', this.i18nService.getLanguage());
        return this.http.get(RestApiBase.CREATORS_CONTRIBUTORS_ETD_LOOKUP, {
            params,
        });
    }

    addNonAffiliatedResearcherUser(
        lastName,
        firstName,
        middleName,
        nameSuffix,
        affiliationCodeList,
    ) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.ADD_RESEARCHER_USER,
            {
                lastName,
                firstName,
                middleName,
                nameSuffix,
                affiliationCodeList,
            },
            { params, responseType: 'text' },
        );
    }

    get lastChosenAffiliation(): string {
        return this._lastChosenAffiliation;
    }

    set lastChosenAffiliation(value: string) {
        this._lastChosenAffiliation = value;
    }

    isUserExist(id) {
        return this.existsUsersList.indexOf(id) > -1;
    }

    addUserId(id) {
        this.existsUsersList.push(id);
    }

    removeUserId(id) {
        const index = this.existsUsersList.indexOf(id);
        if (index != -1) {
            this.existsUsersList.splice(index, 1);
        }
    }
}
