import {
  Component,
  HostBinding,
  OnInit,
  input,
  contentChild
} from '@angular/core';
import { SpinnerComponent } from '../spinner';
import { OpacityInAnimation } from '../../animations';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'mulo-loading-slate',
    templateUrl: './loading-slate.component.html',
    styleUrls: ['./loading-slate.component.scss'],
    animations: [OpacityInAnimation],
    host: {
        class: 'mulo-loading-slate is-loading',
    },
    imports: [NgStyle, MatProgressSpinner, SpinnerComponent]
})
export class LoadingSlateComponent implements OnInit {
  // @HostBinding('class.is-loading') @Input() isLoading: boolean;
  // @Input() spinnerDisabled: boolean = false;
  readonly height = input<string>('calc(100vh - 300px)');

  readonly spinnerStyle = input<'material' | 'mulo'>('mulo');
  readonly spinnerDiameter = input<number>(80);

  readonly projectedSpinner = contentChild(SpinnerComponent);

  private _active: boolean = false;
  @HostBinding('@opacityInAnimation') get visible(): boolean {
    return this._active;
  }

  constructor() {}

  ngOnInit() {
    this._active = true;
  }
}
