<div class="header-wrap">
  <h2 class="padding-zero" mat-dialog-title cdkFocusInitial>
    {{ data.title }}
  </h2>
  <div class="panel-buttons">
    @if (data.actions.close) {
      <button mat-icon-button [mat-dialog-close]="service.chat" class="is-large panel-button">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    }
  </div>
</div>

<mulo-chat mat-dialog-content [entries]="data.chat" [singleInstance]="data.singleInstanceChat"> </mulo-chat>
<div mat-dialog-actions>
  <mulo-chat-input (userEntry)="addUserMessage()"></mulo-chat-input>
  @if (data.actions.confirm) {
    <button
      mat-button
      class="is-large has-icon"
      (click)="addUserMessage()"
      [color]="actionFocus === 'confirm' ? accentColor : null"
      [class.is-large]="actionFocus === 'confirm'"
      [disabled]="!service.input"
      >
      <mat-icon class="mat-icon-rtl-mirror" svgIcon="send"></mat-icon>
      <span>{{ data.actions.confirm }}</span>
    </button>
  }
</div>
