import { Component, inject, input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ChatService } from '../chat.service';
import { ChatInputComponent } from '../chat-input/chat-input.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChatComponent } from '../chat.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'mulo-chat-dialog',
    templateUrl: './chat-dialog.component.html',
    styleUrls: ['./chat-dialog.component.scss'],
    imports: [
        MatDialogTitle,
        MatIconButton,
        MatDialogClose,
        MatIcon,
        ChatComponent,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        ChatInputComponent,
        MatButton
    ]
})
export class ChatDialogComponent {
  data = inject(MAT_DIALOG_DATA);
  service = inject(ChatService);
  private dialogRef = inject<MatDialogRef<ChatDialogComponent>>(MatDialogRef);

  readonly title = input<string>('Chat');
  actionFocus: string = 'confirm';
  accentColor: string = 'accent';

  constructor() {
    if (this.data.actionFocus) this.actionFocus = this.data.actionFocus;
  }

  addUserMessage() {
    this.service.addUserMessage();
    setTimeout(() => {
      this.dialogRef.close(this.service.chat);
    }, 1000);
  }
}
