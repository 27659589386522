import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { GroupByPipe } from '../../../shared/pipes/group-by.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconAnchor, MatButton } from '@angular/material/button';

import { MatSelectionList, MatListSubheaderCssMatStyler, MatListOption, MatListItemAvatar } from '@angular/material/list';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';


@Component({
    selector: 'esp-license-select-dialog',
    templateUrl: './license-select-dialog.component.html',
    styleUrls: ['./license-select-dialog.component.scss'],
    imports: [MatDialogTitle, MatCheckbox, FormsModule, CdkScrollable, MatDialogContent, MatSelectionList, MatListSubheaderCssMatStyler, MatListOption, MatIconAnchor, MatListItemAvatar, MatIcon, MatDialogActions, MatButton, MatDialogClose, TranslateModule, GroupByPipe, CustomTranslatePipe]
})
export class LicenseSelectDialogComponent {
    dialogRef = inject<MatDialogRef<LicenseSelectDialogComponent>>(MatDialogRef);
    data = inject(MAT_DIALOG_DATA);


    licenses = [];
    applyToAll = false;
    saveButton = false;

    constructor() {
        const data = this.data;

        data.licenseCodes.forEach(row => {
            const licenseRow: any = new Object();
            licenseRow['title'] = row.source1;
            licenseRow['category'] = row.source2;
            licenseRow['link'] = row.source3;
            licenseRow['forResearcherView'] = row.source5;

            this.licenses.push(licenseRow);
        });
    }

    selectLicense(license){
        const obj = new Object();
        obj['license'] = license;
        obj['applyToAll'] = this.applyToAll;
        this.dialogRef.close(obj);
    }

    showLicenseInfo(event){
        event.stopPropagation();
    }

    checkboxChanged(){
        if (this.data.selected) {
            this.saveButton = true;
        }
    }

    onSave(){
        const license = this.licenses.filter(obj => obj.title === this.data.selected)[0];
        this.selectLicense(license);
    }


}
