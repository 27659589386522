import {
  Component,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  inject,
  input,
} from '@angular/core';
import { MuloNavLink } from '../../../models/nav-link.model';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { NavComponent } from '../nav.component';
import { defer, of, Observable, take } from 'rxjs';
import { addObjectDepthLevel, getArrayDepth } from '../../../utils';
import { Location, NgTemplateOutlet } from '@angular/common';
import { VerticalTabsDirective } from '../../../directives/vertical-tabs.directive';
import { MatTabNav, MatTabLink, MatTabNavPanel } from '@angular/material/tabs';

@Component({
  selector: 'mulo-vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.scss'],
  host: {
    class: 'mulo-vertical-nav',
    '[class.hasnt-inkbar]': 'this.disableInkbar()',
  },
  imports: [
    MatTabNav,
    VerticalTabsDirective,
    MatTabLink,
    RouterLink,
    NgTemplateOutlet,
    MatTabNavPanel,
  ],
})
export class VerticalNavComponent extends NavComponent implements OnChanges {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private location = inject(Location);

  activeItem: MuloNavLink;
  levelsDepth = 0;
  expandTime = 400;
  readonly requiredLabel = input('Required');

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return null;
    }
    if (changes.items) {
      const items = this.items();
      addObjectDepthLevel(items);
      this.levelsDepth = getArrayDepth(items);
    }
    if (changes.selectedIndex) {
      const idx = changes.selectedIndex;
      this.emitNavDirection(idx.previousValue, idx.currentValue);
      this.updateActiveItem(idx.currentValue);
    }
    if (changes.selectedPath && changes.selectedPath.currentValue) {
      defer(() => this.findPathItems())
        .pipe(take(1))
        .subscribe();
    }
  }

  findPathItems(): Observable<any> {
    const findRecursively = (arr: MuloNavLink[], level = 0) => {
      const depth = this.selectedPath.length;
      return arr.forEach((obj) => {
        if (obj?.name !== this.selectedPath[level] && !obj?.expanded) {
          obj.toggled = false;
        }
        if (obj?.name === this.selectedPath[level]) {
          if (level < depth) {
            obj.toggled = true;
          }
          if (Array.isArray(obj?.children)) {
            findRecursively(obj.children, level + 1);
          }
          if (level === depth - 1) {
            setTimeout(() => {
              const id = obj?.id;
              this.emitNavDirection(this.selectedIndex, id);
              // this.updateActiveItem(id)
              this.selectedIndex = id;
            }, this.expandTime);
          }
        }
      });
    };

    return of(findRecursively(this.items()));
  }

  trackItem(index: number, item: any) {
    return index;
  }

  updateActiveItem(index?: number) {
    const i = index ? index : this.selectedIndex;
    this.activeItem = this.items()[i];
  }

  changeItem(selected: MuloNavLink, fragment?: boolean) {
    if (fragment) {
      const routeWithFragment = this.router
        .createUrlTree([], {
          relativeTo: this.activatedRoute,
          fragment: selected.name,
          queryParamsHandling: 'preserve',
        })
        .toString();
      this.location.replaceState(routeWithFragment);
      this.itemSelected.emit(selected);
      return;
    }

    const toggle = (array: MuloNavLink[]) => {
      array.forEach((el: MuloNavLink) => {
        if (el.level !== 0) {
          el.toggled = false;
        }
        if (!el.expanded) {
          el.toggled = false;
        }
        if (el?.name === selected?.name && el?.label === selected?.label) {
          el.toggled = true;
        }
        if (el.children) {
          toggle(el.children);
        }
      });
    };
    const items = this.items();
    if (selected.level !== this.levelsDepth) {
      toggle(items);
    }

    this.emitNavDirection(this.selectedIndex, items.indexOf(selected));
    if (selected.url) {
      this.router.navigate([selected.url], { relativeTo: this.activatedRoute });
    } else {
      this.itemSelected.emit(selected);
    }
  }
}
