import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from '../../../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../../../shared/utils/url.utils';

@Injectable()
export class DepositSearchForService {
    private http = inject(HttpClient);

    institutionCode: string;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    enrichFromDoi(subtype, doi, includeExternalSearch) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('doi', doi)
            .set('resourceType', subtype)
            .set('includeExternalSearch', includeExternalSearch);
        return this.http.get(RestApiBase.DOI_ENRICHMENT, { params });
    }

    enrichFromFileWithDoi(subtype, doi) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('doi', doi)
            .set('resourceType', subtype);
        return this.http.get(RestApiBase.FILE_WITH_DOI_ENRICHMENT, {
            params,
        });
    }

    enrichFromPmid(subtype, pmid, includeExternalSearch) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('pmid', pmid)
            .set('resourceType', subtype)
            .set('includeExternalSearch', includeExternalSearch);
        return this.http.get(RestApiBase.PMID_ENRICHMENT, { params });
    }

    enrichFromTitle(subtype, titlePrefix, includeExternalSearch, userId) {
        let params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('title', titlePrefix)
            .set('resourceType', subtype)
            .set('includeExternalSearch', includeExternalSearch);
        if (userId) {
            params = params.set('userID', userId);
        }
        return this.http.get(RestApiBase.TITLE_ENRICHMENT, { params });
    }

    enrichFromIsbn(subtype, isbn, includeExternalSearch) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('isbn', isbn)
            .set('resourceType', subtype)
            .set('includeExternalSearch', includeExternalSearch);
        return this.http.get(RestApiBase.ISBN_ENRICHMENT, { params });
    }

    authorMatchingEnrichment(esploroRecordStr, mmsID) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this.http.put(
            RestApiBase.AUTHOR_MATHCHING_ENRICHMENT,
            { esploroRecordStr, mmsID },
            { params }
        );
    }
}
