<div class="cropper-wrapper">
  <image-cropper
    class="image-cropper"
    [class.is-loading]="!cropperReady"
    [imageBase64]="base64Image"
    [imageAltText]="labelImageAlt()"
    [maintainAspectRatio]="true"
    [aspectRatio]="1 / 1"
    [resizeToWidth]="200"
    [roundCropper]="false"
    format="png"
    output="base64"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  @if (!cropperReady) {
    <mulo-loader></mulo-loader>
  }
</div>
@if (cropperReady) {
  <div class="cropper-preview">
    <span class="margin-bottom-sm">{{ labelPreview() }}</span>
    <img [src]="croppedImage" [alt]="labelCroppedImageAlt()" />
  </div>
}
