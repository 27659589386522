import { Component, Input, input } from '@angular/core';
import { SpinnerComponent } from '../spinner';

@Component({
    selector: 'mulo-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    host: { class: 'mulo-loader' },
    imports: [SpinnerComponent]
})
export class LoaderComponent {
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() message: string;
  readonly orientation = input<'vertical' | 'horizontal'>('vertical');
  readonly zIndex = input<number>(undefined);

  constructor() {}
}
