import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  input
} from '@angular/core';

@Component({
    selector: 'mulo-orcid-logo',
    templateUrl: './orcid-logo.component.html',
    styleUrls: ['./orcid-logo.component.scss'],
    host: { class: 'mulo-orcid-logo' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class OrcidLogoComponent implements OnInit {
  readonly bgColor = input<string | null>(null);
  readonly size = input<number>(24);

  constructor() {}

  ngOnInit(): void {}
}
