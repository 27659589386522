<mat-form-field
  [ngClass]="expanded() ? 'is-expanded' : 'is-auto-expanding'"
  [class.is-autopopulated]="!autopopulateDisabled() && autoPopulated"
  [muloInputExpander]="model"
  class="is-comfortably-readable"
  >
  @if (inputLabel) {
    <mat-label>{{ inputLabel }}</mat-label>
  }
  @if (!multipleLines()) {
    <input
      matInput
      [(ngModel)]="model"
      (ngModelChange)="onInputChange($event)"
      [placeholder]="placeholder()"
      #inputElement
      [required]="required()"
      />
  }
  @if (multipleLines()) {
    <textarea
      matInput
      class="hasnt-scrollbar"
      [(ngModel)]="model"
      (ngModelChange)="onInputChange($event)"
      [placeholder]="placeholder()"
      cdkTextareaAutosize
      matAutosizeMinRows="1"
      (cdkAutofill)="onAutoFill($event)"
      #autosize="cdkTextareaAutosize"
      #inputElement
      [maxlength]="lengthLimit"
      [required]="required()"
      [rows]="1"
    ></textarea>
  }

  @if (!autopopulateDisabled() && autoPopulated) {
    <mat-icon
      matPrefix
      svgIcon="auto-populate"
      [matTooltip]="autoPopulatedInfo()"
    ></mat-icon>
  }
  @if (showClearButton() && model) {
    <button
      mat-icon-button
      matSuffix
      (click)="clearInput()"
      class="clear-button"
      [attr.aria-label]="clearButtonLabel()"
      >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  }
  @if (lengthLimit && model) {
    <mat-hint align="end">{{ model.length }} / {{ lengthLimit }}</mat-hint>
  }
  @if (!valid) {
    <mat-error [innerHTML]="errorMsgHtml()"></mat-error>
  }
</mat-form-field>
