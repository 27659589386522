import { Directive, Input, TemplateRef, ViewContainerRef, NgModule, ChangeDetectorRef, OnDestroy, inject } from '@angular/core';
import { Subject, switchMap, map } from 'rxjs';
import { aliasMap, MediaQueryAlias } from '../models/media-query-alias.model';
import { BreakpointObserver } from '@angular/cdk/layout';

@Directive({
    selector: '[mediaIf]',
    standalone: true
})
export class MediaIfDirective implements OnDestroy {
  private viewContainer = inject(ViewContainerRef);
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private breakpointObserver = inject(BreakpointObserver);
  private changeRef = inject(ChangeDetectorRef);

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input()
  set mediaIf(value: MediaQueryAlias | MediaQueryAlias[]) {
    this.matcher.next(value);
  }

  private hasView = false;
  private matcher = new Subject<MediaQueryAlias | MediaQueryAlias[]>();
  private subscription = this.matcher
    .pipe(
      switchMap((mq) =>
        this.breakpointObserver
          .observe(
            typeof mq === 'string'
              ? aliasMap[mq as MediaQueryAlias]
              : (mq as MediaQueryAlias[]).map((s) => aliasMap[s])
          )
          .pipe(map((bpState) => bpState.matches))
      )
    )
    .subscribe((match: boolean) =>
      match ? this.createView() : this.destroyView()
    );

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.changeRef.markForCheck();
      this.hasView = true;
    }
  }

  private destroyView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}


