import { Component, OnInit, Input, AfterContentInit, inject, input, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MediaService, SystemDateFormatService } from '@exl-ng/mulo-core';
import { GrowInAnimation, GrowOutAnimation, SlideInAnimation, InputExpanderDirective } from '@exl-ng/mulo-common';
import { LicenseSelectDialogComponent } from '../../../../parts/dialogs/license-select-dialog/license-select-dialog.component';
import { AccessRightsDialogComponent } from '../../../../parts/dialogs/access-rights-dialog/access-rights-dialog.component';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { ConfigurationHandlerService } from '../../../../core/configuration-handler.service';
import { MappingTable } from '../../../../shared/interfaces/mapping-table.interface';
import { DateUtils } from '../../../../shared/utils/date.utils';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';


@Component({
    selector: 'esp-deposit-file-metadata',
    templateUrl: './deposit-file-metadata.component.html',
    styleUrls: ['./deposit-file-metadata.component.scss'],
    host: { class: 'esp-deposit-file-metadata' },
    animations: [GrowInAnimation, GrowOutAnimation, SlideInAnimation],
    imports: [
        MatFormField,
        InputExpanderDirective,
        MatLabel,
        MatInput,
        FormsModule,
        MatIconButton,
        MatIcon,
        MatSelect,
        MatOption,
        MatButton,
        TranslateModule,
        CustomTranslatePipe
    ]
})
export class DepositFileMetadataComponent implements OnInit, AfterContentInit {
    matDialog = inject(MatDialog);
    media = inject(MediaService);
    depositFormDataService = inject(DepositFormDataService);
    configurationHandlerService = inject(ConfigurationHandlerService);
    private systemDateFormatService = inject(SystemDateFormatService);

    readonly textarea = viewChild(MatInput);
    readonly typeInput = viewChild(MatSelect);

    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() file;
    readonly index = input(undefined);

    private _displayAccessRights: boolean;
    // TODO: Skipped for migration because:
    //  Accessor inputs cannot be migrated as they are too complex.
    @Input()
    get displayAccessRights() {
        return this._displayAccessRights;
    }
    set displayAccessRights(display: boolean) {
        this._displayAccessRights = display ?? true;
    }

    private _displayDescription: boolean;
    // TODO: Skipped for migration because:
    //  Accessor inputs cannot be migrated as they are too complex.
    @Input()
    get displayDescription() {
        return this._displayDescription;
    }
    set displayDescription(display: boolean) {
        this._displayDescription = display ?? true;
    }

    private _displayLicense: boolean;
    // TODO: Skipped for migration because:
    //  Accessor inputs cannot be migrated as they are too complex.
    @Input()
    get displayLicense() {
        return this._displayLicense;
    }
    set displayLicense(display: boolean) {
        this._displayLicense = display ?? true;
    }

    private _displayContentType: boolean;
    // TODO: Skipped for migration because:
    //  Accessor inputs cannot be migrated as they are too complex.
    @Input()
    get displayContentType() {
        return this._displayContentType;
    }
    set displayContentType(display: boolean) {
        this._displayContentType = display ?? true;
    }

    showDescriptionButton = true;
    showAccessButton = true;
    showLicenseButton = true;
    showTypeButton = true;
    fileTypes = [];
    licenseCodes = [];
    labelsPrefix = DepositForm.FILE_ACCESSRIGHTS_LABELS_PREFIX;
    labelsPostfix = DepositForm.FILE_ACCESSRIGHTS_LABELS_POSTFIX;

    showFooter = false;

    ngOnInit() {
        const assetFileAndLinkTypesMT =
            this.configurationHandlerService.getMappingTable(
                'AssetFileAndLinkTypes'
            ) as MappingTable[];
        this.fileTypes = assetFileAndLinkTypesMT
            .filter(
                (row) =>
                    row.enabled === 'true' &&
                    this.relevantForFile(row.source1) &&
                    this.relevantForResourceType(row.source2)
            )
            .map((row) => {
                return row.target;
            });

        const licenseTypesMT = this.configurationHandlerService.getMappingTable(
            'EsploroLicensesData'
        ) as MappingTable[];
        this.licenseCodes = licenseTypesMT.filter(
            (row) =>
                row.enabled === 'true' &&
                row.source5 === 'true' &&
                this.relevantForResourceType(row.source2)
        );
        this.resetTypeAndLicenseIfNotRelevant();
    }

    ngAfterContentInit() {
        setTimeout(() => {
            this.showFooter = true;
        }, 1000);
    }

    resetTypeAndLicenseIfNotRelevant() {
        if (
            this.file.type &&
            this.fileTypes &&
            this.fileTypes.indexOf(this.file.type) === -1
        ) {
            this.file.type = '';
        }
        const codes = this.licenseCodes.map((row) => {
            return row.source1;
        });
        if (this.file.license && codes.indexOf(this.file.license) === -1) {
            this.file.license = '';
        }
    }

    getFixedDateString() {
        if (
            this.file.access === 'embargoed' &&
            this.file.accessRightsFixedDate
        ) {
            return DateUtils.getFullMomentDateAsString(
                this.file.accessRightsFixedDate,
                this.systemDateFormatService.getSystemDateFormat()
            );
        }
        return '';
    }

    relevantForFile(source1) {
        if (source1 === 'file' || source1 === 'both') {
            return true;
        }
        return false;
    }

    relevantForResourceType(source2) {
        if (
            source2.indexOf('all') !== -1 ||
            source2.indexOf(this.depositFormDataService.category.value) !== -1
        ) {
            return true;
        }
        return false;
    }

    onAddLicense() {
        this.showLicenseButton = false;
        this.showLicenseDialog(this.file.license ? this.file.license : null);
    }

    onRemoveLicense() {
        this.showLicenseButton = true;
        this.file.license = '';
        this.depositFormDataService.updateFileLicense(this.index(), '');
    }

    onAddDescription() {
        this.showDescriptionButton = false;
        setTimeout(() => {
            this.textarea().focus();
        }, 0);
    }

    onRemoveDescription() {
        this.file.description = '';
        this.showDescriptionButton = true;
        this.depositFormDataService.updateFileDescription(this.index(), '');
    }

    onEditFileAccess() {
        this.onSetFileAccess();
    }

    onSetDocumentType() {
        this.showTypeButton = false;
        setTimeout(() => {
            this.typeInput().focus();
            this.typeInput().open();
        }, 700);
    }

    onRemoveDocumentType() {
        this.file.type = '';
        this.showTypeButton = true;
        this.depositFormDataService.updateFileType(this.index(), '');
    }

    showLicenseDialog(license?) {
        const dialogRef = this.matDialog.open(LicenseSelectDialogComponent, {
            maxWidth: '800px',
            data: {
                selected: license,
                licenseCodes: this.licenseCodes,
            },
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response && response.applyToAll) {
                this.setLicenseToAll(response.license);
            } else if (!response || response === 'cancel') {
                this.showLicenseButton = true;
            } else {
                this.setLicense(response.license);
            }
        });
    }

    setLicense(license) {
        this.file.license = license.title;
        this.depositFormDataService.updateFileLicense(
            this.index(),
            license.title
        );
    }

    setLicenseToAll(license) {
        this.depositFormDataService.files.value.forEach((file, index) => {
            file.license = license.title;
            this.depositFormDataService.updateFileLicense(index, license.title);
        });
    }

    onSetFileAccess() {
        this.showAccessRightsDialog();
    }

    onRemoveFileAccess() {
        this.file.access = '';
        this.depositFormDataService.updateFileAccess(this.index(), '');
    }

    setFileMetadata(access, file, index) {
        file.access = access.selected;
        if (file.access === 'embargoed') {
            if (access.date) {
                file.accessRightsFixedDate = access.date;
                file.accessRightsFixedYear = access.date.year();
                file.accessRightsFixedMonth = access.date.month() + 1;
                file.accessRightsFixedDay = access.date.date();
                this.depositFormDataService.updateFileAccessDate(
                    index,
                    file.accessRightsFixedDate,
                    file.accessRightsFixedYear,
                    file.accessRightsFixedMonth,
                    file.accessRightsFixedDay
                );
            }
        }
        file.accessRightsReason = access.reason;
        this.depositFormDataService.updateFileAccess(index, access);
        this.depositFormDataService.updateFileAccessReason(index, access);
    }

    setFileAccess(access) {
        this.setFileMetadata(access, this.file, this.index());
    }

    setFileAccessToAll(access) {
        this.depositFormDataService.files.value.forEach((file, index) => {
            this.setFileMetadata(access, file, index);
        });
    }

    showAccessRightsDialog() {
        const access = this.file.access;
        const date = this.file.accessRightsFixedDate;
        const reason = this.file.accessRightsReason;
        const dialogRef = this.matDialog.open(AccessRightsDialogComponent, {
            minWidth: this.media.is('lt-md') ? 'auto' : '500px',
            data: {
                selected: access ? access : null,
                date,
                reason,
            },
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response && response.applyToAll) {
                this.setFileAccessToAll(response);
            } else if (response && response !== 'cancel') {
                this.setFileAccess(response);
            }
        });
    }

    displayType() {
        if (this.file.type && this.fileTypes.indexOf(this.file.type) !== -1) {
            return true;
        }
        return false;
    }

    displaySetTypeButton() {
        if (
            !this.file.type ||
            (this.file.type && this.fileTypes.indexOf(this.file.type) === -1)
        ) {
            return true;
        }
        return false;
    }

    onChangeDescription(event) {
        this.depositFormDataService.updateFileDescription(this.index(), event);
    }

    onChangeType(event) {
        this.depositFormDataService.updateFileType(this.index(), event);
    }
}
