import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  output,
  contentChild
} from '@angular/core';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { of } from 'rxjs';

import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerToggle,
  MatDatepicker,
  MatDatepickerInput,
} from '@angular/material/datepicker';
import {
  MatPrefix,
  MatFormField,
  MatLabel,
  MatHint,
  MatError,
  MatSuffix,
} from '@angular/material/form-field';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// eslint-disable-next-line no-duplicate-imports
import moment, { Moment } from 'moment';

import { MatInput } from '@angular/material/input';

const dateInputFormats = [
  'YYYY',
  'MMM YYYY',
  'MMMM YYYY',
  'MM/YYYY',
  'MM.YYYY',
  'MM-YYYY',
  'YYYY-MM',
  'YYYYMM',
  'YYYY-MM-DD',
  'YYYYMMDD',
  'LL',
  'L',
];

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: dateInputFormats,
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY-MM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY-MM',
  },
};

// Error when control is dirty and date format is bad.
export class DateFormatStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const isInvalidFormat =
      control.dirty &&
      control.value !== '' &&
      !moment.parseZone(control.value, dateInputFormats, true).isValid();
    const hasExternalError = control.hasError('externalError');

    control.setErrors({
      ...control.errors,
      dateFormatInvalid: isInvalidFormat,
    });

    return !!(control && (isInvalidFormat || hasExternalError));
  }
}

export interface DatepickerChangeEvent {
  stringValue: string;
  yearSet: boolean;
  monthSet: boolean;
  dateSet: boolean;
  y: number | null;
  m: number | null;
  d: number | null;
}

@Component({
  selector: 'mulo-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatHint,
    MatError,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatDatepickerInput,
  ],
})
export class DatepickerComponent implements OnInit, OnChanges {
  private dateAdapter = inject<DateAdapter<Moment>>(DateAdapter);

  /**
   * Outputs any internal change in the `dateInput` string
   */
  readonly dateInputChange = output<DatepickerChangeEvent>();
  /**
   * Label to be used for year-only view; defaults to `label`
   */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() yearOnlyLabel: string;
  /**
   * Label to be used for month-and-year view; defaults to `label`
   */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() hasMonthLabel: string;
  /**
   * Label to be used for full date view; defaults to `label`
   */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() hasDateLabel: string;
  /**
   * Optional hint for the field - visible when no errors
   */
  readonly hint = input<string>(undefined);
  /**
   * Input for errors found in server-side validation
   */
  readonly error = input<string>(undefined);
  /**
   * Label to be used for internally-detected invalid date format error
   */
  readonly dateFormatInvalidLabel = input('Date format invalid!');
  /**
   * Optional classes to be passed directly to the `<mat-form-field>` element
   */
  readonly formFieldClass = input(undefined);
  /**
   * Whether the control is required.
   */

  readonly required = input<boolean>(undefined);
  /**
   * Whether the required marker should be hidden.
   */
  readonly hideRequiredMarker = input<boolean>(undefined);

  /**
   * Whether the control is disabled.
   */

  readonly disabled = input(false);
  /**
   * Detects if a MatPrefix element has been passed as content
   *
   * @internal
   */
  readonly matPrefix = contentChild(MatPrefix);
  /**
   * Flag if the year has been set
   *
   * @internal
   */
  yearSet = false;
  /**
   * Flag if the month has been set
   *
   * @internal
   */
  monthSet = false;
  /**
   * Flag if the full date has been set
   *
   * @internal
   */
  dateSet = false;
  /**
   * Holds the year internally
   *
   * @internal
   */
  y: number = moment().year();
  /**
   * Holds the numerical month internally
   *
   * @internal
   */
  m: number = null;
  /**
   * Holds the numerical date internally
   *
   * @internal
   */
  d: number = null;
  /**
   * FormControl to manage the date for the datepicker
   *
   * @internal
   */
  dateCtrl = new UntypedFormControl(moment());
  /**
   * FormControl to manage the date for the `<input>` element
   *
   * @internal
   */
  dateInputCtrl = new UntypedFormControl();
  /**
   * Custom date format matcher to handle partial datepicker logic
   *
   * @internal
   */
  matcher = new DateFormatStateMatcher();

  get dateInput() {
    return this.dateInputCtrl.value;
  }

  /**
   * Allows initialization of the date
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set dateInput(val: string) {
    if (val !== undefined) {
      if (this.dateInputCtrl.value !== val) {
        // this really only applies for external changes, such as on init
        // thus we mostly copy the functionality of dateInputChangeHandler
        this.dateInputCtrl.setValue(val);
        const ctrlValue = this.parseMoment(val);
        this.y = this.yearSet ? ctrlValue.year() : null;
        this.m = this.monthSet ? ctrlValue.month() : null;
        this.d = this.dateSet ? ctrlValue.date() : null;

        if (ctrlValue?.isValid()) {
          this.dateCtrl.setValue(ctrlValue);
          this.setDateInput(ctrlValue);
        }
        this.dateInputCtrl.markAsDirty();
      }

      const eventData = {
        stringValue: val,
        d: this.d,
        m: this.m,
        y: this.y,
        dateSet: this.dateSet,
        monthSet: this.monthSet,
        yearSet: this.yearSet,
      };
      this.dateInputChange.emit(eventData);
    }
  }

  /**
   * @internal
   */
  private _label = 'Select or Enter a Date';

  get label() {
    if (this.dateSet) {
      return this.hasDateLabel;
    } else if (this.monthSet) {
      return this.hasMonthLabel;
    } else if (this.yearSet) {
      return this.yearOnlyLabel;
    } else {
      return this._label;
    }
  }

  /**
   * Label to be used for the field - appears also as placeholder
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set label(val: string) {
    this._label = val;
  }

  /**
   * @internal
   */
  ngOnInit(): void {
    this.yearOnlyLabel = this.yearOnlyLabel ?? this._label;
    this.hasMonthLabel = this.hasMonthLabel ?? this._label;
    this.hasDateLabel = this.hasDateLabel ?? this._label;
  }

  /**
   * @internal
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['error'] && !changes['error'].firstChange) {
      this.dateInputCtrl.setAsyncValidators(() =>
        of({ externalError: !!changes['error'].currentValue }),
      );
      this.dateInputCtrl.updateValueAndValidity();
    }
    if (changes['disabled']) {
      changes['disabled'].currentValue
        ? this.dateInputCtrl?.disable()
        : this.dateInputCtrl?.enable();
    }
  }

  /**
   * Called when a year is selected from datepicker; tries to only update year first
   *
   * @internal
   */
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue: Moment = moment([normalizedYear.year()]);

    this.yearSet = true;
    this.monthSet = false;
    this.dateSet = false;

    this.y = normalizedYear.year();
    this.m = null;
    this.d = null;

    this.setDateInput(ctrlValue);
    this.dateCtrl.setValue(ctrlValue);
  }

  /**
   * Called when a month is selected from datepicker; tries to only update month first
   *
   * @internal
   */
  chosenMonthHandler(normalizedMonth: Moment) {
    const ctrlValue: Moment = moment([this.y]);
    ctrlValue.month(normalizedMonth.month());

    this.monthSet = true;
    this.dateSet = false;

    this.m = normalizedMonth.month();
    this.d = null;

    this.setDateInput(ctrlValue);
    this.dateCtrl.setValue(moment.invalid()); // send invalid so change event fires
  }

  /**
   * Called when a full date is chosen from datepicker
   *
   * @internal
   */
  dateChangeHandler(event: MatDatepickerInputEvent<Moment>) {
    this.dateSet = true;
    this.d = event.value.date();
    this.setDateInput(event.value);
  }

  /**
   * Sets `<input>` text value, according to the amount of data we have set
   *
   * @internal
   */
  setDateInput(date: Moment) {
    const oldValue = this.dateInputCtrl.value;

    if (this.dateSet) {
      this.dateInputCtrl.setValue(date.format('LL'));
    } else if (this.monthSet) {
      this.dateInputCtrl.setValue(date.format('YYYY-MM'));
    } else {
      this.dateInputCtrl.setValue(date.format('YYYY'));
    }

    if (this.dateInputCtrl.value !== oldValue) {
      this.dateInput = this.dateInputCtrl.value;
    }
  }

  /**
   * Called when the `<input>` element is changed independent of the datepicker
   *
   * @internal
   */
  dateInputChangeHandler(event) {
    const input = event.target.value;
    const ctrlValue = this.parseMoment(input);

    this.y = this.yearSet ? ctrlValue.year() : null;
    this.m = this.monthSet ? ctrlValue.month() : null;
    this.d = this.dateSet ? ctrlValue.date() : null;

    this.dateCtrl.setValue(ctrlValue);
    this.dateInput = this.dateInputCtrl.value;
  }

  /**
   * Parses an input into a moment object, setting flags according to specificity
   *
   * @internal
   */
  parseMoment(input) {
    this.yearSet = moment.parseZone(input, dateInputFormats, true).isValid();

    this.monthSet = moment
      .parseZone(input, dateInputFormats.slice(1), true)
      .isValid();

    this.dateSet = moment
      .parseZone(input, dateInputFormats.slice(-4), true)
      .isValid();

    return this.dateAdapter.parse(input, dateInputFormats);
  }
}
