<div
  class="inner-wrapper"
  [style.width]="size() ? sizeString : '100%'"
  [style.height]="size() ? sizeString : 'auto'"
  [style.border-radius]="shape() === 'round' ? '50%' : radius() + 'px'"
  (mouseenter)="showOverlay()"
  (mouseleave)="hideOverlay()"
  >
  <div
    class="image-wrapper"
    [style.backgroundImage]="'url(' + image + ')'"
    [style.backgroundPosition]="position ? position.x + '% ' + position.y + '%' : '50%'"
    [class.is-visible]="imageLoaded"
    >
    <img [src]="image" alt="" (load)="imageLoaded = true" />
  </div>
  <div class="image-placeholder" [class.has-placeholder]="!initials">
    @if (initials) {
      <div class="initials" [style.fontSize]="calcFontSize()">
        {{ initials }}
      </div>
    }
    @if (!initials) {
      <mat-icon svgIcon="account" muloSvgViewbox></mat-icon>
    }
  </div>
  @if (overlayVisible) {
    <ng-content select="[slot='overlay-content']"></ng-content>
  }
</div>
