import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Directive,
  Input,
  HostBinding,
  input
} from '@angular/core';
import { SectionClass } from '../../../shared/classes/sections.class';

@Component({
    selector: 'exl-footer-links-section',
    templateUrl: './footer-links-section.component.html',
    styleUrls: ['./footer-links-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'exl-footer-links-section' },
    standalone: true,
})
export class FooterLinksSectionComponent
    extends SectionClass
    implements OnInit
{
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() public minWidth = '170px';

    @HostBinding('style.minWidth') get calcMinWidth() {
        return `calc(${this.minWidth} - ${this.columnGap()})`;
    }

    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('style.maxWidth')
    @Input()
    public maxWidth = '230px';

    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() public flexBasis: number | null = 4;

    @HostBinding('style.flexBasis') get flexBasisStyle() {
        return `calc(100% / ${this.flexBasis})`;
    }

    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.horizontal-list')
    @Input()
    public isHorizontalList = false;

    public readonly columnGap = input('2rem');

    constructor() {
        super('footer-links');
    }

    ngOnInit(): void {}

    public updateMinWidth(value: string) {
        setTimeout(() => {
            this.minWidth = value;
        });
    }

    public updateMaxWidth(value: string) {
        setTimeout(() => {
            this.minWidth = value;
        });
    }

    public updateFlexBasis(value: number) {
        setTimeout(() => {
            this.flexBasis = value;
        });
    }
}
