import { AfterViewInit, Directive, NgModule, OnInit, inject } from '@angular/core';
import { MatChipRemove } from '@angular/material/chips';

@Directive({
    selector: '[muloFacetChip]',
    host: {},
    standalone: true,
})
export class FacetChipDirective implements OnInit, AfterViewInit {
  private removeButton = inject(MatChipRemove, { host: true });


  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.removeButton.tabIndex = 0;
  }
}


