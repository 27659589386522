<button (click)="close()" [attr.aria-label]="data.dialogCancel" class="close-button" mat-icon-button>
  <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
</button>

<h1 #title mat-dialog-title>{{ dialogTitle }}</h1>
<div mat-dialog-content>
  @if (data.exportType === 'asset') {
    <mulo-asset-exporter
      [labels]="data.labels"
      [inDialog]="true"
      [selectedAssets]="data.selectedAssets"
      [allAssets]="data.allAssets"
      [exportTargets]="(data.exportTargets$ | async) || data.exportTargets"
      [actionClick]="actionClicked"
      (loadingPreviewChange)="loadingPreview.set($event)"
      (actionsChange)="setActions($event)"
      [titleId]="title?.id"
      [selectedScope]="data.selectedScope"
      #exporter
    >
    </mulo-asset-exporter>
  }
</div>
<div mat-dialog-actions>
  @if (targetActions$ | async; as actions) {
    @if (actions.length > 0) {
      <div class="actions-wrap" @heightInAnimation @heightOutAnimation>
        @for (action of actions; track $index) {
          @if (action.url) {
            <a
              mat-button
              color="primary"
              class="is-large has-icon has-icon-after"
              [disabled]="(action.disabled$ | async) || loadingPreview()"
              [href]="action.url"
              target="_blank"
            >
              {{ action.label }}
              <mat-icon class="mat-icon-rtl-mirror" svgIcon="open-in-new" muloSvgViewbox></mat-icon>
            </a>
          } @else {
            <button
              mat-button
              color="primary"
              [disabled]="(action.disabled$ | async) || loadingPreview()"
              class="is-large"
              (click)="onActionClick(action)"
            >
              {{ action.label }}
            </button>
          }
        }
      </div>
    }
  }
</div>
