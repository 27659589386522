/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, RendererFactory2, inject } from '@angular/core';
import {
  HueValue,
  MatCssPalettePrefix,
  MaterialCssVariablesConfig,
  MaterialCssVarsService,
} from 'angular-material-css-vars';
import { DOCUMENT } from '@angular/common';
import { readability, TinyColor } from '@ctrl/tinycolor';

import { MATERIAL_CSS_VARS_CFG } from './token.const';
import { DEFAULT_MAT_CSS_CFG } from './default-cfg.const';

interface CssVariable {
  name: string;
  val: string;
}
export interface MaterialCssColorMapperEntry {
  othermap: number;
}
const CONTRAST_PREFIX = 'contrast-';
const DARK_TEXT_VAR = '--dark-primary-text-no-rgb';
const LIGHT_TEXT_VAR = '--light-primary-text-no-rgb';

enum MatCssAdditionalPalettePrefix {
  secondary = '--palette-secondary',
  tertiary = '--palette-tertiary',
}

export enum MatCssAdditionalPaletteContrastNames {
  dark = '--dark-primary-text-no-rgb',
  light = '--light-primary-text-no-rgb',
}

const enum MatCssAdditionalPaletteTextContrast {
  light = '255, 255, 255, 1',
  dark = '0, 0, 0, 0.87',
}

@Injectable({
  providedIn: 'root',
})
export class MuloMatCssVarsService extends MaterialCssVarsService {
  private _document: Document;
  cfg: MaterialCssVariablesConfig;

  private _ROOT: HTMLElement;
  private definedVars = {};

  constructor() {
    const rendererFactory = inject(RendererFactory2);
    const _document = inject<Document>(DOCUMENT);
    const cfg = inject<MaterialCssVariablesConfig>(MATERIAL_CSS_VARS_CFG);

    super(rendererFactory, _document, cfg);
    this._document = _document;
    this.cfg = cfg;

    this._ROOT = _document.documentElement;
    this.cfg = {
      ...DEFAULT_MAT_CSS_CFG,
      ...cfg,
    };
    this.isAutoContrast = this.cfg.isAutoContrast;

    if (this.cfg.isDarkTheme) {
      this.setDarkTheme(this.cfg.isDarkTheme);
    }
    if (this.cfg.primary) {
      this.setPrimaryColor(this.cfg.primary);
    }
    if (this.cfg.accent) {
      this.setAccentColor(this.cfg.accent);
    }
    if (this.cfg.warn) {
      this.setWarnColor(this.cfg.warn);
    }
  }

  setPrimaryColor(hex: string) {
    this.primary = hex;
    const varPrefix = MatCssPalettePrefix.Primary;
    const stylePrimary = this._computePaletteColorsOverride(
      varPrefix,
      this.primary
    );
    this._setStyleOverride(stylePrimary);

    if (this.isAutoContrast) {
      this._recalculateAndSetContrastColorOverride(varPrefix);
    }
  }

  setAccentColor(hex: string) {
    this.accent = hex;
    const varPrefix = MatCssPalettePrefix.Accent;
    const styleAccent = this._computePaletteColorsOverride(
      varPrefix,
      this.accent
    );
    this._setStyleOverride(styleAccent);

    if (this.isAutoContrast) {
      this._recalculateAndSetContrastColorOverride(varPrefix);
    }
  }

  setWarnColor(hex: string) {
    this.warn = hex;
    const varPrefix = MatCssPalettePrefix.Warn;
    const styleWarn = this._computePaletteColorsOverride(varPrefix, this.warn);
    this._setStyleOverride(styleWarn);

    if (this.isAutoContrast) {
      this._recalculateAndSetContrastColorOverride(varPrefix);
    }
  }

  getStyleGroupName(vars) {
    return vars[0].name
      .replace(/--palette-/, '')
      .replace(/\-\d/g, '')
      .replace(/\d/g, '');
  }

  addStyleToHead(styles, styleGroup?) {
    const css = this._document.createElement('style');
    css.type = 'text/css';
    if (styleGroup) {
      this.removeStyleNode(styleGroup);
      css.setAttribute('vars-group', styleGroup);
    }
    css.appendChild(this._document.createTextNode(styles));
    this._document.getElementsByTagName('head')[0].appendChild(css);
  }

  removeStyleNode(styleGroup) {
    const group = this._document.querySelectorAll(
      'style[vars-group=' + styleGroup + ']'
    );
    Array.prototype.forEach.call(group, (node) => {
      node.parentNode.removeChild(node);
    });
  }

  public setSecondaryColor(hex) {
    const secondary = this.prepareAdditionalPalettes(hex, 'secondary');
    this.setStyle(secondary);
  }

  public setTertiaryColor(hex) {
    const tertiary = this.prepareAdditionalPalettes(hex, 'tertiary');
    this.setStyle(tertiary);
  }

  public prepareAdditionalPalettes(hex, paletteName: string) {
    const c = this.getRgbFromHex(hex);
    const prefix = MatCssAdditionalPalettePrefix[paletteName];
    // URM-190377 - old method: use assessContrast
    const contrast = new TinyColor(hex).isDark()
      ? MatCssAdditionalPaletteTextContrast.light
      : MatCssAdditionalPaletteTextContrast.dark;
    return [
      {
        name: `${prefix}`,
        val: `${c.r}, ${c.g}, ${c.b}`,
      },
      {
        name: `${prefix}-contrast`,
        val: `${contrast}`,
      },
    ];
  }

  public getRgbFromHex(hex) {
    return new TinyColor(hex).toRgb();
  }

  public assessContrast(hex) {
    // const MAGIC_FACTOR = this.cfg.magicAutoContrastFactor || 0;
    const MAGIC_FACTOR = 1 || 0;
    const rLight = readability(
      hex,
      `rgba(${MatCssAdditionalPaletteTextContrast.light})`
    );
    const rDark = readability(
      hex,
      `rgba(${MatCssAdditionalPaletteTextContrast.dark})`
    );
    return rLight + MAGIC_FACTOR > rDark
      ? MatCssAdditionalPaletteContrastNames.light
      : MatCssAdditionalPaletteContrastNames.dark;
  }

  public setElementColors(elements) {
    const validPalettes = [
      'primary',
      'accent',
      'warn',
      'secondary',
      'tertiary',
    ];
    let string = ':root {';
    Object.entries(elements).map(([element, paletteName]: [string, string]) => {
      if (validPalettes.includes(paletteName)) {
        let color: string;
        let hue = '';

        if (['primary', 'accent', 'warn'].includes(paletteName)) {
          color = this[paletteName];
          hue = '-500';
        } else {
          color = `rgb (${getComputedStyle(this._document.documentElement)
            .getPropertyValue('--palette-' + paletteName + '-no-rgb')
            .trim()})`;
        }

        const contrastType = new TinyColor(color).isLight() ? 'dark' : 'light';
        const isBg = !['links'].includes(element);
        const varPrefix = `--${element}${isBg ? '-background' : '-color'}`;
        let v = `${varPrefix}: var(--palette-${paletteName}${hue}-no-rgb);`;
        if (isBg) {
          v += `
            ${varPrefix}-contrast: var(--palette-${paletteName}-contrast${hue}-no-rgb);
            ${varPrefix}-contrast-alpha: ${
            contrastType === 'dark' ? '0.87' : '1'
          };
            ${varPrefix}-contrast-type: ${contrastType};
          `;
        }
        string = `${string} ${v}`;
      }
    });
    string = `${string} }`;

    this.addStyleToHead(string, 'elements-colors');
  }

  public reset() {
    const styles = this._document.querySelectorAll('style[vars-group]');
    Array.prototype.forEach.call(styles, (node) => {
      node.parentNode.removeChild(node);
    });
  }

  public setStyle(vars: CssVariable[]) {
    this._setStyleOverride(vars);
  }
  private _setStyleOverride(vars: CssVariable[]) {
    let string = ':root {';
    vars.forEach((s) => {
      const valArr = s.val.split(',');
      // const rgb = valArr.slice(0, 3);
      // const alpha = valArr.slice(3);
      const v = `${s.name}-no-rgb: ${valArr.join(',')};`;
      this.definedVars[s.name] = valArr.join(',');
      // if (alpha?.length > 0) {
      //   const contrastVal = `${s.name}-alpha: ${alpha};`;
      //   this.definedVars[s.name + '-alpha'] = alpha;
      //   string = `${string} ${v} ${contrastVal}`;
      // } else {
      string = `${string} ${v}`;
      // }
    });
    string = `${string} }`;
    const styleGroup = this.getStyleGroupName(vars);
    this.addStyleToHead(string, styleGroup ? styleGroup : null);
  }

  private _computePaletteColorsOverride(
    prefix: MatCssPalettePrefix,
    hex: string
  ): CssVariable[] {
    return this.getPaletteForColor(hex).map((item) => {
      const c = item.color;
      return {
        name: `${prefix}${item.hue}`,
        val: `${c.r}, ${c.g}, ${c.b}`,
      };
    });
  }

  private _recalculateAndSetContrastColorOverride(
    palettePrefix: MatCssPalettePrefix
  ) {
    const updates = this._calculateContrastColorsForCurrentValuesOverride(
      palettePrefix
    ).map(({ contrastColorVar, hue }) => ({
      val: this._getCssVarValueOverride(contrastColorVar),
      name: `${palettePrefix + CONTRAST_PREFIX}${hue}`,
    }));
    this._setStyleOverride(updates);
  }

  private _calculateContrastColorsForCurrentValuesOverride(
    palettePrefix: MatCssPalettePrefix
  ): { contrastColorVar: string; hue: HueValue }[] {
    return this.cfg.sortedHues.map((hue) => {
      const hueVarVal = this._getCssVarValueOverride(`${palettePrefix}${hue}`);
      const c = new TinyColor(`rgb(${hueVarVal})`);
      const contrastColorVar = c.isDark() ? LIGHT_TEXT_VAR : DARK_TEXT_VAR;
      return {
        contrastColorVar,
        hue,
      };
    });
  }

  private _getCssVarValueOverride(v: string): string {
    return (
      this.definedVars[v] || getComputedStyle(this._ROOT).getPropertyValue(v)
    );
  }
}
