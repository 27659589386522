import { Component, OnInit, inject, input } from '@angular/core';
import {DepositFormDataService} from '../../deposit-form-data.service';
import {UntypedFormControl} from '@angular/forms';
import { DateInputComponent } from '../../../parts/date-input/date-input.component';

@Component({
    selector: 'esp-deposit-dates',
    templateUrl: './deposit-dates.component.html',
    styleUrls: ['./deposit-dates.component.scss'],
    imports: [DateInputComponent]
})
export class DepositDatesComponent implements OnInit {
    depositFormDataService = inject(DepositFormDataService);


    readonly textPlaceholder = input<string>(undefined);
    readonly range = input(false);
    readonly error = input(false);
    readonly dateHint = input(false);
    readonly autoPopulated = input<boolean>(undefined);
    readonly hasTextInput = input(false);
    readonly fromDate = input<UntypedFormControl>(undefined);
    readonly toDate = input<UntypedFormControl>(undefined);

    ngOnInit() {
    }

}
