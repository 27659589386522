import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  input
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'mulo-side-main-layout',
    templateUrl: './side-main-layout.component.html',
    styleUrls: ['./side-main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgTemplateOutlet]
})
export class SideMainLayoutComponent implements OnInit {
  /**
   * Position of the side column - to the left of the main colum, or to its right
   */
  readonly sideSide = input<'left' | 'right'>('left');
  /**
   * Overrides the default sidebar width
   */
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('style.--side-column-size') @Input() sideColumnSize:
    | number
    | string = '15em';

  constructor() {}

  ngOnInit(): void {}
}
