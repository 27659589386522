<div class="metadata-container">
  @if (!showDescriptionButton || file.description) {
    <div @growOutAnimation @slideInAnimation class="metadata-block">
      <mat-form-field [muloInputExpander]="file.description" class="hasnt-underline">
        <mat-label>{{ 'research.fdescription.title' | customTranslate }}</mat-label>
        <textarea
          #textarea
          (ngModelChange)="file.description = $event; onChangeDescription($event)"
          [ngModel]="file.description"
          [placeholder]="'research.fdescription.subtitle' | customTranslate"
          [rows]="1"
          matInput
          matTextareaAutosize
          >
        </textarea>
      </mat-form-field>
      <span class="actions">
        @if (!showDescriptionButton || file.description) {
          <button
            (click)="onRemoveDescription()"
            [attr.aria-label]="'research.aria.deposit.files.remove.file.description' | translate"
            class="is-small"
            mat-icon-button
            type="button"
            >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        }
      </span>
    </div>
  }
  @if (file.license) {
    <div @growOutAnimation @slideInAnimation class="metadata-block">
      <div class="inner">
        <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
          <mat-label>{{ 'research.files.file.license.title' | customTranslate }}</mat-label>
          <mat-select disabled value="0">
            <mat-option value="0">{{ file.license + '.name' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span class="actions">
          <button
            (click)="onAddLicense()"
            [attr.aria-label]="'research.aria.deposit.files.edit.license' | translate"
            class="is-small"
            mat-icon-button
            type="button"
            >
            <mat-icon svgIcon="pencil"></mat-icon>
          </button>
          <button
            (click)="onRemoveLicense()"
            [attr.aria-label]="'research.aria.deposit.files.remove.license' | translate"
            class="is-small"
            mat-icon-button
            type="button"
            >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </span>
      </div>
    </div>
  }
</div>
@if (file.access && displayAccessRights) {
  <div @growOutAnimation @slideInAnimation class="metadata-block">
    <div class="inner">
      <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
        <mat-label>{{ 'research.files.file.access.title' | customTranslate }}</mat-label>
        <mat-select disabled value="0">
          <mat-option value="0"
            >{{ labelsPrefix + file.access + labelsPostfix | translate }}{{ getFixedDateString() }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <span class="actions">
          <button
            (click)="onEditFileAccess()"
            [attr.aria-label]="'research.aria.deposit.files.edit.access' | translate"
            class="is-small"
            mat-icon-button
            type="button"
            >
            <mat-icon svgIcon="pencil"></mat-icon>
          </button>
          <button
            (click)="onRemoveFileAccess()"
            [attr.aria-label]="'research.aria.deposit.files.remove.access' | translate"
            class="is-small"
            mat-icon-button
            type="button"
            >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </span>
      </div>
    </div>
  }
  @if (!showTypeButton || displayType()) {
    <div @growOutAnimation @slideInAnimation class="metadata-block">
      <div class="inner">
        <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
          <mat-label>{{ 'research.ftype.title' | customTranslate }}</mat-label>
          <mat-select #typeInput (ngModelChange)="file.type = $event; onChangeType($event)" [ngModel]="file.type">
            @for (type of fileTypes; track type) {
              <mat-option [value]="type">
                {{ 'AssetFileAndLinkTypesLabels.' + type | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <span class="actions">
          <button
            (click)="onRemoveDocumentType()"
            [attr.aria-label]="'research.aria.deposit.files.remove.file.type' | translate"
            class="is-small"
            mat-icon-button
            type="button"
            >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </span>
      </div>
    </div>
  }

  @if (showFooter) {
    <div class="metadata-footer">
      @if (displayLicense && showLicenseButton && !file.license) {
        <button
          (click)="onAddLicense()"
          @growInAnimation
          class="has-icon"
          color="primary"
          mat-button
          type="button"
          >
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'research.filemd.license' | customTranslate }}
        </button>
      }
      @if (displayDescription && showDescriptionButton && !file.description) {
        <button
          (click)="onAddDescription()"
          @growInAnimation
          class="has-icon"
          color="primary"
          mat-button
          type="button"
          >
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'research.filemd.description' | customTranslate }}
        </button>
      }
      @if (!file.access && displayAccessRights) {
        <button
          (click)="onSetFileAccess()"
          @growInAnimation
          class="has-icon"
          color="primary"
          mat-button
          type="button"
          >
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'research.filemd.access' | customTranslate }}
        </button>
      }
      @if (displayContentType && showTypeButton && fileTypes.length > 0 && displaySetTypeButton()) {
        <button
          (click)="onSetDocumentType()"
          @growInAnimation
          class="has-icon"
          color="primary"
          mat-button
          type="button"
          >
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'research.filemd.type' | customTranslate }}
        </button>
      }
    </div>
  }
