import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, inject, output } from '@angular/core';
import { Thumbnail } from '../../models/thumbnail.model';
import { numberOrNumberUnit } from '../../utils/string-utils';


@Component({
    selector: 'mulo-thumbnail',
    templateUrl: './thumbnail.component.html',
    styleUrls: ['./thumbnail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-thumbnail' },
    imports: []
})
export class ThumbnailComponent {
  private cdr = inject(ChangeDetectorRef);

  readonly thumbHeight = output<number>();

  opacity: 0 | 1 = 0;

  private _thumbs: Thumbnail[];
  private _width: number | string;

  /**
   * Array of thumbnail images
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() get thumbs(): Thumbnail[] {
    return this._thumbs;
  }
  set thumbs(array) {
    array.forEach((thumb) => (thumb['disabled'] = true));
    this._thumbs = array;
  }

  /**
   * Width of the thumbnail
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set width(val: number | string) {
    this._width = numberOrNumberUnit(val);
  }
  get width() {
    return this._width;
  }

  onLoad(ev, thumb) {
    const imgEl = ev.target;
    if (imgEl.naturalWidth !== 1280) {
      thumb.disabled = false;
      setTimeout(() => {
        this.thumbHeight.emit(imgEl.clientHeight);
        this.opacity = 1;
        this.cdr.markForCheck();
      });
    }
  }
}
