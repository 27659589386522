import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    inject,
    output,
} from '@angular/core';
import { SearchScopesByEntity } from '../../shared/configurations/portal-search.constant';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { Discovery } from '../../shared/configurations/discovery.constant';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormsModule,
} from '@angular/forms';
import moment, { Moment } from 'moment';
import {
    componentDestroyed,
    MediaService,
    MediaIfDirective,
} from '@exl-ng/mulo-core';
import { ActivatedRoute, Params } from '@angular/router';
import { PortalService } from '../../portal/portal.service';
import { AdvancedSearchService } from './advanced-search.service';
import { takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardActions } from '@angular/material/card';
import { DateInputComponent } from '../date-input/date-input.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { LowerCasePipe, SlicePipe } from '@angular/common';

export interface QueryLine {
    boolOperator: string;
    searchOperator: string;
    searchIndex: string;
    searchQuery: string;
}

@Component({
    selector: 'esp-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.scss'],
    imports: [
        MatFormField,
        MatSelect,
        FormsModule,
        MatOption,
        MatLabel,
        MatInput,
        MatError,
        MatButton,
        MatIcon,
        DateInputComponent,
        MediaIfDirective,
        MatCardActions,
        LowerCasePipe,
        SlicePipe,
        TranslateModule,
    ],
})
export class AdvancedSearchComponent implements OnInit, OnDestroy, OnChanges {
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private formBuilder = inject(UntypedFormBuilder);
    media = inject(MediaService);
    private route = inject(ActivatedRoute);
    private portalService = inject(PortalService);
    private advancedSearchService = inject(AdvancedSearchService);
    private cdr = inject(ChangeDetectorRef);

    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() initQuery: string;
    ADVANCED_SEARCH_MAX_QUERY_LINES = Discovery.ADVANCED_SEARCH_MAX_QUERY_LINES;
    PRECISION_OPERATORS_ALL = ['contains', 'exact', 'begins_with'];
    PRECISION_OPERATORS_PARTIAL = ['contains', 'exact'];
    BOOLEAN_OPERATORS = ['AND', 'OR', 'NOT'];

    dateForm: UntypedFormGroup;

    formClauseCount = 2;
    scopes = SearchScopesByEntity.OUTPUTS;
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() selectedScope = SearchScopesByEntity.OUTPUTS[0].value;

    searchIndex = '';
    searchIndexes = [];
    searchFilters = [];

    lines: QueryLine[] = [];

    displayResourceTypeFilter = false;
    displayYearFilter = false;

    rtypeFilterOptions = [];
    rtypeFilter = '';

    readonly advancedSearch = output<Params>();

    ngOnInit(): void {
        this.initAdvancedSearchFormFromConfiguration();
        this.initQueryLines();
        this.initDateForm();

        this.route.queryParams
            .pipe(takeUntil(componentDestroyed(this)))
            .subscribe((params) => {
                if (params.mode === 'advanced') {
                    this.fillScopeFromURL();
                    this.fillComplexLineFromURL();
                    this.fillPreFiltersFromURL();
                }
            });
        this.cdr.detectChanges();
    }

    ngOnDestroy() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initQuery && !changes.initQuery.firstChange) {
            if (this.lines.length > 0) {
                this.lines[0].searchQuery = changes.initQuery.currentValue;
            }
        }
    }

    initDateForm() {
        this.dateForm = this.formBuilder.group({
            fromDate: '',
            toDate: '',
        });
    }

    fillScopeFromURL() {
        if (this.route.snapshot.queryParams.scope) {
            this.selectedScope = this.route.snapshot.queryParams.scope;
        }
    }

    fillComplexLineFromURL() {
        let queries = this.route.snapshot.queryParams.query;
        if (queries) {
            if (!Array.isArray(queries)) {
                queries = [queries];
            }
            this.lines[0].boolOperator = 'AND';
            queries.forEach((line, index) => {
                const arr = line.split(',');
                this.lines[index].searchIndex = arr[0];
                this.lines[index].searchOperator = arr[1];
                const searchTerm =
                    this.advancedSearchService.getSearchTerm(arr);
                this.lines[index].searchQuery = searchTerm;
                if (index + 1 < this.lines.length) {
                    this.lines[index + 1].boolOperator = arr[arr.length - 1];
                }
            });
            this.formClauseCount = Math.max(2, queries.length);
        }
    }

    fillPreFiltersFromURL() {
        let pfilter = this.route.snapshot.queryParams.pfilter;
        if (pfilter) {
            if (!Array.isArray(pfilter)) {
                pfilter = [pfilter];
            }
            pfilter.forEach((filter, index) => {
                const arr = filter.split(',');
                const key = arr[0];
                switch (key) {
                    case 'rtype':
                        this.rtypeFilter = arr[2];
                        break;
                    case 'dr_s':
                        const fromDate = moment(
                            arr[2],
                            Discovery.ADVANCED_SEARCH_DATE_FORMAT,
                        );
                        this.dateForm.patchValue({ fromDate });
                        break;
                    case 'dr_e':
                        const toDate = moment(
                            arr[2],
                            Discovery.ADVANCED_SEARCH_DATE_FORMAT,
                        );
                        this.dateForm.patchValue({ toDate });
                        break;
                }
            });
        } else {
            if (this.rtypeFilterOptions.indexOf('any') !== -1) {
                this.rtypeFilter = 'any';
            }
        }
    }

    initAdvancedSearchFormFromConfiguration() {
        this.searchIndexes = this.configurationHandlerService
            .advancedSearchConfiguration()
            ?.searchIndexes.map((index) => index.code);
        this.searchIndex = this.searchIndexes?.[0];

        this.searchFilters = this.configurationHandlerService
            .advancedSearchConfiguration()
            ?.searchFilters.map((index) => index.code);
        this.displayResourceTypeFilter =
            this.getFilterByType('resource_type')?.active;
        this.displayYearFilter = this.getFilterByType('year')?.active;

        this.rtypeFilterOptions = this.configurationHandlerService
            .advancedSearchConfiguration()
            ?.resourceTypes.map((type) => type.code);
        this.rtypeFilter = this.rtypeFilterOptions?.[0];

        this.scopes = this.portalService.filterSearchScopesByCtrlCode(
            this.scopes,
        );
    }

    addFormClause() {
        if (this.formClauseCount < this.ADVANCED_SEARCH_MAX_QUERY_LINES) {
            this.formClauseCount++;
        }
    }

    isDateValid() {
        if (
            this.toDate.value &&
            this.fromDate.value &&
            this.toDate.value < this.fromDate.value
        ) {
            return false;
        }
        return true;
    }

    get fromDate() {
        return this.dateForm.get('fromDate') as UntypedFormControl;
    }

    get toDate() {
        return this.dateForm.get('toDate') as UntypedFormControl;
    }

    getFilterByType(type) {
        return this.configurationHandlerService
            .advancedSearchConfiguration()
            ?.searchFilters.filter((filter) => filter.code === type)[0];
    }

    resetForm() {
        this.formClauseCount = 2;
        this.initQueryLines();
        this.rtypeFilter = this.rtypeFilterOptions[0];
        this.initDateForm();
    }

    initQueryLines() {
        this.lines = [];
        for (let i = 0; i < this.ADVANCED_SEARCH_MAX_QUERY_LINES; i++) {
            this.lines.push({
                boolOperator: 'AND',
                searchOperator: 'contains',
                searchIndex: this.searchIndex,
                searchQuery:
                    i === 0 && this.initQuery != null ? this.initQuery : '',
            });
        }
    }

    submitSearch() {
        const queryLines = this.lines.filter((line) => line.searchQuery);
        if (this.isDateValid() && queryLines.length > 0) {
            const query = this.getQuery(queryLines);
            const pfilter = this.getPfilter();
            this.advancedSearch.emit({
                scope: this.selectedScope,
                query,
                pfilter,
                mode: 'advanced',
            });
        }
    }

    getQuery(queryLines: QueryLine[]) {
        const queryList = [];
        for (let i = 0; i < queryLines.length; i++) {
            let query = '';
            const line = queryLines[i];
            query +=
                line.searchIndex +
                ',' +
                line.searchOperator +
                ',' +
                line.searchQuery +
                ',';
            if (i + 1 < queryLines.length) {
                query += queryLines[i + 1].boolOperator;
            } else {
                query += 'AND';
            }
            queryList.push(query);
        }
        return queryList;
    }

    getPfilter() {
        const pfilter = [];
        if (this.rtypeFilter && this.rtypeFilter != 'any') {
            pfilter.push('rtype,exact,' + this.rtypeFilter + ',AND');
        }
        if (this.fromDate.value) {
            pfilter.push(
                'dr_s,exact,' +
                    this.getMomentDateAsString(this.fromDate.value) +
                    ',AND',
            );
            if (!this.toDate.value) {
                pfilter.push('dr_e,exact,99991231,AND');
            }
        }
        if (this.toDate.value) {
            pfilter.push(
                'dr_e,exact,' +
                    this.getMomentDateAsString(this.toDate.value) +
                    ',AND',
            );
            if (!this.fromDate.value) {
                pfilter.push('dr_s,exact,00000101,AND');
            }
        }
        return pfilter;
    }

    getMomentDateAsString(moment: Moment) {
        return moment.format(Discovery.ADVANCED_SEARCH_DATE_FORMAT);
    }
}
