import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../shared/utils/url.utils';
import { SettingsService } from '../settings/settings.service';

@Injectable({
    providedIn: 'root',
})
export class ResearcherDataService {
    private http = inject(HttpClient);
    private setSvc = inject(SettingsService);

    imageBlobUrl: any;
    institutionCode;
    imageUrl$: Subject<any> = new Subject();

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getResearcherProfileImage(userID) {
        return this.setSvc.getResearcherProfileImage(userID);
    }

    setProfileImageUrl(image) {
        if (image) {
            try {
                this.imageBlobUrl = window.URL.createObjectURL(image);
            } catch (e) {
                this.imageBlobUrl = image;
            }
        } else {
            this.imageBlobUrl = '';
        }
        this.imageUrl$.next(this.imageBlobUrl);
    }

    removeProfileImage(userID) {
        const params = new HttpParams()
            .set('userId', userID)
            .set('institution', this.institutionCode);
        return this.http.delete(RestApiBase.DELETE_PROFILE_IMAGE, { params });
    }
}
