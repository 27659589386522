import {
  Component,
  OnInit,
  Input,
  ElementRef,
  inject,
  output,
  viewChild
} from '@angular/core';
import { mixinColor } from '../../shared/common-behavior/color';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

class OnboardingComponentBase {
    constructor(public _elementRef: ElementRef) {}
}

const _OnboardingComponentMixinBase = mixinColor(OnboardingComponentBase);

@Component({
    selector: 'exl-common-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    imports: [MatMenuTrigger, MatMenu, MatButton, TranslateModule],
})
export class OnboardingComponent
    extends _OnboardingComponentMixinBase
    implements OnInit
{
    guideShowDelay = 600;
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() text: string;
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() align: 'left' | 'right' | 'center' = 'left';
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() textOffset: number;
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() buttonText = 'research.header.proxy.menu.message.go.it';
    readonly guideClosed = output<null>();

    readonly guideTextTrigger = viewChild<MatMenuTrigger>('guideTextTrigger');

    constructor() {
        const elementRef = inject(ElementRef);

        super(elementRef);
    }

    ngOnInit() {
        setTimeout(() => {
            this.guideTextTrigger().openMenu();
        }, this.guideShowDelay);
    }

    onGuideTextClosed() {
        this.guideClosed.emit(null);
    }

    get offsetTextPosition() {
        const off = this.textOffset;
        return this.align === 'left' ? `-${off}rem` : `${off}rem`;
    }
}
