import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { ActivatedRoute } from '@angular/router';
import { ResearchPerson } from '../shared/interfaces/research-person.model';
import { ResearchPersonData } from '../shared/interfaces/research-person-data.model';
import { UrlUtils } from '../shared/utils/url.utils';
import { JwtUtilService } from '../core/jwt-util.service';
import { ResearcherProfile } from '../shared/interfaces/researcher-profile.interface';
import { Observable, Subject, tap } from 'rxjs';
import { ResearcherOverview } from '../shared/interfaces/researcher-overview.interface';
import { ResearcherMetrics } from '../shared/interfaces/researcher-metrics.interface';

export interface ResponseData {
    status: string;
    'reply-text': string;
}

@Injectable()
export class ProfileService {
    private http = inject(HttpClient);
    private route = inject(ActivatedRoute);
    private jwtUtilService = inject(JwtUtilService);

    _institutionCode: string;
    _userId: string;
    _isPublicPage = false;
    _userID;
    profile: ResearchPerson;
    sections = [];
    metricsLoading = false;

    /** When we navigate to researcher public profile from the search state, the researcher profile is for sure active,
     therefore we will skip the extra testing of "hasActiveProfile" rest **/
    //navigateToProfileFromSearch = false;

    nameChange$: Subject<any> = new Subject();

    constructor() {
        const jwtUtilService = this.jwtUtilService;

        this._institutionCode = UrlUtils.getParam('institution');
        this._userId = jwtUtilService.getUserID();
    }

    getProfileData() {
        if (!this.profile) {
            this.retrieveProfileData();
        }
        return this.profile;
    }

    retrieveProfileData() {
        if (this.jwtUtilService.isResearcherTypeLoggedIn()) {
            this._institutionCode = UrlUtils.getParam('institution');
            this._userId = this.jwtUtilService.getUserID();
            const params = new HttpParams().set(
                'institution',
                this._institutionCode
            );
            this.http
                .get(RestApiBase.PROFILE_SERVICE + '/' + this._userId, {
                    params,
                })
                .subscribe((data) => {
                    if (data) {
                        this.profile = new ResearchPerson(
                            data as ResearchPersonData
                        );
                    }
                });
        }
    }

    get isPublicPage() {
        return this._isPublicPage;
    }

    set isPublicPage(value: boolean) {
        this._isPublicPage = value;
    }

    get userID() {
        return this._userID;
    }

    set userID(value) {
        this._userID = value;
    }

    hasActiveProfile(urlIdentifier) {
        const params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('urlIdentifier', urlIdentifier);
        return this.http.get(RestApiBase.HAS_ACTIVE_PROFILE, {
            params,
            responseType: 'text',
        });
    }

    displayOutputTabInPublicProfile(userID) {
        let params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('isUserLoggedIn', String(this.jwtUtilService.isLoggedIn()));
        params = params.set('urlIdentifier', userID);
        return this.http.get(RestApiBase.DISPLAY_ASSET_LIST_URL_IDENTIFIER, {
            params,
        });
    }

    getResearcherProfileData(userID): Observable<ResearcherProfile> {
        let params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('isUserLoggedIn', String(this.jwtUtilService.isLoggedIn()));
        if (this.isPublicPage) {
            params = params.set('urlIdentifier', userID);
            return this.http.get<ResearcherProfile>(
                RestApiBase.GET_RESEARCHER_PROFILE_DATA_URL_IDENTIFIER,
                { params }
            );
        }
        params = params.set('userID', userID);
        return this.http.get<ResearcherProfile>(
            RestApiBase.GET_RESEARCHER_PROFILE_DATA,
            { params }
        );
    }

    getResearcherProfileOverview(userID): Observable<ResearcherOverview> {
        let params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('isUserLoggedIn', String(this.jwtUtilService.isLoggedIn()));
        if (this.isPublicPage) {
            params = params.set('urlIdentifier', userID);
            return this.http.get<ResearcherOverview>(
                RestApiBase.GET_RESEARCHER_PROFILE_OVERVIEW_URL_IDENTIFIER,
                { params }
            );
        }
        params = params.set('userID', userID);
        return this.http.get<ResearcherOverview>(
            RestApiBase.GET_RESEARCHER_PROFILE_OVERVIEW,
            { params }
        );
    }
    getResearcherProfileMetrics(userID): Observable<ResearcherMetrics> {
        this.metricsLoading = true;
        let params = new HttpParams().set('institution', this._institutionCode);
        if (this.isPublicPage) {
            params = params.set('urlIdentifier', userID);
            return this.http
                .get<ResearcherMetrics>(
                    RestApiBase.GET_RESEARCHER_PROFILE_METRICS_URL_IDENTIFIER,
                    { params }
                )
                .pipe(tap({ finalize: () => (this.metricsLoading = false) }));
        }
        params = params.set('userID', userID);
        return this.http
            .get<ResearcherMetrics>(
                RestApiBase.GET_RESEARCHER_PROFILE_METRICS,
                { params }
            )
            .pipe(tap({ finalize: () => (this.metricsLoading = false) }));
    }

    getResearcherDataForSEO(userID): Observable<ResearcherProfile> {
        let params = new HttpParams().set('institution', this._institutionCode);
        if (this.isPublicPage) {
            params = params.set('urlIdentifier', userID);
            return this.http.get<ResearcherProfile>(
                RestApiBase.GET_RESEARCHER_DARA_FOR_SEO_URL_IDENTIFIER,
                { params }
            );
        }
        params = params.set('userID', userID);
        return this.http.get<ResearcherProfile>(
            RestApiBase.GET_RESEARCHER_DARA_FOR_SEO,
            { params }
        );
    }

    getResearcherHighlights(urlIdentifier) {
        const params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('urlIdentifier', urlIdentifier);
        return this.http.get(RestApiBase.GET_RESEARCHER_HIGHLIGHTS, { params });
    }

    getResearcherGrantsHighlights(urlIdentifier) {
        const params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('urlIdentifier', urlIdentifier);
        return this.http.get(RestApiBase.GET_RESEARCHER_GRANTS_HIGHLIGHTS, {
            params,
        });
    }

    getUserID() {
        let userID;
        if (this.isPublicPage) {
            userID = this.userID;
        } else {
            userID = this.jwtUtilService.getUserID();
        }
        return userID;
    }

    displayAwardsTab() {
        let params = new HttpParams().set('institution', this._institutionCode);
        params = params.set('userID', this.jwtUtilService.getUserID());
        return this.http.get(RestApiBase.DISPLAY_AWARDS_TAB, { params });
    }

    displayAwardsTabInPublicProfile(userID) {
        const params = new HttpParams()
            .set('institution', this._institutionCode)
            .set('isUserLoggedIn', String(this.jwtUtilService.isLoggedIn()))
            .set('urlIdentifier', userID);
        return this.http.get(RestApiBase.DISPLAY_AWARDS_TAB_IN_PUBLIC_PROFILE, {
            params,
        });
    }

    changePassword(oldPassword, newPassword, repeatPassword) {
        const payload = {};
        payload['data'] = {
            old_password: oldPassword,
            new_password: newPassword,
            repeat_password: repeatPassword,
        };
        let params = new HttpParams();
        params = params.set('institution', this._institutionCode);
        return this.http.post<ResponseData>(
            RestApiBase.UPDATE_USER_PASSWORD,
            payload,
            {
                params,
            }
        );
    }
}
