<svg [attr.height]="diameter()" [attr.width]="diameter()" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <linearGradient id="gradient" x1="100%" x2="0%" y1="100%" y2="0%">
      <stop class="spinner-color-start" offset="0%"></stop>
      <stop class="spinner-color-end" offset="100%"></stop>
    </linearGradient>
  </defs>

  <circle cx="50" cy="50" r="45" stroke="url(#gradient)" />
</svg>
