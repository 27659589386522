@if (data?.actions?.close) {
  <button
    mat-icon-button
    class="dialog-close-button"
    [mat-dialog-close]="'cancel'"
    aria-label="{{ 'research.aria.dialog.close.dialog' | translate }}"
    >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
}
<h2 mat-dialog-title cdkFocusInitial>
  @if (!useCustomPipe) {
    {{ data?.title | translate : titleParam }}
  }
  @if (useCustomPipe) {
    {{ data?.title | customTranslate }}
  }
</h2>

@if (data?.message) {
  <div mat-dialog-content>
    @if (!useCustomPipe) {
      {{ data?.message | translate }}
    }
    @if (useCustomPipe) {
      {{ data?.message | customTranslate : messageParam }}
    }
  </div>
}

<div mat-dialog-actions>
  @if (data?.actions?.discard) {
    <button
      mat-button
      #discard
      [mat-dialog-close]="'discard'"
      [color]="actionFocus === 'discard' ? accentColor : null"
      [class.is-large]="actionFocus === 'discard'"
      >
      @if (!useCustomPipe) {
        {{ data?.actions?.discard | translate }}
      }
      @if (useCustomPipe) {
        {{ data?.actions?.discard | customTranslate }}
      }
    </button>
  }
  <span class="spacer"></span>
  @if (data?.actions?.cancel) {
    <button
      mat-button
      #cancel
      [mat-dialog-close]="'cancel'"
      [color]="actionFocus === 'cancel' ? accentColor : null"
      [class.is-large]="actionFocus === 'cancel'"
      >
      @if (!useCustomPipe) {
        {{ data?.actions?.cancel | translate }}
      }
      @if (useCustomPipe) {
        {{ data?.actions?.cancel | customTranslate }}
      }
    </button>
  }
  @if (data?.actions?.confirm) {
    <button
      mat-button
      #confirm
      [mat-dialog-close]="'ok'"
      [color]="actionFocus === 'confirm' ? accentColor : null"
      [class.is-large]="actionFocus === 'confirm'"
      >
      @if (!useCustomPipe) {
        {{ data?.actions?.confirm | translate }}
      }
      @if (useCustomPipe) {
        {{ data?.actions?.confirm | customTranslate }}
      }
    </button>
  }
</div>
