import {
    enableProdMode,
    APP_INITIALIZER,
    importProvidersFrom,
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { init, tokenGetter } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgOptimizedImage } from '@angular/common';
import { EsploroMissingTranslationHandler } from './app/missing-translation-handler';
import { UrlUtils } from './app/shared/utils/url.utils';
import { RestApiBase } from './app/shared/configurations/rest-api-base.config';
import { Observable, map } from 'rxjs';
import {
    TranslateModule,
    TranslateLoader,
    MissingTranslationHandler,
} from '@ngx-translate/core';
import {
    HideOnPrintDirective,
    ShowOnlyOnPrintDirective,
    KeepOnPrintDirective,
} from '@exl-ng/mulo-core';
import {
    BrandComponent,
    SidenavComponent,
    MuloMatCssVarsModule,
    HtmlSanitizePipe,
} from '@exl-ng/mulo-common';
import {
    HeaderMainFooterLayoutComponent,
    CenteredBlockLayoutComponent,
} from '@exl-ng/mulo-views';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EsploroFooterModule } from './app/esploro-footer/esploro-footer.module';
import { FooterModule } from './app/parts/footer/footer.module';
import { EsploroHeaderModule } from './app/esploro-header/esploro-header.module';
import { LayoutsModule } from './app/parts/layouts/layouts.module';
import { DepositModule } from './app/deposit/deposit.module';
import { CoreModule } from './app/core/core.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BidiModule } from '@angular/cdk/bidi';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import {
    provideHttpClient,
    withInterceptorsFromDi,
    withXsrfConfiguration,
    HttpClient,
} from '@angular/common/http';
import { customTooltipDefaults } from './app/shared/a11y/mat-tooltip-default-option';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateTimeService } from './app/core/date-time.service';
import {
    MAT_DATE_FORMATS,
    DateAdapter,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { ConfigService } from './app/config.service';
import { GoogleAnalyticsService } from './app/core/google-analytics.service';
import { DepositFormOverlayService } from './app/deposit/deposit-form-overlay/deposit-form-overlay.service';

class CustomLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        const stored = JSON.parse(
            localStorage.getItem('rvXl8n_' + lang) || '{}',
        );
        return this.http
            .get(
                `${RestApiBase.TRANSLATIONS}?institution=${UrlUtils.getParam(
                    'institution',
                )}&lang=${lang}`,
            )
            .pipe(map((remoteData) => ({ ...stored, ...remoteData })));
    }
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            JwtModule.forRoot({
                config: {
                    tokenGetter,
                },
            }),
            BidiModule,
            AppRoutingModule,
            CoreModule,
            DepositModule,
            LayoutsModule,
            EsploroHeaderModule,
            FooterModule,
            EsploroFooterModule,
            MatIconModule,
            MatButtonModule,
            HideOnPrintDirective,
            ShowOnlyOnPrintDirective,
            KeepOnPrintDirective,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useClass: CustomLoader,
                    deps: [HttpClient],
                },
                missingTranslationHandler: {
                    provide: MissingTranslationHandler,
                    useClass: EsploroMissingTranslationHandler,
                },
                useDefaultLang: false,
            }),
            MuloMatCssVarsModule.forRoot({
                isAutoContrast: true,
                darkThemeClass: 'isDarkTheme',
                lightThemeClass: 'isLightTheme',
            }),
            NgOptimizedImage,
            HtmlSanitizePipe,
        ),
        DepositFormOverlayService,
        GoogleAnalyticsService,
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: init,
            multi: true,
            deps: [ConfigService],
        },
        {
            provide: MAT_DATE_FORMATS,
            deps: [DateTimeService],
            useFactory: (dateTimeService: DateTimeService) =>
                dateTimeService.getDateFormat(),
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, DateTimeService],
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: customTooltipDefaults,
        },
        provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({})),
        provideAnimations(),
    ],
}).catch((err) => console.error(err));
