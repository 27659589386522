import {
  Component,
  Directive,
  HostBinding,
  ContentChild,
  ElementRef,
  AfterViewInit,
  input,
  contentChildren,
  viewChild
} from '@angular/core';
import { MatAccordionDisplayMode, MatAccordion } from '@angular/material/expansion';
import { FileListItemComponent } from './file-list-item/file-list-item.component';
import { MatList } from '@angular/material/list';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'mulo-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
    host: { class: 'mulo-file-list' },
    imports: [
        MatAccordion,
        NgTemplateOutlet,
        MatList
    ]
})
export class FileListComponent implements AfterViewInit {
  readonly expandAllButton = input<string>(undefined);
  readonly multiOpen = input(true);
  readonly displayMode = input<MatAccordionDisplayMode>('default');
  readonly listStyle = input<'accordion' | 'list'>('accordion');

  readonly items = contentChildren(FileListItemComponent);

  readonly listTitle = viewChild<ElementRef>('listTitle');
  title = false;

  ngAfterViewInit() {
    this.title = this.listTitle()?.nativeElement?.children?.length > 0;
  }

  expandAll() {
    this.items().forEach((item) => item.expand());
  }
  collapseAll() {
    this.items().forEach((item) => item.expand());
  }
}

@Directive({
    selector: '[mulo-file-list-action]',
    standalone: true,
})
export class FileListActionDirective {
  @HostBinding('class.mulo-file-list-action') class = true;
}
