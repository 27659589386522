import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  ElementRef,
  OnChanges,
  OnDestroy,
  Renderer2,
  inject,
  input,
  model,
  output,
  viewChildren,
  viewChild,
  computed,
  signal,
} from '@angular/core';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { CdkAriaLive } from '@angular/cdk/a11y';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton, MatAnchor, MatIconButton } from '@angular/material/button';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';

import { componentDestroyed, SvgViewboxDirective } from '@exl-ng/mulo-core';
import {
  BehaviorSubject,
  Observable,
  map,
  takeUntil,
  tap,
  startWith,
} from 'rxjs';

import { AssetExportTargets } from './asset-export-targets';
import {
  HeightInAnimation,
  HeightOutAnimation,
  OpacityInAnimation,
  OpacityOutAnimation,
  WidthInAnimation,
} from '../../../animations';
import { ExportersService } from '../exporter.service';
import {
  ExportTarget,
  ExportScope,
  ExporterLabels,
  ExportAsset,
  ExportAction,
  ExportOutputStyle,
  TargetOptionSelect,
} from '../exporter.model';
import { HtmlSanitizePipe } from '../../../pipes';
import { InfobarComponent } from '../../infobar';
import { AriaProgressSpinnerDirective } from '../../../directives';
import { CopierComponent } from '../../copier';
import { AnimatedCheckmarkComponent } from '../../animated-checkmark';

const defaultLabels: ExporterLabels = {
  all: 'All assets',
  selected: 'Selected assets',
  scope: 'Export',
  to: 'To',
  copy: 'Copy',
  copied: 'Copied!',
};
@Component({
  selector: 'mulo-asset-exporter',
  templateUrl: './asset-exporter.component.html',
  styleUrls: ['./asset-exporter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    HeightInAnimation,
    HeightOutAnimation,
    OpacityInAnimation,
    OpacityOutAnimation,
    WidthInAnimation,
  ],
  host: { class: 'mulo-asset-exporter' },
  imports: [
    MatTabNav,
    MatTabLink,
    MatIcon,
    SvgViewboxDirective,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    CdkAriaLive,
    AnimatedCheckmarkComponent,
    CopierComponent,
    MatProgressSpinner,
    AriaProgressSpinnerDirective,
    InfobarComponent,
    MatButton,
    MatAnchor,
    AsyncPipe,
    HtmlSanitizePipe,
    MatTabNavPanel,
    JsonPipe,
  ],
})
export class AssetExporterComponent implements OnInit, OnChanges, OnDestroy {
  private service = inject(ExportersService);
  private renderer = inject(Renderer2);

  readonly titleId = input<string>(undefined);
  /** Whether the asset export component is loaded in a dialog context */
  readonly inDialog = input(false);
  /** static labels (for translation) */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() labels: ExporterLabels;
  /** The source to be exported */
  /** The source to be exported, can be a single asset or a an array of assets */
  readonly selectedAssets = input<ExportAsset[]>(undefined);
  readonly asset = computed(() => this.selectedAssets()?.[0]);
  /** Optional array to give the option to export an "All" assets array */
  readonly allAssets = input<ExportAsset[]>(undefined);
  /** The target format to be exported to */
  readonly exportTargets = input<ExportTarget[]>(AssetExportTargets);
  /** The content of the output, where available, to be previewed  */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() outputPreview: string;

  /** Selected export scope */
  readonly selectedScope = model<ExportScope>('single');
  readonly computedScope = computed<ExportScope>(() => {
    const selectedScope = this.selectedScope();
    if (selectedScope === 'selected' && this.selectedCount() === 1) {
      return 'single';
    } else if (
      selectedScope === 'selected' &&
      this.selectedCount() === this.totalCount()
    ) {
      return 'all';
    } else {
      return selectedScope;
    }
  });

  /** Selected export target */
  readonly selectedTarget = input<string>(undefined);

  /** On selected target change */
  readonly selectedTargetChange = output<ExportTarget>();

  /** Array of actions per export target. Here as an amitter to communicate it to the hosting dialog, if used */
  readonly actionsChange = output<ExportAction[]>();
  /** Emit the clicked action */
  // @Output() actionClick = new EventEmitter<ExportAction>();
  readonly actionClick = input<ExportAction>(undefined);

  /** Create reference to the mat-tab-nav items */
  readonly selectors = viewChildren(MatTabLink);

  /**
   * Element referece to output frame so we can scroll it later
   */
  readonly output = viewChild<ElementRef>('output');

  selectedCount = computed(() => this.selectedAssets()?.length);
  totalCount = computed(() => this.allAssets()?.length);
  showSourceOptions = false;
  private selectedExportTargetSubject = new BehaviorSubject<ExportTarget>(null);
  selectedExportTarget$ = this.selectedExportTargetSubject.asObservable();
  outputPreview$ = new Observable<string>();
  actionSuccess$ = new Observable();
  selectedTargetIndex: number;
  targetActions$ = new Observable<ExportAction[]>();
  outputStyle$: Observable<ExportOutputStyle>;

  /** Loading indicator when processing a preview */
  readonly loadingPreview = model(true);

  ngOnInit() {
    this.labels = { ...defaultLabels, ...this.labels };
    this.outputStyle$ = this.selectedExportTarget$.pipe(
      map((target) => target.preview),
      startWith('plain' as ExportOutputStyle),
    );
    this.outputPreview$ = this.service.outputPreview$.pipe(
      tap((content: string) => {
        this.outputPreview = content;
        this.loadingPreview.set(false);
        this.output().nativeElement.scrollTo(0, 0);
      }),
    );
    this.actionSuccess$ = this.service.actionSuccess$.pipe(
      map((content) => content?.action?.successMsg || ''),
      tap((msg) => {
        if (msg !== '') {
          setTimeout(() => {
            const unlisteners = [];
            for (const ev of ['click', 'keydown.enter', 'keydown.space']) {
              unlisteners.push(
                this.renderer.listen('window', ev, () => {
                  this.service.onActionSuccess(null);

                  setTimeout(() => {
                    // discard listeners nicely
                    unlisteners.forEach((ul) => ul());
                  }, 500);
                }),
              );
            }
          }, 1000);
        }
      }),
      takeUntil(componentDestroyed(this)),
    );

    this.targetActions$ = this.service.targetActions$;

    if (this.selectedTarget()) {
      const target = this.exportTargets().filter(
        (t) => t.value === this.selectedTarget(),
      )[0];
      this.setExportTarget(target);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return null;
    }
    if (changes['actionClick'] && changes['actionClick'].currentValue) {
      this.onActionClick(changes['actionClick'].currentValue);
    }
    if (changes['exportTargets'] && changes['exportTargets']?.currentValue) {
      this.setExportTarget(this.exportTargets()[0]);
    }
  }
  /**
   * @internal
   */
  ngOnDestroy() {}

  setExportTarget(target: ExportTarget) {
    this.loadingPreview.set(true);
    this.selectedExportTargetSubject.next(target);
    this.updateActions(target.actions);
    this.service.onTargetChanged(target);
    if (!this.inDialog()) {
      this.selectedTargetChange.emit(target);
    }
    if (!target.preview) {
      this.loadingPreview.set(false);
    }
  }

  updateActions(actions) {
    this.service.updateActions(actions);
    this.actionsChange.emit(actions);
  }

  onScopeChange(newScope: ExportScope) {
    this.selectedScope.set(newScope);
  }

  onOptionsChange(option, event) {
    const selected = event.target ? event.target.value : event;

    const target = this.selectedExportTargetSubject.getValue();
    const targetOption = target.targetOptions.find(
      (o) => o.value === option.value,
    );

    if (targetOption.type === 'select') {
      (targetOption as TargetOptionSelect).selected = selected;
    } else if (['text', 'email'].includes(targetOption.type)) {
      targetOption.value = selected;
    }
    this.setExportTarget(target);
  }

  onActionClick(action: ExportAction) {
    this.service.onActionClick(action);
  }

  onOutputCopied() {
    this.service.onOutputCopied();
  }

  getFieldControl(field) {
    if (!field.control) {
      field.control = new UntypedFormControl('');
    }
    return field.control;
  }
}

@Component({
  selector: 'mulo-exporters-dialog',
  templateUrl: './exporters-dialog.component.html',
  styleUrls: ['./exporters-dialog.component.scss'],
  animations: [HeightInAnimation, HeightOutAnimation],
  imports: [
    MatIconButton,
    MatIcon,
    SvgViewboxDirective,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    AssetExporterComponent,
    MatDialogActions,
    MatButton,
    MatAnchor,
    AsyncPipe,
  ],
})
export class ExportersDialogComponent implements OnInit {
  dialogRef = inject<MatDialogRef<ExportersDialogComponent>>(MatDialogRef);
  data = inject(MAT_DIALOG_DATA);

  dialogTitle = 'Export';
  // actions: ExportAction[] | null = null;
  readonly loadingPreview = signal(false);
  targetActions$ = new BehaviorSubject<ExportAction[]>([]);
  actionClicked: ExportAction;

  ngOnInit() {
    if (this.data.dialogTitle) {
      this.dialogTitle = this.data.dialogTitle;
    }
  }

  setActions(actions: ExportAction[]) {
    setTimeout(() => {
      this.targetActions$.next(actions);
    });
  }

  onActionClick(action: ExportAction) {
    this.actionClicked = action;
    setTimeout(() => {
      this.actionClicked = null;
    });
  }

  close() {
    this.dialogRef.close();
  }
}
