import {
  Directive,
  ElementRef,
  OnDestroy,
  NgModule,
  inject,
  output,
} from '@angular/core';

@Directive({
  selector: '[muloDomChange]',
  standalone: true,
})
export class DomChangeDirective implements OnDestroy {
  private elementRef = inject(ElementRef);

  private changes: MutationObserver;

  public readonly domChange = output<MutationRecord>();

  constructor() {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) =>
        this.domChange.emit(mutation),
      );
    });

    this.changes.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
