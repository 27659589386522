<div class="field-wrap">
    <exl-plain-text-input
        [autoPopulated]="isAutoPopulated"
        [input]="depositFormDataService.title"
        [jump]="jump()"
        [label]="'research.assetitle.overline' | customTranslate"
        [multipleLines]="true"
    >
    </exl-plain-text-input>
</div>
