@if (scopes?.length > 1) {
  <div class="scopes">
    <span id="title-label">{{ 'research.advanced.search.scopes.title' | translate }}:</span>
    <mat-form-field class="no-subscript">
      <mat-select [attr.aria-labelledby]="'title-label'" [(ngModel)]="selectedScope" id="scope">
        @for (option of scopes; track option) {
          <mat-option [value]="option.value">
            {{ option.viewValue | translate }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
}
<fieldset [class.margin-top-sm]="media.is('gt-sm') && !(scopes?.length > 1)">
  <legend>{{ 'research.advanced.search.filters.title' | translate }}:</legend>
  <div class="fields-grid">
    @for (line of lines | slice : 0 : formClauseCount; track line; let i = $index; let first = $first) {
      @if (!first) {
        <mat-form-field>
          <mat-label>{{ 'research.aria.search.combobox.operator' | translate }}</mat-label>
          <mat-select [(ngModel)]="line.boolOperator" id="clause-{{ i }}-bool">
            @for (option of this.BOOLEAN_OPERATORS; track option) {
              <mat-option [value]="option">
                {{ 'research.advanced.search.boolOperator.option.' + option | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      <mat-form-field [class.is-first]="first" class="clause-index">
        <mat-label>{{ 'research.aria.search.combobox.index' | translate }}</mat-label>
        <mat-select [(ngModel)]="line.searchIndex" id="clause-{{ i }}-index">
          @for (option of searchIndexes; track option) {
            <mat-option [value]="option">
              {{ 'research.portal.search.index_names.' + option | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (line.searchIndex === 'title') {
        <mat-form-field>
          <mat-label>{{ 'research.aria.search.combobox.type' | translate }}</mat-label>
          <mat-select [(ngModel)]="line.searchOperator" id="clause-{{ i }}-precision_all">
            @for (option of PRECISION_OPERATORS_ALL; track option) {
              <mat-option [value]="option">
                {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      @if (line.searchIndex !== 'title') {
        <mat-form-field>
          <mat-label>{{ 'research.aria.search.combobox.type' | translate }}</mat-label>
          <mat-select [(ngModel)]="line.searchOperator" id="clause-{{ i }}-precision_partial">
            @for (option of PRECISION_OPERATORS_PARTIAL; track option) {
              <mat-option [value]="option">
                {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      <mat-form-field class="clause-term">
        <mat-label>{{ 'research.advanced.search.line.placeholder' | translate }}</mat-label>
        <input
          (keydown.enter)="submitSearch()"
          [(ngModel)]="line.searchQuery"
          id="clause-{{ i }}-term"
          matInput
          [required]="first"
          />
        <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
      </mat-form-field>
    }

    <div class="add-clause">
      <button
        (click)="addFormClause()"
        [disabled]="formClauseCount >= ADVANCED_SEARCH_MAX_QUERY_LINES"
        class="has-icon"
        color="primary"
        mat-button
        >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.advanced.search.line.add' | translate }}
      </button>
      <button (click)="resetForm()" mat-button>
        {{ 'research.advanced.search.line.clear' | translate }}
      </button>
    </div>
  </div>

  @if (displayYearFilter || displayResourceTypeFilter) {
    <div class="special-filters">
      @for (filter of searchFilters; track filter) {
        @if (displayResourceTypeFilter && filter === 'resource_type') {
          <mat-form-field>
            <mat-label>{{ 'research.portal.search.filters.resource_type' | translate }}</mat-label>
            <mat-select [(ngModel)]="rtypeFilter" id="resource-type">
              @for (option of rtypeFilterOptions; track option) {
                <mat-option [value]="option">
                  {{ 'research.advanced.resourceType.' + option | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (displayYearFilter && filter === 'year') {
          <exl-date-input
            [fromDateTranslation]="'research.portal.search.filters.year.start' | translate"
            [fromDate]="this.dateForm.get('fromDate')"
            [rangeError]="!this.isDateValid()"
            [range]="true"
            [showHint]="false"
            [toDateTranslation]="'research.portal.search.filters.year.end' | translate"
            [toDate]="this.dateForm.get('toDate')"
            id="date-input"
          ></exl-date-input>
        }
      }
    </div>
  }
</fieldset>
<div *mediaIf="'gt-sm'" class="summary">
  @for (line of lines | slice : 0 : formClauseCount; track line; let i = $index; let first = $first) {
    @if (line.searchQuery) {
      <div [class]="'summary-line-' + line.boolOperator | lowercase">
        @if (first || line.boolOperator === 'OR') {
          <mat-icon
            color="primary"
            svgIcon="arrow-curve-down-right"
          ></mat-icon>
        }
        @if (!first) {
          <mat-form-field class="summary-clause summary-clause-boolean">
            <mat-label class="cdk-visually-hidden">{{
              'research.aria.search.combobox.operator' | translate
            }}</mat-label>
            <mat-select [(ngModel)]="line.boolOperator" id="summary-clause-{{ i }}-bool">
              @for (option of this.BOOLEAN_OPERATORS; track option) {
                <mat-option [value]="option">
                  {{ 'research.advanced.search.boolOperator.option.' + option | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        <mat-form-field class="summary-clause summary-clause-index">
          <mat-label class="cdk-visually-hidden">{{
            'research.aria.search.combobox.index' | translate
          }}</mat-label>
          <mat-select [(ngModel)]="line.searchIndex" id="summary-clause-{{ i }}-index">
            @for (option of searchIndexes; track option) {
              <mat-option [value]="option">
                {{ 'research.portal.search.index_names.' + option | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (line.searchIndex === 'title') {
          <mat-form-field class="summary-clause summary-clause-precision">
            <mat-label class="cdk-visually-hidden">{{
              'research.aria.search.combobox.type' | translate
            }}</mat-label>
            <mat-select [(ngModel)]="line.searchOperator" id="summary-clause-{{ i }}-precision_all">
              @for (option of PRECISION_OPERATORS_ALL; track option) {
                <mat-option [value]="option">
                  {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        @if (line.searchIndex !== 'title') {
          <mat-form-field class="summary-clause summary-clause-precision">
            <mat-label class="cdk-visually-hidden">{{
              'research.aria.search.combobox.type' | translate
            }}</mat-label>
            <mat-select [(ngModel)]="line.searchOperator" id="summary-clause-{{ i }}-precision_partial">
              @for (option of PRECISION_OPERATORS_PARTIAL; track option) {
                <mat-option [value]="option">
                  {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        <mat-form-field class="summary-clause summary-clause-term">
          <span #termWidthCtrl aria-hidden class="summary-clause-term-hidden">{{ line.searchQuery }} </span>
          <input
            [(ngModel)]="line.searchQuery"
            [style.min-width]="'min(' + termWidthCtrl.scrollWidth + 'px, 100%)'"
            [style.width.px]="termWidthCtrl.scrollWidth"
            id="summary-clause-{{ i }}-term"
            matInput
            />
        </mat-form-field>
      </div>
    }
  }
</div>

<mat-card-actions *mediaIf="'gt-sm'" align="end">
  <button (click)="submitSearch()" color="primary" mat-flat-button>
    <span class="margin-right-xs">{{ 'research.advanced.search.submit' | translate }}</span>
    <mat-icon svgIcon="magnify"></mat-icon>
  </button>
</mat-card-actions>

<button
  (click)="submitSearch()"
  *mediaIf="'lt-md'"
  class="adv-search-search-btn has-icon"
  color="primary"
  mat-flat-button
  >
  <mat-icon svgIcon="magnify"></mat-icon>
  <span>{{ 'research.advanced.search.submit' | translate }}</span>
</button>
