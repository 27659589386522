<mat-form-field floatLabel="never" class=" is-comfortably-readable">
  <mat-select [formControl]="depositFormDataService.toaSubject" placeholder="{{'research.topics.toa.select' | customTranslate}}">
    <mat-option [value] = "" >--{{'research.settings.research.none' | customTranslate}}--</mat-option>
    @for (act of activities; track act) {
      <mat-option
        [value]="act"> {{act | translate}}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
