@if (listStyle() === 'accordion') {
  <mat-accordion [multi]="multiOpen()" [displayMode]="displayMode()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-accordion>
}
@if (listStyle() === 'list') {
  <mat-list class="mulo-elevation-list">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-list>
}
<ng-template #content><ng-content></ng-content></ng-template>

<!-- Content projection for slot "[slot-file-list-footer]" -->
<div select="[slot='file-list-footer']" class="files-list-footer"></div>
