import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'highlight',
    standalone: true
})
export class HighlightFilterPipe implements PipeTransform {
    transform(text: string, search): string {
        if (search && text) {
            const textString = text.toString();
            const pattern = search.toString().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
            // pattern = pattern.split(' ').filter((t) => {
            //     return t.length > 0;
            // }).join('|');
            const regex = new RegExp(pattern, 'gi');

            return textString.replace(regex, match => `<span class="highlight">${match}</span>`);
        } else {
            return text;
        }
    }
}
