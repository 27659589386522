import {
    Component,
    OnInit,
    Input,
    HostBinding,
    input,
    output as output_1,
    output,
} from '@angular/core';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';

import { FormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'esp-deposit-radios-boolean',
    templateUrl: './deposit-radios-boolean.component.html',
    styleUrls: ['./deposit-radios-boolean.component.scss'],
    imports: [
        MatRadioGroup,
        FormsModule,
        BadgeAutoPopulatedComponent,
        MatRadioButton,
    ],
})
export class DepositRadiosBooleanComponent implements OnInit {
    readonly input = input<string>(undefined);
    readonly buttonOne = input(undefined);
    readonly buttonTwo = input(undefined);
    readonly output = output<string>();
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.is-autopopulated') @Input() autoPopulated: string =
        null;
    readonly autoPopulatedTooltip = input<string>(undefined);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() model = 'UNKNOWN';
    readonly ariaLabel = input<string>(undefined, { alias: 'aria-label' });

    constructor() {}

    ngOnInit() {}

    onSelectionChange(event) {
        this.output.emit(event);
    }
}
