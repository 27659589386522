import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { DepositSearchForComponent } from './deposit-search-for.component';

@NgModule({
    imports: [CommonModule, SharedModule, DepositSearchForComponent],
    exports: [DepositSearchForComponent],
})
export class DepositSearchModule {}
