@if (data.actions && data.actions.close) {
  <button
    mat-icon-button
    class="dialog-close-button"
    [mat-dialog-close]="'cancel'"
    >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
}
<h2 mat-dialog-title cdkFocusInitial>
  {{ data.title }}
</h2>
@if (data.message) {
  <div mat-dialog-content [innerHTML]="data.message"></div>
}
<div mat-dialog-actions>
  @if (data.actions && data.actions.discard) {
    <button
      mat-button
      #discard
      [mat-dialog-close]="'discard'"
      [color]="actionFocus === 'discard' ? accentColor : null"
      [class.is-large]="actionFocus == 'discard'"
      >
      {{ data.actions.discard }}
    </button>
  }
  <span class="action-spacer"></span>
  <button
    mat-button
    #confirm
    [mat-dialog-close]="'ok'"
    [color]="actionFocus === 'confirm' ? accentColor : null"
    [class.is-large]="actionFocus == 'confirm'"
    >
    {{ confirmText }}
  </button>
  <button
    mat-button
    #cancel
    [mat-dialog-close]="'cancel'"
    [color]="actionFocus === 'cancel' ? accentColor : null"
    [class.is-large]="actionFocus == 'cancel'"
    >
    {{ cancelText }}
  </button>
</div>
