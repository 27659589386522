import {
    Component,
    ChangeDetectionStrategy,
    SimpleChanges,
    ElementRef,
    Renderer2,
    AfterViewInit,
    AfterContentInit,
    OnChanges,
    inject,
    input,
    viewChild,
    contentChildren,
    effect,
} from '@angular/core';
import { FooterLinksSectionComponent } from '../footer-links-section/footer-links-section.component';
import { MatNavList } from '@angular/material/list';

@Component({
    selector: 'exl-footer-links',
    templateUrl: './footer-links.component.html',
    styleUrls: ['./footer-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-footer-links' },
    imports: [MatNavList],
})
export class FooterLinksComponent {
    private renderer = inject(Renderer2);

    readonly maxColumns = input<number | null>(null);
    readonly minColumnWidth = input<string | null>(null);
    readonly maxColumnWidth = input<string | null>(null);
    readonly fxLayout = input(undefined);
    readonly ariaLabel = input<string>(undefined, { alias: 'aria-label' });
    readonly role = input<'list' | 'navigation' | 'none'>('navigation');

    readonly navList = viewChild(MatNavList, { read: ElementRef });

    private _sections: any[];
    readonly sections = contentChildren(FooterLinksSectionComponent);

    constructor() {
        effect(() => {
            // Change list role
            const role = this.role();
            if (role !== 'navigation') {
                this.renderer.setAttribute(
                    this.navList().nativeElement,
                    'role',
                    role,
                );
            }
        });

        effect(() => {
            const sections = this.sections();
            const maxColumns = this.maxColumns();
            const count = Math.min(sections.length, maxColumns);

            if (!sections) return null;
            sections.forEach((section) => {
                section.updateFlexBasis(count);
            });
        });

        effect(() => {
            const minColumnWidth = this.minColumnWidth();
            if (minColumnWidth) {
                this.sections().forEach((section) => {
                    section.updateMinWidth(minColumnWidth);
                });
            }
        });
    }
}
