@if (data.actions.close) {
  <button mat-icon-button class="dialog-close-button" [mat-dialog-close]="'cancel'">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
}
<h2 class="padding-zero" mat-dialog-title>
  {{ data.title }}
</h2>
<div mat-dialog-content>
  {{ data.message }}
</div>
<div mat-dialog-actions>
  @if (data.actions.confirm) {
    <button mat-raised-button color="primary" [mat-dialog-close]="'ok'">
      {{ data.actions.confirm }}
    </button>
  }
  @if (data.actions.discard) {
    <button mat-button color="warn" [mat-dialog-close]="'discard'">
      {{ data.actions.discard }}
    </button>
  }
  @if (data.actions.cancel) {
    <button mat-button [mat-dialog-close]="'cancel'">
      {{ data.actions.cancel }}
    </button>
  }
</div>
