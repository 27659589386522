import { Component, Input, OnInit, input } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'mulo-featured-tile',
    templateUrl: './featured-tile.component.html',
    styleUrls: ['./featured-tile.component.scss'],
    imports: [
        MatIcon,
        SvgViewboxDirective
    ]
})
export class FeaturedTileComponent implements OnInit {
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() image;
  readonly icon = input(undefined);
  readonly rtlMirrorIcon = input<boolean>(undefined);
  readonly title = input(undefined);

  constructor() {}

  ngOnInit(): void {}
}
