import { ChangeDetectorRef, Pipe, PipeTransform, inject } from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'defaultTranslate',
    pure: false,
    standalone: true
})

export class DefaultTranslatePipe extends TranslatePipe implements PipeTransform{
    private translateService: TranslateService;




    constructor() {
        const translateService = inject(TranslateService);
        const _ref = inject(ChangeDetectorRef);

        super(translateService, _ref);
        this.translateService = translateService;

    }

    transform(query: string, ...args: any[]): any {
        try {
            let translation =  super.transform(query);
            if ((args.length > 0) && (!translation || args[0].forceDefault)){
                translation = args[0].default;
            }
            return translation;
        } catch (e) {
            return '';
        }

    }
}
