<div>
  <div>
    @if (displayBookTitle()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('relationTitle')"
        [muloInputExpander]="depositFormDataService.relationTitle.value"
        >
        <input matInput [formControl]="depositFormDataService.relationTitle" />
        <mat-label>{{ 'research.status.title' | customTranslate }}</mat-label>
        @if (isAutoPopulated('relationTitle')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
            matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayISBN()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfIsbnForDisplay')"
        [muloInputExpander]="depositFormDataService.isPartOfIsbnForDisplay.value"
        >
        <input matInput [formControl]="depositFormDataService.isPartOfIsbnForDisplay" />
        <mat-label>{{ 'research.status.isbn' | customTranslate }}</mat-label>
        @if (isAutoPopulated('isPartOfIsbnForDisplay')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
            matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
  </div>

  <div>
    @if (displayPages()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfPages')"
        class="is-short-input"
        >
        <input matInput [formControl]="depositFormDataService.isPartOfPages" />
        <mat-hint align="start">{{ 'research.status.page.hint' | customTranslate }}</mat-hint>
        <mat-label>{{ 'research.status.pages' | customTranslate }}</mat-label>
        @if (isAutoPopulated('isPartOfPages')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
            matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayBookDOI()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfDoi')"
        [muloInputExpander]="depositFormDataService.isPartOfDoi.value"
        >
        <input matInput [formControl]="depositFormDataService.isPartOfDoi" />
        <mat-label>{{ 'research.status.bookdoi' | customTranslate }}</mat-label>
        <mat-hint>{{ 'research.status.doi.hint' | translate }}</mat-hint>
        <mat-error>
          {{ 'research.status.doi.error' | customTranslate }}
        </mat-error>
        @if (isAutoPopulated('isPartOfDoi')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
            matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayPublisherName()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('publisher')"
        [muloInputExpander]="depositFormDataService.publisher.value"
        class="is-short-input"
        >
        <input matInput [formControl]="depositFormDataService.publisher" />
        <mat-label>{{ 'research.status.publisher' | customTranslate }}</mat-label>
        @if (isAutoPopulated('publisher')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
            matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
  </div>
</div>
