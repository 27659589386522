<mat-form-field
    floatLabel="auto"
    class="is-comfortably-readable"
    [muloInputExpander]="topicNamesArray || label || ('research.topics.overline' | customTranslate)"
>
    <mat-label>
        @if (label) {
            {{ label }}
        } @else {
            {{ 'research.topics.overline' | customTranslate }}
        }
    </mat-label>
    <mat-chip-grid #chipGrid>
        @for (
            chip of depositFormDataService.topics.value;
            track trackByTopic(i, chip);
            let isFirst = $first;
            let i = $index
        ) {
            <mat-chip-row
                [removable]="removable"
                [aria-label]="chip.description"
                (removed)="removeChip(chip, i)"
                [matTooltip]="chip.hierarchy"
                matTooltipPosition="above"
                [class.is-autopopulated]="isAutoPopulated(chip)"
                [disableRipple]="true"
                @widthInAnimation
                @widthOutAnimation
            >
                @if (isAutoPopulated(chip)) {
                    <exl-badge-auto-populated
                        tooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
                        color="primary"
                    >
                    </exl-badge-auto-populated>
                }
                <span class="semibold chip-content">
                    {{ chip.description }}
                </span>
                @if (removable) {
                    <button
                        mat-icon-button
                        matChipRemove
                        attr.aria-label="{{ 'research.aria.deposit.keywords.remove' | translate }}{{ ' ' }}{{
                            chip.description
                        }}"
                    >
                        <mat-icon svgIcon="close"></mat-icon>
                    </button>
                }
            </mat-chip-row>
        }
    </mat-chip-grid>

    <input
        matInput
        (keydown.enter)="$event.preventDefault()"
        [required]="this.depositFormDataService.topics.invalid"
        [formControl]="autoCompleteChipList"
        #chipInput
        [matAutocomplete]="auto"
        (keyup.enter)="onInputEnterKey(chipInput)"
        [exlFocus]="jump()"
        [matChipInputFor]="chipGrid"
    />
    @if (this.depositFormDataService.topics?.invalid) {
        <mat-error [innerHTML]="this.depositFormDataService.topics?.errors | errorTranslate"> </mat-error>
    }

    @if (searchInProgress) {
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    }
    <mat-autocomplete #auto="matAutocomplete">
        @if (filteredSuggestions.length > 0) {
            <mat-optgroup>
                <label>
                    <span [muloElementAsHeading]="4">
                        {{ 'research.topics.suggestions' | customTranslate }}
                    </span>
                </label>
                @for (suggestion of filteredSuggestions; track suggestion) {
                    <mat-option
                        [value]="suggestion.description"
                        (onSelectionChange)="addSuggestionChip(suggestion, chipInput)"
                    >
                        <div class="autocomplete-option-multiline">
                            <span
                                class="semibold"
                                [innerHTML]="suggestion.description | highlight: autoCompleteChipList.value"
                            ></span>
                            <span
                                class="italics"
                                [innerHTML]="suggestion.hierarchy | highlight: autoCompleteChipList.value"
                            ></span>
                        </div>
                    </mat-option>
                }
            </mat-optgroup>
        }

        @for (topic of topics; track topic) {
            <mat-option [value]="topic.description" (onSelectionChange)="addChip(topic, chipInput)">
                <div class="autocomplete-option-multiline">
                    <span
                        class="semibold"
                        [innerHTML]="topic.description | highlight: autoCompleteChipList.value"
                    ></span>
                    <span
                        class="italics"
                        [innerHTML]="getHierarchyAsString(topic.hierarchy) | highlight: autoCompleteChipList.value"
                    ></span>
                </div>
            </mat-option>
        }
        @if (autoCompleteChipList.value && noValuesFound && !searchInProgress) {
            <mat-option [value]="autoCompleteChipList.value" (onSelectionChange)="onInputEnterKey(chipInput)">
                {{ 'research.topics.add.new.topic' | customTranslate }} "<span class="bold">{{
                    autoCompleteChipList.value
                }}</span
                >"
            </mat-option>
        }
    </mat-autocomplete>
</mat-form-field>
