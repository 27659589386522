import { Directive, AfterViewInit, QueryList, ContentChildren, OnChanges, SimpleChanges, NgModule, inject, input } from '@angular/core';
import { MatTabLink, MatTabNav } from '@angular/material/tabs';
import { Observable } from 'rxjs';

@Directive({
    selector: '[muloVerticalTabs]',
    host: { class: 'has-vertical-ink-bar is-vertical' },
    standalone: true,
})
export class VerticalTabsDirective implements AfterViewInit, OnChanges {
  private host = inject(MatTabNav);

  readonly muloVerticalTabs = input(undefined);
  // TODO: Skipped for migration because:
  //  There are references to this query that cannot be migrated automatically.
  @ContentChildren(MatTabLink, { descendants: true })
  contentChildren: QueryList<MatTabLink>;

  /* the inkbar  element */
  inkbar;
  /* the margin of the tabs (done by css externally) */
  margin;
  /* adding a few pixels to the bar for aesthtics and usability */
  tweak = 3;

  offsetSet = false;

  contentLoaded$: Observable<QueryList<MatTabLink>> = new Observable();

  constructor() {
    const host = this.host;

    host.disablePagination = true;
  }
  /* We're listening to input changes (typically the 'selectedIndex') */
  ngOnChanges(changes: SimpleChanges) {
    const tabChange = changes['muloVerticalTabs'];

    if (!this.offsetSet && this.contentChildren) {
      // this.setInkbarOffset(this.contentChildren);
    }
    if (!this.offsetSet && tabChange.firstChange) {
      setTimeout(() => {
        this.getTabOffset(tabChange.currentValue);
        // this.setInkbarOffset(this.contentChildren);
      }, 0);
    } else {
      this.getTabOffset(tabChange.currentValue);
    }
  }

  /* Since we're getting things fom the DOM, ewe need to wait for the view */
  ngAfterViewInit() {
    /* get a hold of the inkbar DOM element */
    // this.inkbar = this.host._inkBar?.['_elementRef']?.nativeElement;

    /* subscribe and wait till all content children (tabs) are loaded */
    this.contentChildren.changes.subscribe((list) => {
      if (list.first) {
        if (!this.offsetSet) {
          setTimeout(() => {
            // this.setInkbarOffset(list);
          }, 0);
        } else {
          const activeChild = list.toArray().findIndex((child) => child.active);
        }
      }
    });
  }

  setInkbarOffset(tabs) {
    /* Early exit if no tabs */
    if (!tabs || !tabs.first) return null;

    const el = tabs.first.elementRef.nativeElement; //elementRef.nativeElement
    this.margin = parseFloat(getComputedStyle(el).paddingTop);
    const height = parseFloat(getComputedStyle(el).paddingTop);
    const offset = `calc(${this.margin}px - ${this.tweak}px)`;

    this.inkbar = this.host._inkBar?.['_elementRef']?.nativeElement;

    this.inkbar.style.top = offset;
    this.offsetSet = true;
  }

  /* find the offset of the relevant selected tab by its index */
  getTabOffset(selectedTab) {
    setTimeout(() => {
      const activeTab = this.contentChildren['_results'].filter(
        (c) => c.active
      )[0];

      if (activeTab) {
        const offset = activeTab.elementRef.nativeElement.offsetTop;
        const height = activeTab.elementRef.nativeElement.clientHeight;
        // this.setInkbar(offset, height);
      }
    }, 100);
  }

  /* apply transform styles and height calculations to inkbar */
  setInkbar(offset, height) {
    this.inkbar.style.transform = `translateY(${offset}px)`;
    this.inkbar.style.height = `calc(${height}px - (${this.margin}px * 2) + (${this.tweak}px * 2))`;
  }
}

