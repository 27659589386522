import { Component, OnInit, inject, viewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import moment from 'moment';
import { preventOnKeyboardOrigin } from '../../../shared/annotations/prevent-on-keyboard-origin.annotation';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { AriaCalendarDirective } from '../../../shared/directives/a11y/aria-calendar.directive';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'esp-access-rights-dialog',
    templateUrl: './access-rights-dialog.component.html',
    styleUrls: ['./access-rights-dialog.component.scss'],
    imports: [MatDialogTitle, MatCheckbox, FormsModule, CdkScrollable, MatDialogContent, MatRadioGroup, MatRadioButton, MatFormField, MatInput, MatDatepickerInput, MatLabel, MatDatepickerToggle, MatSuffix, MatDatepicker, AriaCalendarDirective, MatDialogActions, MatButton, MatDialogClose, TranslateModule, CustomTranslatePipe]
})
export class AccessRightsDialogComponent implements OnInit {
    dialogRef = inject<MatDialogRef<AccessRightsDialogComponent>>(MatDialogRef);
    data = inject(MAT_DIALOG_DATA);


    readonly input = viewChild<any>('input');
    applyToAll = false;
    focus = false;
    date = moment();
    labelsPrefix = DepositForm.FILE_ACCESSRIGHTS_LABELS_PREFIX;

    ngOnInit() {
        if (this.data.date){
            this.date = this.data.date;
        }
    }

    @preventOnKeyboardOrigin
    onSelection(){
        setTimeout(() => {
            const input = this.input();
            if (input) {
                input.nativeElement.focus();
            }
        }, 0);
    }

    onSubmit(){
        if (this.data.selected === 'embargoed') {
            this.data.date = this.date;
        }
        this.dialogRef.close(this.data);
    }

    checkboxChanged(event){
        this.data.applyToAll = event.checked;
    }

}
