import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostBinding, HostListener, OnChanges, SimpleChanges, ViewContainerRef, inject, input } from '@angular/core';
import { OnboardingComponent } from './onboarding.component';
import { DomSanitizer } from '@angular/platform-browser';
import { addBodyClass, removeBodyClass } from '../../shared/utils/dom.utils';
import { ThemePalette } from '../../shared/common-behavior/color';

const bodyClass = 'exl-common-onboarding-active';

@Directive({
    selector: '[exlCommonOnboarding]',
    host: {
        class: 'exl-common-onboarding-root',
        '(click)': 'clicked($event)',
    },
    standalone: true,
})
export class OnboardingDirective implements OnChanges {
    private el = inject(ElementRef);
    private vcRef = inject(ViewContainerRef);
    private resolver = inject(ComponentFactoryResolver);
    private sanitizer = inject(DomSanitizer);

    /* Trigger to start the onboarding */
    public readonly exlCommonOnboarding = input(false);
    /* The text/html string to show in the guide */
    public readonly exlCommonOnboardingText = input<string>(undefined);
    /* The text to show in the close/confirm button */
    public readonly exlOnboardingButtonText = input(undefined);
    /* Allow to set alignment of text content */
    public readonly exlCommonOnboardingAlign = input<'left' | 'right' | 'center'>('left');
    /* set the desired background color for onboarding */
    public readonly exlCommonOnboardingColor = input<ThemePalette>(undefined);
    /* Set the amount of padding around the source object */
    public readonly exlCommonOnboardingScale = input(1);
    @HostBinding('attr.style') public get valueAsStyle(): any {
        return this.sanitizer.bypassSecurityTrustStyle(
            `--exl-onboarding-scale: ${this.exlCommonOnboardingScale()}rem`
        );
    }
    /* Bind class when the onboarding intializes */
    @HostBinding('class.exl-common-onboarding-active') isActive = false;
    /* Bind class to show the onboarding */
    @HostBinding('class.exl-common-onboarding-showing') isShowing = false;
    @HostListener('click') public onClick() {
        removeBodyClass(bodyClass);
        this.removeOnboarding();
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.exlCommonOnboarding && this.exlCommonOnboarding()) {
            this.startOnboarding();
        }
    }

    addGuidance() {
        /* Create the component */
        const componentRef: ComponentRef<OnboardingComponent> =
            this.vcRef.createComponent(OnboardingComponent);
        /* Move the created component to be a child of the directive host */
        this.el.nativeElement.appendChild(componentRef.location.nativeElement);
        /* Add class to body to be able to control (override) cdk-overlay */
        addBodyClass(bodyClass);
        /* Add the text/html string to the mat-menu */
        componentRef.instance.text = `${this.exlCommonOnboardingText()}`;
        /* set ailgnment direction */
        componentRef.instance.align = this.exlCommonOnboardingAlign();
        /* set text offset position */
        componentRef.instance.textOffset = this.exlCommonOnboardingScale();
        /* Set button text */
        componentRef.instance.buttonText = this.exlOnboardingButtonText();
        /* Set theme palette */
        componentRef.instance.color = this.exlCommonOnboardingColor();
        /* subscribe to the component's close event */
        componentRef.instance.guideClosed.subscribe(() =>
            this.removeOnboarding()
        );
    }

    startOnboarding() {
        this.isActive = true;
        this.addGuidance();
        setTimeout(() => {
            this.isShowing = true;
        }, 0);
    }

    removeOnboarding() {
        this.isShowing = false;
        setTimeout(() => {
            removeBodyClass(bodyClass);
            this.isActive = false;
            this.vcRef.clear();
        }, 1000);
    }
}
