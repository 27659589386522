import { Component, OnDestroy, OnInit, inject, input, viewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { ResearchTopic } from '../../../shared/interfaces/research-topic.intreface';
import { DepositKeywordsSuggestionsService } from '../deposit-keywords/deposit-keywords-suggestions.service';
import {
  SelectedResearchTopic,
  TopicType,
} from '../../../shared/interfaces/selected-research-topic.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs';
import { WidthInAnimation, WidthOutAnimation, InputExpanderDirective, CollectByFieldPipe } from '@exl-ng/mulo-common';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../../shared/pipes/highlight-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptgroup, MatOption } from '@angular/material/core';
import { EspMatAutocompleteA11yDirective } from '../../../shared/directives/a11y/mat-autocomplete-extension.directive';
import { FocusDirective } from '../../../shared/directives/focus/focus.directive';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';
import { CdkMonitorFocus } from '@angular/cdk/a11y';

import { MatChipGrid, MatChipRow, MatChipRemove, MatChipInput } from '@angular/material/chips';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-freetext-keywords',
    templateUrl: './deposit-freetext-keywords.component.html',
    styleUrls: ['./deposit-freetext-keywords.component.scss'],
    animations: [WidthInAnimation, WidthOutAnimation],
    imports: [
        MatFormField,
        InputExpanderDirective,
        MatLabel,
        MatChipGrid,
        MatChipRow,
        CdkMonitorFocus,
        BadgeAutoPopulatedComponent,
        MatIconButton,
        MatChipRemove,
        MatIcon,
        MatInput,
        FormsModule,
        MatAutocompleteTrigger,
        MatChipInput,
        ReactiveFormsModule,
        FocusDirective,
        MatAutocomplete,
        EspMatAutocompleteA11yDirective,
        MatOptgroup,
        MatOption,
        TranslateModule,
        HighlightFilterPipe,
        CustomTranslatePipe,
        CollectByFieldPipe
    ]
})
export class DepositFreetextKeywordsComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);
  private suggestionsService = inject(DepositKeywordsSuggestionsService);

  readonly chipInput = viewChild('chipInput', { read: MatAutocompleteTrigger });
  readonly jump = input(undefined);

  topics: ResearchTopic[];
  autoCompleteChipList: UntypedFormControl = new UntypedFormControl();
  removable = true;
  filteredSuggestions: SelectedResearchTopic[];
  private topicsDestroy = new Subject<void>();

  trackByTopic = (index: number, topic: ResearchTopic) => topic.description;

  ngOnInit() {
    this.filteredSuggestions = this.suggestionsService.getSuggestions('');
    this.listenToSearchInput();
  }

  listenToSearchInput() {
    this.autoCompleteChipList.valueChanges
      .pipe(
        debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.topicsDestroy)
      )
      .subscribe((value) => {
        this.filteredSuggestions =
          this.suggestionsService.getSuggestions(value);
      });
  }

  addSuggestionChip(topic: SelectedResearchTopic, input): void {
    const description = topic.description;
    this.insertChip(description);
    if (input) {
      input.value = '';
      this.autoCompleteChipList.reset();
    }
  }

  isTopicExists(description) {
    for (const topic of this.depositFormDataService.topics.value) {
      if (topic.description === description) {
        return true;
      }
    }
    return false;
  }

  insertChip(description) {
    this.chipInput().closePanel();
    if (!this.isTopicExists(description)) {
      const selectedTopic = new SelectedResearchTopic(
        '',
        description,
        '',
        TopicType.KEYWORD
      );
      this.depositFormDataService.updateTopics(selectedTopic);
      this.depositFormDataService.updateKeywords(description);
    }
  }

  removeChip(topic: SelectedResearchTopic, index): void {
    this.depositFormDataService.removeTopic(index);
    this.removeTopicFromArray(
      this.depositFormDataService.keywords,
      topic.description
    );
  }

  removeTopicFromArray(array: UntypedFormArray, element) {
    const i = array.value.indexOf(element);
    if (i > -1) {
      array.removeAt(i);
    }
    this.depositFormDataService.keywordsAutoPopulated.splice(i, 1);
  }

  onInputEnterKey(input: any): void {
    const value = input.value;
    if (value) {
      this.insertChip(value);
      input.value = '';
      this.autoCompleteChipList.reset();
    }
  }

  isAutoPopulated(keyword) {
    return (
      this.depositFormDataService.keywordsAutoPopulated.indexOf(
        keyword.description
      ) !== -1
    );
  }

  ngOnDestroy() {
    if (!this.topicsDestroy.isStopped) {
      this.topicsDestroy.next();
      //unsubscribe from the subject itself
      this.topicsDestroy.unsubscribe();
    }
  }
}
