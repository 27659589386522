import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'getKeys',
  standalone: true,
})
export class GetKeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value || {}); //.map(key => value[key]);
  }
}
