import { Component, ContentChildren, Directive, ElementRef, QueryList, HostListener, AfterContentInit, inject, input } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Directive({
    selector: '[exlTransitionGroupItem]',
    standalone: true
})
export class TransitionGroupItemDirective {
  prevPos: any;
  newPos: any;
  el: HTMLElement;
  moved: boolean;
  moveCallback: any;

  constructor() {
    const elRef = inject(ElementRef);

    this.el = elRef.nativeElement;
  }
}


@Component({
    selector: '[exl-transition-group]',
    template: '<ng-content></ng-content>',
    styleUrls: ['./transition-group.directive.scss'],
    standalone: true
})
export class TransitionGroupComponent implements AfterContentInit {
  // base name for setting class name on moving items - taken from the input
  readonly class = input(undefined, { alias: "exl-transition-group" });
  resizeEvent$: Subject<MouseEvent> = new Subject<MouseEvent>();

  // declare the moving components
  // TODO: Skipped for migration because:
  //  There are references to this query that cannot be migrated automatically.
  @ContentChildren(TransitionGroupItemDirective) items: QueryList<TransitionGroupItemDirective>;

  // listen to windows resize events in order to refresh group position once its done
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.resizeEvent$.next(event);
  }

  // reset group position on mouse enter
  @HostListener('mouseenter', ['$event']) onMouseEnter(event) {
    setTimeout(() => this.refreshPosition('newPos'), 0);
  }


  constructor() {
    // refresh position once resize event endes
    this.resizeEvent$.pipe(debounceTime(290)).subscribe(_ => setTimeout(() => this.refreshPosition('newPos'), 0));
  }

  ngAfterContentInit() {
    setTimeout(() => this.refreshPosition('prevPos'), 0); // save init positions on next 'tick'

    this.items.changes.subscribe(items => {
      items.forEach(item => item.prevPos = item.newPos || item.prevPos);
      items.forEach(this.runCallback);
      this.refreshPosition('newPos');
      items.forEach(item => item.prevPos = item.prevPos || item.newPos); // for new items

      const animate = () => {
        items.forEach(this.applyTranslation);
        this['_forceReflow'] = document.body.offsetHeight; // force reflow to put everything in position
        this.items.forEach(this.runTransition.bind(this));
      };

      const willMoveSome = items.some((item) => {
        const dx = item.prevPos.left - item.newPos.left;
        const dy = item.prevPos.top - item.newPos.top;
        return dx || dy;
      });

      if (willMoveSome) {
        animate();
      } else {
        setTimeout(() => { // for removed items
          this.refreshPosition('newPos');
          animate();
        }, 0);
      }
    });
  }

  runCallback(item: TransitionGroupItemDirective) {
    if (item.moveCallback) {
      item.moveCallback();
    }
  }

  runTransition(item: TransitionGroupItemDirective) {
    if (!item.moved) {
      return;
    }
    const cssClass = this.class() + '-move';
    const el = item.el;
    const style: any = el.style;
    el.classList.add(cssClass);
    style.transform = style.WebkitTransform = style.transitionDuration = '';
    el.addEventListener('transitionend', item.moveCallback = (e: any) => {
      if (!e || /transform$/.test(e.propertyName)) {
        el.removeEventListener('transitionend', item.moveCallback);
        item.moveCallback = null;
        el.classList.remove(cssClass);
      }
    });
  }

  refreshPosition(prop: string) {
    this.items.forEach(item => {
      item[prop] = item.el.getBoundingClientRect();
    });
  }

  applyTranslation(item: TransitionGroupItemDirective) {
    item.moved = false;
    const dx = item.prevPos.left - item.newPos.left;
    const dy = item.prevPos.top - item.newPos.top;
    if (dx || dy) {
      item.moved = true;
      const style: any = item.el.style;
      style.transform = style.WebkitTransform = 'translate(' + dx + 'px,' + dy + 'px)';
      style.transitionDuration = '0s';
    }
  }
}
