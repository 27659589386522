/**
 * IANA-approved top-level media content types
 * @see https://www.iana.org/assignments/media-types/media-types.xhtml
 */
const ianaContentTypes = [
  'application',
  'audio',
  'font',
  'example',
  'image',
  'message',
  'model',
  'multipart',
  'text',
  'video',
];

/**
 * Adapted from @mattkrick/sanitize-svg to avoid more dependencies
 */

// List of disallowed SVG elements
// Adjusted from https://github.com/cure53/DOMPurify/blob/f6fcdb9f1c13b3559697db0038744a0a327d46ab/src/tags.js#L201
const svgDisallowed = [
  'a',
  'animate',
  'color-profile',
  'cursor',
  'discard',
  'fedropshadow',
  'font-face',
  'font-face-format',
  'font-face-name',
  'font-face-src',
  'font-face-uri',
  'foreignobject',
  'hatch',
  'hatchpath',
  'mesh',
  'meshgradient',
  'meshpatch',
  'meshrow',
  'missing-glyph',
  'script',
  'set',
  'solidcolor',
  'unknown',
  'use',
];

const getWindow = () => (typeof window === 'undefined' ? null : window);
const readAsText = (svg: File) =>
  new Promise<string | null>((resolve) => {
    if (!isFile(svg)) {
    } else {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result as string | null);
      };
      fileReader.readAsText(svg);
    }
  });

const isFile = (obj: File): obj is File => {
  return (obj as File).size !== undefined;
};

export class FileUtils {
  public static getFileProfile(extension: string, mediaType?: string) {
    if (mediaType !== undefined) {
      // use the MIME type if available
      const typeParts = mediaType?.split('/') || ['', ''];

      switch (typeParts[1]?.toLowerCase()) {
        case 'zip':
        case 'x-zip-compressed':
        case 'rar':
        case '7z':
          return 'compressed';
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'vnd.ms-word.document.macroEnabled.12':
        case 'vnd.ms-word.template.macroEnabled.12':
        case 'msword':
        case 'vnd.oasis.opendocument.text':
        case 'vnd.oasis.opendocument.text-template':
          return 'word';
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'vnd.openxmlformats-officedocument.spreadsheetml.template':
        case 'vnd.ms-excel.sheet.macroEnabled.12':
        case 'vnd.ms-excel.template.macroEnabled.12':
        case 'vnd.ms-excel.addin.macroEnabled.12':
        case 'vnd.ms-excel.sheet.binary.macroEnabled.12':
        case 'vnd.ms-excel':
        case 'vnd.oasis.opendocument.spreadsheet':
        case 'vnd.oasis.opendocument.spreadsheet-template':
          return 'excel';
        case 'vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'vnd.openxmlformats-officedocument.presentationml.template':
        case 'vnd.openxmlformats-officedocument.presentationml.slideshow':
        case 'vnd.ms-powerpoint.addin.macroEnabled.12':
        case 'vnd.ms-powerpoint.presentation.macroEnabled.12':
        case 'vnd.ms-powerpoint.template.macroEnabled.12':
        case 'vnd.ms-powerpoint.slideshow.macroEnabled.12':
        case 'vnd.ms-powerpoint':
        case 'vnd.oasis.opendocument.presentation':
        case 'vnd.oasis.opendocument.presentation-template':
          return 'powerpoint';
        case 'html':
        case 'css':
        case 'javascript':
        case 'json':
          return 'code';
        case 'rtf':
        case 'x-rtf':
        case 'richtext':
          return 'rtf';
        case 'pdf':
          return typeParts[1];
        case 'x-research-info-systems':
          return 'text';
        case 'vnd.ms-pki.stl':
          return 'stl';
        default:
          if (ianaContentTypes.includes(typeParts[0])) {
            return typeParts[0];
          } else {
            return this.getFileProfileFromExt(extension);
          }
      }
    } else {
      // otherwise use extension as fallback
      return this.getFileProfileFromExt(extension);
    }
  }

  public static getFileProfileFromExt(extension: string) {
    if (extension.match(/^(gif|jpe?g|tiff?|png|svg|bmp)$/i)) {
      return 'image';
    } else if (extension.match(/^(zip|rar|dmg)$/i)) {
      return 'compressed';
    } else if (extension.match(/^(pdf)$/i)) {
      return 'pdf';
    } else if (extension.match(/^(docx?)$/i)) {
      return 'word';
    } else if (extension.match(/^(xlsx?)$/i)) {
      return 'excel';
    } else if (extension.match(/^(pptx?)$/i)) {
      return 'powerpoint';
    } else if (extension.match(/^(txt|rtf)$/i)) {
      return 'text';
    } else if (extension.match(/^(xml|json)$/i)) {
      return 'code';
    } else if (extension.match(/^(avi|mp4|mov|mkv)$/i)) {
      return 'video';
    } else if (extension.match(/^(mp3|wav)$/i)) {
      return 'audio';
    } else {
      return 'application';
    }
  }

  public static getHumanFileSize(size: number | string, decimals: number) {
    const bytes = Number(size);
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public static getExtension(fileName: string) {
    return fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
  }

  public static stripExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  public static sanitizeSVG = async (svg: File, window = getWindow()) => {
    if (!window) {
      throw new Error('DOM window required');
    }
    if (isFile(svg) && svg.type !== 'image/svg+xml') {
      return svg;
    }
    const svgText = await readAsText(svg);
    if (!svgText) {
      throw new Error('Image corrupt');
    }
    const playground = window.document.createElement('template');
    playground.innerHTML = svgText;
    const svgEl = playground.content.firstElementChild!;
    const attributes = Array.from(svgEl.attributes).map(({ name }) => name);
    const hasScriptAttr = !!attributes.find((attr) => attr.startsWith('on'));
    const disallowedSvgElements = svgEl.querySelectorAll(
      svgDisallowed.join(','),
    );
    return disallowedSvgElements.length === 0 && !hasScriptAttr ? svg : null;
  };
}
