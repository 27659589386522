@if (thumbs?.length > 0) {
  @for (thumb of thumbs; track thumb) {
    <img
      src="{{thumb.url}}"
      alt="{{thumb.alt}}"
      [style.width]="thumb.disabled ? null : width"
      (load)="onLoad($event, thumb)"
      [class.is-disabled]="thumb.disabled"
      [style.opacity]="opacity"
      />
    }
  }
