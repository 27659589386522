// prettier-ignore
export class RestApiBase {
    public static API_ENDPOINT = "../esplorows/rest/research";

    public static API_ENDPOINT_PROJECTS = "../esplorows/rest/research/projects";
    public static API_ENDPOINT_ACTIVITIES = "../esplorows/rest/research/activities";

    public static API_ENDPOINT_RESOURCES = RestApiBase.API_ENDPOINT + "/resources";
    public static API_ENDPOINT_CV_CONFIG = RestApiBase.API_ENDPOINT + "/cvConfig";
    public static API_ENDPOINT_CV_OUTPUT = RestApiBase.API_ENDPOINT + "/cvOutput";
    public static API_ENDPOINT_ORG_UNITS = RestApiBase.API_ENDPOINT + "/academicUnits";
    public static API_ENDPOINT_INCITES = RestApiBase.API_ENDPOINT + "/incites";
    public static ASSET_REVISION = RestApiBase.API_ENDPOINT + "/assetrevision";

    public static AMAZOM_INFO = RestApiBase.API_ENDPOINT + "/fileUploader/amazonInfo";
    public static DELETE_FILE_FROM_UPLOAD = RestApiBase.API_ENDPOINT + "/fileUploader/deleteFileFromUpload";
    public static GROBID_SERVICE = RestApiBase.API_ENDPOINT + "/fileUploader/grobid";
    public static DOI_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/doiEnrichment";
    public static FILE_WITH_DOI_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/doiFromFileEnrichment";
    public static PMID_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/pmidEnrichment";
    public static TITLE_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/titleEnrichment";
    public static ISSN_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/issnEnrichment";
    public static ISBN_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/isbnEnrichment";
    public static JOURNAL_NAME_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/journalNameEnrichment";
    public static AUTHOR_MATHCHING_ENRICHMENT = RestApiBase.API_ENDPOINT + "/enrichment/authorMatchingEnrichment";
    public static RESEARCH_TOPICS = RestApiBase.API_ENDPOINT + "/researchTopics/lookup";
    public static RESEARCH_TOPICS_ANZ = RestApiBase.API_ENDPOINT + "/researchTopicsANZ/lookup";
    public static CREATORS_CONTRIBUTORS_LOOKUP = RestApiBase.API_ENDPOINT + "/creatorsContributors/lookup";
    public static CREATORS_CONTRIBUTORS_ETD_LOOKUP = RestApiBase.API_ENDPOINT + "/creatorsContributors/etd/lookup";
    public static ADD_RESEARCHER_USER = RestApiBase.API_ENDPOINT + "/creatorsContributors/addResearcherUser";
    public static ADDITIONAL_DATA_ON_DEPOSITOR = RestApiBase.API_ENDPOINT + "/creatorsContributors/additionalDataOnDepositor";
    public static SUBMIT_NEW_ASSET = RestApiBase.API_ENDPOINT + "/depositAsset/submitNewAsset";
    public static SUBMIT_NEW_STUDENT_ASSET = RestApiBase.API_ENDPOINT + "/depositAsset/submitNewStudentAsset";
    public static SUBMIT_FILES_AND_LINKS = RestApiBase.API_ENDPOINT + "/depositAsset/addFiles";
    public static SAVE_DRAFT = RestApiBase.API_ENDPOINT + "/depositAsset/saveDraft";
    public static DELETE_DEPOSIT = RestApiBase.API_ENDPOINT + "/depositAsset/deleteDeposit";
    public static LANGUAGES_LOOKUP = RestApiBase.API_ENDPOINT + "/languages/lookup";
    public static LANGUAGES_THREE_CHARACTERS_CONVERSION = RestApiBase.API_ENDPOINT + "/languages/threeCharactersLangConversion";
    public static LANGUAGES_MULTI_LINGUAL = RestApiBase.API_ENDPOINT + "/languages/multiLingual3LetterCodes";
    public static LOGIN_JWT_CACHE = RestApiBase.API_ENDPOINT + "/login/loginJwtCache";
    public static INTERNAL_LOGIN = "/esplorows/esploroLogin";
    public static EXTERNAL_LOGIN = "/esplorows/esploroExternalLogin";
    public static LOGOUT = "/esplorows/esploroLogout";
    public static PROFILE_SERVICE = RestApiBase.API_ENDPOINT + "/profile";
    public static CONFIGURATION = RestApiBase.API_ENDPOINT + "/configuration";
    public static EXTENDED_CONFIGURATION = RestApiBase.API_ENDPOINT + "/extendedConfiguration";
    public static TRANSLATIONS = RestApiBase.API_ENDPOINT + "/translations";
    public static EXTERNAL_AFFILIATION = RestApiBase.API_ENDPOINT + "/creatorsContributors/externalAffiliation";
    public static ADD_EXTERNAL_AFFILIATION = RestApiBase.API_ENDPOINT + "/creatorsContributors/addExternalAffiliation";
    public static ASSET_SUMMARY_LIST = RestApiBase.API_ENDPOINT + "/userAssets/getAssetSummaryListByUserID";
    public static ASSET_BY_MMS_ID = RestApiBase.API_ENDPOINT + "/userAssets/getAssetByMmsID";
    public static ASSET_LIST_COUNT = RestApiBase.API_ENDPOINT + "/userAssets/getAssetListCountByUserID";
    public static OUTPUT_LIST_FACETS = RestApiBase.API_ENDPOINT + "/userAssets/getOutputListFacets";
    public static DELETE_ASSET = RestApiBase.API_ENDPOINT + "/userAssets/deleteAsset";
    public static TERMS_OF_USE = RestApiBase.API_ENDPOINT + "/configTermsOfUse/termsOfUse";
    public static GRANT_LIST = RestApiBase.API_ENDPOINT + "/grant/lookup";
    public static SUGGESTED_GRANT_FOR_USER = RestApiBase.API_ENDPOINT + "/grant";
    public static ADD_GRANT = RestApiBase.API_ENDPOINT + "/grant/addGrant";
    public static SEARCH_EXTERNAL_GRANTS = RestApiBase.API_ENDPOINT + "/grant/searchExternalGrants";
    public static ENRICH_AND_ADD_GRANT_FROM_PIVOT = RestApiBase.API_ENDPOINT + "/grant/enrichAndAddGrantFromPivot";
    public static RESEARCHER_DETAILS = RestApiBase.API_ENDPOINT + "/settings/getResearcherDetails";
    public static RESEARCHER_PROXIES = RestApiBase.API_ENDPOINT + "/settings/getResearcherProxies";
    public static ADD_VARIANT = RestApiBase.API_ENDPOINT + "/settings/addVariant";
    public static REMOVE_VARIANT = RestApiBase.API_ENDPOINT + "/settings/deleteVariant";
    public static UPDATE_VARIANT = RestApiBase.API_ENDPOINT + "/settings/updateVariant";
    public static UPDATE_DISPLAY_TITLE = RestApiBase.API_ENDPOINT + "/settings/updateDisplayTitle";
    public static UPDATE_PREFERRED_NAME = RestApiBase.API_ENDPOINT + "/settings/updatePreferredName";
    public static CHANGE_PREFERRED_NAME = RestApiBase.API_ENDPOINT + "/settings/changePreferredName";
    public static ADD_NEW_IDENTIFIER = RestApiBase.API_ENDPOINT + "/settings/addIdentifier";
    public static DELETE_IDENTIFIER = RestApiBase.API_ENDPOINT + "/settings/deleteIdentifier";
    public static UPDATE_IDENTIFIER = RestApiBase.API_ENDPOINT + "/settings/updateIdentifier";
    public static ADD_SUBJECT = RestApiBase.API_ENDPOINT + "/settings/addSubject";
    public static ADD_KEYWORD = RestApiBase.API_ENDPOINT + "/settings/addKeyword";
    public static REMOVE_SUBJECT = RestApiBase.API_ENDPOINT + "/settings/removeSubject";
    public static REMOVE_KEYWORD = RestApiBase.API_ENDPOINT + "/settings/removeKeyword";
    public static ADD_EMAIL = RestApiBase.API_ENDPOINT + "/settings/addEmail";
    public static ADD_PROXY_BY_MAIL = RestApiBase.API_ENDPOINT + "/settings/addProxyByMail";
    public static DELETE_EMAIL = RestApiBase.API_ENDPOINT + "/settings/deleteEmail";
    public static DELETE_PROXY = RestApiBase.API_ENDPOINT + "/settings/deleteProxy";
    public static UPDATE_EMAIL = RestApiBase.API_ENDPOINT + "/settings/updateEmail";
    public static CHANGE_PREFERRED_EMAIL = RestApiBase.API_ENDPOINT + "/settings/changePreferredEmail";
    public static CHANGE_EMAIL_VISIBILITY_IN_PROFILE = RestApiBase.API_ENDPOINT + "/settings/changeEmailVisibilityInProfile";
    public static ADD_PHONE = RestApiBase.API_ENDPOINT + "/settings/addPhone";
    public static DELETE_PHONE = RestApiBase.API_ENDPOINT + "/settings/deletePhone";
    public static UPDATE_PHONE = RestApiBase.API_ENDPOINT + "/settings/updatePhone";
    public static CHANGE_PHONE_VISIBILITY_IN_PROFILE = RestApiBase.API_ENDPOINT + "/settings/changePhoneVisibilityInProfile";
    public static SAVE_AREA_OF_INTEREST = RestApiBase.API_ENDPOINT + "/settings/saveAreaOfInterest";
    public static GET_AFFILIATION_POSITION_CODES = RestApiBase.API_ENDPOINT + "/settings/getAffiliationPositionCodes";
    public static GET_NEW_RESERVE_DOI = RestApiBase.API_ENDPOINT + "/doi/getNewReserveDoi";
    public static HAVE_RESERVE_DOI_BUTTON = RestApiBase.API_ENDPOINT + "/doi/haveReservedDoiButton";
    public static ADD_LINK = RestApiBase.API_ENDPOINT + "/settings/addWebPage";
    public static DELETE_LINK = RestApiBase.API_ENDPOINT + "/settings/deleteWebPage";
    public static UPDATE_LINK = RestApiBase.API_ENDPOINT + "/settings/updateWebPage";
    public static UPDATE_LINKS_LIST = RestApiBase.API_ENDPOINT + "/settings/updateWebPageList";
    public static UPDATE_TOPICS_ORDER = RestApiBase.API_ENDPOINT + "/settings/updateTopicsOrder";
    public static GET_RESEARCHER_PROFILE_IMAGE = RestApiBase.API_ENDPOINT + "/settings/getResearcherProfileImage";
    public static PROFILE_IMAGE = RestApiBase.API_ENDPOINT + "/settings/updateProfileImage";
    public static DELETE_PROFILE_IMAGE = RestApiBase.API_ENDPOINT + "/settings/deleteProfileImage";
    public static GET_EXTERNAL_AFFILIATIION_AUTO_COMPLETE = RestApiBase.API_ENDPOINT +
        "/settings/getExternalAffiliationsAutoComplete";
    public static GET_INTERNAL_AFFILIATIION_AUTO_COMPLETE = RestApiBase.API_ENDPOINT +
        "/settings/getInternalAffiliationsAutoComplete";
    public static ADD_AFFILIATION = RestApiBase.API_ENDPOINT + "/settings/addAffiliation";
    public static DELETE_AFFILIATION = RestApiBase.API_ENDPOINT + "/settings/deleteAffiliation";
    public static MOVE_AFFILIATION = RestApiBase.API_ENDPOINT + "/settings/moveAffiliation";
    public static UPDATE_AFFILIATION = RestApiBase.API_ENDPOINT + "/settings/updateAffiliation";
    public static UPDATE_AFFILIATION_AND_MOVE = RestApiBase.API_ENDPOINT + "/settings/updateAffiliationAndMove";
    public static HAS_ACTIVE_PROFILE = RestApiBase.API_ENDPOINT + "/profile/hasActiveProfile";
    public static GET_RESEARCHER_PROFILE_DATA = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileData";
    public static GET_RESEARCHER_PROFILE_OVERVIEW = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileOverview";
    public static GET_RESEARCHER_PROFILE_METRICS = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileMetrics";
    public static GET_RESEARCHER_DARA_FOR_SEO = RestApiBase.API_ENDPOINT + "/profile/getResearcherDataForSEO";
    public static GET_COUNTRIES = RestApiBase.API_ENDPOINT + "/settings/address/countries/lookup";
    public static ADD_ADDRESS = RestApiBase.API_ENDPOINT + "/settings/addAddress";
    public static DELETE_ADDRESS = RestApiBase.API_ENDPOINT + "/settings/deleteAddress";
    public static UPDATE_ADDRESS = RestApiBase.API_ENDPOINT + "/settings/updateAddress";
    public static ADD_HONOR = RestApiBase.API_ENDPOINT + "/settings/addHonor";
    public static DELETE_HONOR = RestApiBase.API_ENDPOINT + "/settings/deleteHonor";
    public static UPDATE_HONOR = RestApiBase.API_ENDPOINT + "/settings/updateHonor";
    public static UPDATE_HONORS_LIST = RestApiBase.API_ENDPOINT + "/settings/updateHonorList";
    public static GET_METRIC_DISPLAY_SETTINGS = RestApiBase.API_ENDPOINT + "/settings/getMetricDisplaySettings";
    public static UPDATE_METRIC_DISPLAY = RestApiBase.API_ENDPOINT + "/settings/updateMetricDisplay";
    public static UPDATE_ENGAGEMENT_DISPLAY = RestApiBase.API_ENDPOINT + "/settings/updateEngagementDisplay";
    public static GET_LETTERS_SETTINGS = RestApiBase.API_ENDPOINT + "/settings/getLettersSettings";
    public static UPDATE_LETTERS_SETTING = RestApiBase.API_ENDPOINT + "/settings/updateLettersSetting";

    public static GET_RESEARCHERS_BY_SEARCH_INDEX = RestApiBase.API_ENDPOINT + "/simpleSearch/researchers/getResearchersBySearchIndex";
    public static GET_RESEARCHER_COUNT = RestApiBase.API_ENDPOINT + "/simpleSearch/researchers/researchersCount";

    public static GET_PROJECTS = RestApiBase.API_ENDPOINT + "/simpleSearch/projects";
    public static GET_PROJECTS_COUNT = RestApiBase.API_ENDPOINT + "/simpleSearch/projects/count";

    public static GET_PROXIES_FOR = RestApiBase.API_ENDPOINT + "/proxy/getProxiesFor";
    public static GET_RESEARCHER_PROFILE_DATA_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileDataByUrlIdentifier";
    public static GET_RESEARCHER_PROFILE_OVERVIEW_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileOverviewByUrlIdentifier";
    public static GET_RESEARCHER_PROFILE_METRICS_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileMetricsByUrlIdentifier";
    public static GET_RESEARCHER_DARA_FOR_SEO_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/profile/getResearcherDataForSEOByUrlIdentifier";
    public static ASSET_LIST_COUNT_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/userAssets/getAssetListCountByUserIDByUrlIdentifier";
    public static GET_RESEARCHER_PROFILE_IMAGE_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/settings/getResearcherProfileImageByUrlIdentifier";
    public static OUTPUT_LIST_FACETS_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/userAssets/getOutputListFacetsByUrlIdentifier";
    public static ASSET_SUMMARY_LIST_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/userAssets/getAssetSummaryListByUrlIdentifier";
    public static GET_FULL_MMS_IDS_BY_USER_ID = RestApiBase.API_ENDPOINT + "/userAssets/getFullMmsIdsListByUserID";
    public static GET_FULL_MMS_IDS_BY_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/userAssets/getFullMmsIdsListByUrlIdentifier";
    public static ANALYTICS = RestApiBase.API_ENDPOINT + "/analytics";

    public static GET_USAGE_BY_COUNTRY = RestApiBase.ANALYTICS + "/getUsageByCountry";
    public static GET_ASSETS_USAGE = RestApiBase.ANALYTICS + "/getAssetsUsage";
    public static ANALYTICS_GET_ORG_UNIT_METRICS = RestApiBase.ANALYTICS + "/getOrgUnitMetrics";
    public static GET_TOTAL_ASSETS_COUNT = RestApiBase.API_ENDPOINT + "/usage/getTotalAssets";
    public static GET_TOTAL_ASSETS_USAGE = RestApiBase.API_ENDPOINT + "/usage/getTotalAssetsUsages";
    public static GET_WOS_CITATION_TOTAL = RestApiBase.API_ENDPOINT + "/usage/getWOSCitationTotal";
    public static GET_CITATION_TOTAL = RestApiBase.API_ENDPOINT + "/usage/getCitationTotal";

    public static PUSH_TO_BIBTEX = RestApiBase.API_ENDPOINT + "/pushTo/EXPORTBIBTEX";
    public static PUSH_TO_RIS = RestApiBase.API_ENDPOINT + "/pushTo/EXPORTRIS";
    public static CODE_TABLE_BASE = RestApiBase.API_ENDPOINT + "/codetable";
    public static CHANGE_USER_LANGUAGE = RestApiBase.API_ENDPOINT + "/userPreferences/changeUserLanguage";
    public static UPDATE_USER_PASSWORD = RestApiBase.API_ENDPOINT + "/userPreferences/updateUserPassword";
    public static RESET_PASSWORD_BY_EMAIL = RestApiBase.API_ENDPOINT + "/userPreferences/sendResetPassword";
    public static GET_HIGHLIGHTS_FULL_LIST = RestApiBase.API_ENDPOINT + "/userAssets/getResearcherHighlightsFullList";
    public static GET_RESEARCHER_HIGHLIGHTS = RestApiBase.API_ENDPOINT + "/userAssets/getResearcherHighlights";
    public static GET_RESEARCHER_GRANTS_HIGHLIGHTS = RestApiBase.API_ENDPOINT + "/awards/getResearcherGrantsHighlights";
    public static ADD_HIGHLIGHT = RestApiBase.API_ENDPOINT + "/userAssets/addHighlight";
    public static REMOVE_HIGHLIGHT = RestApiBase.API_ENDPOINT + "/userAssets/removeHighlight";
    public static ADD_GRANT_HIGHLIGHT = RestApiBase.API_ENDPOINT + "/awards/addGrantHighlight";
    public static REMOVE_GRANT_HIGHLIGHT = RestApiBase.API_ENDPOINT + "/awards/removeGrantHighlight";
    public static ADD_EDUCATION = RestApiBase.API_ENDPOINT + "/settings/addEducation";
    public static DELETE_EDUCATION = RestApiBase.API_ENDPOINT + "/settings/deleteEducation";
    public static UPDATE_EDUCATION = RestApiBase.API_ENDPOINT + "/settings/updateEducation";
    public static UPDATE_EDUCATION_LIST = RestApiBase.API_ENDPOINT + "/settings/updateEducationList";
    public static IS_ORCID_ACTIVE_DIRECT_MODE = RestApiBase.API_ENDPOINT + "/settings/isOrcidActiveDirectMode";
    public static GET_ORCID_AUTORIZATION_URL = RestApiBase.API_ENDPOINT + "/settings/getOrcidAuthorizationUrl";
    public static IS_RESEARCHER_HAS_TRUST_ORCID = RestApiBase.API_ENDPOINT + "/settings/isResearcherHasTrustOrcid";
    public static GET_TRUSTED_ORCID = RestApiBase.API_ENDPOINT + "/settings/getTrustedORCID";
    public static DISPLAY_ASSET_LIST = RestApiBase.API_ENDPOINT + "/userAssets/displayOutputTabByUserID";
    public static DISPLAY_ASSET_LIST_URL_IDENTIFIER = RestApiBase.API_ENDPOINT + "/userAssets/displayOutputTabByUrlIdentifier";
    public static GET_PUBLIC_PROFILE_CONFIGURATION = RestApiBase.API_ENDPOINT + "/profileConfiguration/getResearcherProfileViewConfiguration";
    public static GET_RESEARCHER_PROFILE_TABS = RestApiBase.API_ENDPOINT + "/profile/getResearcherProfileTabs";

    public static GET_RESEARCHER_AWARDS = RestApiBase.API_ENDPOINT + "/awards/getResearcherAwards";
    public static GET_RESEARCHER_AWARDS_FOR_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/awards/getResearcherAwardsForPublicProfile";
    public static HIDE_GRANT_FROM_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/awards/hideGrantFromPublicProfile";
    public static DISPLAY_GRANT_ON_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/awards/displayGrantOnPublicProfile";
    public static DISPLAY_AWARDS_TAB = RestApiBase.API_ENDPOINT + "/awards/displayAwardsTab";
    public static DISPLAY_AWARDS_TAB_IN_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/awards/displayAwardsTabInPublicProfile";
    public static DISPLAY_ASSET_ON_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/userAssets/unhideAssetOnPublicProfile";
    public static HIDE_ASSET_ON_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/userAssets/hideAssetFromPublicProfile";
    public static GET_ASSET_METRICS_USAGE = RestApiBase.API_ENDPOINT + "/usage";
    public static GET_ASSET_FUILL_PAGE_BY_MMS_ID = RestApiBase.API_ENDPOINT + "/fullAssetPage/assets";
    public static GET_VIEWER_REPRESENTATION_INFO = RestApiBase.API_ENDPOINT + "/viewer/representationInfo";
    public static UPDATE_STATISTICS = RestApiBase.API_ENDPOINT + "/fullAssetPage/updateStatistics";
    public static GET_JWT_FOR_LIBRARIAN = RestApiBase.API_ENDPOINT + "/login/loginAsLibrarian";
    public static FULL_ASSET_PAGE_CITATION_ACTION = RestApiBase.API_ENDPOINT + "/actions/citationMultiple";
    public static SEND_ASSET_BY_MAIL = RestApiBase.API_ENDPOINT + "/actions/sendAssetByMail";
    public static GET_CITATION_LIST = RestApiBase.API_ENDPOINT + "/actions/getCitationList";
    public static GET_RESEARCH_ACTIVATION_STATUS = RestApiBase.API_ENDPOINT + "/profileActivation/getResearchActivationStatus";
    public static SET_RESEARCH_ACTIVATION_STATUS = RestApiBase.API_ENDPOINT + "/profileActivation/setResearchActivationStatus";
    public static ADD_COMMUNICATION_MESSAGE = RestApiBase.API_ENDPOINT + "/userAssets/addCommunicationMessage";
    public static READ_COMMUNICATION_MESSAGE_BY_UUID = RestApiBase.API_ENDPOINT + "/userAssets/readCommunicationMessageByUuId";
    public static READ_ALL_COMMUNICATION_MESSAGE = RestApiBase.API_ENDPOINT + "/userAssets/readAllCommunicationMessage";

    public static GET_ASSETS = RestApiBase.API_ENDPOINT + "/simpleSearch/assets";
    public static GET_FACETS = RestApiBase.API_ENDPOINT + "/simpleSearch/facets";
    public static GET_ASSETS_COUNT = RestApiBase.API_ENDPOINT + "/simpleSearch/assetsCount";

    public static DISPLAY_MENTIONS_TAB = RestApiBase.API_ENDPOINT + "/mediaMentions/displayMentionsTab";
    public static DISPLAY_MENTIONS_TAB_IN_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/mediaMentions/displayMentionsTabInPublicProfile";
    public static GET_RESEARCHER_MEDIA_MENTIONS = RestApiBase.API_ENDPOINT + "/mediaMentions/getResearcherMediaMentions";
    public static GET_RESEARCHER_MEDIA_MENTIONS_FOR_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/mediaMentions/getResearcherMediaMentionsForPublicProfile";
    public static HIDE_MENTION_FROM_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/mediaMentions/hideMentionFromPublicProfile";
    public static DISPLAY_MENTION_ON_PUBLIC_PROFILE = RestApiBase.API_ENDPOINT + "/mediaMentions/displayMentionOnPublicProfile";

    public static ORGANIZATION_TREE = RestApiBase.API_ENDPOINT + "/organization/getOrganizationTree";
    public static PROFILES_ORGANIZATION_TREE = RestApiBase.API_ENDPOINT + "/organization/getProfilesOrganizationTree";
    public static NEW_ORGANIZATION_TREE = RestApiBase.API_ENDPOINT_ORG_UNITS + "/getVisibleUnitsTree";
    public static NEW_ORGANIZATION_ANCESTORS = RestApiBase.API_ENDPOINT_ORG_UNITS + "/getOrganizationAncestors";
    public static NEW_ORGANIZATION_FIRST_LEVEL_SUB_UNITS = RestApiBase.API_ENDPOINT_ORG_UNITS + "/getOrganizationFirstLevelSubUnits";
    public static ORGANIZATION_ANCESTORS = RestApiBase.API_ENDPOINT + "/organization/getOrganizationAncestors";
    public static ORGANIZATION_FIRST_LEVEL_SUB_UNITS = RestApiBase.API_ENDPOINT + "/organization/getOrganizationFirstLevelSubUnits";
    public static PROFILES_ORGANIZATION_FIRST_LEVEL_SUB_UNITS = RestApiBase.API_ENDPOINT +
        "/organization/getProfilesOrganizationFirstLevelSubUnits";
    public static GET_INTERNAL_ORGANIZATION_AUTO_COMPLETE = RestApiBase.API_ENDPOINT +
        "/organization/getInternalOrganizationAutoComplete";
    public static RESEARCHER_PRIMARY_ORGANIZATION = RestApiBase.API_ENDPOINT + "/organization/getReseracherPrimaryOrganization";
    public static GET_ALL_INTERNAL_ORGANIZATIONS = RestApiBase.API_ENDPOINT + "/organization/getAllInternalOrganizations";

    public static projectApi: EntityApi = {
        base: RestApiBase.API_ENDPOINT_PROJECTS,
        facets: RestApiBase.API_ENDPOINT_PROJECTS + "/getFacetsProjects",
        displayTab: RestApiBase.API_ENDPOINT_PROJECTS + "/displayProjectsTab",
        delete: RestApiBase.API_ENDPOINT_PROJECTS + "/deleteProject",
        ALL_PROJECTS: RestApiBase.API_ENDPOINT_PROJECTS,
        FACETS_PROJECTS:
            RestApiBase.API_ENDPOINT_PROJECTS + "/getFacetsProjects",
        DELETE_PROJECT: RestApiBase.API_ENDPOINT_PROJECTS + "/deleteProject",
        ADD_NEW_PROJECT: RestApiBase.API_ENDPOINT_PROJECTS + "/newProject",
        IS_PROJECT_IDENTIFIERUNIQUE:
            RestApiBase.API_ENDPOINT_PROJECTS + "/isProjectIdentifierUnique",
        DISPLAY_PROJECTS_TAB:
            RestApiBase.API_ENDPOINT_PROJECTS + "/displayProjectsTab"
    };

    public static ActivityApi: EntityApi = {
        base: RestApiBase.API_ENDPOINT_ACTIVITIES,
        facets: RestApiBase.API_ENDPOINT_ACTIVITIES + "/getFacetsActivities",
        displayTab:
            RestApiBase.API_ENDPOINT_ACTIVITIES + "/displayActivitiesTab",
        delete: RestApiBase.API_ENDPOINT_ACTIVITIES + "/deleteActivity",
        ALL_ACTIVITIES: RestApiBase.API_ENDPOINT_ACTIVITIES,
        FACETS_ACTIVITIES:
            RestApiBase.API_ENDPOINT_ACTIVITIES + "/getFacetsActivities",
        DISPLAY_ACTIVITIES_TAB:
            RestApiBase.API_ENDPOINT_ACTIVITIES + "/displayActivitiesTab",
        GET_ACTIVITY: RestApiBase.API_ENDPOINT_ACTIVITIES,
        DELETE_ACTIVITY:
            RestApiBase.API_ENDPOINT_ACTIVITIES + "/deleteActivity",
        ADD_NEW_ACTIVITY: RestApiBase.API_ENDPOINT_ACTIVITIES + "/newActivity"
    };

    public static ResearchResourcesAPi: EntityApi = {
        base: RestApiBase.API_ENDPOINT_RESOURCES,
        facets: "",
        displayTab: "",
        delete: ""
    };

    public static ADD_RESEARCHER_TO_ASSET = RestApiBase.API_ENDPOINT + "/userAssets/addResearcherToAssetRequest";

    public static GET_PORTAL_HOMEPAGE_IMAGES = RestApiBase.API_ENDPOINT + "/homepage/images";
    public static GET_PORTAL_HOMEPAGE_LINK_IMAGE = RestApiBase.API_ENDPOINT + "/homepage/linkImage";
    public static GET_PORTAL_ASSETS_BRIEF_BY_IDS_LIST = RestApiBase.API_ENDPOINT + "/homepage/getAssetsBriefByIdsList";
    public static GET_PORTAL_ASSETS_BRIEF_BY_SET_ID = RestApiBase.API_ENDPOINT + "/homepage/getAssetsBriefBySetId";
    public static GET_FEATURED_RESEARCHERS = RestApiBase.API_ENDPOINT + "/homepage/getFeaturedResearchers";

    public static APC_FORM_SUBMITION = RestApiBase.API_ENDPOINT + "/apc/apcSubmition";
    public static APC_FORM_INSTITUION_SUFFIX = RestApiBase.API_ENDPOINT + "/apc/getInstitutionEmailSuffix";
    public static APC_FORM_MAIN_INSTITUION_ROR = RestApiBase.API_ENDPOINT + "/apc/getMainInstitutionROR";
    public static APC_RESEARCHER_AFFILIATION = RestApiBase.API_ENDPOINT + "/apc/getReseracherPrimaryAffiliation";
    public static APC_RESEARCHER_PREFERED_EMAIL = RestApiBase.API_ENDPOINT + "/apc/getPreferedUserEmail";
    public static APC_RESEARCHER_ORCID = RestApiBase.API_ENDPOINT + "/apc/getReseracherOrcid";
    public static GET_RESEARCHER_AFFILIATION =
        RestApiBase.API_ENDPOINT + "/studentDeposit/getReseracherPrimaryAffiliation";

    public static PIVOT_ESPLORO_EMBEDDED_LINK = RestApiBase.API_ENDPOINT + "/pivotFunding/getPivotEsploroFundingEmbeddedLink";
    public static HAS_PIVOT = RestApiBase.API_ENDPOINT + "/pivotFunding/hasPivot";

    public static GET_STUDENT_DEPOSIT_FORM = RestApiBase.API_ENDPOINT + "/studentDeposit/getStudentDeposit";
    public static GET_STUDENT_EMAIL = RestApiBase.API_ENDPOINT + "/studentDeposit/getUserEmail";
    public static GET_USER_ORCID = RestApiBase.API_ENDPOINT + "/studentDeposit/getUserOrcid";
    public static GET_ALLOW_REMOVE_CREATOR = RestApiBase.API_ENDPOINT + "/studentDeposit/getAllowRemoveCreator";
    public static GET_RESEARCHER_DEPOSIT_PROFILE = RestApiBase.API_ENDPOINT + "/studentDeposit/getResearcherDepositProfile";

    public static GET_GENERAL_PAGE_BY_PAGE_CODE = RestApiBase.API_ENDPOINT + "/generalPage/getGeneralPageByPageCode";

    public static BROWSE_COLLECTIONS = RestApiBase.API_ENDPOINT + "/collections/browse";
    public static GET_COLLECTION_BY_CODE = RestApiBase.API_ENDPOINT + "/collections/getCollection";
    public static GET_FEATURED_COLLECTIONS = RestApiBase.API_ENDPOINT + "/collections/featuredCollections";
    public static GET_COLLECTION_CODES_BY_ENTITY_ID = RestApiBase.API_ENDPOINT + "/collections/getCollectionCodesByEntityId";
    public static GET_ORG_UNIT_COLLECTIONS = RestApiBase.API_ENDPOINT + "/collections/getOrganizationCollections";

    public static GET_RESEARCHER_APPROVAL_LIST = RestApiBase.API_ENDPOINT + "/researcherApproval/query";
    public static RESEARCHER_APPROVAL_CLAIM_ACTION = RestApiBase.API_ENDPOINT + "/researcherApproval/approve";
    public static RESEARCHER_APPROVAL_REJECT_ACTION = RestApiBase.API_ENDPOINT + "/researcherApproval/reject";

    public static GET_SEO_FILE_INDEX_BY_NAME = RestApiBase.API_ENDPOINT + "/seo/getIndexFileByName";
}

export interface EntityApi {
    [key: string]: string;

    base: string;
    facets: string;
    displayTab: string;
    delete: string;
}
