import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { BrandComponent } from '@exl-ng/mulo-common';
import { LayoutsModule } from '../parts/layouts/layouts.module';
import { FooterModule } from '../parts/footer/footer.module';
import { EsploroFooterComponent } from './esploro-footer.component';

@NgModule({
    imports: [
        TranslateModule,
        RouterModule,
        FooterModule,
        BrandComponent,
        PortalModule,
        LayoutsModule,
        EsploroFooterComponent,
    ],
    exports: [EsploroFooterComponent],
})
export class EsploroFooterModule {}
