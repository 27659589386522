import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { RequiredInputDirective } from '../../../shared/directives/input-required/input-required.directive';
import { PlainTextInputComponent } from '../../../parts/plain-text-input/plain-text-input.component';

import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'esp-deposit-spatial',
    templateUrl: './deposit-spatial.component.html',
    styleUrls: ['./deposit-spatial.component.scss'],
    animations: [GrowOutAnimation, GrowInAnimation],
    imports: [
        MatCheckbox,
        FormsModule,
        ReactiveFormsModule,
        PlainTextInputComponent,
        RequiredInputDirective,
        MatFormField,
        MatInput,
        MatLabel,
        MatError,
        CustomTranslatePipe
    ]
})
export class DepositSpatialComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private addressDestroy = new Subject<void>();

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.address.valueChanges
      .pipe(takeUntil(this.addressDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.ADDRESS
          );
        }
      });
  }

  get isAddressAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.ADDRESS
    );
  }

  getErrorMessage(formControl: UntypedFormControl, message: string) {
    if (formControl.value) {
      return message;
    } else {
      return 'research.field.required.error.message';
    }
  }

  ngOnDestroy() {
    if (!this.addressDestroy.isStopped) {
      this.addressDestroy.next();
      //unsubscribe from the subject itself
      this.addressDestroy.unsubscribe();
    }
  }
}
