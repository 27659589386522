import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  input,
  output
} from '@angular/core';
import { numberOrNumberUnit, StickyDirective } from '@exl-ng/mulo-common';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';

export type NavSize = number | string | 'auto' | 'none';

@Component({
    selector: 'mulo-nav-main-layout',
    templateUrl: './nav-main-layout.component.html',
    styleUrls: ['./nav-main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-nav-main-layout',
        '[class.is-horizontal]': '_dir === "h"',
        '[class.is-vertical]': '_dir === "v"',
        '[class.is-sticky]': 'navBehavior() === "sticky"',
        '[class.has-nav-shifted]': 'navShifted() === "in" || navShifted() === "out"',
    },
    imports: [NgTemplateOutlet, NgStyle, StickyDirective, NgClass]
})
export class NavMainLayoutComponent implements OnChanges {
  /**
   * Position of the side column - to the left of the main colum, or to its right
   */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() navPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  /**
   * Overrides the default sidebar width
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input()
  set navSize(val: NavSize) {
    this._navSize = val;
  }
  get navSize() {
    return numberOrNumberUnit(this._navSize);
  }
  private _navSize: NavSize = 'auto';
  /**
   * Prevent content from flowing off flex containers
   */
  readonly preventOverflow = input<boolean>(true);
  /**
   * Navigation bar behavior: Stick to top, fixed or none (scroll with page)
   */
  readonly navBehavior = input<'sticky' | 'fixed' | 'default'>('default');
  /**
   * When sticky - sets the distance from top of view (in pixels)
   */
  readonly stickyOffset = input<number>(0);
  /**
   * Set the option to display the nav shifted ouside/inside the main container
   */
  readonly navShifted = input<'in' | 'out' | null>(null);
  readonly stickyZIndex = input(1);

  readonly navStuck = output<boolean>();

  navStyle;
  mainStyle;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.navPosition) {
      this.navPosition = changes.navPosition.currentValue;
      this.recalcLayout();
    }
  }

  /**
   * Calculate vertical or horizontal alignment
   * @internal
   */
  get _dir() {
    return this.navPosition === 'top' || this.navPosition === 'bottom'
      ? 'v'
      : 'h';
  }

  recalcLayout() {
    this.navStyle = this._navCalc;
    this.mainStyle = this._mainCalc;
  }

  get _navCalc() {
    const n = this.navSize;
    return this._dir == 'h'
      ? { flex: `0 0 ${n}`, maxWidth: n, minWidth: n }
      : { flex: `0 0 ${n}`, maxHeight: n, minHeight: n };
  }

  get _mainCalc() {
    const n = this.navShifted() ? '100%' : `calc(100% - ${this.navSize})`;
    return this._dir == 'h'
      ? { flexBasis: n, maxWidth: n, minWidth: n }
      : { flexBasis: n, maxHeight: n, minHeight: n };
  }

  get nav() {
    return this.navSize != 'none';
  }

  getNavClass(): string {
    const n = this.navPosition;
    return this._dir === 'v' ? `is-at-${n}` : `is-on-${n}`;
  }

  onNavStick(state) {
    this.navStuck.emit(state);
  }
}
