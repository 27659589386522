import { Injectable, Injector, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from './shared/configurations/rest-api-base.config';
import { JwtUtilService } from './core/jwt-util.service';
import { Router } from '@angular/router';
import { UrlUtils } from './shared/utils/url.utils';
import { ConfigurationHandlerService } from './core/configuration-handler.service';
import { Configuration } from './shared/interfaces/configuration.interface';
import { State } from './shared/configurations/state.constant';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { AuthenticationInfo } from './shared/interfaces/authentication-info.model';
import { shareReplay, startWith } from 'rxjs';

@Injectable()
export class ConfigService {
    private http = inject(HttpClient);
    private jwtUtilService = inject(JwtUtilService);
    private injector = inject(Injector);
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private systemDateFormatService = inject(SystemDateFormatService);

    private institution: string;

    constructor() {
        this.institution = UrlUtils.getParam('institution');
    }

    load(): Promise<any> {
        const promise = new Promise<void>((resolve, reject) => {
            const loginId = UrlUtils.getParam('loginId');
            const loginError = UrlUtils.getParam('login_error');
            const loginAsUser = UrlUtils.getParam('loginAsUser');
            if (!loginError && loginId) {
                this.http
                    .get(
                        RestApiBase.LOGIN_JWT_CACHE +
                            '/' +
                            loginId +
                            '?institution=' +
                            this.institution,
                    )
                    .toPromise()
                    .then(
                        (data) => {
                            this.jwtUtilService.storeJwt(data);
                            const path = window.location.pathname.replace(
                                '/esploro',
                                '',
                            );
                            const proxyFor = this.jwtUtilService.getProxyFor();
                            if (proxyFor) {
                                this.router.navigate([State.LOGIN_AS], {
                                    queryParams: {
                                        institution: this.institution,
                                    },
                                });
                            } else {
                                this.router.navigate([path], {
                                    queryParams: {
                                        loginId: null,
                                        institution: this.institution,
                                    },
                                    queryParamsHandling: 'merge',
                                });
                            }
                            this.getConfiguration().subscribe(
                                (data) => {
                                    this.loadConfiguration(data);
                                    resolve();
                                },
                                (error) => {
                                    //reject(msg); TODO decide what to de in case of error
                                    resolve();
                                },
                            );
                        },
                        (msg) => {
                            //reject(msg); TODO decide what to de in case of error
                            resolve();
                        },
                    );
            } else if (loginError && loginId) {
                this.http
                    .get(
                        RestApiBase.LOGIN_JWT_CACHE +
                            '/' +
                            loginId +
                            '?institution=' +
                            this.institution,
                    )
                    .toPromise()
                    .then((data) => {
                        this.jwtUtilService.storeJwt(data);
                        this.getConfiguration().subscribe((data) => {
                            this.loadConfiguration(data);
                            this.router.navigate(['login-error'], {
                                queryParams: {
                                    loginError: null,
                                    institution: this.institution,
                                },
                                queryParamsHandling: 'merge',
                            });
                            resolve();
                        });
                    });
            } else if (loginAsUser) {
                this.getLibrarianJWT(loginAsUser).subscribe(
                    (data) => {
                        const authenticationInfo = data as AuthenticationInfo;
                        this.jwtUtilService.removeJwt();
                        this.jwtUtilService.storeJwtInSessionStorage(
                            authenticationInfo.jwtData,
                        );
                        this.getConfiguration().subscribe((data) => {
                            this.loadConfiguration(data);
                            this.router.navigate([State.PROFILE], {
                                queryParams: { institution: this.institution },
                            });
                            resolve();
                        });
                    },
                    () => {
                        this.getConfiguration().subscribe((data) => {
                            this.loadConfiguration(data);
                            this.router.navigate([State.SEARCH], {
                                queryParams: { institution: this.institution },
                            });
                            resolve();
                        });
                    },
                );
            } else {
                this.jwtUtilService.removeJwtFromSessionStorage();
                this.getConfiguration().subscribe((data) => {
                    this.loadConfiguration(data);

                    const config = data as Configuration;
                    if (config.startupTime) {
                        if (
                            !this.jwtUtilService.isJwtCreationTimeValid(
                                config.startupTime,
                            )
                        ) {
                            this.router.navigate([State.LOGIN], {
                                queryParams: { institution: this.institution },
                            });
                        }
                    }

                    resolve();
                });
            }
        });
        return promise;
    }

    loadConfiguration(config: Configuration) {
        this.configurationHandlerService.setConfig(config);
        this.systemDateFormatService.setSystemDateFormat(
            this.configurationHandlerService.systemDateFormat(),
        );
        this.systemDateFormatService.setMomentLocale();
    }

    public get router(): Router {
        return this.injector.get(Router);
    }

    private getConfiguration() {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode);
        const cached: Configuration = JSON.parse(
            localStorage.getItem(
                'espConfig' + (institutionCode ? '_' + institutionCode : ''),
            ),
        );

        const observable = this.http
            .get<Configuration>(RestApiBase.CONFIGURATION, { params })
            .pipe(shareReplay());
        if (cached) {
            return observable.pipe(startWith(cached));
        } else {
            return observable;
        }
    }

    private getLibrarianJWT(loginAsUser) {
        return this.http.get(
            RestApiBase.GET_JWT_FOR_LIBRARIAN + '/' + loginAsUser,
        );
    }
}
