import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, inject, input, contentChildren, viewChild } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { PageLineDirective } from '../page-line/page-line.directive';
import { Dir } from '@angular/cdk/bidi';
import { HtmlSanitizePipe, ThumbnailComponent } from '@exl-ng/mulo-common';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'mulo-page-mast',
    templateUrl: './page-mast.component.html',
    styleUrls: ['./page-mast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-page-mast' },
    imports: [
        ThumbnailComponent,
        Dir,
        MatRipple,
        AsyncPipe,
        HtmlSanitizePipe
    ]
})
export class PageMastComponent implements OnInit, AfterContentInit {
  private el = inject(ElementRef);

  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() thumbnailUrl: string;
  readonly title = input<string>(undefined);
  readonly useHtmlAsTitle = input(false);

  readonly _lines = contentChildren(PageLineDirective, { descendants: true });
  readonly ripple = viewChild(MatRipple);

  highlightActive$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {}

  ngAfterContentInit() {}

  public highlight() {
    this.focusSectionOnHighlight();

    const el = this.el.nativeElement.getBoundingClientRect();
    this._launchRipple(el.left, el.top + el.height / 2);
  }

  private focusSectionOnHighlight() {
    this.el.nativeElement.tabIndex = 0;
    this.el.nativeElement.focus();
    this.el.nativeElement.tabIndex = -1;
  }

  private _launchRipple(x: number = 0, y: number = 0) {
    this.highlightActive$.next(true);
    this.ripple().fadeOutAll();
    const rippleRef = this.ripple().launch(x, y, {
      persistent: true,
      centered: false,
      animation: { exitDuration: 600 },
    });
    setTimeout(() => {
      rippleRef.fadeOut();
      setTimeout(() => {
        this.highlightActive$.next(false);
      }, 400);
    }, 600);
  }
}
