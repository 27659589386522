import { Component, input } from '@angular/core';
import { DecimalPipe, NgOptimizedImage } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'mulo-sdg-icon-list',
    templateUrl: './sdg-icon-list.component.html',
    styleUrls: ['./sdg-icon-list.component.scss'],
    imports: [NgOptimizedImage, DecimalPipe, MatTooltipModule]
})
export class SdgIconListComponent {
  readonly activeSdgs = input([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
]);
  readonly labels = input([
    '#1 No Poverty',
    '#2 Zero Hunger',
    '#3 Good Health and Well-Being',
    '#4 Quality Education',
    '#5 Gender Equality',
    '#6 Clean Water and Sanitation',
    '#7 Affordable and Clean Energy',
    '#8 Decent Work and Economic Growth',
    '#9 Industry, Innovation and Infrastructure',
    '#10 Reduced Inequalities',
    '#11 Sustainable Cities and Communities',
    '#12 Responsible Consumption & Production',
    '#13 Climate Action',
    '#14 Life Below Water',
    '#15 Life on Land',
    '#16 Peace, Justice and Strong Institutions',
    '#17 Partnerships for the Goals',
]);
}
