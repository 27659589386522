import { Component, OnInit, Input } from '@angular/core';
import { BadgeComponent } from '../badge.component';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'exl-badge-auto-populated',
    templateUrl: './badge-auto-populated.component.html',
    styleUrls: ['./badge-auto-populated.component.scss'],
    host: { class: 'exl-badge-auto-populated' },
    imports: [MatIcon, SvgViewboxDirective, NgClass, MatTooltip]
})
export class BadgeAutoPopulatedComponent extends BadgeComponent implements OnInit  {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
