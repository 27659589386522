@if (shareData.url) {
  <div>
    <mulo-copier
      [copyLabel]="labels.copy"
      [copiedLabel]="labels.copied"
      [content]="shareData.url"
      (copied)="onLinkCopied()"
      >
      <span class="padding-sm">
        {{ shareData.url }}
      </span>
    </mulo-copier>
  </div>
}
@if (shareTargets?.length) {
  <ul class="share-targets">
    @for (target of shareTargets; track target) {
      <li>
        <a
          mat-raised-button
          [target]="target.openAs === 'popup' ? shareWindowId() : '_blank'"
          class="share-target-button margin-right-xs"
          [class.has-icon]="target.icon || target.iconSvg"
          [attr.href]="target.key !== 'native' ? target.url : '#'"
          [tabIndex]="0"
      (click)="
        target.key === 'native'
          ? openNativeShare($event)
          : target.openAs === 'popup'
          ? openPopupShare(target.url)
          : null
      "
      (muloMultipleEvents)="
        target.key === 'native'
          ? openNativeShare($event)
          : target.openAs === 'popup'
          ? openPopupShare(target.url)
          : null
      "
          >
          @if (target.icon) {
            <mat-icon [svgIcon]="target.icon ? target.icon : null" muloSvgViewbox></mat-icon>
          }
          @if (!target.icon && target.iconSvg) {
            <mat-icon [innerHTML]="target.iconSvg | htmlTrust"></mat-icon>
          }
          @if (target.label) {
            <span class="share-target-label">{{ target.label }}</span>
          }
        </a>
      </li>
    }
  </ul>
}
