import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  input,
  output
} from '@angular/core';
import { MuloNavLink } from '../../models/nav-link.model';
import { addObjectDepthLevel, getArrayDepth } from '../../utils/array-utils';
import { QueryParamsHandling } from '@angular/router';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'mulo-nav',
  template: '',
  standalone: true,
})
export class NavComponent implements OnInit, OnChanges {
  /**
   * Array of nav items
   */
  readonly items = input<MuloNavLink[]>(undefined);
  /**
   * Optional template to override the default nav display
   */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() itemTemplate: TemplateRef<any>;
  /**
   * The selected nav item as index number
   */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() selectedIndex = 0;
  /**
   * The selected nav/path item as array of string
   */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() selectedPath: string[] = [];
  /**
   * Optionally set a different color theme
   */
  readonly color = input<ThemePalette>('primary');
  /**
   * The travel distance to be used on correponding content panels
   */
  readonly tabTravelDistance = input('50px');
  /**
   * Minimum number of item to children to expand when active
   */
  readonly expandMinimum = input(1);
  /**
   * Keep URL query parameters when handling links
   */
  readonly queryParamsHandling = input<QueryParamsHandling>('preserve');
  /**
   * Skip writing route events to browser history
   */
  readonly skipLocationChange = input(true);
  /**
   * Disable the inkbar
   */
  readonly disableInkbar = input(false);
  /**
   * Disable the nav-focus indication
   */
  readonly disableActiveIndication = input(false);
  /**
   * Event fired when an item gets selected
   */
  readonly itemSelected = output<MuloNavLink>();
  /**
   * Emit the nav direction so that corresponding content panels can use for animation
   */
  readonly navDirection = output<string>();
  /**
   * Used as base to track how deep the items array is
   */
  levelsDepth = 0;

  constructor() {}

  ngOnInit(): void {
    this.emitNavDirection(this.tabTravelDistance());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return null;
    if (changes.items) {
      const items = this.items();
      addObjectDepthLevel(items);
      this.levelsDepth = getArrayDepth(items);
    }
  }

  emitNavDirection(prev, next?) {
    this.navDirection.emit(this.setTabDir(prev, next));
  }

  setTabDir(prev, next) {
    return next >= prev ? this.tabTravelDistance() : '-' + this.tabTravelDistance();
  }

  assessUrl(url: string) {
    return this.containsHash(url) ? '.' : url;
  }

  unHash(url) {
    return url.replace('#', '');
  }

  containsHash(url: string) {
    return url.toString().startsWith('#');
  }

  findNavLink(value, key = 'id', reverse = false) {
    const stack = [...this.items()];
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']();
      if (node[key] === value) return node;
      if (node.children) stack.push(...node.children);
    }
    return null;
  }
}
