import {
    Component,
    OnInit,
    AfterViewInit,
    HostListener,
    HostBinding,
    OnDestroy,
    inject,
    output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtUtilService } from '../core/jwt-util.service';
import { DepositFormDataService } from './deposit-form-data.service';
import { ProfileOutputService } from '../profile/profile-output/profile-output.service';
import { timer } from 'rxjs';
import { DepositForm } from '../shared/configurations/deposit-form.constant';
import { DepositFormService } from './deposit-form.service';
import { ResearcherAdditionalData } from '../shared/interfaces/researcher-additional-data.interface';
import {
    UntypedFormControl,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DepositStepperComponent } from './deposit-stepper/deposit-stepper.component';
import { LoadingSlateComponent } from '@exl-ng/mulo-common';

@Component({
    selector: 'esp-deposit-form',
    templateUrl: './deposit-form.component.html',
    styleUrls: ['./deposit-form.component.scss'],
    host: { class: 'esp-deposit-form' },
    animations: [],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        LoadingSlateComponent,
        DepositStepperComponent,
        TranslateModule,
    ],
})
export class DepositFormComponent implements OnInit, AfterViewInit, OnDestroy {
    private confirmDialog = inject(MatDialog);
    private depositDialog = inject(MatDialog);
    private jwtUtilService = inject(JwtUtilService);
    depositFormDataService = inject(DepositFormDataService);
    profileOutputService = inject(ProfileOutputService);
    private depositFormService = inject(DepositFormService);

    @HostBinding('class.is-drag-over') isDragOver = false;

    depositFormMode = 'stepper';
    showFormSwitcher = false;
    readonly closeEmitter = output<any>();
    timer;

    ngOnInit() {
        this.depositFormDataService.updateOwner(
            this.jwtUtilService.getUserID(),
        );
        this.depositFormDataService.updateUserPrimaryID(
            this.jwtUtilService.getUserName(true),
        );
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            this.depositFormDataService.updateProxy(
                this.jwtUtilService.getUserID(true),
            );
            this.depositFormDataService.updateProxyName(
                this.jwtUtilService.getUserDisplayName(true),
            );
        } else {
            this.depositFormDataService.updateProxy('');
            this.depositFormDataService.updateProxyName('');
        }
        if (!this.depositFormDataService.isDraft()) {
            this.addCurrentAuthor();
        }

        this.depositFormService.needToSaveDraftInOpen.set(true);
        this.depositFormService.draftInProcess.set(false);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.showFormSwitcher = true;
        }, 1500);
    }

    addCurrentAuthor() {
        this.depositFormDataService.clearListAndAddCurrentAuthor();
        this.enrichDepositor();
    }

    enrichDepositor() {
        this.depositFormService
            .getAdditionalDataOnDepositor()
            .subscribe((data) => {
                const additionalData = data as ResearcherAdditionalData;
                if (additionalData.affiliationCode || additionalData.orcid) {
                    for (
                        let i = 0;
                        i < this.depositFormDataService.authors.length;
                        i++
                    ) {
                        const author = this.depositFormDataService.getAuthor(
                            i,
                        ) as UntypedFormGroup;
                        if (author.value.id === additionalData.id) {
                            if (additionalData.affiliationCode) {
                                author.setControl(
                                    'affiliationCode',
                                    new UntypedFormControl(
                                        additionalData.affiliationCode,
                                    ),
                                );
                                author.setControl(
                                    'affiliationName',
                                    new UntypedFormControl(
                                        additionalData.affiliationName,
                                    ),
                                );
                            }
                            if (additionalData.orcid) {
                                author.setControl(
                                    'orcid',
                                    new UntypedFormControl(
                                        additionalData.orcid,
                                    ),
                                );
                            }
                        }
                    }
                }
            });
    }

    @HostListener('dragover', ['$event']) public onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.isDragOver = true;
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        setTimeout(() => {
            this.isDragOver = false;
        }, 1000);
    }

    onSwitchFormMode() {
        this.depositFormMode === 'stepper'
            ? (this.depositFormMode = 'advanced')
            : (this.depositFormMode = 'stepper');
    }

    ngOnDestroy() {
        this.depositFormService.needToSaveDraftInOpen.set(false);
    }
}
