@if (!advSearchStartOpen()) {
  <div class="inputs-wrapper">
    <mat-form-field [appearance]="inputAppearance()" [color]="color()" [floatLabel]="floatLabel()">
      <div #beforeInput class="before-input-container" matPrefix>
        <!-- Slot to project selectable options 'before' -->
        <ng-content select="mulo-searchbar-options[placement='before']"></ng-content>
      </div>

      @if (label()) {
        <mat-label>{{ label() }}</mat-label>
      }

      <input
        (keyup.enter)="submitSearch($event)"
        [attr.tabIndex]="0"
        [formControl]="inputControl"
        [matAutocomplete]="matAutocomplete() ? matAutocomplete() : auto"
        [placeholder]="placeholder()"
        [attr.aria-label]="label()"
        id="searchInput-#{{ id() }}"
        matInput
      />
      @if (clearInputButton() && inputControl.value) {
        <button
          (click)="clearInput()"
          [attr.aria-label]="clearInputButtonLabel()"
          class="clear-input-btn"
          mat-icon-button
          matSuffix
        >
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      }
      <div class="after-input-container" matSuffix>
        <ng-content select="mulo-searchbar-options[placement='after']"></ng-content>
        <button
          #submitButton
          (click)="submitSearch($event)"
          [class.is-hidden]="!searchButton()"
          [class.is-large]="size() === 'large'"
          [color]="color()"
          [disabled]="!searchButton()"
          [tabIndex]="hasOptionsAfter() ? '-1' : '0'"
          aria-label="perform search"
          class="search-button is-square"
          mat-icon-button
        >
          <mat-icon muloSvgViewbox svgIcon="magnify"></mat-icon>
        </button>
      </div>
    </mat-form-field>

    @if (inProgress()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </div>
}
@if (advSearchRoot() && media.is('gt-sm')) {
  @if (!advSearchStartOpen()) {
    <button (click)="toggleAdvSearchOpen(true)" [color]="color()" class="adv-search-btn" mat-button>
      {{ advSearchLabel() }}
    </button>
  }
}
<ng-template #advSearch>
  @if (advSearchRoot() && media.is('gt-sm')) {
    <div @opacityInAnimation @opacityOutAnimation class="mulo-adv-search-backdrop"></div>
    <div
      @opacityInAnimation
      @opacityOutAnimation
      class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"
    ></div>
    <div class="mulo-adv-search-wrap">
      <mat-card
        @heightInAnimation
        @heightOutAnimation
        [@.disabled]="advSearchDisableAnim()"
        appearance="outlined"
        class="mulo-adv-search-card"
      >
        <button
          (click)="toggleAdvSearchOpen(false)"
          [attr.aria-label]="advSearchCloseAriaLabel()"
          class="mulo-adv-search-close-btn"
          mat-icon-button
        >
          <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
        </button>
        <ng-content></ng-content>
      </mat-card>
    </div>
  }
</ng-template>

<mat-autocomplete #auto="matAutocomplete" class="searchbar-autocomplete for-{{ size() }}-size">
  @if (inputControl.value; as val) {
    @for (scope of assessedScopes(); track $index) {
      <mat-option (onSelectionChange)="submitSearch($event)" [value]="{ query: val, scope }">
        @if (autocompleteTemplate('scopes'); as t) {
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: scope, index: $index, val }"
            [ngTemplateOutlet]="t.templateRef"
          >
          </ng-container>
        } @else {
          <mat-icon muloSvgViewbox svgIcon="magnify"></mat-icon>
          <span>{{ val }}</span>
          <span class="semibold">{{ autocompleteRelationWord() }}&nbsp;</span>
          <span class="semibold">{{ scope }}</span>
        }
      </mat-option>
    }
  }

  @for (suggestion of autocompleteSuggestions(); track $index) {
    <mat-option (onSelectionChange)="submitSearch($event)" [value]="{ suggestion }">
      @if (autocompleteTemplate('suggestions'); as t) {
        <ng-container *ngTemplateOutlet="t.templateRef; context: { value: suggestion }"></ng-container>
      } @else {
        <span [innerHTML]="suggestion | muloHighlightText: inputControl.value"></span>
      }
    </mat-option>
  }

  @if (inputControl.value; as val) {
    @for (result of autocompleteResults(); track $index) {
      <mat-option (onSelectionChange)="submitSearch($event)" [value]="{ result }">
        @if (autocompleteTemplate('results'); as t) {
          <ng-container *ngTemplateOutlet="t.templateRef; context: { value: result }"></ng-container>
        } @else {
          <span [innerHTML]="result | muloHighlightText: val"></span>
        }
      </mat-option>
    }
  }
</mat-autocomplete>
