import {
  AfterContentInit,
  Component,
  contentChild,
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  model,
  NgZone,
  OnInit,
  viewChild,
  output
} from '@angular/core';
import { ExtendedFile } from '../../../models';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MediaIfDirective, MediaService } from '@exl-ng/mulo-core';
import {
  ActionListItemActionComponent,
  ActionListItemComponent,
} from '../../action-list-item';
import { MatListItemIcon } from '@angular/material/list';
import { Platform } from '@angular/cdk/platform';
import { FileSizePipe, FileTypeIconPipe } from '../../../pipes';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import {
  MatMenu,
  MatMenuContent,
  MatMenuTrigger,
} from '@angular/material/menu';
import { StatusTagComponent } from '../../status-tag';
import { MatIcon } from '@angular/material/icon';
import { NgTemplateOutlet, PercentPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[slot="file-info"]',
  standalone: true,
})
export class FileListItemInfoDirective {}

@Component({
    selector: 'mulo-file-list-item',
    templateUrl: './file-list-item.component.html',
    styleUrls: ['./file-list-item.component.scss'],
    host: {
        class: 'mulo-file-list-item',
        '[class.is-hover]': 'showOnHover()',
        '[class.has-actions]': 'actions().length > 0 || removable()',
        '[class.is-expanded]': 'expanded()',
    },
    imports: [
        MatListItemIcon,
        MatIcon,
        StatusTagComponent,
        MatMenuContent,
        ActionListItemActionComponent,
        MatTooltip,
        MediaIfDirective,
        NgTemplateOutlet,
        MatIconAnchor,
        MatProgressBar,
        MatIconButton,
        MatMenuTrigger,
        MatMenu,
        PercentPipe,
        FileTypeIconPipe,
        FileSizePipe,
    ]
})
export class FileListItemComponent
  extends ActionListItemComponent
  implements OnInit, AfterContentInit
{
  media = inject(MediaService);

  item = input<ExtendedFile>();
  /** Full file name (basename + extension) */
  name = input<string>();
  /** Base file name (without extension) */
  baseName = input<string>();
  /** File type (MIME) */
  type = input<string>();
  /** File size in bytes */
  size = input<number>();
  /** file extension */
  extension = input<string>();

  date = input<string>();

  /** Whether the file item is removeable */
  removable = input(false);
  /** Index number to show before the file name */
  index = input<number>(null);
  /** Title URL */
  url = input<string>(null);
  /** URL target */
  urlTarget = input('_blank');
  /** toggle the expanded state */
  expanded = model(false);
  /** Whether the panel is expandable (similar to disabled, but only on the expanding container, since 'disable' prevent access to content in the panel header) */
  expandable = model(true);
  listStyle = input<'accordion' | 'list'>('accordion');
  useListIcon = input(false);

  progress = input<number>(null);
  doneUploadMsg = input('Done uploading!');
  filePreviewTip = input('Preview this file');
  fileRemoveTip = input('Remove this file');
  readonly remove = output();
  fileInfoTemplate = contentChild(FileListItemInfoDirective);
  panel = viewChild(MatExpansionPanel);
  content = viewChild<ElementRef>('content');

  elementHeight = '48px';
  destroyRef = inject(DestroyRef);

  constructor() {
    super();
  }

  ngOnInit() {
    // Listen to changes on the panel
    this.panel()
      ?.expandedChange.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => {
        if (this.expandable()) {
          this.expanded.set(state);
        } else {
          this.panel().close();
        }
      });
  }

  ngAfterContentInit() {
    // Check for presence of projected content in the drawer and set expandability of the drawe accordingly
    if (!this.content()?.nativeElement.children.length) {
      this.expandable.set(false);
    }
  }

  onRemove(event, index) {
    event?.preventDefault();
    event?.stopPropagation();
    this.remove.emit(index);
  }

  public expand() {
    this.expanded.set(true);
  }

  public collapse() {
    this.expanded.set(false);
  }
}

@Directive({
  selector: '[mulo-file-action]',
  standalone: true,
})
export class FileActionDirective {
  @HostBinding('class.mulo-file-action') class = true;

  @HostListener('click', ['$event']) onClick($event: Event) {
    $event.stopPropagation();
  }

  @HostListener('keydown', ['$event']) onKeydown(event) {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.stopPropagation();
    }
  }
}
