import { Component, Input, OnDestroy, OnInit, inject, input, viewChild } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { DepositLanguageService } from './deposit-language.service';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { SortUtils } from '../../../shared/utils/sort.utils';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { WidthInAnimation, WidthOutAnimation } from '@exl-ng/mulo-common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ErrorsTranslatePipe } from '../../../shared/pipes/errors-translate.pipe';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../../shared/pipes/highlight-filter.pipe';
import { MatOption } from '@angular/material/core';
import { EspMatAutocompleteA11yDirective } from '../../../shared/directives/a11y/mat-autocomplete-extension.directive';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';
import { CdkMonitorFocus } from '@angular/cdk/a11y';
import { MatChipGrid, MatChipRow, MatChipRemove, MatChipInput } from '@angular/material/chips';

import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-language',
    templateUrl: './deposit-language.component.html',
    styleUrls: ['./deposit-language.component.scss'],
    animations: [WidthInAnimation, WidthOutAnimation],
    imports: [
        MatFormField,
        MatLabel,
        MatChipGrid,
        MatChipRow,
        CdkMonitorFocus,
        BadgeAutoPopulatedComponent,
        MatIconButton,
        MatChipRemove,
        MatIcon,
        MatInput,
        FormsModule,
        MatAutocompleteTrigger,
        MatChipInput,
        ReactiveFormsModule,
        MatAutocomplete,
        EspMatAutocompleteA11yDirective,
        MatOption,
        MatError,
        TranslateModule,
        HighlightFilterPipe,
        CustomTranslatePipe,
        ErrorsTranslatePipe
    ]
})
export class DepositLanguageComponent implements OnInit, OnDestroy {
    private depositLanguageService = inject(DepositLanguageService);
    depositFormDataService = inject(DepositFormDataService);
    private translate = inject(TranslateService);

    readonly required = input<boolean>(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() label: string;
    readonly ariaLabel = input('Languages', { alias: "aria-label" });

    readonly autoCompleteTrigger = viewChild('autoCompleteTrigger', { read: MatAutocompleteTrigger });

    private languagesDestroy = new Subject<void>();

    autoCompleteChipList: UntypedFormControl = new UntypedFormControl();
    selectable = true;
    removable = true;
    addOnBlur = true;

    filteredOptions = [];
    languages = [];
    languagesCodes: string[] = [];
    _lastSearchInput: string;

    ngOnInit() {
        this.depositLanguageService.getLanguages().subscribe((data) => {
            this.languagesCodes = data as string[];
            this.translateLanguagesAndSort();
            this.filteredOptions = this.languages;
        });
        this.listenToSearchInput();

        if (this.required()) {
            // * This hack provide error for required array controls
            // without it no error will be display if the array is pristine
            this.depositFormDataService.languages.setErrors({ required: true });
        }
    }

    translateLanguagesAndSort() {
        this.translate.get(this.languagesCodes).subscribe((res: Object) => {
            this.languages = Object.entries(res);
            this.languages.sort(SortUtils.labelSort);
        });
    }

    listenToSearchInput() {
        this.autoCompleteChipList.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this.languagesDestroy))
            .subscribe((val) => {
                if (val !== this._lastSearchInput) {
                    this.filterOptions(val);
                }
                this._lastSearchInput = val;
            });
    }

    filterOptions(text: string) {
        this.filteredOptions = this.languages.filter(
            (obj) =>
                obj[1].toLowerCase().indexOf(text.toString().toLowerCase()) !==
                -1
        );
        this.autoCompleteTrigger().autocomplete.options.forEach((_) => {
            if (!_.selected && this.isLanguageExists(_.value)) {
                _.select();
            } else if (_.selected && !this.isLanguageExists(_.value)) {
                _.deselect();
            }
        });
    }

    optionSelected(ev: MatAutocompleteSelectedEvent, input) {
        if (!this.isLanguageExists(ev.option.value)) {
            this.depositFormDataService.updateLanguages(ev.option.value);
        }
        if (input) {
            input.value = '';
            this.autoCompleteChipList.reset('');
        }
        this.autoCompleteTrigger().closePanel();
    }

    isLanguageExists(code) {
        for (const language of this.depositFormDataService.languages.value) {
            if (language === code) {
                return true;
            }
        }
        return false;
    }

    removeChip(index, langCode): void {
        this.depositFormDataService.removeLanguage(index);
        const langToRemove =
            this.depositFormDataService.languagesAutoPopulated.indexOf(
                langCode
            );
        if (langToRemove !== -1) {
            this.depositFormDataService.languagesAutoPopulated.splice(
                langToRemove,
                1
            );
        }
        this.autoCompleteTrigger().autocomplete.options
            .find((_) => _.value === langCode)
            ?.deselect();
    }

    openAutoComplete() {
        this.autoCompleteTrigger()._onChange('');
        this.autoCompleteTrigger().openPanel();
    }

    get displayLanguages() {
        return this.depositFormDataService.languages.value;
    }

    isAutoPopulated(lang) {
        return (
            this.depositFormDataService.languagesAutoPopulated.indexOf(lang) !==
            -1
        );
    }

    ngOnDestroy() {
        if (!this.languagesDestroy.isStopped) {
            this.languagesDestroy.next();
            //unsubscribe from the subject itself
            this.languagesDestroy.unsubscribe();
        }
    }
}
