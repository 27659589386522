<div class="info">
  @if (!proxyName) {
    <span>
      {{ 'research.header.signin.as' | translate }} <b class="semibold">{{ userName }}</b>
    </span>
  }
  @if (proxyName) {
    <span>
      {{ 'research.header.signin.as' | translate }} <b class="semibold">{{ proxyName }}</b
      >, {{ 'research.header.signin.manage' | translate }}
      <b class="semibold">{{ userName }}</b>
    </span>
  }
</div>
@for (section of menuItems; track section) {
  <!-- <div> -->
  @for (item of section.items; track item) {
    <button mat-menu-item [disabled]="item.disabled" (click)="onItemClick(item)" id="{{ item.label }}">
      @if (item.icon) {
        <mat-icon [svgIcon]="item.icon"></mat-icon>
      }
      @if (item.label) {
        <span>
          {{ item.label | translate }}
        </span>
      }
      @if (item.labelMethod) {
        <span>{{ item.labelMethod() }}</span>
      }
      @if (section.section === 'language') {
        <span>
          {{ getCurrentLanguage() | translate }} {{ getCurrentLanguage().toUpperCase() }}
          <span class="cdk-visually-hidden">{{ ' ' }}{{ getLabel(item) | translate }}{{ ' ' }}</span>
        </span>
      }
    </button>
  }
<!-- </div> -->
@if (section.divider) {
  <mat-divider></mat-divider>
}
}

<button mat-menu-item (click)="doSignOut()" id="signOut">
  {{ 'research.header.sign.out' | translate }}
</button>
