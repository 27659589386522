<exl-labeled-row label="{{ 'research.asset.type.title.summary' | customTranslate }}">
  {{ resourceType | translate }}
  @if (displayPeerReviewed()) {
    <span> | {{ 'research.summary.peer.reviewed' | customTranslate }}</span>
  }
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.title.title.summary' | customTranslate }}">
  {{ asset.title }}
  @if (depositFormDataService.title.status === 'INVALID') {
    <span class="warning">
      {{ 'research.title.warning.summary' | customTranslate }}
      <button mat-button class="is-inline" color="primary" (click)="goToTitle()">
        {{ 'research.title.summary.add.title' | customTranslate }}
      </button>
    </span>
  }
</exl-labeled-row>

@if (displaySubtitle()) {
  <exl-labeled-row label="{{ 'research.subtitle.title.summary' | customTranslate }}">
    {{ asset.subtitle }}
  </exl-labeled-row>
}

<exl-labeled-row label="{{ 'research.authors.title.summary' | customTranslate }}">
  @for (author of asset.authors; track author; let last = $last) {
    <span
      >{{ author.name }}
      @if (!last) {
        <span>, </span>
      }
    </span>
  }
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.files.title.summary' | customTranslate }}">
  @for (file of files; track file; let i = $index) {
    <div class="file">
      <ng-container>{{ i + 1 }}. {{ file.fullName }}</ng-container>
    </div>
  }

  @if (!files || files.length === 0) {
    <span class="suggestion">
      {{ 'research.file.warning.summary' | customTranslate }}
      <button mat-button class="is-inline" color="primary" (click)="goToFiles()">
        {{ 'research.file.summary.add.files' | customTranslate }}
      </button>
    </span>
  }
</exl-labeled-row>

@if (asset.links.length > 0) {
  <exl-labeled-row label="{{ 'research.links.title.summary' | customTranslate }}">
    @for (link of asset.links; track link; let i = $index) {
      <div class="file">
        <ng-container>{{ i + 1 }}. {{ link.url }}</ng-container>
      </div>
    }
  </exl-labeled-row>
}

<exl-labeled-row label="{{ 'research.topics.title.summary' | customTranslate }}">
  @for (topic of assetTopics; track topic; let last = $last) {
    <span
      >{{ topic }}
      @if (!last) {
        <span>, </span>
      }
    </span>
  }
  @if (assetTopics.length === 0) {
    <span class="suggestion">
      {{ 'research.topics.warning.summary' | customTranslate }}
      <button mat-button class="is-inline" color="primary" (click)="goToTopics()">
        {{ 'research.file.summary.add.topics' | customTranslate }}
      </button>
    </span>
  }
</exl-labeled-row>

@if (asset.description) {
  <exl-labeled-row label="{{ 'research.title.summary.description.title' | customTranslate }}">
    <span [innerHTML]="asset.description"></span>
  </exl-labeled-row>
}

@if (asset.doi || asset.pmid || asset.isbnForDisplay) {
  <exl-labeled-row
    label="{{ 'research.summary.asset.identifiers.title' | customTranslate }}"
    >
    @if (asset.doi) {
      {{ 'research.summary.asset.doi' | customTranslate }} {{ asset.doi }}
    }
    @if ((asset.doi && asset.pmid) || (asset.doi && asset.isbnForDisplay)) {
      ,
    }
    @if (asset.pmid) {
      <ng-container class="line"
        >{{ 'research.summary.asset.pmid' | customTranslate }} {{ asset.pmid }}</ng-container
        >
      }
      @if (asset.isbnForDisplay) {
        <ng-container class="line"
          >{{ 'research.summary.asset.isbn' | customTranslate }} {{ asset.isbnForDisplay }}</ng-container
          >
        }
      </exl-labeled-row>
    }

    <!-- geolocation -->
    @if (displayGeoLocationInfo()) {
      <exl-labeled-row
        label="{{ 'research.summary.asset.geolocation.title' | customTranslate }}"
        >
        @if (asset.address) {
          <p>
            {{ 'research.summary.asset.geolocation.address' | customTranslate }}: {{ asset.address }}
          </p>
        }
        @if (asset.pointLatitude && asset.pointLongitude) {
          <p>
            {{ 'research.summary.asset.geolocation.latitude' | customTranslate }}: {{ asset.pointLatitude }},
            {{ 'research.summary.asset.geolocation.longitude' | customTranslate }}: {{ asset.pointLongitude }}
          </p>
        }
        @if (asset.boundingBoxSouth && asset.boundingBoxWest && asset.boundingBoxNorth && asset.boundingBoxEast) {
          <p>
            {{ 'research.summary.asset.geolocation.south' | customTranslate }}: {{ asset.boundingBoxSouth }},
            {{ 'research.summary.asset.geolocation.west' | customTranslate }}: {{ asset.boundingBoxWest }},
            {{ 'research.summary.asset.geolocation.north' | customTranslate }}: {{ asset.boundingBoxNorth }},
            {{ 'research.summary.asset.geolocation.east' | customTranslate }}: {{ asset.boundingBoxEast }}
          </p>
        }
      </exl-labeled-row>
    }

    @if (
      depositFormDataService.mainForm?.errors?.validateSpatialMandatoryFields ||
      !depositFormDataService.pointLongitude.valid ||
      !depositFormDataService.pointLatitude.valid ||
      !depositFormDataService.boundingBoxEast.valid ||
      !depositFormDataService.boundingBoxNorth.valid ||
      !depositFormDataService.boundingBoxWest.valid ||
      !depositFormDataService.boundingBoxSouth.valid
      ) {
      <exl-labeled-row
        label="{{ 'research.summary.asset.geolocation.title' | customTranslate }}"
        >
        @if (depositFormDataService.mainForm?.errors?.validateSpatialMandatoryFields) {
          <span class="warning">
            {{ 'research.geospatial.error' | customTranslate }}
          </span>
        }
        @if (!depositFormDataService.pointLongitude.valid) {
          <span class="warning">
            {{ 'research.geospatial.longitude.error' | customTranslate }}
          </span>
        }
        @if (!depositFormDataService.pointLatitude.valid) {
          <span class="warning">
            {{ 'research.geospatial.latitude.error' | customTranslate }}
          </span>
        }
        @if (!depositFormDataService.boundingBoxEast.valid) {
          <span class="warning">
            {{ 'research.geospatial.east.error' | customTranslate }}
          </span>
        }
        @if (!depositFormDataService.boundingBoxNorth.valid) {
          <span class="warning">
            {{ 'research.geospatial.north.error' | customTranslate }}
          </span>
        }
        @if (!depositFormDataService.boundingBoxWest.valid) {
          <span class="warning">
            {{ 'research.geospatial.west.error' | customTranslate }}
          </span>
        }
        @if (!depositFormDataService.boundingBoxSouth.valid) {
          <span class="warning">
            {{ 'research.geospatial.south.error' | customTranslate }}
          </span>
        }
      </exl-labeled-row>
    }

    @if (displayPublicationDetails()) {
      <exl-labeled-row
        label="{{ 'research.summary.asset.publication.title' | customTranslate }}"
        >
        @if (displayPublicationDate()) {
          <p>
            {{ getDateTitleBasedOnStatus() | customTranslate }} : {{ asset.seasonPublished | translate }}
            {{ formattedDate(asset.date) }}
          </p>
        }
        <p class="comma-separated-values">
          @if (displayRelationTitle()) {
            <span>{{ asset.relationTitle }}</span>
          }
          @if (displayIsPartOfIssn()) {
            <span
              >{{ 'research.summary.asset.publication.part.issn' | customTranslate }} {{ asset.isPartOfIssn }}</span
              >
            }
            @if (displayIsPartOfIsbnForDisplay()) {
              <span
                >{{ 'research.summary.asset.publication.part.isbn' | customTranslate }}
                {{ asset.isPartOfIsbnForDisplay }}</span
                >
              }
              @if (displayIsPartOfVolume()) {
                <span
                  >{{ 'research.summary.asset.publication.volume' | customTranslate }} {{ asset.isPartOfVolume }}</span
                  >
                }
                @if (displayIsPartOfIssue()) {
                  <span
                    >{{ 'research.summary.asset.publication.issue' | customTranslate }} {{ asset.isPartOfIssue }}</span
                    >
                  }
                  @if (displayIsPartOfPages()) {
                    <span
                      >{{ 'research.summary.asset.publication.pages' | customTranslate }} {{ asset.isPartOfPages }}</span
                      >
                    }
                    @if (asset.publisher) {
                      <span
                        >{{ 'research.summary.asset.publication.publisher' | customTranslate }} {{ asset.publisher }}</span
                        >
                      }
                      @if (displayIsPartOfDoi()) {
                        <span
                          >{{ 'research.summary.asset.publication.part.doi' | customTranslate }} {{ asset.isPartOfDoi }}</span
                          >
                        }
                        @if (isBook() && asset.edition) {
                          <span
                            >{{ 'research.summary.asset.publication.edition' | customTranslate }} {{ asset.edition }}</span
                            >
                          }
                          @if (isBook() && asset.pages) {
                            <span
                              >{{ 'research.summary.asset.publication.pages' | customTranslate }} {{ asset.pages }}</span
                              >
                            }
                            @if (isBook() && asset.series) {
                              <span
                                >{{ 'research.summary.asset.publication.series' | customTranslate }} {{ asset.series }}</span
                                >
                              }
                              @if (isBook() && asset.publicationPlaceForDisplay) {
                                <span
                                  >{{ 'research.summary.asset.publication.publication.place' | customTranslate }}
                                  {{ asset.publicationPlaceForDisplay }}</span
                                  >
                                }
                              </p>
                            </exl-labeled-row>
                          }

                          @if (depositFormDataService.mainForm?.errors?.validDate) {
                            <exl-labeled-row
                              label="{{ 'research.summary.asset.publication.title' | customTranslate }}"
                              >
                              <span class="warning">
                                {{ 'research.status.error' | customTranslate }}
                              </span>
                            </exl-labeled-row>
                          }

                          <!--Conference-->
                          @if (displayConferenceDetails()) {
                            <exl-labeled-row
                              label="{{ 'research.summary.asset.conference.title' | customTranslate }}"
                              >
                              <p class="comma-separated-values">
                                @if (asset.conferenceName) {
                                  <span>
                                    {{ asset.conferenceName }}
                                  </span>
                                }
                                @if (asset.conferenceNumber) {
                                  <span>
                                    {{ asset.conferenceNumber }}
                                  </span>
                                }
                                @if (asset.conferenceLocation) {
                                  <span>
                                    {{ asset.conferenceLocation }}
                                  </span>
                                }
                                @if (asset.conferenceStartDate) {
                                  <span class="line">
                                    {{ formattedDate(asset.conferenceStartDate) }}
                                    @if (asset.conferenceEndDate) {
                                      &ndash;{{ formattedDate(asset.conferenceEndDate) }}
                                    }
                                  </span>
                                }
                              </p>
                            </exl-labeled-row>
                          }

                          <!--Event-->
                          @if (displayEventDetails()) {
                            <exl-labeled-row label="{{ 'research.summary.asset.event.title' | customTranslate }}">
                              <p class="comma-separated-values">
                                @if (asset.eventName) {
                                  <span>
                                    {{ asset.eventName }}
                                  </span>
                                }
                                @if (asset.eventType) {
                                  <span>
                                    {{ asset.eventType }}
                                  </span>
                                }
                                @if (asset.eventLocation) {
                                  <span>
                                    {{ asset.eventLocation }}
                                  </span>
                                }
                                @if (asset.eventStartDate) {
                                  <span class="line">
                                    {{ formattedDate(asset.eventStartDate) }}
                                    @if (asset.eventEndDate) {
                                      &ndash;{{ formattedDate(asset.eventEndDate) }}
                                    }
                                  </span>
                                }
                              </p>
                            </exl-labeled-row>
                          }

                          <!--Presentation-->
                          @if (displayPresentationDetails()) {
                            <exl-labeled-row
                              label="{{ 'research.summary.asset.presentation.title' | customTranslate }}"
                              >
                              <p class="comma-separated-values">
                                @if (asset.pages) {
                                  <span>
                                    {{ 'research.summary.asset.publication.pages' | customTranslate }}: {{ asset.pages }}
                                  </span>
                                }
                                @if (asset.audience) {
                                  <span>
                                    {{ 'research.summary.asset.conference.audience' | customTranslate }}: {{ asset.audience }}
                                  </span>
                                }
                              </p>
                            </exl-labeled-row>
                          }

                          <!-- dates (other than publication) -->
                          @if (displayDatesSection()) {
                            <exl-labeled-row label="{{ 'research.summary.asset.dates.title' | customTranslate }}">
                              <p class="comma-separated-values">
                                @if (asset.datePublished) {
                                  <span
                                    >{{ 'research.summary.asset.date.published' | customTranslate }} :
                                    {{ formattedDate(asset.datePublished) }}</span
                                    >
                                  }
                                  @if (asset.datePresented) {
                                    <span
                                      >{{ 'research.summary.asset.date.presented' | customTranslate }} :
                                      {{ formattedDate(asset.datePresented) }}</span
                                      >
                                    }
                                    @if (asset.dateCollectedStart) {
                                      <span
                                        >{{ 'research.summary.asset.date.collected' | customTranslate }} :
                                        {{ formattedDate(asset.dateCollectedStart) }}
                                        @if (asset.dateCollectedEnd) {
                                          &ndash;{{ formattedDate(asset.dateCollectedEnd) }}
                                        }
                                      </span>
                                    }
                                    @if (asset.dateCreatedStart) {
                                      <span class="line"
                                        >{{ 'research.summary.asset.date.created' | customTranslate }} : {{ formattedDate(asset.dateCreatedStart) }}
                                        @if (asset.dateCreatedEnd) {
                                          &ndash;{{ formattedDate(asset.dateCreatedEnd) }}
                                        }
                                      </span>
                                    }
                                  </p>
                                </exl-labeled-row>
                              }

                              @if (displayMethods()) {
                                <exl-labeled-row label="{{ 'research.title.summary.methods.title' | customTranslate }}">
                                  <span [innerHTML]="asset.methods"></span>
                                </exl-labeled-row>
                              }

                              @if (displayCoverage()) {
                                <exl-labeled-row label="{{ 'research.title.summary.coverage.title' | customTranslate }}">
                                  <span [innerHTML]="asset.coverage"></span>
                                </exl-labeled-row>
                              }

                              @if (displayTechInformation()) {
                                <exl-labeled-row
                                  label="{{ 'research.title.summary.technical.title' | customTranslate }}"
                                  >
                                  <span [innerHTML]="asset.technicalInformation"></span>
                                </exl-labeled-row>
                              }

                              @if (asset.languages.length > 0) {
                                <exl-labeled-row label="{{ 'research.languages.title.summary' | customTranslate }}">
                                  @for (lang of asset.languages; track lang; let last = $last) {
                                    <span>
                                      {{ lang | translate }}
                                      @if (!last) {
                                        <span>, </span>
                                      }
                                    </span>
                                  }
                                </exl-labeled-row>
                              }

                              @if (asset.grants.length > 0) {
                                <exl-labeled-row label="{{ 'research.grants.title.summary' | customTranslate }}">
                                  @for (grant of asset.grants; track grant; let i = $index) {
                                    <p>
                                      {{ i + 1 }}. {{ grant.grantId }}, {{ grant.grantName }} ,
                                      {{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}
                                    </p>
                                  }
                                </exl-labeled-row>
                              }
