<mat-radio-group [formControl]="depositFormDataService.peerReviewed">
  @if (isAutoPopulated && depositFormDataService.peerReviewed.value === 'NO') {
    <exl-badge-auto-populated
      tooltip="{{'research.auto.populate.tooltip' | customTranslate}}"
      color="primary">
    </exl-badge-auto-populated>
  }
  <mat-radio-button value="NO"
    type="radio"
    [class.is-autopopulated]="isAutoPopulated && depositFormDataService.peerReviewed.value === 'NO'">
    {{'research.peereview.no' | customTranslate}}
  </mat-radio-button>
  @if (isAutoPopulated && depositFormDataService.peerReviewed.value === 'YES') {
    <exl-badge-auto-populated
      tooltip="{{'research.auto.populate.tooltip' | customTranslate}}"
      color="primary">
    </exl-badge-auto-populated>
  }
  <mat-radio-button value="YES"
    type="radio"
    [class.is-autopopulated]="isAutoPopulated && depositFormDataService.peerReviewed.value === 'NO'">
    {{'research.peereview.yes' | customTranslate}}
  </mat-radio-button>
</mat-radio-group>
