import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { OrcidLogoComponent } from '../orcid-logo/orcid-logo.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconAnchor } from '@angular/material/button';


@Component({
    selector: 'mulo-orcid-link',
    templateUrl: './orcid-link.component.html',
    styleUrls: ['./orcid-link.component.scss'],
    host: { class: 'mulo-orcid-link' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatIconAnchor,
        MatTooltip,
        OrcidLogoComponent
    ]
})
export class OrcidLinkComponent {
  /** The orcid identifier string */
  readonly orcidId = input<string>(undefined);
  /** Show as logo only, identifier only, or logo + identifier */
  readonly show = input<'logo' | 'id' | 'both'>('both');
  /** Tooltip for the logo (link to the orcid website) */
  readonly logoTooltip = input<string>('ORCID website');
  /** Description for the URL to the researcher page on the ORCID website */
  readonly idDescription = input<string>('ORCID page');
  /** Whether to show the ID as link or not */
  readonly showAsLink = input<boolean>(true);
}
