import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EditionGuard } from './core/edition-guard.service';
import { decommissionGuard } from './core/decommissionGuard';

import { routes } from './app.routes';

import { isNotProduction } from './core/production.guard';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'unavailable',
                    loadComponent: () =>
                        import(
                            './tombstone-page/tombstone-page.component'
                        ).then((m) => m.TombstonePageComponent),
                },
                {
                    path: 'uxdemo',
                    loadComponent: () =>
                        import(
                            './design-system-demo/design-system-demo.component'
                        ).then((m) => m.DesignSystemDemoComponent),
                    canActivate: [isNotProduction],
                },
                {
                    path: '',
                    canActivateChild: [decommissionGuard],
                    children: routes,
                },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
                bindToComponentInputs: true,
            },
        ),
    ],
    exports: [RouterModule],
    providers: [EditionGuard],
})
export class AppRoutingModule {}
