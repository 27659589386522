import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DateUtils } from '../../../shared/utils/date.utils';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositDatesComponent } from '../deposit-dates/deposit-dates.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

import { AutomationClassDirective } from '../../../shared/directives/tests/automation-tests-class.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '@exl-ng/mulo-common';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-conference-information',
    templateUrl: './deposit-conference-information.component.html',
    styleUrls: ['./deposit-conference-information.component.scss'],
    imports: [
        MatFormField,
        InputExpanderDirective,
        MatInput,
        FormsModule,
        AutomationClassDirective,
        ReactiveFormsModule,
        MatLabel,
        MatIcon,
        MatPrefix,
        MatTooltip,
        DepositDatesComponent,
        CustomTranslatePipe
    ]
})
export class DepositConferenceInformationComponent
  implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private titleDestroy = new Subject<void>();
  private conferenceNameDestroy = new Subject<void>();
  private formatDestroy = new Subject<void>();
  private audienceDestroy = new Subject<void>();
  private startDateDestroy = new Subject<void>();
  private endDateDestroy = new Subject<void>();

  ngOnInit() {
    this.onChangesRelationTitle();
    this.onChangesConferenceName();
    this.onChangesConferenceStartDate();
    this.onChangesConferenceEndDate();
  }

  isAutoPopulated(key) {
    return this.depositFormDataService.isKeyAutoPopulated(key);
  }

  onChangesConferenceName(): void {
    this.depositFormDataService.conferenceName.valueChanges
      .pipe(takeUntil(this.conferenceNameDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.CONFERENCE_NAME
          );
        }
      });
  }

  onChangesRelationTitle(): void {
    this.depositFormDataService.relationTitle.valueChanges
      .pipe(takeUntil(this.titleDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.RELATION_TITLE
          );
        }
      });
  }

  onChangesConferenceStartDate(): void {
    this.depositFormDataService.conferenceStartDate.valueChanges
      .pipe(takeUntil(this.startDateDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.conferenceStartDate.value) {
          this.depositFormDataService.updateConferenceStartYear('');
          this.depositFormDataService.updateConferenceStartMonth('');
          this.depositFormDataService.updateConferenceStartDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.conferenceStartDate.value
          );
          this.depositFormDataService.updateConferenceStartYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateConferenceStartMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateConferenceStartDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  onChangesConferenceEndDate(): void {
    this.depositFormDataService.conferenceEndDate.valueChanges
      .pipe(takeUntil(this.endDateDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.conferenceEndDate.value) {
          this.depositFormDataService.updateConferenceEndYear('');
          this.depositFormDataService.updateConferenceEndMonth('');
          this.depositFormDataService.updateConferenceEndDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.conferenceEndDate.value
          );
          this.depositFormDataService.updateConferenceEndYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateConferenceEndMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateConferenceEndDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  isConference() {
    return this.depositFormDataService.isConference();
  }

  isPublicationAbstract(): boolean {
    return this.depositFormDataService.isPublicationAbstract();
  }

  ngOnDestroy() {
    if (!this.titleDestroy.isStopped) {
      this.titleDestroy.next();
      //unsubscribe from the subject itself
      this.titleDestroy.unsubscribe();
    }
    if (!this.conferenceNameDestroy.isStopped) {
      this.conferenceNameDestroy.next();
      this.conferenceNameDestroy.unsubscribe();
    }
    if (!this.formatDestroy.isStopped) {
      this.formatDestroy.next();
      this.formatDestroy.unsubscribe();
    }
    if (!this.audienceDestroy.isStopped) {
      this.audienceDestroy.next();
      this.audienceDestroy.unsubscribe();
    }
    if (!this.startDateDestroy.isStopped) {
      this.startDateDestroy.next();
      this.startDateDestroy.unsubscribe();
    }
    if (!this.endDateDestroy.isStopped) {
      this.endDateDestroy.next();
      this.endDateDestroy.unsubscribe();
    }
  }
}
