import { Component, Input, OnInit, input } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
    selector: 'exl-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    host: {
        class: 'exl-badge',
        '[class.is-small]': 'size() === "small"',
        '[class.is-medium]': 'size() === "medium"',
        '[class.is-large]': 'size() === "large"',
    },
    imports: [
        MatIcon,
        SvgViewboxDirective,
        NgClass
    ]
})
export class BadgeComponent implements OnInit {
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() icon: string;
    readonly rtlMirrorIcon = input<boolean>(undefined);
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() color = 'accent';
    readonly size = input<'small' | 'medium' | 'large'>('medium');
    readonly tooltip = input<string>(undefined);
    readonly type = input<'dot' | 'icon'>('icon');
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() count: string;

    hexColor: string; // HEX color
    hexColorRegex = '^#(?:[0-9a-fA-F]{3}){1,2}$';

    constructor() {}

    ngOnInit() {
        this.hexColor = this.color.match(this.hexColorRegex)
            ? this.color
            : null;
    }
}
