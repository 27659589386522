import { Component, Input, input } from '@angular/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';
import { BadgeComponent } from '../badge/badge.component';

import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'mulo-notify-bell',
    templateUrl: './notify-bell.component.html',
    styleUrls: ['./notify-bell.component.scss'],
    imports: [
        MatIconButton,
        MatMenuTrigger,
        MatIcon,
        SvgViewboxDirective,
        BadgeComponent
    ]
})
export class NotifyBellComponent {
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() ariaLabel: string;
  readonly triggerFor = input<MatMenuPanel>(undefined);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() count: number;

  constructor() {
    this.ariaLabel = `Notifications (${this.count})`;
  }
}
