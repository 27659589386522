<div>
  <div>
    @if (!isPosted) {
      <mat-form-field style="min-width:350px" [class.is-autopopulated]="isAutoPopulated('relationTitle')">
        <input matInput
          [formControl]="depositFormDataService.relationTitle"
          [matAutocomplete]="auto"
          placeholder="{{'research.deposit.searchable.fields.placeholder' | translate}}">
        <mat-label>{{'research.status.title' | customTranslate}}</mat-label>
        @if (isAutoPopulated('relationTitle')) {
          <mat-icon matPrefix
            svgIcon="auto-populate"
          matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
        }
        @if (searchTitleInProgress) {
          <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
        }
        <mat-autocomplete #auto="matAutocomplete">
          @for (result of czSearchResults; track result) {
            <mat-option
              [value]="result.title"
              (onSelectionChange)="onJournalNameSelect(result)"
              [disabled]="searchTitleInProgress">
              <span class="semibold">{{result.title}}</span>
              <!--<span [innerHTML]="result.title" | highlight: depositFormData.isPartOfIssn></span>-->
            </mat-option>
          }
          <!--<mat-option *ngIf="depositFormData.journalName && noValuesFound && !searchTitleInProgress">-->
          <!--<span>no values found</span>-->
        <!--</mat-option>-->
      </mat-autocomplete>
    </mat-form-field>
  }

  @if (!isPosted) {
    <mat-form-field [class.is-autopopulated]="isAutoPopulated('isPartOfIssn')" class="is-short-input">
      <input matInput
        [formControl]="depositFormDataService.isPartOfIssn"
        placeholder="{{'research.deposit.searchable.fields.placeholder' | translate}}">
      <mat-label>{{'research.status.issn' | customTranslate}}</mat-label>
      <mat-error>
        {{'research.status.issn.error' | customTranslate}}
      </mat-error>
      @if (isAutoPopulated('isPartOfIssn')) {
        <mat-icon matPrefix
          svgIcon="auto-populate"
        matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
      }
      @if (searchIssnInProgress) {
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      }
    </mat-form-field>
  }
</div>

<div>

  @if (displayPmid) {
    <mat-form-field [class.is-autopopulated]="isAutoPopulated('pmid')">
      <input matInput
        [formControl]="depositFormDataService.pmid">
      <mat-label>{{'research.status.pmid' | customTranslate}}</mat-label>
      <mat-error>
        {{'research.status.pmid.error' | customTranslate}}
      </mat-error>
      @if (isAutoPopulated('pmid')) {
        <mat-icon matPrefix
          svgIcon="auto-populate"
        matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
      }
    </mat-form-field>
  }

  @if (displayVolume) {
    <mat-form-field [class.is-autopopulated]="isAutoPopulated('isPartOfVolume')" class="is-short-input">
      <input matInput
        [formControl]="depositFormDataService.isPartOfVolume">
      <mat-label>{{'research.status.volume' | customTranslate}}</mat-label>
      @if (isAutoPopulated('isPartOfVolume')) {
        <mat-icon matPrefix
          svgIcon="auto-populate"
        matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
      }
    </mat-form-field>
  }

  @if (displayIssue) {
    <mat-form-field [class.is-autopopulated]="isAutoPopulated('isPartOfIssue')" class="is-short-input">
      <input matInput
        [formControl]="depositFormDataService.isPartOfIssue">
      <mat-label>{{'research.status.issue' | customTranslate}}</mat-label>
      @if (isAutoPopulated('isPartOfIssue')) {
        <mat-icon matPrefix
          svgIcon="auto-populate"
        matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
      }
    </mat-form-field>
  }

  @if (displayPages) {
    <mat-form-field [class.is-autopopulated]="isAutoPopulated('isPartOfPages')"  class="is-short-input">
      <input matInput
        [formControl]="depositFormDataService.isPartOfPages">
      <mat-hint align="start">{{'research.status.page.hint' | customTranslate}}</mat-hint>
      <mat-label>{{'research.status.pages' | customTranslate}}</mat-label>
      @if (isAutoPopulated('isPartOfPages')) {
        <mat-icon matPrefix
          svgIcon="auto-populate"
        matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
      }
    </mat-form-field>
  }


</div>
</div>
