import { Component, Input, input } from '@angular/core';
import {
  PaginationControlsComponent,
  PaginationControlsDirective,
} from 'ngx-pagination';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { DecimalPipe } from '@angular/common';

function coerceToBoolean(input: string | boolean): boolean {
  return !!input && input !== 'false';
}

@Component({
    selector: 'mulo-paging',
    templateUrl: './paging.component.html',
    styleUrls: ['./paging.component.scss'],
    imports: [
        MatButton,
        MatIcon,
        DecimalPipe
    ]
})
export class PagingComponent extends PaginationControlsComponent {
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input('data') p: PaginationControlsDirective;
  readonly icons = input<boolean>(false, { alias: "directionLinksAsIcons" });
  readonly hideLastPage = input(undefined);
  readonly screenReaderJumpLabel = input('Jump to page {{value}}');

  constructor() {
    super();
  }

  _parseJumpLabel = (_) => this.screenReaderJumpLabel().replace('{{value}}', _);
}
