import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterList',
    pure: false,
    standalone: true
})
export class FilterListPipe implements PipeTransform {
    transform(items: any[], filterCallback: (item: any) => boolean): any {
        if (!items || !filterCallback) {
            return items;
        }
        return items.filter(item => filterCallback(item));
    }
}
