import {
  Component,
  Input,
  AfterContentInit,
  ElementRef,
  input,
  output,
  viewChild
} from '@angular/core';
import {
  trigger,
  state,
  animate,
  transition,
  style,
  keyframes,
} from '@angular/animations';
import * as easings from '../../animations/easings';
import { MatButton } from '@angular/material/button';

const timingShort = '350ms ';
const easing = easings.OutBackMod;
type ToggleState = 'left' | 'right';

@Component({
  selector: 'mulo-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  host: { class: 'mulo-toggle-button' },
  animations: [
    trigger('toggle', [
      state(
        'left',
        style({
          transform: 'translateX(0)',
          width: '{{ widthLeft }}px',
        }),
        {
          params: {
            widthLeft: '*',
          },
        },
      ),
      state(
        'right',
        style({
          transform: 'translateX({{ widthLeft }}px)',
          width: '{{ widthRight }}px',
        }),
        {
          params: {
            widthLeft: '*',
            widthRight: '*',
          },
        },
      ),
      transition('left => right', [
        animate(
          timingShort + easing,
          keyframes([
            style({
              width: '{{ widthLeft }}px',
              transform: 'translateX(0)',
              offset: 0,
            }),
            style({
              width: '100%',
              offset: 0.65,
            }),
            style({
              width: '{{ widthRight }}px',
              transform: 'translateX({{ left }}px)',
              offset: 1,
            }),
          ]),
        ),
      ]),
      transition('right => left', [
        animate(
          timingShort + easing,
          keyframes([
            style({
              width: '{{ widthRight }}px',
              transform: 'translateX({{ left }}px)',
              offset: 0,
            }),
            style({
              width: '100%',
              transform: 'translateX(calc({{ left }}px / 3)',
              offset: 0.65,
            }),
            style({
              transform: 'translateX(0)',
              width: '{{ widthLeft }}px',
              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
  ],
  imports: [MatButton],
})
export class ToggleButtonComponent implements AfterContentInit {
  /**
   * The toggle state
   */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() public get state() {
    return this._stateValue;
  }
  public set state(val) {
    this._stateValue = val;
  }

  /**
   * Button color, corresponds to Material button color
   */
  readonly color = input('');

  /**
   * Button type, correspond to Material button directives
   */
  readonly flavor = input<null | 'stroked' | 'raised' | 'flat'>(null);

  readonly ariaLabel = input(undefined, { alias: 'aria-label' });

  /**
   * Emitter for state change
   */
  readonly stateChange = output<ToggleState>();

  // DOM hooks
  /**
   * @internal
   */
  readonly toggleButton = viewChild('toggleButton', { read: ElementRef });
  /**
   * @internal
   */
  readonly leftContainer = viewChild<ElementRef<HTMLElement>>('left');
  /**
   * @internal
   */
  readonly rightContainer = viewChild<ElementRef<HTMLElement>>('right');

  /**
   * The current state of the toggle (left || right)
   *
   * @internal
   */
  private _stateValue: ToggleState = 'left';

  // Values to calculate the sliding background
  /**
   * @internal
   */
  leftWidth: number = null;
  /**
   * @internal
   */
  rightWidth: number;
  /**
   * @internal
   */
  leftPadding = false;
  /**
   * @internal
   */
  rightPadding = false;

  /**
   * @internal
   */
  ngAfterContentInit() {
    const left = this.leftContainer().nativeElement;
    const right = this.rightContainer().nativeElement;
    this.leftPadding = !this.checkIfIcon(left);
    this.rightPadding = !this.checkIfIcon(right);
    setTimeout(() => {
      this.leftWidth = this.leftContainer().nativeElement.clientWidth;
      this.rightWidth = this.rightContainer().nativeElement.clientWidth;
    });
  }

  /**
   * toggle button state
   *
   * @internal
   */
  toggle() {
    this.state = this._stateValue === 'left' ? 'right' : 'left';
    this.stateChange.emit(this.state);
    // Accessibility - unfocus and then refocus the button in order for a screen reader to read the new button state
    setTimeout(() => {
      this.toggleButton().nativeElement.focus();
    }, 500);
  }

  /**
   * Check if the injected content is of icon type
   *
   * @internal
   */
  checkIfIcon(el) {
    return el.innerHTML.includes('mat-icon');
  }
}
