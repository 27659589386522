import { NgModule } from '@angular/core';
import { CitationBriefComponent } from './citation-brief/citation-brief.component';
import { CitationBriefPlaceholderComponent } from './citation-brief-placeholder/citation-brief-placeholder.component';
import { BriefsModule } from '../briefs/briefs.module';
import { SharedModule } from '../../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { CitationBriefNotificationComponent } from './citation-brief-notification/citation-brief-notification.component';
import { ChatModule } from '../chat/chat.module';
import { ContentLoaderModule } from '@ngneat/content-loader';
import {
    AltmetricComponent,
    ChatBubbleComponent,
    ChatComponent,
    ChatDialogComponent,
    ChatEntryComponent,
    ChatInputComponent,
    HtmlSanitizePipe,
    HtmlStripPipe,
    ThumbnailComponent,
} from '@exl-ng/mulo-common';
import { BriefComponent } from '@exl-ng/mulo-views';

import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        SharedModule,
        ThumbnailComponent,
        ContentLoaderModule,
        BriefsModule,
        MomentModule,
        ChatModule,
        AltmetricComponent,
        RouterModule,
        HtmlSanitizePipe,
        HtmlStripPipe,
        BriefComponent,
        ChatComponent,
        ChatInputComponent,
        ChatEntryComponent,
        ChatBubbleComponent,
        ChatDialogComponent,
        CitationBriefComponent,
        CitationBriefPlaceholderComponent,
        CitationBriefNotificationComponent,
    ],
    exports: [
        CitationBriefComponent,
        CitationBriefPlaceholderComponent,
        CitationBriefNotificationComponent,
    ],
})
export class CitationsModule {}
