import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, inject, input } from '@angular/core';
import { ColorComponentMixinBase } from '../../../common-behavior/color';

import { OrcidLogoComponent } from '../orcid-logo/orcid-logo.component';
import { MatAnchor } from '@angular/material/button';

@Component({
    selector: 'mulo-orcid-connect',
    templateUrl: './orcid-connect.component.html',
    styleUrls: ['./orcid-connect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-orcid-connect' },
    inputs: ['color'],
    imports: [
        MatAnchor,
        OrcidLogoComponent
    ]
})
export class OrcidConnectComponent extends ColorComponentMixinBase
  implements OnInit {
  readonly linkText = input<string>(undefined);
  readonly linkUrl = input<string>('#');
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() description: string;

  constructor() {
    const elementRef = inject(ElementRef);

    super(elementRef);
  }

  ngOnInit(): void {}
}
