<mat-form-field floatLabel="auto" class="is-comfortably-readable">
  <mat-label>
    @if (label) {
      {{ label }}
    } @else {
      {{ 'research.language.overline' | customTranslate }}
    }
  </mat-label>

  <mat-chip-grid #chipGrid>
    @for (chip of displayLanguages; track chip; let isFirst = $first; let i = $index) {
      <mat-chip-row
        cdkMonitorSubtreeFocus
        [removable]="removable"
        (removed)="removeChip(i, chip)"
        [class.is-autopopulated]="isAutoPopulated(chip)"
        @widthInAnimation
        @widthOutAnimation
        >
        @if (isAutoPopulated(chip)) {
          <exl-badge-auto-populated
            [tooltip]="'research.auto.populate.tooltip' | customTranslate"
            color="primary"
            >
          </exl-badge-auto-populated>
        }
        <span class="chip-content semibold">{{ chip | translate }}</span>
        @if (removable) {
          <button
            mat-icon-button
            matChipRemove
            [attr.aria-label]="('research.aria.deposit.languages.remove' | translate) + ' ' + (chip | translate)"
            >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        }
      </mat-chip-row>
    }
  </mat-chip-grid>
  <input
    matInput
    (keydown.enter)="$event.preventDefault()"
    [formControl]="autoCompleteChipList"
    [required]="this.depositFormDataService.languages.invalid"
    #autoCompleteTrigger
    [matAutocomplete]="auto"
    (focus)="openAutoComplete()"
    [matChipInputFor]="chipGrid"
    />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="optionSelected($event, autoCompleteTrigger)"
    [hideSingleSelectionIndicator]="false"
    >
    @for (option of filteredOptions; track option) {
      <mat-option [value]="option[0]">
        <span [innerHTML]="option[1] | highlight : autoCompleteChipList.value"></span>
      </mat-option>
    }
  </mat-autocomplete>
  @if (this.depositFormDataService.languages?.invalid) {
    <mat-error
      [innerHTML]="this.depositFormDataService.languages?.errors | errorTranslate"
      >
    </mat-error>
  }
</mat-form-field>
