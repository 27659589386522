import { Component, OnInit, inject, input } from '@angular/core';
import {TabComponentModel} from '../../../shared/interfaces/tab-component.model';
import {ConfigurationHandlerService} from '../../../core/configuration-handler.service';
import {DepositFormDataService} from '../../deposit-form-data.service';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositDescriptionComponent } from '../../deposit-sections/deposit-description/deposit-description.component';
import { DepositAnzToaComponent } from '../../deposit-sections/deposit-anz-toa/deposit-anz-toa.component';
import { DepositFreetextKeywordsComponent } from '../../deposit-sections/deposit-freetext-keywords/deposit-freetext-keywords.component';
import { DepositAnzFieldsComponent } from '../../deposit-sections/deposit-anz-fields/deposit-anz-fields.component';
import { DepositKeywordsComponent } from '../../deposit-sections/deposit-keywords/deposit-keywords.component';
import { DepositAuthorsComponent } from '../../deposit-sections/deposit-authors/deposit-authors.component';
import { DepositSubtitleComponent } from '../../deposit-sections/deposit-subtitle/deposit-subtitle.component';
import { DepositTitleComponent } from '../../deposit-sections/deposit-title/deposit-title.component';
import { DepositFormSectionComponent } from '../../deposit-form-section/deposit-form-section.component';



@Component({
    selector: 'esp-deposit-step-two',
    templateUrl: './deposit-step-two.component.html',
    styleUrls: ['./deposit-step-two.component.scss'],
    imports: [DepositFormSectionComponent, DepositTitleComponent, DepositSubtitleComponent, DepositAuthorsComponent, DepositKeywordsComponent, DepositAnzFieldsComponent, DepositFreetextKeywordsComponent, DepositAnzToaComponent, DepositDescriptionComponent, CustomTranslatePipe]
})
export class DepositStepTwoComponent implements OnInit {
    private configurationHandlerService = inject(ConfigurationHandlerService);
    depositFormDataService = inject(DepositFormDataService);


    readonly tab2 = input(undefined);
    readonly jumpToTitle = input(undefined);
    readonly jumpToTopics = input(undefined);
    readonly selectedType = input(undefined);
    readonly selectedSubtype = input(undefined);

    researchTerms;

    ngOnInit() {
        this.researchTerms = this.configurationHandlerService.getCustomerParameter('esploro_research_subject_terms');
    }

    displayTitle(comp: TabComponentModel){
        return comp.visible && comp.field === 'title';
    }

    displaySubtitle(comp: TabComponentModel){
        return comp.visible && comp.field === 'subtitle';
    }

    displayInvolved(comp: TabComponentModel){
        return comp.visible && comp.field ==='involved';
    }

    displayKeywords(comp: TabComponentModel){
        return comp.visible && comp.field === 'keywords';
    }

    displayDescription(comp: TabComponentModel){
        return comp.visible && comp.field === 'description';
    }

    anzInstitution(){
        return this.researchTerms === 'ANZSRC';
    }

    isRequired(comp){
        return comp.mandatory;
    }

}
