import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { StatusUtils } from '../../../../shared/utils/status.utils';
import { AutoPopulateKeys } from '../../../../shared/configurations/auto-populate-keys.config';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '@exl-ng/mulo-common';
import { MatFormField, MatLabel, MatPrefix, MatHint, MatError } from '@angular/material/form-field';


@Component({
    selector: 'esp-deposit-publication-details-book-chapter',
    templateUrl: './deposit-publication-details-book-chapter.component.html',
    styleUrls: ['./deposit-publication-details-book-chapter.component.scss'],
    imports: [
        MatFormField,
        InputExpanderDirective,
        MatInput,
        FormsModule,
        ReactiveFormsModule,
        MatLabel,
        MatIcon,
        MatPrefix,
        MatTooltip,
        MatHint,
        MatError,
        TranslateModule,
        CustomTranslatePipe
    ]
})
export class DepositPublicationDetailsBookChapterComponent
  implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private doiDestroy = new Subject<void>();
  private isPartOfDoiDestroy = new Subject<void>();
  private titleDestroy = new Subject<void>();
  private pagesDestroy = new Subject<void>();
  private publisherDestroy = new Subject<void>();
  private isbnDestroy = new Subject<void>();

  ngOnInit() {
    this.onChangesIsPartOfDoi();
    this.onChangesRelationTitle();
    this.onChangesPages();
    this.onChangesPublisher();
    this.onChangesIsbnForDisplay();
  }

  onChangesIsPartOfDoi(): void {
    this.depositFormDataService.isPartOfDoi.valueChanges
      .pipe(takeUntil(this.isPartOfDoiDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_DOI
          );
        }
      });
  }

  onChangesIsbnForDisplay(): void {
    this.depositFormDataService.isPartOfIsbnForDisplay.valueChanges
      .pipe(takeUntil(this.isbnDestroy))
      .subscribe((value) => {
        const isbnForDisplay = value as string;
        let isPartOfIsbn = [];
        if (isbnForDisplay) {
          isPartOfIsbn = isbnForDisplay.split(
            DepositForm.VALUES_SEPARATOR
          );
        }
        this.depositFormDataService.updateIsPartOfIsbn(isPartOfIsbn);
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_ISBN_FOR_DISPLAY
          );
        }
      });
  }

  onChangesPublisher(): void {
    this.depositFormDataService.publisher.valueChanges
      .pipe(takeUntil(this.publisherDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PUBLISHER
          );
        }
      });
  }

  onChangesRelationTitle(): void {
    this.depositFormDataService.relationTitle.valueChanges
      .pipe(takeUntil(this.titleDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.RELATION_TITLE
          );
        }
      });
  }

  onChangesPages(): void {
    this.depositFormDataService.isPartOfPages.valueChanges
      .pipe(takeUntil(this.pagesDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_PAGES
          );
        }
      });
  }

  displayISBN() {
    return StatusUtils.displayISBN(this.status);
  }

  displayBookTitle() {
    return StatusUtils.displayBookTitle(this.status);
  }

  displayPages() {
    return StatusUtils.displayPages(this.status);
  }

  displayBookDOI() {
    return StatusUtils.displayBookDOI(this.status);
  }

  displayPublisherName() {
    return StatusUtils.displayPublisherName(this.status);
  }

  get status() {
    return this.depositFormDataService.status.value;
  }

  isAutoPopulated(key) {
    return this.depositFormDataService.isKeyAutoPopulated(key);
  }

  ngOnDestroy() {
    if (!this.doiDestroy.isStopped) {
      this.doiDestroy.next();
      //unsubscribe from the subject itself
      this.doiDestroy.unsubscribe();
    }
    if (!this.isPartOfDoiDestroy.isStopped) {
      this.isPartOfDoiDestroy.next();
      //unsubscribe from the subject itself
      this.isPartOfDoiDestroy.unsubscribe();
    }
    if (!this.titleDestroy.isStopped) {
      this.titleDestroy.next();
      //unsubscribe from the subject itself
      this.titleDestroy.unsubscribe();
    }
    if (!this.pagesDestroy.isStopped) {
      this.pagesDestroy.next();
      //unsubscribe from the subject itself
      this.pagesDestroy.unsubscribe();
    }
    if (!this.publisherDestroy.isStopped) {
      this.publisherDestroy.next();
      //unsubscribe from the subject itself
      this.publisherDestroy.unsubscribe();
    }
    if (!this.isbnDestroy.isStopped) {
      this.isbnDestroy.next();
      //unsubscribe from the subject itself
      this.isbnDestroy.unsubscribe();
    }
  }
}
