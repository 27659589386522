import { Injectable, signal } from '@angular/core';

@Injectable()
export class DepositStepThreeService {
    readonly showAdditionalInfo = signal(false);

    toggleShowAddtlInfo() {
        this.showAdditionalInfo.update((prev) => !prev);
    }
}
