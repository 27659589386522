import { Component, OnInit, inject } from '@angular/core';
import {DepositFormDataService} from '../../deposit-form-data.service';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { AutomationClassDirective } from '../../../shared/directives/tests/automation-tests-class.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '@exl-ng/mulo-common';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-presentation-information',
    templateUrl: './deposit-presentation-information.component.html',
    styleUrls: ['./deposit-presentation-information.component.scss'],
    imports: [MatFormField, InputExpanderDirective, MatInput, FormsModule, AutomationClassDirective, ReactiveFormsModule, MatLabel, CustomTranslatePipe]
})
export class DepositPresentationInformationComponent implements OnInit {
    depositFormDataService = inject(DepositFormDataService);


    ngOnInit() {
    }

}
