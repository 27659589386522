<mat-radio-group [ngModel]="model" (ngModelChange)="model = $event; onSelectionChange($event)"
  [attr.aria-label]="ariaLabel()">
  @if (autoPopulated && model == buttonOne()) {
    <exl-badge-auto-populated
      tooltip="autoPopulatedTooltip"
      color="primary">
    </exl-badge-auto-populated>
  }
  <mat-radio-button [value]="buttonOne()" type="radio" [class.is-autopopulated]="autoPopulated && model == buttonOne()">
    {{buttonOne()}}
  </mat-radio-button>

  @if (autoPopulated && model == buttonTwo()) {
    <exl-badge-auto-populated
      tooltip="autoPopulatedTooltip"
      color="primary">
    </exl-badge-auto-populated>
  }
  <mat-radio-button [value]="buttonTwo()" type="radio" [class.is-autopopulated]="autoPopulated && model == buttonTwo()">
    {{buttonTwo()}}
  </mat-radio-button>
</mat-radio-group>
