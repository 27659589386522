import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';

import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'mulo-status-tag',
    templateUrl: './status-tag.component.html',
    styleUrls: ['./status-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-status-tag',
        '[class.has-border]': 'flavor() === "bordered"',
        '[class.is-plain]': 'flavor() === "plain"',
    },
    imports: [
        MatTooltip,
        MatIcon,
        SvgViewboxDirective
    ]
})
export class StatusTagComponent {
  /**
   * Optional text to appear in a tooltip when hovering over the tag
   */
  readonly tooltip = input<string>(undefined);
  /**
   * An optional icon to be used for the status tag
   */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() icon?: string;
  readonly rtlMirrorIcon = input<boolean>(undefined);
  /**
   * An optional description text to appear next to the tag
   */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() description?: string;
  /**
   * Style (flavor) of the rendered tag
   */
  readonly flavor = input<'bordered' | 'plain'>('bordered');

  constructor() {}
}
