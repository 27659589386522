import { Injectable, inject } from '@angular/core';
import { Observable, merge, Subject, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ShareDialogComponent } from './sharer/sharer.component';
import { AssetShareConfig } from './share.model';
import { ShareTarget } from './share.model';

const shareDialogDefaults = {
  panelClass: 'mulo-share-dialog-container',
  minWidth: 320,
  maxWidth: 600,
};

type ShareChangeEvent = 'dialogClosed' | 'linkCopied';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  private dialog = inject(MatDialog);

  private _dialogClosed = new Subject<{ event: ShareChangeEvent }>();
  public dialogClosed$ = this._dialogClosed.asObservable();

  private _linkCopied = new Subject<{ event: ShareChangeEvent }>();
  public linkCopied$ = this._linkCopied.asObservable();

  public shareServices: ShareTarget[] = [
    {
      key: 'facebook',
      label: 'Facebook',
      icon: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=${url}',
      openAs: 'popup'
    },
    {
      key: 'twitter',
      label: 'Twitter',
      icon: 'twitter',
      url: 'https://twitter.com/intent/tweet?text=${text}&url=${url}',
      openAs: 'popup'
    },
    {
      key: 'linkedin',
      label: 'LinkedIn',
      icon: 'linkedin',
      url: 'https://www.linkedin.com/sharing/share-offsite/?url=${url}',
      openAs: 'popup'
    },
    {
      key: 'native',
      label: 'Share to app',
      icon: 'share',
    },
  ];

  /** Call the ASSET share dialog */
  public showAssetShareDialog(
    config: AssetShareConfig
  ): Observable<{ event: ShareChangeEvent }> {
    this.openShareDialog({ ...config });
    return merge(this.linkCopied$, this.dialogClosed$);
  }

  /** Call the share dialog */
  public openShareDialog(
    config: AssetShareConfig
    //width? //: string = exportDialogDefaults.width
  ) {
    this.dialog
      .open(ShareDialogComponent, {
        ...shareDialogDefaults,
        data: { ...config },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.onDialogClosed());
  }

  /** Share Dialog closed */
  public onDialogClosed() {
    this._dialogClosed.next({
      event: 'dialogClosed',
    });
  }

  /** link copied */
  public onLinkCopied() {
    this._linkCopied.next({
      event: 'linkCopied',
    });
  }

  public urlParse(str, base): URL {
    for (let t in base) {
      str = str.replace(
        new RegExp('\\$\\{' + t + '\\}', 'g'),
        encodeURIComponent(base[t])
      );
    }
    return new URL(str);
  }

}
