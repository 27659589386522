import { Component, HostBinding, OnInit, OnDestroy, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AriaProgressBarDirective } from '@exl-ng/mulo-common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FileListItemComponent } from '../../../../parts/handle-files/file-list/file-list-item/file-list-item.component';
import { FileListComponent } from '../../../../parts/handle-files/file-list/file-list.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';


@Component({
    selector: 'exl-file-progress-dialog',
    templateUrl: './file-progress-dialog.component.html',
    styleUrls: ['./file-progress-dialog.component.scss'],
    host: { class: 'exl-file-progress-dialog' },
    imports: [
        MatIconButton,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
        FileListComponent,
        FileListItemComponent,
        MatProgressBar,
        AriaProgressBarDirective,
        TranslateModule,
        CustomTranslatePipe
    ]
})
export class FileProgressDialogComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);
  dialogRef = inject<MatDialogRef<FileProgressDialogComponent>>(MatDialogRef);
  data = inject(MAT_DIALOG_DATA);

  @HostBinding('class.is-collapsed') panelCollapsed = false;

  uploadQueue = [];
  uploaderTitle = 'research.files.popup1';
  uploadQueueCount = 0;
  uploadDone = false;

  private filesDestroy = new Subject<void>();

  constructor() {
    this.uploadQueue = this.data.files.filter(
      (obj) => obj.uploadProgress === 0
    );
  }

  ngOnInit() {
    this.subscribeToFileActivity();
  }

  setUploaderTitle() {
    if (this.uploadQueue.length === 0) {
      this.uploaderTitle = 'research.files.popup2';
      this.uploadDone = true;
      this.panelCollapsed = false;
    } else {
      this.uploaderTitle = 'research.files.popup1';
    }
  }

  subscribeToFileActivity() {
    this.depositFormDataService.files.valueChanges
      .pipe(takeUntil(this.filesDestroy))
      .subscribe((data) => {
        this.uploadQueue = data.filter(
          (obj) => obj.uploadProgress < 100
        );
        this.uploadQueueCount = this.uploadQueue.length;
        if (this.uploadQueueCount === 0) {
          setTimeout(() => {
            this.close();
          }, 2000);
        }
        this.setUploaderTitle();
      });
  }

  togglePanelSize() {
    this.panelCollapsed = !this.panelCollapsed;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (!this.filesDestroy.isStopped) {
      this.filesDestroy.next();
      //unsubscribe from the subject itself
      this.filesDestroy.unsubscribe();
    }
  }

  getAriaLabelForPanelButton() {
    if (this.panelCollapsed) {
      return 'research.aria.deposit.upload.files.progress.panel.open';
    }
    return 'research.aria.deposit.upload.files.progress.panel.close';
  }
}
