import { Component, OnInit, ElementRef, inject } from '@angular/core';
import { ColorComponentMixinBase } from '../../common-behavior/color';

@Component({
    selector: 'mulo-prologue',
    templateUrl: './prologue.component.html',
    styleUrls: ['./prologue.component.scss'],
    host: { class: 'mulo-prologue' },
    inputs: ['color'],
    standalone: true,
})
export class PrologueComponent extends ColorComponentMixinBase
  implements OnInit {
  constructor() {
    const elementRef = inject(ElementRef);

    super(elementRef);
  }

  ngOnInit() {}
}
