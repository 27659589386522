<div class="logo-wrapper">
  <img
    [alt]="label()"
    [src]="brandLogo$ | async"
    [style.width]="width ? width : null"
    [style.min-width.px]="width ? minWidth : null"
    [style.opacity]="opacity"
    (load)="onImageLoaded($event)"
    [class.hasnt-width]="widthless"
  />
</div>
