<ng-template #image>
  <img [src]="src() | safeUrl" [alt]="alt()" />
</ng-template>

<ng-template #video>
  <video
    controls
    [src]="src() | safeUrl"
    [attr.aria-label]="ariaLabel()"
    [attr.aria-labelledby]="ariaLabelledBy()"
    (error)="handleUnsupported($event)"
  ></video>
</ng-template>

<ng-template #audio>
  <audio
    controls
    [src]="src() | safeUrl"
    [attr.aria-label]="ariaLabel()"
    [attr.aria-labelledby]="ariaLabelledBy()"
    (error)="handleUnsupported($event)"
  ></audio>
</ng-template>

<ng-template #iframe>
  <iframe
    (error)="handleError($event)"
    [src]="src() | safeUrl"
    [attr.aria-label]="ariaLabel()"
    [attr.aria-labelledby]="ariaLabelledBy()"
  ></iframe>
</ng-template>

<ng-template #pdf>
  @if (srcContent()) {
    <iframe
      *mediaIf="'gt-md'"
      [src]="srcContent() | safeUrl"
      [attr.aria-label]="ariaLabel()"
      [attr.aria-labelledby]="ariaLabelledBy()"
    ></iframe>
    <pdf-viewer
      *mediaIf="'lt-lg'"
      [src]="srcContent()"
      [original-size]="false"
      (error)="handleError($event)"
    ></pdf-viewer>
  }
</ng-template>

<ng-template #office>
  <iframe
    (error)="handleError($event)"
    [src]="officeSrc() | safeUrl"
    [attr.aria-label]="ariaLabel()"
    [attr.aria-labelledby]="ariaLabelledBy()"
  ></iframe>
</ng-template>

<ng-template #gDocs>
  @if (src()) {
    <iframe
      (load)="handleGdocsLoad($event)"
      (error)="handleError($event)"
      [src]="gDocsSrc() | safeUrl"
      [attr.aria-label]="ariaLabel()"
      [attr.aria-labelledby]="ariaLabelledBy()"
    ></iframe>
  }
</ng-template>

<ng-template #text>
  @if (srcContent()) {
    <pre>{{ srcContent() }}</pre>
  }
</ng-template>

<ng-template #stl>
  <stl-model-viewer [stlModels]="[src()]"></stl-model-viewer>
</ng-template>

<ng-template #unsupported>
  <div class="notice">
    <img src="./assets/images/format-unsupported.svg" alt="" class="margin-sm" />
    <p class="margin-md">{{ unsupportedMsg() }}</p>
  </div>
</ng-template>

<ng-template #error>
  <div class="notice">
    <img src="./assets/images/unexpected-error.svg" alt="" class="margin-sm" />
    <p class="margin-md">{{ errorMsg() }}</p>
  </div>
</ng-template>

@if (loading()) {
  <mulo-loading-slate height="100%"></mulo-loading-slate>
  @if (loadingText()) {
    <p class="notice loading-text">{{ loadingText() }}</p>
  }
}
@switch (type() ? (type() | fileTypeIcon: false) : '') {
  @case ('image') {
    @if (type() === 'image/tiff') {
      <ng-container *ngTemplateOutlet="gDocs"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="image"></ng-container>
    }
  }
  @case ('video') {
    <ng-container *ngTemplateOutlet="video"></ng-container>
  }
  @case ('audio') {
    <ng-container *ngTemplateOutlet="audio"></ng-container>
  }
  @case ('pdf') {
    <ng-container *ngTemplateOutlet="pdf"></ng-container>
  }
  @case ('word') {
    <ng-container *ngTemplateOutlet="office"></ng-container>
  }
  @case ('excel') {
    <ng-container *ngTemplateOutlet="office"></ng-container>
  }
  @case ('powerpoint') {
    <ng-container *ngTemplateOutlet="office"></ng-container>
  }
  @case ('code') {
    @if (type() === 'text/html' && !viewHtmlAsCode()) {
      <ng-container *ngTemplateOutlet="iframe"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="text"></ng-container>
    }
  }
  @case ('text') {
    <ng-container *ngTemplateOutlet="text"></ng-container>
  }
  @case ('rtf') {
    <ng-container *ngTemplateOutlet="gDocs"></ng-container>
  }
  @case ('stl') {
    <ng-container *ngTemplateOutlet="stl"></ng-container>
  }
  @case ('compressed') {
    <ng-container *ngTemplateOutlet="unsupported"></ng-container>
  }
  @case ('application') {
    <ng-container *ngTemplateOutlet="unsupported"></ng-container>
  }
  @case ('example') {
    @if (type() === 'example/error') {
      <ng-container *ngTemplateOutlet="error"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="unsupported"></ng-container>
    }
  }
  @default {
    <ng-container *ngTemplateOutlet="iframe"></ng-container>
  }
}
