import { NgModule, inject } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import {DepositFormService} from '../deposit/deposit-form.service';
import {LoginService} from './login.service';
import {StorageUtilService} from './storage-util.service';
import {JwtUtilService} from './jwt-util.service';
import {AuthService} from './auth.service';
import {RestUtilService} from './rest-util.service';
import { ProfileService } from '../profile/profile.service';
import { DepositKeywordsSuggestionsService } from '../deposit/deposit-sections/deposit-keywords/deposit-keywords-suggestions.service';
import {SnackbarService} from './snackbar.service';
import {ConfigurationHandlerService} from './configuration-handler.service';
import {DepositStepThreeService} from '../deposit/deposit-steps/deposit-step-three/deposit-step-three.service';
import {ProfileGuard} from './profile-guard.service';
import { AnnouncerService } from './announcer.service';
import { ApcGuard } from './apc-guard.service';
import { PivotGuard } from './pivot-guard.service';
import { EntityEditableGuard } from './entity-editable-guard.service';
import { ResearchUnitsGuard } from './research-units-guard.service';


//All services which have to have only one instance per application (singleton services) should be implemented here
@NgModule({
    imports: [
    ],
    declarations: [],
    providers: [
        AuthGuard,
        ApcGuard,
        PivotGuard,
        ProfileGuard,
        DepositFormService,
        LoginService,
        StorageUtilService,
        JwtUtilService,
        AuthService,
        RestUtilService,
        ProfileService,
        DepositKeywordsSuggestionsService,
        SnackbarService,
        ConfigurationHandlerService,
        DepositStepThreeService,
        AnnouncerService,
        EntityEditableGuard,
        ResearchUnitsGuard
    ]
})
export class CoreModule {
    constructor () {
        const parentModule = inject(CoreModule, { optional: true, skipSelf: true });

        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }

}
