import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    inject,
    input,
    AfterViewInit,
    output,
    viewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UserMenuPanelComponent } from './user-menu-panel/user-menu-panel.component';
import { take } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { AvatarComponent } from '@exl-ng/mulo-common';
import { MatButton } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'exl-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'exl-user-menu' },
    imports: [
        MatButton,
        MatMenuTrigger,
        NgTemplateOutlet,
        AvatarComponent,
        MatIcon,
        MatMenu,
        UserMenuPanelComponent,
        TranslateModule,
    ],
})
export class UserMenuComponent implements AfterViewInit {
    private _bottomSheet = inject(MatBottomSheet);

    readonly userName = input(undefined);
    readonly userImage = input(undefined);
    readonly userInitials = input(undefined);
    readonly proxyName = input(undefined);
    readonly proxyInitials = input(undefined);
    readonly proxyImage = input(undefined);
    readonly menuItems = input(undefined);
    readonly showArrow = input(true);
    readonly menuStyle = input<'menu' | 'sheet'>('menu');
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input('ariaLabel') menuA11yLabel: string;

    menuOpen = false;

    readonly signOut = output();
    readonly action = output<string>();

    readonly menuTrigger = viewChild(MatMenuTrigger);
    readonly userMenuPanel = viewChild(UserMenuPanelComponent);
    readonly menu = viewChild(MatMenu);

    @HostBinding('class.has-proxy') get hasProxy() {
        const proxyName = this.proxyName();
        return proxyName ? proxyName.length : null;
    }

    ngAfterViewInit() {
        const menu = this.menu();
        menu._allItems = this.userMenuPanel().matMenuItems;
        menu.ngAfterContentInit();

        if (!this.menuA11yLabel) {
            this.menuA11yLabel = this.hasProxy
                ? this.proxyName()
                : this.userName();
        }
    }

    showBottomSheet(): void {
        const config = {
            panelClass: 'padding-zero',
            data: this.userMenuPanel(),
        };
        this.menuOpen = true;

        this._bottomSheet
            .open(UserMenuPanelComponent, config)
            .afterDismissed()
            .pipe(take(1))
            .subscribe((action) => this.onMenuAction(action));
    }

    doSignOut() {
        this.signOut.emit();
    }

    onMenuAction(action) {
        this.menuOpen = false;
        switch (action) {
            case 'signout':
                this.signOut.emit();
                break;
            default:
                this.action.emit(action);
                break;
        }
    }
}
