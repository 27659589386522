<div
    exlFileUpload
    (dragOverEmitter)="onDragOver($event)"
    (filesDroppedEmitter)="onFilesDropped($event)"
    [ngClass]="{ 'is-minimal': minimal(), 'is-hidden': hidden() }"
    [multiple]="multiple()"
>
    <div class="inner">
        @if (!dragOverActive) {
            <div class="label">
                @if (multiple()) {
                    {{ 'research.files.loader' | customTranslate }}
                }
            </div>
            <button
                mat-raised-button
                color="primary"
                class="has-icon has-file-input is-large"
                (click)="fileRequester.click()"
                type="button"
                role="button"
            >
                <span class="button-content-wrapper">
                    <span>{{ 'research.files.button' | customTranslate }}</span>
                </span>
            </button>
            <input
                type="file"
                (change)="onFilesSelected($event)"
                style="display: none"
                attr.aria-label="{{ 'research.aria.deposit.select.files' | translate }}"
                [multiple]="multiple()"
                tabindex="-1"
                id="selected_file"
                #fileRequester
            />
            <!--</span>-->
        }
        @if (dragOverActive) {
            <div class="label">
                @if (multiple()) {
                    <span>{{ multiDropActionLabel }}</span>
                }
                @if (!multiple()) {
                    <span>{{ singleDropActionLabel }}</span>
                }
            </div>
        }
    </div>
</div>

@if (depositFormDataService.invalidFiles().length) {
    <div class="invalid-files">
        <mat-list>
            <h3 mat-subheader style="color: red">{{ 'research.files.max.file.size.error' | translate }}</h3>
            @for (file of depositFormDataService.invalidFiles(); track file) {
                <mat-list-item>
                    <h4 mat-line>{{ file.name }}</h4>
                </mat-list-item>
            }
        </mat-list>
    </div>
}
