import { Component, OnInit, Input, OnDestroy, HostListener, inject, input, output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { Subject, takeUntil } from 'rxjs';
import { Grant } from '../../../../shared/interfaces/grant.model';
import { DepositGrantsService } from '../deposit-grants.service';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationHandlerService } from '../../../../core/configuration-handler.service';
import { DefaultTranslatePipe } from '../../../../shared/pipes/default-translate.pipe';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../../../shared/pipes/highlight-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { ExternalOrganizationFieldComponent } from '../../../../parts/external-organization-field/external-organization-field.component';
import { MatOption } from '@angular/material/core';

import { EspMatAutocompleteA11yDirective } from '../../../../shared/directives/a11y/mat-autocomplete-extension.directive';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'esp-new-grant',
    templateUrl: './new-grant.component.html',
    styleUrls: ['./new-grant.component.scss'],
    host: { class: 'esp-new-grant' },
    animations: [GrowOutAnimation, GrowInAnimation],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        MatAutocompleteTrigger,
        MatAutocomplete,
        EspMatAutocompleteA11yDirective,
        MatOption,
        MatError,
        ExternalOrganizationFieldComponent,
        MatButton,
        TranslateModule,
        HighlightFilterPipe,
        CustomTranslatePipe,
        DefaultTranslatePipe
    ]
})
export class NewGrantComponent implements OnInit, OnDestroy {
  private formBuilder = inject(UntypedFormBuilder);
  private grantService = inject(DepositGrantsService);
  depositFormDataService = inject(DepositFormDataService);
  confirmDialog = inject(MatDialog);
  configurationHandlerService = inject(ConfigurationHandlerService);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() enterNewGrant;
  readonly enterNewGrantChange = output();

  readonly allGrants = input(undefined);
  readonly allGrantsChange = output();

  newGrant: UntypedFormGroup;
  filteredGrantIdsGrants = [];
  private grantIdDestroy = new Subject<void>();
  saveInProcess = false;
  private externalOrganizationDestroy = new Subject<void>();

  @HostListener('keydown.Escape', ['$event']) private keyFunc(event) {
    event.stopPropagation();
    this.close();
  }

  constructor() {
    this.newGrant = this.formBuilder.group({
      grantId: ['', Validators.required],
      grantName: [''],
      funderCode: ['', Validators.required],
      funderName: ['', [Validators.required, this.validateInstitution]],
    });
  }

  ngOnInit() {
    this.filteredGrantIdsGrants = this.allGrants();
    this.listenToGrantIdInput();
  }

  listenToGrantIdInput() {
    this.newGrant
      .get('grantId')
      .valueChanges.pipe(takeUntil(this.grantIdDestroy))
      .subscribe((value) => {
        if (value != null) {
          this.filterGrants(value);
        }
      });
  }

  filterGrants(text: string) {
    if (text.length === 0) {
      this.filteredGrantIdsGrants = this.allGrants();
    }
    this.filteredGrantIdsGrants = this.allGrants().filter(
      (obj) =>
        obj.grantId
          .toLowerCase()
          .indexOf(text.toString().toLowerCase()) !== -1
    );
  }

  get grantId() {
    return this.newGrant?.get('grantId').value;
  }

  get grantName() {
    return this.newGrant?.get('grantName').value;
  }

  get funderCode() {
    return this.newGrant?.get('funderCode').value;
  }

  get funderName() {
    return this.newGrant?.get('funderName').value;
  }

  isValid() {
    return this.newGrant.valid && (!this.funderName || this.funderCode);
  }

  onSubmitNewGrant() {
    if (this.isGrantIdAlreadyExist(this.grantId, this.funderCode)) {
      const grant = this.allGrants().filter(
        (g) =>
          g.grantId === this.grantId &&
          g.funderCode === this.funderCode
      )[0];
      this.onGrantSelect(grant);
      return;
    }

    const newGrantAsGrant: Grant = {} as Grant;
    newGrantAsGrant.grantId = this.grantId;
    newGrantAsGrant.grantName = this.grantName;
    newGrantAsGrant.funderCode = this.funderCode;
    newGrantAsGrant.funderName = this.funderName;
    this.saveInProcess = true;
    this.grantService.addNewGrant(newGrantAsGrant).subscribe(
      (data) => {
        newGrantAsGrant.id = data.toString();
        newGrantAsGrant.selfCreated = true;
        this.depositFormDataService.updateGrants(newGrantAsGrant);
        this.allGrants().push(newGrantAsGrant);
        this.allGrantsChange.emit(this.allGrants());
        this.saveInProcess = false;
        this.close();
      },
      () => {
        this.saveInProcess = false;
      }
    );
  }

  isGrantIdAlreadyExist(grantId, funderCode): boolean {
    return this.allGrants().some(
      (g) => g.grantId === grantId && g.funderCode === funderCode
    );
  }

  close() {
    this.resetForm();
    this.enterNewGrant = false;
    this.enterNewGrantChange.emit(this.enterNewGrant);
  }

  resetForm() {
    this.newGrant.reset();
  }

  addGrant(item) {
    if (!this.depositFormDataService.isGrantExist(item)) {
      this.depositFormDataService.updateGrants(item);
    }
  }

  onGrantSelect(grant) {
    this.addGrant(grant);
    this.close();
  }

  get numberOfItemsToDisplay() {
    return DepositForm.MAX_DISPLAYED_ITEMS_IN_DROP_DOWN;
  }

  validateInstitution: ValidationErrors | null = () => {
    const institutionValidator = !this.funderName || this.funderCode;
    return institutionValidator
      ? null
      : {
        validateInstitution: {
          valid: false,
        },
      };
  };

  ngOnDestroy() {
    if (!this.grantIdDestroy.isStopped) {
      this.grantIdDestroy.next();
      this.grantIdDestroy.unsubscribe();
    }
    if (!this.externalOrganizationDestroy.isStopped) {
      this.externalOrganizationDestroy.next();
      this.externalOrganizationDestroy.unsubscribe();
    }
  }
}
