<mat-radio-group class="lang-group" [(ngModel)]="selectedLanguage" (change)="onLanguageChange($event)">
  @for (lang of languages; track lang) {
    <mat-radio-button [value]="lang">
      <div>
        <span class="semibold">{{ lang | translate }}</span>
        <span>&nbsp;·&nbsp;{{ lang | uppercase }}</span>
      </div>
    </mat-radio-button>
  }
</mat-radio-group>
