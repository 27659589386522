<div class="title">
  <h2 cdkFocusInitial class="padding-zero" mat-dialog-title>
    {{ data.title | translate }}
  </h2>
  <div class="panel-buttons">
    @if (data.actions.close) {
      <button
        [aria-label]="'research.aria.dialog.close.dialog' | translate"
        [mat-dialog-close]="service.chat"
        class="is-large panel-button"
        mat-icon-button
        >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    }
  </div>
</div>

<mulo-chat
  [entries]="data.chat"
  [reasonPrefix]="'research.output.list.note.return.reason' | translate"
  [singleInstance]="data.singleInstanceChat"
  mat-dialog-content
></mulo-chat>
<div mat-dialog-actions>
  <mulo-chat-input
    (userEntry)="addUserMessage()"
    [clearButtonLabel]="'research.aria.search.bar.clear' | translate"
    [errorMsgHtml]="'research.output.list.error.message' | translate"
    [inputLabel]="'research.output.list.message.window.body' | translate"
  ></mulo-chat-input>
  @if (data.actions.confirm) {
    <button
      (click)="addUserMessage()"
      [class.is-large]="actionFocus === 'confirm'"
      [color]="actionFocus === 'confirm' ? accentColor : null"
      [disabled]="!enableSendMessage()"
      class="is-large has-icon close-button"
      mat-button
      >
      <mat-icon class="mat-icon-rtl-mirror" svgIcon="send"></mat-icon>
      <span>{{ data.actions.confirm | translate }}</span>
    </button>
  }
</div>
