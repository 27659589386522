<!-- HEADER -->
<div class="header-title">
    <h1 mat-dialog-title class="cdk-visually-hidden">
        {{ 'research.output.list.files.links.dialog.title' | translate }}
    </h1>
    <p class="pre-title">{{ type | translate }}</p>
    <h2 mat-dialog-title>{{ title }}</h2>
</div>

<!-- FILES ADD AND SUBMIT -->
<esp-deposit-form-section sectionTitle="{{ 'research.output.list.files.links.dialog.subtitle' | translate }}">
    <esp-deposit-files [multiple]="true" [displayAccessRights]="false"> </esp-deposit-files>
</esp-deposit-form-section>
<div class="align-end margin-right-md margin-bottom-sm margin-bottom-xs">
    <button mulo-funky-button [gleam]="false" class="margin-top-xs" [disabled]="!isFilesAdded" (clicked)="onSubmit()">
        <span>
            {{ 'research.output.list.files.links.button.submit' | translate }}
        </span>
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="send"></mat-icon>
    </button>
</div>

<!-- CLOSE -->
<button
    mat-icon-button
    [mat-dialog-close]="true"
    matTooltip="{{ 'research.view.asset.dialog.close.tooltip' | customTranslate }}"
    class="close-button"
    aria-label="{{ 'research.aria.view.asset.dialog.close' | translate }}"
>
    <mat-icon svgIcon="close"></mat-icon>
</button>
