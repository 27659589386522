@if (!backButtonDisabled) {
  <esp-deposit-stepper-back-button
    [disabled]="backButtonDisabled"
    (back)="onBackButtonClick()"
    [class.is-detached]="headerIsHidden"
    >
  </esp-deposit-stepper-back-button>
}

<mat-horizontal-stepper [linear]="false" class="has-hidden-last-step" (selectionChange)="stepChanged($event)" #stepper>
  <mat-step>
    <ng-template matStepLabel>
      <h2>{{ steps[0].title | translate }}</h2>
    </ng-template>
    <esp-deposit-step-one
      #step
      (closeDeposit)="closeDeposit.emit($event)"
      [tab1]="firstTabComponents"
      [jumpToFiles]="jumpToFiles"
      (assetTypeSelectedEmitter)="assetTypeSelected()"
      (assetTypeClearedEmitter)="assetTypeCleared()"
      style="min-height: calc(100vh - 65px); display: block"
      >
      <!-- REMOVE -->
    </esp-deposit-step-one>
  </mat-step>

  @if (displayTab(secondTabComponents)) {
    <mat-step>
      <ng-template matStepLabel>
        <h2>{{ steps[1].title | translate }}</h2>
      </ng-template>
      <esp-deposit-step-two
        #step
        [tab2]="secondTabComponents"
        [jumpToTitle]="jumpToTitle"
        [jumpToTopics]="jumpToTopics"
        >
      </esp-deposit-step-two>
    </mat-step>
  }

  @if (displayTab(thirdTabComponents)) {
    <mat-step>
      <ng-template matStepLabel>
        <h2>{{ steps[2].title | translate }}</h2>
      </ng-template>
      <esp-deposit-step-three #step [tab3]="thirdTabComponents"></esp-deposit-step-three>
    </mat-step>
  }

  @if (displayTab(fourthTabComponents)) {
    <mat-step>
      <ng-template matStepLabel>
        <h2>{{ steps[3].title | translate }}</h2>
      </ng-template>
      <esp-deposit-step-four #step [tab4]="fourthTabComponents"></esp-deposit-step-four>
    </mat-step>
  }

  <mat-step class="is-hidden" label="hidden">
    @if (stepper.selectedIndex === 4) {
      <esp-deposit-last-step
        (jumpToSection)="jumpToSection($event)"
      ></esp-deposit-last-step>
    }
  </mat-step>
</mat-horizontal-stepper>

<div class="form-buttons" [class.is-detached]="headerIsHidden">
  <div class="form-buttons-inner">
    @if (showSkipToEnd && !isLastStep) {
      <esp-deposit-stepper-skip-to-end-button
        (skip)="onSkipToEndButtonClick()"
        [@opacityInAnimation]="{ value: 'enter', params: { time: '.4s .4s' } }"
        [@opacityOutAnimation]="{ value: 'leave', params: { time: '.2s' } }"
        >
      </esp-deposit-stepper-skip-to-end-button>
    }

    <esp-deposit-stepper-next-button
      (advance)="onNextButtonClick()"
      [steps]="steps"
      [selectedStep]="stepper.selectedIndex"
      [disabled]="!nextStepEnabled"
      [rounded]="headerIsHidden"
      [loading]="processingNextStep"
      >
    </esp-deposit-stepper-next-button>
  </div>
</div>
