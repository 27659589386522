import { Component, input } from '@angular/core';

@Component({
    selector: 'mulo-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
    host: {
        class: 'mulo-separator',
        '[class.is-vertical]': 'orientation() === "vertical"',
        '[class.is-horizontal]': 'orientation() === "horizontal"',
        '[style.width]': 'width',
        '[style.height]': 'height',
    },
    standalone: true,
})
export class SeparatorComponent {
  readonly orientation = input<'vertical' | 'horizontal'>('vertical');
  readonly thickness = input<number>(undefined);
  readonly length = input<number>(undefined);

  constructor() {}

  get height() {
    const lengthValue = this.length();
    const length = lengthValue ? lengthValue / 16 + 'rem' : undefined;
    const thickness = this.length() ? this.thickness() + 'px' : undefined;

    return this.orientation() === 'vertical' ? length : thickness;
  }

  get width() {
    const lengthValue = this.length();
    const length = lengthValue ? lengthValue / 16 + 'rem' : undefined;
    const thickness = this.length() ? this.thickness() + 'px' : undefined;

    return this.orientation() === 'vertical' ? thickness : length;
  }
}
