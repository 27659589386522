import {
    computed,
    Injectable,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { Configuration } from '../shared/interfaces/configuration.interface';
import { ResearcherOrganization } from '../shared/interfaces/researcher-organization.interface';
import { ResearcherDepositConfigurations } from '../shared/interfaces/researcher-deposit-configuration-data.interface';
import { EsploroEntity } from '../shared/interfaces/esploro-entity';
import { PortalSearchScope } from '../shared/configurations/portal-search.constant';
import { ActivitiesCourseSection } from '../shared/interfaces/view-configuration/portal-activities-configuration.interface';
import { ResearcherProfileGeneralConfiguration } from '../shared/interfaces/researcher-profile-general-configuration.interface';
import { PortalOrgTreeConfiguration } from '../shared/interfaces/view-configuration/portal-org-tree-configuration.interface';
import { PortalActivityTabConfiguration } from '../shared/interfaces/view-configuration/portal-activity-tab-configuration.interface';
import { MappingTable } from '../shared/interfaces/mapping-table.interface';
import { UrlUtils } from '../shared/utils/url.utils';
import { map, Observable } from 'rxjs';
import { AssetFieldType } from '../shared/interfaces/asset/revision/asset-field-type';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable()
export class ConfigurationHandlerService {
    private _config: WritableSignal<Configuration>;
    config: Signal<Configuration>;
    config$: Observable<Configuration>;

    private _depositConfig: any;

    constructor() {
        const code = UrlUtils.getParam('institution');
        const cached = JSON.parse(
            localStorage.getItem('espConfig' + (code ? '_' + code : '')),
        );
        this._config = signal<Configuration>(cached || {});
        this.config = this._config.asReadonly();
        this.config$ = toObservable(this.config);
    }

    setConfig(value: Configuration) {
        const cfgStr = JSON.stringify(value);
        localStorage.setItem('espConfig', cfgStr);
        if (value && value.institutionCode) {
            localStorage.setItem('espConfig_' + value.institutionCode, cfgStr);

            const isDevServer = new URLSearchParams(
                document?.location?.search,
            ).has('institution');
            if (isDevServer) {
                localStorage.removeItem('espConfig');
            }

            UrlUtils.setParam('institution', value.institutionCode);
        }

        this._config.set(value);
    }

    enrichConfiguration(extendedConfig: Configuration) {
        this._config.update((existingCfg) =>
            Object.assign({}, existingCfg, extendedConfig),
        );
    }

    getMappingTable(mappingTableName: string): MappingTable[] {
        return this.config().mappingTables?.[mappingTableName] || [];
    }

    getMappingTableEnabledMap(mtName: string) {
        return computed(() =>
            this.config().mappingTables?.[mtName]?.reduce(
                (returnObj, mtEntry) =>
                    returnObj.set(
                        mtEntry.target as AssetFieldType,
                        mtEntry.enabled === 'true',
                    ),
                new Map<AssetFieldType, boolean>(),
            ),
        );
    }

    getMappingTableEnabledMap$(
        mappingTableName: string,
    ): Observable<Map<AssetFieldType, boolean>> {
        return this.config$.pipe(
            map((config) =>
                config.mappingTables?.[mappingTableName]?.reduce(
                    (returnObj, mtEntry) =>
                        returnObj.set(
                            mtEntry.target as AssetFieldType,
                            mtEntry.enabled === 'true',
                        ),
                    new Map<AssetFieldType, boolean>(),
                ),
            ),
        );
    }

    getCustomerParameter(param: string) {
        return this.config().customerParameters?.[param];
    }

    getFeatureFlagSignal = (param: string) => computed(
        () => this.config().featureFlags?.[param],
    );

    getFeatureFlagEnabled(param: string): boolean {
        return this.getFeatureFlagSignal(param)();
    }

    isCustomerParameterEnabled(param: string): boolean {
        return this.getCustomerParameter(param) === 'true';
    }

    systemDateFormat = computed(
        () => this.config().customerParameters?.['esploro_date_format'],
    );

    isEsploroEditionAdvanced = computed(
        () =>
            this.config().customerParameters?.['esploro_edition'] ===
            'advanced',
    );

    isProfileActivate = computed(
        () =>
            this.config().customerParameters?.[
                'esploro_public_profiles_active'
            ] === 'true',
    );

    get depositConfig() {
        return this._depositConfig;
    }

    set depositConfig(value) {
        this._depositConfig = value;
    }

    customization = computed(() => this.config().customization);

    defaultDisplayLanguage = computed(
        () => this.config().customization.defaults.defaultDisplayLanguage,
    );

    defaultLogoUrl = computed(
        () => this.config().customization.defaults.logoUrl,
    );

    mainInstitution = computed(() => {
        const mainInst = this.config()
            .mainInstitution as ResearcherOrganization;
        if (mainInst) {
            return mainInst;
        }
        return undefined;
    });

    mainInstitutionName = computed(() => {
        const mainInst = this.config()
            .mainInstitution as ResearcherOrganization;
        if (mainInst) {
            return mainInst.organizationName;
        }
        return '';
    });

    mainInstitutionCode = computed(() => {
        const mainInst = this.config()
            .mainInstitution as ResearcherOrganization;
        if (mainInst) {
            return mainInst.organizationCode;
        }
        return '';
    });

    authProfile = computed(() => this.config().authProfile);

    isExternalLogin = computed(() => {
        const auth = this.authProfile();
        return (
            auth?.system === 'SAML' ||
            auth?.system === 'CAS' ||
            auth?.system === 'SOCIAL_VIA_ALMA'
        );
    });

    researcherIdentifiersOptions = computed(
        () => this.config().researcherIdentifiersOptions,
    );

    externalFacingBaseUrl = computed(() => {
        let baseUrl = this.config().externalFacingBaseUrl;
        if (baseUrl.charAt(baseUrl.length - 1) === '/') {
            baseUrl = baseUrl.slice(0, -1);
        }

        return baseUrl;
    });

    configurationByDefualtView = computed(
        () =>
            this.config().portalViewConfiguration?.configurationByView.default,
    );

    portalConfigurationByAssetType = computed(
        () => this.configurationByDefualtView()?.configurationByAssetType,
    );

    portalConfigurationByDefaultAssetType = computed(
        () => this.portalConfigurationByAssetType()?.default,
    );

    portalConfigurationHeaderLinks = computed(
        () => this.configurationByDefualtView()?.headerFooter?.headerLinks,
    );

    portalConfigurationFooterLinks = computed(
        () => this.configurationByDefualtView()?.headerFooter?.footerLinks,
    );

    portalConfigurationHedearIncludeSignIn = computed(
        () => this.configurationByDefualtView()?.headerFooter?.includeSignIn,
    );

    portalConfigurationHedearIncludePivot = computed(
        () => this.configurationByDefualtView()?.headerFooter?.includePivot,
    );

    portalConfigurationHedearIncludeDropdownMenu = computed(
        () =>
            this.configurationByDefualtView()?.headerFooter
                ?.includeDropdownMenu,
    );

    portalConfigurationFooterIncludeCopyrights = computed(
        () =>
            this.configurationByDefualtView()?.headerFooter?.includeCopyrights,
    );

    portalConfigurationFooterIncludeLogo = computed(
        () =>
            this.configurationByDefualtView()?.headerFooter?.includeFooterLogo,
    );

    portalConfigurationSocialMediaLinks = computed(
        () =>
            this.configurationByDefualtView()?.headerFooter?.socialMediaLinks ||
            [],
    );

    briefDisplayAltmetrics = computed(
        () =>
            this.configurationByDefualtView()?.configurationByAssetType?.default
                ?.briefResults?.general?.displayAltmetrics,
    );

    darkLogoImg = computed(() => this.config().darkLogoImg);

    lightLogoImg = computed(() => this.config().lightLogoImg);

    faviconImg = computed(() => this.config().faviconImg);

    researcherDepositConfigurations = computed(() => {
        const depositConfiguration = this.config()
            ?.researcherDepositConfiguration as ResearcherDepositConfigurations;
        if (depositConfiguration) {
            return depositConfiguration;
        }
        return undefined;
    });

    researcherProfileLockFieldsConfiguration = computed(
        () => this.config().researcherProfileLockFieldsConfiguration,
    );

    researcherProfileGeneralConfiguration =
        computed<ResearcherProfileGeneralConfiguration>(
            () =>
                this.config().researcherProfileGeneralConfiguration ||
                ({} as ResearcherProfileGeneralConfiguration),
        );

    researcherProfileSearchResultsConfiguration = computed(
        () => this.config().researcherProfileSearchResultsConfiguration,
    );

    portalProjectSearchConfiguration = computed(
        () => this.config().portalProjectSearchConfiguration,
    );

    portalHomepageConfiguration = computed(
        () => this.config().portalHomepageConfiguration?.homepageSections || [],
    );

    portalProjectConfiguration = computed(
        () => this.config().portalProjectConfiguration,
    );

    portaActivitiesConfiguration = computed(
        () => this.config().portaActivitiesConfiguration?.sections,
    );

    portalNonVisbleActivityCategoriesConfiguration = computed(
        () => this.config().portalNonVisbleActivityCategoriesConfiguration,
    );

    isMultiLingualEnabled = computed(() => this.config().isMultiLingualEnabled);

    undefinedLanguage = computed(() => this.config().undefinedLanguage);

    isActivityCourseActive(courseField: string) {
        const courseSection = <ActivitiesCourseSection>(
            this.portaActivitiesConfiguration()?.find(
                (section) => section.code == 'activities.course',
            )
        );
        return !!courseSection[courseField];
    }

    portalResearchResourcesConfiguration = computed(
        () => this.config().portalResourcesConfiguration?.sections,
    );

    advancedSearchConfiguration = computed(
        () => this.config().portalAdvancedSearchConfiguration,
    );

    resetPwUrl = computed(() => this.config().resetPwUrl);

    portalSearchOptionsConfiguration = computed(() =>
        this.config()
            .portalSearchOptionsConfiguration?.sections.filter(
                (entity) => entity.active,
            )
            .map((section) => section.code.split('.').pop()),
    );

    activeEntities = computed(() => {
        const data: EsploroEntity[] =
            this.configurationByDefualtView()?.headerFooter
                ?.headerEntitiesLinks || [];

        const entityCustParamFlags = {
            profileLink: 'esploro_public_profiles_active',
        };

        const entityCustParamFlagsOrAdvanced = {
            projectLink: 'esploro_projects',
        };

        const entityRouteData = {
            portalLink: {
                path: 'search/outputs',
                qParams: {
                    query: 'any,contains,*',
                    page: 1,
                    enable_asterisk_search: true,
                },
            },
            profileLink: {
                path: '/search/researchers',
                qParams: {
                    query: '*',
                    page: 1,
                },
            },
            orgUnitLink: { path: '/research-units', qParams: {} },
            collectionLink: { path: '/collections', qParams: {} },
            resourcesLink: { path: '/resources', qParams: {} },
            projectLink: {
                path: 'search/projects',
                qParams: {
                    query: '*',
                    page: 1,
                },
            },
        };

        // remove the ones deactivated by config
        let resultList = data.filter((e) => e.active === true);

        // remove the ones deactivated by CP
        resultList = resultList.filter((e) => {
            const cpFlagName = entityCustParamFlags[e.code];
            if (cpFlagName) {
                return this.isCustomerParameterEnabled(cpFlagName);
            } else {
                return true;
            }
        });

        resultList = resultList.filter((e) => {
            const cpFlagName = entityCustParamFlagsOrAdvanced[e.code];
            if (cpFlagName) {
                return (
                    this.isCustomerParameterEnabled(cpFlagName) ||
                    this.isEsploroEditionAdvanced()
                );
            } else {
                return true;
            }
        });

        // add in route data
        resultList = resultList.map((e) => {
            const routeData = entityRouteData[e.code] || {};
            return { ...e, ...routeData };
        });
        return resultList;
    });

    profilesSearchScopesFromConfiguration = computed(() =>
        this.getActiveSearchScopes(
            this.researcherProfileSearchResultsConfiguration(),
            'research.profiles.search.scope.',
        ),
    );

    projectsSearchScopesFromConfiguration = computed(() =>
        this.getActiveSearchScopes(
            this.portalProjectSearchConfiguration(),
            'research.projects.scope.',
        ),
    );

    getActiveSearchScopes(scopes, prefix) {
        const searchIndexesArr =
            scopes?.searchIndexes.filter((index) => index.active) || [];
        return searchIndexesArr.map(
            (index) => new PortalSearchScope(index.code, prefix),
        );
    }

    canEditEntityByConfig(customerParam: string, headerKey: string) {
        return (
            (this.getCustomerParameter(customerParam) === 'true' ||
                this.isEsploroEditionAdvanced()) &&
            this.configurationByDefualtView().headerFooter[headerKey]
        );
    }

    portalOrgTreeConfig = computed(
        () => this.config().portalOrgTreeConfiguration,
    );

    activityDisplayTabConfiguration = computed(
        () => this.config().activityDisplayTabConfiguration,
    );
}
