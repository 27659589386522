@if (!isMenuItem()) {
  @switch (type()) {
    @case ('toggle') {
      <mat-slide-toggle
        [checked]="data().checked"
        (change)="onActionClick($event)"
        color="primary"
        [disabled]="isDisabled()"
        class="is-slim is-quiet has-label-below"
      >
        @if (data().checked) {
          {{ (labels()[0] | translate) || labels()[0] }}
        } @else {
          {{ (labels()[1] | translate) || labels()[1] }}
        }
      </mat-slide-toggle>
    }
    @case ('visibility') {
      <mulo-visibility-switch
        [active]="data().visible"
        (changed)="onActionClick($event)"
        [visibleLabel]="labels()[0]"
        [hiddenLabel]="labels()[1]"
        [toolTipText]="labels()[2]"
        [disabled]="isDisabled()"
      >
      </mulo-visibility-switch>
    }
    @case ('icon-btn') {
      <button
        mat-icon-button
        (click)="onActionClick($event)"
        (muloMultipleEvents)="onActionClick($event)"
        [attr.aria-label]="labels()[1] || labels()[0]"
        [disabled]="isDisabled()"
        type="button"
      >
        <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon()"></mat-icon>
      </button>
    }
    @case ('button') {
      <button
        mat-button
        (click)="onActionClick($event)"
        (muloMultipleEvents)="onActionClick($event)"
        [disabled]="isDisabled()"
        color="primary"
        type="button"
      >
        @if (icon()) {
          <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon()"></mat-icon>
        }
        <span>{{ (labels()[0] | translate) || labels()[0] }}</span>
      </button>
    }
  }
} @else {
  @switch (type()) {
    @case ('toggle') {
      <button mat-menu-item (click)="$event?.stopPropagation()" [disabled]="isDisabled()" type="button">
        @if (icon()) {
          <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon()"></mat-icon>
        }
        <mat-slide-toggle
          [checked]="data().checked"
          (change)="onActionClick($event)"
          color="primary"
          labelPosition="before"
          [disabled]="isDisabled()"
        >
          @if (data().checked) {
            {{ (labels()[0] | translate) || labels()[0] }}
          } @else {
            {{ (labels()[1] | translate) || labels()[1] }}
          }
        </mat-slide-toggle>
      </button>
    }
    @case ('visibility') {
      <button mat-menu-item (click)="$event?.stopPropagation()" [disabled]="isDisabled()" type="button">
        @if (icon()) {
          <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon()"></mat-icon>
        }
        <mat-slide-toggle
          [checked]="data().visible"
          (change)="onActionClick($event)"
          color="primary"
          labelPosition="before"
          [disabled]="isDisabled()"
        >
          @if (data().visible) {
            {{ (labels()[0] | translate) || labels()[0] }}
          } @else {
            {{ (labels()[1] | translate) || labels()[1] }}
          }
        </mat-slide-toggle>
      </button>
    }
    @case ('icon-btn') {
      <button
        mat-menu-item
        (click)="onActionClick($event)"
        (muloMultipleEvents)="onActionClick($event)"
        [disabled]="isDisabled()"
        type="button"
      >
        @if (icon()) {
          <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon()"></mat-icon>
        }
        <span>{{ (labels()[0] | translate) || labels()[0] }}</span>
      </button>
    }
    @case ('button') {
      <button
        mat-menu-item
        (click)="onActionClick($event)"
        (muloMultipleEvents)="onActionClick($event)"
        [disabled]="isDisabled()"
        type="button"
      >
        @if (icon()) {
          <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon()" [svgIcon]="icon()"></mat-icon>
        }
        <span>{{ (labels()[0] | translate) || labels()[0] }}</span>
      </button>
    }
  }
}
