import {
  Component,
  ElementRef,
  inject,
  input,
  signal,
  computed,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'mulo-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  host: {
    '[class.mulo-chat-bubble]': 'true',
    '[class]': 'classes()',
  },
  imports: [MatIcon, MomentModule, TranslateModule],
})
export class ChatBubbleComponent {
  private elRef = inject(ElementRef);

  readonly color = input<'accent' | 'primary' | string>('accent');
  readonly time = input(new Date());
  readonly anchor = input<string>('bottom left');
  readonly content = input<any>(undefined);
  readonly align = input<'left' | 'right'>('left');
  readonly icon = input<string | null>(null);
  readonly rtlMirrorIcon = input<boolean>(undefined);
  readonly reasonPrefix = input('Return reason:');
  classList = signal<string>(undefined);

  classes = computed(() =>
    [
      this.classList(),
      'anchor-' + this.anchor().replace(/\s+/g, '-').toLowerCase(),
      'mulo-' + this.color(),
      'align-' + this.align(),
    ]
      .filter(Boolean)
      .join(' '),
  );

  constructor() {
    const elRef = this.elRef;

    this.classList.set(elRef.nativeElement.className);
  }
}
