import { Component, OnInit, ViewChild, inject, input } from '@angular/core';
import { DepositFormService } from '../../deposit-form.service';
import { MatDialog } from '@angular/material/dialog';
import { MediaService } from '@exl-ng/mulo-core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { PDFMetadata } from '../../../shared/interfaces/pdf-metadata.interface';
import { FileUploadService } from '../../../parts/handle-files/file-upload/file-upload.service';
import { GrobidService } from '../../../parts/handle-files/file-upload/grobid.service';
import { FilesFromOnlineDialogComponent } from '../../../parts/dialogs/files-from-online-dialog/files-from-online-dialog.component';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { DepositSearchForService } from '../deposit-search-for/deposit-search-for.service';
import { EsploroRecord } from '../../../shared/interfaces/esploro-record.model';
import { FileProgressDialogComponent } from './file-progress-dialog/file-progress-dialog.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ConfirmDialogComponent } from '../../../parts/dialogs/confirm-dialog/confirm-dialog.component';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { Link } from '../../../shared/interfaces/link.model';
import { DepositFilesLinksService } from '../../deposit-files-links-dialog/deposit-files-links.service';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { DepositLinkMetadataComponent } from './deposit-link-metadata/deposit-link-metadata.component';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';
import { DepositFileMetadataComponent } from './deposit-file-metadata/deposit-file-metadata.component';
import { FileListItemComponent } from '../../../parts/handle-files/file-list/file-list-item/file-list-item.component';
import { FileListComponent } from '../../../parts/handle-files/file-list/file-list.component';
import { FocusDirective } from '../../../shared/directives/focus/focus.directive';
import { FileUploadComponent } from '../../../parts/handle-files/file-upload/file-upload.component';

import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-files',
    templateUrl: './deposit-files.component.html',
    styleUrls: ['./deposit-files.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
    imports: [
        MatButton,
        FileUploadComponent,
        FocusDirective,
        FileListComponent,
        FileListItemComponent,
        DepositFileMetadataComponent,
        BadgeAutoPopulatedComponent,
        DepositLinkMetadataComponent,
        MatIcon,
        TranslateModule,
        CustomTranslatePipe
    ]
})
export class DepositFilesComponent implements OnInit {
    depositFormDataService = inject(DepositFormDataService);
    private fileUploadService = inject(FileUploadService);
    private grobidService = inject(GrobidService);
    private depositFormService = inject(DepositFormService);
    private depositSearchForService = inject(DepositSearchForService);
    media = inject(MediaService);
    matDialog = inject(MatDialog);

    readonly isAutoPopulateEnabled = input(true);
    readonly multiple = input<boolean>(undefined);
    readonly jump = input(undefined);
    readonly displayAccessRights = input<boolean>(undefined);
    readonly displayLicense = input<boolean>(undefined);
    readonly displayContentType = input<boolean>(undefined);
    readonly displayDescription = input<boolean>(undefined);

    readonly alwaysExpandPanel = input<boolean>(undefined);
    readonly editableFiles = input(true);
    allFilesEditable = false;
    allLinksEditable = false;
    miniFileUploaderOpen = false;
    fileUploadRegex = new RegExp('/esploro/(.+)/');
    trackByLink = (index: number, link: any) => link.url;
    trackByFile = (index: number, file: any) => file.fullName;

    ngOnInit() {}

    addNewFile(event) {
        const file = event;
        if (!this.depositFormDataService.isFileExists(file)) {
            file.uploadProgress = 0;
            this.uploadFileToAmazon(file);
            this.callGrobid(file);
            if (!this.miniFileUploaderOpen) {
                this.showUploadMiniDialog();
            }
        }
    }

    showUploadMiniDialog() {
        this.miniFileUploaderOpen = true;
        const dialogRef = this.matDialog.open(FileProgressDialogComponent, {
            panelClass: 'exl-file-progress-dialog-overlay-pane',
            hasBackdrop: false,
            disableClose: true,
            position: {
                bottom: '16px',
                left: '16px',
            },
            data: { files: this.depositFormDataService.files.value },
        });

        dialogRef.afterOpened().subscribe((val) => {});
        dialogRef.afterClosed().subscribe((result) => {
            this.miniFileUploaderOpen = false;
        });
    }

    uploadFileToAmazon(file) {
        file.subscriber = this.fileUploadService.uploadS3(file).subscribe(
            (event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(
                        (100 * event.loaded) / event.total
                    );
                    this.onFileUpload(file, percentDone);
                } else if (event instanceof HttpResponse) {
                }
            },
            (error) => {
                console.error('error uploading to amazom');
            }
        );
        this.depositFormDataService.updateFiles(file);
    }

    callGrobid(file) {
        if (
            this.depositFormDataService.isReservedOrRegisteredDoi() ||
            !this.isAutoPopulateEnabled()
        ) {
            return;
        }
        const fileSizeMB = file.size / 1024 / 1024;
        if (
            !this.depositFormDataService.enrichForm &&
            !this.grobidService.grobidExecuted &&
            file.extension === 'pdf' &&
            fileSizeMB < 5
        ) {
            this.grobidService.grobidExecuted = true;
            this.grobidService.grobid(file).subscribe(
                (data) => {
                    const pdsMetadata = data as PDFMetadata;
                    this.enrichForm(pdsMetadata);
                },
                (error) => {
                    this.grobidService.grobidExecuted = false;
                }
            );
        }
    }

    enrichForm(pdfMetadata) {
        if (pdfMetadata.doi) {
            this.enrichFromDoi(pdfMetadata);
        } else {
            this.enrichFromPDF(pdfMetadata);
        }
    }

    enrichFromDoi(pdfMetadata) {
        this.depositSearchForService
            .enrichFromFileWithDoi(
                this.depositFormDataService.type.value,
                pdfMetadata.doi
            )
            .subscribe(
                (data) => {
                    if (data && !this.isEmptyObject(data)) {
                        const esploroRecord = data as EsploroRecord;
                        this.depositFormDataService.populateForm(
                            esploroRecord,
                            true
                        );
                    } else {
                        this.enrichFromPDF(pdfMetadata);
                    }
                },
                (error) => {
                    this.enrichFromPDF(pdfMetadata);
                }
            );
    }

    enrichFromPDF(pdfMetadata: PDFMetadata) {
        if (pdfMetadata && !this.isEmptyObject(pdfMetadata)) {
            this.depositFormDataService.populateFormFromPDF(pdfMetadata);
        }
    }

    isEmptyObject(o) {
        return Object.keys(o).every(function (x) {
            return o[x].length === 0;
        });
    }

    onFileUpload(file, percentDone) {
        this.depositFormDataService.setFileUploaded(file, percentDone);
    }

    onAddLink() {
        this.showUrlDialog();
    }

    showUrlDialog(link?, index?: number) {
        const dialogRef = this.matDialog.open(FilesFromOnlineDialogComponent, {
            minWidth: this.media.is('lt-md') ? 'auto' : '500px',
            data: {
                index,
                title: link
                    ? 'research.links.title.edit'
                    : 'research.links.title.add',
                url: link ? link.url : '',
                description: link ? link.description : '',
                new: link ? false : true,
                actions: {
                    cancel: 'research.links.no',
                    confirm: link
                        ? 'research.links.yes.save'
                        : 'research.links.yes.add',
                },
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result !== 'cancel') {
                if (result.index >= 0) {
                    this.onLinkEdited(result);
                } else {
                    this.onNewLinkAdded(result);
                }
            }
        });
    }

    onNewLinkAdded(link) {
        const newLink = new Link(link.url, link.description, link.type);
        this.depositFormDataService.updateLinks(newLink);
    }

    onLinkEdited(link) {
        this.depositFormDataService.updateLink(link.index, link);
    }

    hasFiles() {
        return this.depositFormDataService.hasFiles();
    }

    hasLinks() {
        return this.depositFormDataService.hasLinks();
    }

    onRemoveAllFiles() {
        this.showFileDeleteConfirmationDialog('all');
    }

    onFileRemove(event) {
        const file = event.item;
        const index = event.index;
        this.showFileDeleteConfirmationDialog('single', index, file);
    }

    deleteFileFromUpload(fileName) {
        this.fileUploadService
            .deleteFileFromUpload(
                DepositForm.UPLOAD_FILE_PATH +
                    this.depositFormDataService.filePath.value,
                fileName
            )
            .subscribe();
    }

    get isFileInUploadingProcess() {
        return this.depositFormDataService.isFileInUploadingProcess();
    }

    onLinkEdit(link, index) {
        link.existing = true;
        this.showUrlDialog(link, index);
    }

    onRemoveAllLinks() {
        this.allLinksEditable = false;
        this.depositFormDataService.clearLinks();
    }

    onLinkRemove(event) {
        const index = event.index;
        this.depositFormDataService.removeLink(index);
    }

    removeSingleFile(index) {
        const file = this.depositFormDataService.files.value[index];
        if (file.uploadProgress < 100) {
            file.subscriber.unsubscribe();
        } else {
            // if (file.mid){
            //     this.depositFormDataService.addDeletedStorageFilesNames(file);
            // } else {
            //     this.deleteFileFromUpload(file.fullName);
            // }
            this.handleFileDeletion(file);
        }
        this.depositFormDataService.removeFile(index);
        (<HTMLInputElement>document.getElementById('selected_file')).value = '';
    }

    handleFileDeletion(file) {
        if (file.mid) {
            //file in storage
            this.depositFormDataService.addDeletedStorageFilesNames(file);
        } else {
            //file in upload
            this.deleteFileFromUpload(file.fullName);
        }
    }

    removeAllFiles() {
        this.depositFormDataService.files.value.forEach((file) => {
            if (file.uploadProgress < 100) {
                file.subscriber.unsubscribe();
            } else {
                //this.deleteFileFromUpload(file.fullName);
                this.handleFileDeletion(file);
            }
        });
        this.depositFormDataService.clearFiles();
        (<HTMLInputElement>document.getElementById('selected_file')).value = '';
    }

    isAutoPopulated(url) {
        return (
            this.depositFormDataService.linksAutoPopulated.indexOf(url) !== -1
        );
    }

    showFileDeleteConfirmationDialog(target, index?, file?) {
        const single = target === 'single';
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                useCustomPipe: true,
                title: single
                    ? 'research.filetable.remove.single'
                    : 'research.filetable.remove.multiple',
                message: single ? 'research.file.remove.title' : '',
                param: single ? file.fullName : '',
                actions: {
                    cancel: single
                        ? 'research.filetable.remove.no.single'
                        : 'research.filetable.remove.no.multiple',
                    confirm: single
                        ? 'research.filetable.remove.yes.single'
                        : 'research.filetable.remove.yes.multiple',
                },
            },
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response === 'ok') {
                this.allFilesEditable = false;
                single ? this.removeSingleFile(index) : this.removeAllFiles();
            }
        });
    }
}
