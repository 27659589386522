import { Injectable, inject } from '@angular/core';
import { StorageUtilService } from './storage-util.service';
import { SessionStorageUtilService } from './session-storage-util.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtInfo } from '../shared/interfaces/jwt-info.interface';

@Injectable()
export class JwtUtilService {
    private storageUtilService = inject(StorageUtilService);
    private sessionStorageUtilService = inject(SessionStorageUtilService);

    jwtStorageKey = 'access_token';
    helper = new JwtHelperService();

    storeJwt(jwt) {
        this.storageUtilService.setItem(this.jwtStorageKey, jwt);
    }

    getJwtFromStorage() {
        if (this.storageUtilService.getItem(this.jwtStorageKey)) {
            return this.storageUtilService.getItem(this.jwtStorageKey);
        }
        return this.getJwtFromSessionStorage();
    }

    removeJwt() {
        this.storageUtilService.removeItem(this.jwtStorageKey);
        this.removeJwtFromSessionStorage();
    }

    decodeJwt(): JwtInfo {
        return this.helper.decodeToken(this.getJwtFromStorage());
    }

    isExpired() {
        return this.helper.isTokenExpired(this.getJwtFromStorage());
    }

    expirationDate() {
        return this.helper.isTokenExpired(this.getJwtFromStorage());
    }

    getFirstName(forceJwt = false) {
        if (!forceJwt && this.getCurrentUserForProxy()) {
            return this.getCurrentUserForProxy().firstName;
        }
        const firstName = this.decodeJwt() ? this.decodeJwt().firstName : '';
        return firstName;
    }

    getLastName(forceJwt = false) {
        if (!forceJwt && this.getCurrentUserForProxy()) {
            return this.getCurrentUserForProxy().lastName;
        }
        const lastName = this.decodeJwt() ? this.decodeJwt().lastName : '';
        return lastName;
    }

    getMiddleName() {
        return this.decodeJwt().middleName;
    }

    getNameSuffix() {
        return this.getCurrentUserForProxy() ? '' : this.decodeJwt().nameSuffix;
    }

    getUserDisplayName(forceJwt = false) {
        if (!forceJwt && this.getCurrentUserForProxy()) {
            return this.getCurrentUserForProxy().displayName;
        }
        const displayName = this.decodeJwt()
            ? this.decodeJwt().displayName
            : '';
        return displayName;
    }

    getUserName(forceJwt = false) {
        return !forceJwt && this.getCurrentUserForProxy()
            ? this.getCurrentUserForProxy().userPrimaryID
            : this.decodeJwt()?.userName;
    }

    getUserID(forceJwt = false) {
        if (!forceJwt && this.getCurrentUserForProxy()) {
            return this.getCurrentUserForProxy().userID;
        }
        if (this.isLoggedIn()) {
            return this.decodeJwt().user;
        }
        return '-9999999';
    }

    getAccessToResearcherCenter(): boolean {
        return this.decodeJwt().accessToResearcherCenter === 'true';
    }

    getProxyFor() {
        if (this.isLoggedIn()) {
            return this.decodeJwt().proxyFor;
        }
        return '';
    }

    getCurrentUserForProxy() {
        return this.storageUtilService.getObject('current_user');
    }

    getOwnerID() {
        return !!this.getCurrentUserForProxy()
            ? this.getCurrentUserForProxy().userID
            : '';
    }

    isLoggedIn() {
        if (this.getJwtFromStorage()) {
            return true;
        }
        return false;
    }

    isResearcherLoggedIn() {
        if (this.storageUtilService.getItem(this.jwtStorageKey)) {
            return true;
        }
        return false;
    }

    getAuthenticationProfile() {
        return this.decodeJwt().authenticationProfile;
    }

    getAuthenticationSystem() {
        return this.decodeJwt().authenticationSystem;
    }

    getLanguage() {
        return this.decodeJwt().language;
    }

    isJwtCreationTimeValid(time) {
        if (this.getJwtFromStorage()) {
            if (
                !this.decodeJwt().jwtCreationTime ||
                this.decodeJwt().jwtCreationTime < time
            ) {
                this.storageUtilService.clear();
                return false;
            }
        }

        return true;
    }

    storeJwtInSessionStorage(jwt) {
        this.sessionStorageUtilService.setItem(this.jwtStorageKey, jwt);
    }

    getJwtFromSessionStorage() {
        return this.sessionStorageUtilService.getItem(this.jwtStorageKey);
    }

    removeJwtFromSessionStorage() {
        this.sessionStorageUtilService.removeItem(this.jwtStorageKey);
    }

    decodeSessionJwt(): JwtInfo {
        return this.helper.decodeToken(this.getJwtFromSessionStorage());
    }

    isAuthorizedForDeposit() {
        if (this.getJwtFromSessionStorage()) {
            return this.decodeSessionJwt().restrictedControl === 'N';
        }

        return true;
    }

    isResearcherTypeLoggedIn() {
        return (
            this.getJwtFromStorage() &&
            this.decodeJwt().loginType === 'RESEARCHER'
        );
    }

    isUserTypeLoggedIn() {
        return (
            this.getJwtFromStorage() && this.decodeJwt().loginType === 'USER'
        );
    }

    isAdminLoggedIn() {
        return !!this.getJwtFromSessionStorage();
    }
}
