<mat-form-field style="min-width:250px" [class.is-autopopulated]="isAutoPopulated">
  @if (isAutoPopulated) {
    <mat-icon matPrefix
      svgIcon="auto-populate"
    matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
  }
  <mat-select placeholder="{{ placeholder | customTranslate}}"
    [formControl]="depositFormDataService.status"
    #statusSelect>
    @for (option of statusOptions; track option; let i = $index) {
      <mat-option
        [value]="option">
        {{'research.publication.status.'+ option | customTranslate}}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

