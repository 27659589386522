import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * ! Accessibility
 * This service enable to announce to screen readers helpful information on actions
 * (like add, delete etc.)
 */
@Injectable({ providedIn: 'root' })
export class AnnouncerService {
    private announcer = inject(LiveAnnouncer);
    private translate = inject(TranslateService);


    private listToString(list: string[]): string {
        return list.filter((item) => item != null).join(' ');
    }

    private translation(label: string, nameToAnnounce: string): string {
        return this.translate.instant(label, { value: nameToAnnounce });
    }

    annonuceAdd(...list: string[]) {
        const toAnnounce = this.listToString(list);
        const translatedLabel = this.translation(
            'research.aria.description.announce.added',
            toAnnounce
        );
        this.announcer.announce(translatedLabel);
    }

    annonuceRemove(...list: string[]) {
        const toAnnounce = this.listToString(list);
        const translatedLabel = this.translation(
            'research.aria.description.announce.removed',
            toAnnounce
        );
        this.announcer.announce(translatedLabel);
    }

    labelTranslation(label: string, values?: CodeTableLabelValues) {
        const translatedLabel = this.translate.instant(label, values);
        this.announcer.announce(translatedLabel);
    }

    clearAnnouncement() {
        this.announcer.clear();
    }
}

interface CodeTableLabelValues {
    [key: string]: string;
}
