import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { MomentModule } from 'ngx-moment';
import {
    ChatBubbleComponent,
    ChatComponent,
    ChatDialogComponent as MuloChatDialogComponent,
    ChatEntryComponent,
    ChatInputComponent,
    InputExpanderDirective,
} from '@exl-ng/mulo-common';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        MomentModule,
        InputExpanderDirective,
        ChatComponent,
        ChatBubbleComponent,
        MuloChatDialogComponent,
        ChatEntryComponent,
        ChatInputComponent,
        ChatDialogComponent,
    ],
    exports: [ChatDialogComponent],
})
export class ChatModule {}
