import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  output,
  viewChild,
  viewChildren
} from '@angular/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';


@Component({
    selector: 'mulo-scrollable-toggle-group',
    templateUrl: './scrollable-toggle-group.component.html',
    styleUrls: ['./scrollable-toggle-group.component.scss'],
    imports: [
        MatIconButton,
        MatIcon,
        MatButtonToggleGroup,
        MatButtonToggle
    ]
})
export class ScrollableToggleGroupComponent implements OnChanges {
  readonly buttonsKeys = input([]);
  readonly buttonsKeysAriaLabels = input([]);
  readonly selectedButton = input(undefined);
  readonly canAdd = input(true);
  readonly scrollLeftBtnAriaLabel = input('Scroll Backward');
  readonly scrollRightBtnAriaLabel = input('Scroll Forward');
  readonly buttonGroupLabel = input('Languages');

  readonly selectedButtonChanged = output();
  readonly scrollerWrapper = viewChild<ElementRef>('scrollerWrapper');
  readonly buttons = viewChildren(MatButtonToggle);

  _disableScrollBefore = true;
  _disableScrollAfter = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.buttonsKeys) {
      setTimeout(() => {
        this._checkScrollingControls();
      });
    }
    if (changes.selectedButton) {
      this.moveToSelected();
    }
  }

  moveToSelected() {
    if (!this.buttons()) return;
    setTimeout(() => {
      const el = this.buttons().find((item) => item.checked)?._buttonElement
        .nativeElement.parentElement.offsetLeft;
      this.scrollerWrapper().nativeElement.scrollLeft = el;
      this._checkScrollingControls();
    }, 100);
  }

  scroll(event, direction: string) {
    const scrollWrapper = this.scrollerWrapper().nativeElement;
    scrollWrapper.scrollLeft += direction === 'right' ? 100 : -100;
    this._checkScrollingControls();
  }

  _getMaxScrollDistance(): number {
    const scrollWrapper = this.scrollerWrapper().nativeElement;
    const buttonsSize = this.buttons().reduce(
      (accumulator, obj) =>
        accumulator +
        obj._buttonElement.nativeElement.parentElement.offsetWidth,
      0
    );
    // get how much of menu is invisible
    return buttonsSize - scrollWrapper.offsetWidth || 0;
  }

  _checkScrollingControls() {
    const scrollerWrapper = this.scrollerWrapper();
    if (!scrollerWrapper) return;
    // Check if the pagination arrows should be activated.
    this._disableScrollBefore =
      scrollerWrapper.nativeElement.scrollLeft <= 8;
    this._disableScrollAfter =
      scrollerWrapper.nativeElement.scrollLeft >=
      this._getMaxScrollDistance();
  }
}
