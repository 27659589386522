import { Component, OnInit, HostBinding, ElementRef, Renderer2, AfterContentInit, inject, input, contentChild } from '@angular/core';
import { ColorComponentMixinBase } from '../../common-behavior/color';
import { BrandComponent } from '../brand/brand.component';

@Component({
    selector: 'mulo-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
})
export class HeaderComponent extends ColorComponentMixinBase
  implements OnInit, AfterContentInit {
  private renderer = inject(Renderer2);

  //extends _HeaderComponentMixinBase
  readonly themeColor = input<'primary' | 'accent' | 'secondary' | 'tertiary' | 'none'>(undefined);
  readonly backgroundColor = input<string>(undefined);
  readonly textColor = input<string>(undefined);
  readonly height = input(64);
  @HostBinding('class') hostThemeClass = 'mulo-header';
  @HostBinding('style.backgroundColor') customBackgroundColor;
  @HostBinding('style.color') customTextColor;

  readonly logo = contentChild(BrandComponent, { read: ElementRef });

  constructor() {
    const elementRef = inject(ElementRef);

    super(elementRef);
  }

  ngOnInit() {
    if (this.themeColor()) {
      this.setHostThemeClass();
    } else if (this.backgroundColor() || this.textColor()) {
      this.setCustomColors();
    }
  }

  ngAfterContentInit() {
    const logo = this.logo();
    if (logo?.nativeElement?.parentNode?.localName === 'a') {
      this.renderer.addClass(
        logo.nativeElement.parentNode.parentNode,
        'brand-wrapper'
      );
    }
  }

  setCustomColors() {
    this.customBackgroundColor = this.backgroundColor();
    this.customTextColor = this.textColor();
  }

  setHostThemeClass() {
    const themeColor = this.themeColor();
    if (themeColor === 'none') {
      this.hostThemeClass = 'hasnt-background';
    } else {
      this.hostThemeClass = `palette-${themeColor}`;
    }
  }
}
