import {
  Component,
  HostBinding,
  HostListener,
  Input,
  inject,
  input,
  output,
} from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService, SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MomentModule } from 'ngx-moment';
import { Dir } from '@angular/cdk/bidi';
import {
  ElementAsHeadingDirective,
  HtmlSanitizePipe,
  ThumbnailComponent,
} from '@exl-ng/mulo-common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'mulo-brief',
  templateUrl: './brief.component.html',
  styleUrls: ['./brief.component.scss'],
  imports: [
    ThumbnailComponent,
    Dir,
    ElementAsHeadingDirective,
    RouterLink,
    HtmlSanitizePipe,
    MomentModule,
    MatIcon,
    SvgViewboxDirective,
  ],
})
export class BriefComponent {
  media = inject(MediaService);
  private sanitizer = inject(DomSanitizer);

  @HostBinding('class.mulo-brief') hostClass = true;
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.is-minimal') @Input() minimal = false;
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.is-hidden') @Input() hidden = false;
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.is-disabled') @Input() disabled = false;
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.is-hoverable') @Input() hoverable = true;
  @HostBinding('class.is-mouseover') mouseOver = false;
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.is-title-cutoff') @Input() titleCutoff = false;

  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() type;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() postType;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() title;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() titleUrl;
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  titleUrlExternalIcon = input(false);
  @Input() thumbs: any[];
  readonly thumbsWidth = input('50px');
  readonly titleAsLink = input(false);
  readonly titleClickable = input<boolean>(undefined);
  readonly titleHeaderLevel = input<number>(undefined);
  readonly images = input<any[]>(undefined);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() status: string | string[];
  readonly created = input<Date>(undefined);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() modified: Date;
  readonly dateLabel = input<string>(undefined);
  readonly href = input<string>(undefined);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() routerLinkUrl: string;
  readonly queryParams = input<Params>(undefined);
  readonly id = input(Math.random().toString(36).substring(2));

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() labelOpenInNewWindow: string;

  highlightHover = false;
  maxVisibleAuthors = 10;
  hostMinHeight;

  readonly titleClick = output();
  readonly actionClick = output();
  readonly authorClick = output();

  @HostListener('mouseover') onMouseEnter() {
    this.mouseOver = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseOver = false;
  }

  constructor() {
    this.labelOpenInNewWindow = `View ${this.title}, opens in a new window`;
  }

  onTitleClick(event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.titleClick.emit(event);
  }

  onThumbLoad(height) {
    this.hostMinHeight = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${height}px + 4rem)`,
    );
  }
}
