<quill-editor
  [modules]="defaultModule"
  (onContentChanged)="emitChangedContent($event)"
  [theme]="editorTheme"
  [formControl]="editorContent"
  [class.has-sneaky-toolbar]="sneakyToolbar()"
  [class.has-content]="editorContent?.value"
  [class.is-disabled]="disabled()"
  [class.is-floating-label]="!!placeholder()"
  [placeholder]="placeholder()"
  [readOnly]="disabled()"
  [required]="required()"
  (onEditorCreated)="a11yRichText.onEditorCreated($event)"
  #a11yRichText="muloRichTextA11y"
  muloRichTextA11y
  [aria-label]="ariaLabel()"
>
</quill-editor>
@if (editorContent?.invalid && (editorContent?.dirty || editorContent?.touched)) {
  <div id="ql-error" class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align">
    <div class="mat-mdc-form-field-error-wrapper">
      @for (error of editorContent.errors | keyvalue; track error.key) {
        @switch (error.key) {
          @case ('required') {
            <mat-error [innerHTML]="requiredError()"></mat-error>
          }
        }
      }
    </div>
  </div>
}
