import {
  Component,
  OnInit,
  input,
  output,
  viewChild
} from '@angular/core';

import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'exl-picker-item',
    templateUrl: './picker-item.component.html',
    styleUrls: ['./picker-item.component.scss'],
    imports: [MatMenu, MatMenuItem, MatMenuTrigger],
})
export class PickerItemComponent implements OnInit {
    readonly items = input(undefined);
    readonly selected = output<any>();
    readonly childMenu = viewChild('childMenu');

    constructor() {}

    ngOnInit() {}

    onMenuItemClick(item) {
        this.selected.emit(item);
    }
}
