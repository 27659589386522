<mulo-horizontaller
  [buttonWidth]="16"
  [minHeight]="80"
  [placeholderWidth]="160"
  [placeholders]="images()?.length"
  [rtl]="rtl()"
  verticalAlign="center"
  >
  @for (img of images(); track img; let i = $index) {
    <mulo-horizontaller-item [minWidth]="0" class="margin-right-sm">
      <figure>
        <button (click)="openDialog(i)" [attr.aria-expanded]="currentFullImgIdx === i" class="image-btn">
          <img (load)="imgLoaded(img.src)" [alt]="img.alt || img.caption" [src]="img.src" />
        </button>
        @if (img.caption) {
          <figcaption>{{ img.caption }}</figcaption>
        }
      </figure>
    </mulo-horizontaller-item>
  }
</mulo-horizontaller>
<ng-template #dialogTmpl>
  <button [aria-label]="closeBtnAriaLabel()" class="is-large close-button" mat-dialog-close mat-icon-button>
    <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
  </button>

  <button
    (click)="currentFullImgIdx = currentFullImgIdx - 1"
    [attr.aria-label]="scrollLeftBtnAriaLabel()"
    [disabled]="currentFullImgIdx === 0"
    class="is-large scroll-button scroll-button-left"
    mat-icon-button
    >
    <mat-icon class="mat-icon-rtl-mirror" muloSvgViewbox svgIcon="chevron-left"></mat-icon>
  </button>
  @if (images()[currentFullImgIdx]; as img) {
    <figure>
      <img [alt]="img.alt || img.caption" [src]="img.src" />
      @if (img.caption) {
        <figcaption @heightInAnimation @heightOutAnimation>
          <span>{{ img.caption }}</span>
        </figcaption>
      }
    </figure>
  }
  <button
    (click)="currentFullImgIdx = currentFullImgIdx + 1"
    [attr.aria-label]="scrollRightBtnAriaLabel()"
    [disabled]="currentFullImgIdx === images().length - 1"
    class="is-large scroll-button scroll-button-right"
    mat-icon-button
    >
    <mat-icon class="mat-icon-rtl-mirror" muloSvgViewbox svgIcon="chevron-right"></mat-icon>
  </button>
</ng-template>
