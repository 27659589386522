import { Component, ElementRef, HostBinding, Input, NgZone, inject, input, output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { Platform } from '@angular/cdk/platform';
import { IntervalerDirective } from '../../directives/intervaler.directive';

import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'mulo-funky-button, [mulo-funky-button]',
    templateUrl: './funky-button.component.html',
    styleUrls: ['./funky-button.component.scss'],
    host: {
        class: 'mulo-funky-button has-icon is-loud is-funky-cool is-rounded mdc-button mdc-button--raised mat-mdc-raised-button mat-mdc-button-base',
        '[class.jelly-on-hover]': 'jellyOnHover',
        '[class.is-large]': 'isLarge()',
        '[disabled]': 'disabled',
        '[attr.aria-label]': 'ariaLabel()',
        '(click)': 'buttonClick.emit($event)',
    },
    imports: [
        MatRipple,
        IntervalerDirective
    ]
})
export class FunkyButtonComponent extends MatButton {
  _elementRef: ElementRef;

  readonly gleam = input(true);
  readonly classList = input('', { alias: "class" });
  readonly ariaLabel = input<string>(undefined);
  readonly buttonClick = output({ alias: 'clicked' });

  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.jelly-on-hover') @Input() jellyOnHover = true;
  readonly isLarge = input(false);

  constructor() {
    const platform = inject(Platform);
    const _elementRef = inject(ElementRef);
    const _ngZone = inject(NgZone);
    const animationMode = inject(ANIMATION_MODULE_TYPE, { optional: true });

    super(_elementRef, platform, _ngZone, animationMode);
    this._elementRef = _elementRef;

  }
}
