import {
  Component,
  ChangeDetectionStrategy,
  Directive,
  Input,
  input,
  contentChild
} from '@angular/core';


@Directive({
    selector: '[slot="label"]',
    standalone: true
})
export class DictionaryItemLabelDirective {}

@Directive({
    selector: '[slot="content"]',
    standalone: true
})
export class DictionaryItemContentDirective {}

@Component({
    selector: '[mulo-dictionary-item]',
    templateUrl: './dictionary-item.component.html',
    styleUrls: ['./dictionary-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-dictionary-item' },
    imports: []
})
export class DictionaryItemComponent {
  readonly labelView = contentChild(DictionaryItemLabelDirective);
  readonly contentView = contentChild(DictionaryItemContentDirective);

  readonly label = input<string>(undefined);
  readonly content = input<any>(undefined);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() labelAlign: 'left' | 'right' = 'right';

  constructor() {}

  changeAlignment(dir) {
    this.labelAlign = dir;
  }
}
