import {
  Component,
  Input,
  ChangeDetectorRef,
  HostListener,
  inject,
  input,
  output
} from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { FilePreviewDialogComponent } from '../../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { File } from '../../../../shared/interfaces/file.model';
import { CdkAccordion } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { ExpansionPanelComponent } from '@exl-ng/mulo-common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';

@Component({
    selector: 'exl-file-list-item',
    templateUrl: './file-list-item.component.html',
    styleUrls: ['./file-list-item.component.scss'],
    host: {
        class: 'exl-file-list-item',
    },
    animations: [
        trigger('scaleInOut', [
            transition(':enter', [
                style({
                    transform: 'scale(.8)',
                    opacity: 0,
                }),
                animate(
                    '600ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    style({
                        transform: 'scale(1)',
                        opacity: 1,
                    }),
                ),
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1)',
                    height: '*',
                    opacity: 1,
                }),
                animate(
                    '300ms cubic-bezier(0.895, 0.03, 0.685, 0.22)',
                    style({
                        transform: 'scale(.8)',
                        height: 0,
                        opacity: 0,
                    }),
                ),
            ]),
        ]),
    ],
    imports: [
        MatButton,
        MatIcon,
        SvgViewboxDirective,
        MatProgressBar,
        MatIconButton,
        TranslateModule,
    ],
})
export class FileListItemComponent extends ExpansionPanelComponent {
    filePreviewDialog = inject(MatDialog);

    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() item: File;
    readonly index = input<number>(undefined);
    readonly expandable = input(true);
    readonly minimal = input(false);
    readonly allPanelsExpanded = input(false);
    readonly progress = input<number>(undefined);
    readonly remove = output<any>();
    readonly edit = output<any>();

    panelOpenState: boolean;

    constructor() {
        super();
    }

    onRemove(event, item, index) {
        event.preventDefault();
        event.stopPropagation();
        const removedItem = { item: item, index: index };
        this.remove.emit(removedItem);
    }

    onEdit(event, item) {
        event.preventDefault();
        event.stopPropagation();
        this.edit.emit(item);
    }

    onPreviewClick(event, file) {
        event.preventDefault();
        event.stopPropagation();
        this.filePreviewDialog.open(FilePreviewDialogComponent, {
            data: {
                file: file,
            },
        });
    }

    preventEditMetadataOpen(event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
