import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, take } from 'rxjs';
import { ContentDialogComponent } from './content-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ContentDialogService {
  private dialog = inject(MatDialog);


  private _dialogClosed = new Subject();
  public dialogClosed$ = this._dialogClosed.asObservable();

  openDialog(component: any, title: string) {
    this.dialog.open(ContentDialogComponent, {
      width: '960px',
      panelClass: 'esp-content-dialog',
      disableClose: true,
      data: {
        component: component,
        title: title,
      },
    }).afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.onDialogClosed();
      });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  /** Dialog closed */
  public onDialogClosed() {
    this._dialogClosed.next({
      event: 'dialogClosed',
    });
  }


}
