<h2 mat-dialog-title>{{ file.name }}</h2>
<mat-dialog-content>
  <!-- <img [src]="file.data" alt=""> -->
  <mulo-image-cropper
    [file]="file"
    (imageOutput)="onImageCropped($event)"
    (loadedOutput)="onImageLoaded()"
  ></mulo-image-cropper>
</mat-dialog-content>
<div mat-dialog-actions>
  <span class="action-spacer"></span>
  @if (data.actions.confirm) {
    <button mat-button class="is-large" color="accent" [mat-dialog-close]="preparedImage">
      {{ data.actions.confirm }}
    </button>
  }
  @if (data.actions.cancel) {
    <button mat-button [mat-dialog-close]="'cancel'">
      {{ data.actions.cancel }}
    </button>
  }
</div>
