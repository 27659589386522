import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { ScaleInOutAnimation } from '@exl-ng/mulo-common';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Status } from '../../../shared/configurations/status.constant';
import { DateUtils } from '../../../shared/utils/date.utils';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { StatusUtils } from '../../../shared/utils/status.utils';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositPublishedDateComponent } from '../deposit-published-date/deposit-published-date.component';
import { RequiredInputDirective } from '../../../shared/directives/input-required/input-required.directive';
import { DepositDatesComponent } from '../deposit-dates/deposit-dates.component';
import { DepositDoiComponent } from '../deposit-doi/deposit-doi.component';
import { DepositPublicationDetailsBookComponent } from '../deposit-status-details/deposit-publication-details-book/deposit-publication-details-book.component';
import { DepositPublicationDetailsConferenceProceedingComponent } from '../deposit-status-details/deposit-publication-details-conference-proceeding/deposit-publication-details-conference-proceeding.component';
import { DepositPublicationDetailsBookChapterComponent } from '../deposit-status-details/deposit-publication-details-book-chapter/deposit-publication-details-book-chapter.component';
import { DepositPublicationDetailsJournalArticleComponent } from '../deposit-status-details/deposit-publication-details-journal-article/deposit-publication-details-journal-article.component';

import { DepositPublicationStatusComponent } from '../deposit-publication-status/deposit-publication-status.component';
import { DepositFormSectionComponent } from '../../deposit-form-section/deposit-form-section.component';

@Component({
    selector: 'esp-deposit-status',
    templateUrl: './deposit-status.component.html',
    styleUrls: ['./deposit-status.component.scss'],
    animations: [ScaleInOutAnimation],
    imports: [
        DepositFormSectionComponent,
        DepositPublicationStatusComponent,
        DepositPublicationDetailsJournalArticleComponent,
        DepositPublicationDetailsBookChapterComponent,
        DepositPublicationDetailsConferenceProceedingComponent,
        DepositPublicationDetailsBookComponent,
        DepositDoiComponent,
        DepositDatesComponent,
        RequiredInputDirective,
        DepositPublishedDateComponent,
        CustomTranslatePipe
    ]
})
export class DepositStatusComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private dateDestroy = new Subject<void>();
  readonly statusConst = Status;

  readonly required = input(undefined);

  dateTitleOptions = {
    unpublished: '',
    posted: 'research.status.post',
    submitted: 'research.status.submit',
    accepted: 'research.status.accept',
    epub: 'research.status.online',
    published: 'research.status.publish',
  };

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.date.valueChanges
      .pipe(takeUntil(this.dateDestroy))
      .subscribe((value) => {
        if (this.status === this.statusConst.PUBLISHED) {
          return;
        }
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.DATE
          );
        }
        if (!this.depositFormDataService.date.value) {
          this.depositFormDataService.updateYear('');
          this.depositFormDataService.updateMonth('');
          this.depositFormDataService.updateDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.date.value
          );
          this.depositFormDataService.updateYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  displayMoreInformation() {
    return (
      this.status &&
      this.status !== Status.UNPUBLISHED &&
      this.status !== Status.POSTED
    );
  }

  displayDate() {
    return this.status && this.status !== Status.UNPUBLISHED;
  }

  get isDateAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.DATE
    );
  }

  dateTitle() {
    return this.dateTitleOptions[this.status];
  }

  get status() {
    return this.depositFormDataService.status.value;
  }

  isJournalArticle() {
    return this.depositFormDataService.isPublicationJournalArticle();
  }

  isBookReview() {
    return this.depositFormDataService.isPublicationBookReview();
  }

  isBookChapter() {
    return this.depositFormDataService.isPublicationBookChapter();
  }

  isConferenceProceedings() {
    return this.depositFormDataService.isPublicationConferenceProceedings();
  }

  isBook() {
    return this.depositFormDataService.isPublicationBook();
  }

  ngOnDestroy() {
    if (!this.dateDestroy.isStopped) {
      this.dateDestroy.next();
      //unsubscribe from the subject itself
      this.dateDestroy.unsubscribe();
    }
  }
}
