import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  input,
  output
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { componentDestroyed, SvgViewboxDirective } from '@exl-ng/mulo-core';
import { merge, takeUntil } from 'rxjs';

import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

interface NumericalFacet {
  from: number;
  to: number;
  min?: number;
  max?: number;
}

@Component({
    selector: 'mulo-facet-numerical',
    templateUrl: './facet-numerical.component.html',
    styleUrls: ['./facet-numerical.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-facet-numerical',
    },
    imports: [
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        ReactiveFormsModule,
        MatIcon,
        SvgViewboxDirective
    ]
})
export class FacetNumericalComponent implements OnInit, OnDestroy, OnChanges {
  readonly min = input(undefined);
  readonly max = input(undefined);
  readonly fromLabel = input('From');
  readonly toLabel = input('To');
  readonly fromAriaLabel = input('From year');
  readonly toAriaLabel = input('To year');
  readonly labelledBy = input<string>(undefined);

  readonly facetChange = output<NumericalFacet>();
  readonly facetSubmit = output<NumericalFacet>();

  fromControl: UntypedFormControl;
  toControl: UntypedFormControl;
  initialValues: NumericalFacet;

  private _from = 0;
  private _to = 0;

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input()
  get from(): number {
    return this._from;
  }
  set from(value: number) {
    this._from = typeof value === 'number' ? value : parseInt(value, 10);
  }

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input()
  get to(): number {
    return this._to;
  }
  set to(value: number) {
    this._to = typeof value === 'number' ? value : parseInt(value, 10);
  }

  ngOnInit() {
    this.initialValues = {
      min: this.min(),
      max: this.max(),
      from: this.from,
      to: this.to,
    };
    this.fromControl = new UntypedFormControl(this.from);
    this.toControl = new UntypedFormControl(this.to);

    merge(this.fromControl.valueChanges, this.toControl.valueChanges)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(() => {
        this.facetChange.emit(this.currentValues);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const f = changes.from;
    const t = changes.to;
    if (f && !f.firstChange && f.currentValue !== f.previousValue) {
      this.fromControl.setValue(f.currentValue, { emitEvent: false });
    }
    if (t && !t.firstChange && t.currentValue !== t.previousValue) {
      this.toControl.setValue(t.currentValue, { emitEvent: false });
    }
  }

  ngOnDestroy() {}

  public reset() {
    this.fromControl.setValue(this.initialValues.from, { emitEvent: false });
    this.toControl.setValue(this.initialValues.to, { emitEvent: false });
  }

  doSubmit() {
    this.facetSubmit.emit(this.currentValues);
  }

  public get currentValues() {
    return { from: this.fromControl.value, to: this.toControl.value };
  }
}
