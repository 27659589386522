<esp-deposit-form-section class="margin-top-zero margin-neg-bottom-sm">
  <span [innerHTML]="'research.deposit.intro' | translate"></span>
</esp-deposit-form-section>
<esp-deposit-form-section
  sectionTitle="{{ 'research.assetype.title' | translate }}"
  [required]="true"
  [exlCommonOnboarding]="false"
  exlCommonOnboardingText="research.begin.message"
  exlOnboardingButtonText="research.begin.button"
  [exlCommonOnboardingScale]="0"
  class="margin-neg-bottom-sm"
  >
  <esp-deposit-type (typeSelected)="onTypeSelection($event)" (clearSelection)="onClearSelection()"></esp-deposit-type>
</esp-deposit-form-section>

@if (isTypeSelected && displaySearchFor()) {
  <esp-deposit-form-section
    sectionTitle="{{ getSearchForTitle() | customTranslate }}"
    description="{{ getSearchForDescription() | customTranslate }}"
    >
    @if (selectedResearch) {
      <esp-deposit-selected-research
        [research]="selectedResearch"
        (closeDeposit)="closeDeposit.emit($event)"
        (removeSelected)="onRemoveSelectedWork()"
        [@growInAnimation]="{ value: ':enter', params: { time: '0.7s' } }"
        [@growOutAnimation]="{ value: ':enter', params: { time: '0.7s' } }"
        >
      </esp-deposit-selected-research>
    }
    @if (!selectedResearch) {
      <esp-deposit-search-for
        (selectedResearch)="onResearchSelection($event)"
        [userId]="userId"
        @growInAnimation
        @growOutAnimation
        class="margin-neg-bottom-sm"
        >
      </esp-deposit-search-for>
    }
  </esp-deposit-form-section>
}

@for (component of tab1(); track component) {
  @if (displayFileUploader(component)) {
    <esp-deposit-form-section
      sectionTitle="{{ 'research.files.title' | customTranslate }}"
      [required]="isRequired(component)"
      >
      <esp-deposit-files [multiple]="allowMultipleFiles" [jump]="jumpToFiles()"></esp-deposit-files>
    </esp-deposit-form-section>
  }
}
