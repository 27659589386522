import {
  Component,
  Input,
  ContentChild,
  Directive,
  input,
  output,
  contentChild
} from '@angular/core';
import {
  WidthInAnimation,
  WidthOutAnimation,
  shortenLargeNumber,
} from '@exl-ng/mulo-common';
import { FacetNumericalComponent } from '../facet-numerical/facet-numerical.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';


@Directive({
    selector: '[slot="facet-content"]',
    standalone: true
})
export class FacetItemContentDirective {}

@Component({
    selector: 'mulo-facet-item',
    templateUrl: './facet-item.component.html',
    styleUrls: ['./facet-item.component.scss'],
    host: {
        class: 'mulo-facet-item',
        '[class.is-checked]': 'hasCheckbox ? checked : false',
        '[class.is-excluded]': 'excluded',
        '[class.is-hovered]': 'mouseOver',
    },
    animations: [WidthInAnimation, WidthOutAnimation],
    imports: [
        MatCheckbox,
        MatButton,
        MatIconButton,
        MatIcon
    ]
})
export class FacetItemComponent {
  readonly key = input<string>(undefined);
  readonly label = input<string>(undefined);
  readonly id = input<string>(undefined);

  readonly abbreviateCount = input(true);
  readonly countDecimalPlaces = input(1);
  private _count: string | number = '';

  readonly excludeAriaLabel = input('Exclude');
  readonly selectArialLabel = input('Select');

  readonly clicked = output<null>();

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() hasCheckbox = true;
  private _checked = false;
  readonly checkedChange = output<boolean>();
  checkboxDisabled = false;
  private _excluded = false;
  readonly excludedChange = output<boolean>();

  // TODO: Skipped for migration because:
  //  Your application code writes to the query. This prevents migration.
  @ContentChild(FacetItemContentDirective) content;
  readonly numericalFacet = contentChild(FacetNumericalComponent);

  mouseOver = false;

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() public get count(): string | number {
    return this._count;
  }
  public set count(count: string | number) {
    if (typeof count === 'string') {
      parseInt(count);
    }
    this._count = this.abbreviateCount()
      ? shortenLargeNumber(count, this.countDecimalPlaces())
      : count;
  }

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() public get checked(): boolean {
    return this._checked;
  }
  public set checked(value: boolean) {
    this._checked = value;
    if (!value && this.excluded) {
      this.excluded = false;
    }
    // this.checkedChange.emit(value)
  }

  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() public get excluded(): boolean {
    return this._excluded;
  }
  public set excluded(value: boolean) {
    this._excluded = value;
  }

  constructor() {}

  ngAfterContentInit() {
    if (this.numericalFacet()) {
      this.content = true;
    }
  }

  handleButtonClick() {
    this.clicked.emit(null);
  }

  handleCheckedChange(ev) {
    this.checked = ev.checked;
    this.checkedChange.emit(ev.checked);
    // this.change.emit(null)
  }

  handleExcludeClick() {
    this.excluded = this.checked ? !this.excluded : true;
    this.excludedChange.emit(this.excluded);
  }

  onCheckboxClick(ev) {
    this.checked = !this.checked;
    this.handleCheckedChange({ checked: this.checked });
  }

  onCheckboxPadClick(ev) {
    ev.stopPropagation;
    if (!this.checkboxDisabled) {
      this.onCheckboxClick(ev);
    }
  }

  disableCheckbox(value: boolean) {
    this.checkboxDisabled = value;
  }
}
