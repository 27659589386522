import { Component, AfterContentInit, HostListener, inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { AnimatedCheckmarkComponent } from '@exl-ng/mulo-common';

@Component({
    selector: 'exl-success-snackbar',
    templateUrl: './success-snackbar.component.html',
    styleUrls: ['./success-snackbar.component.scss'],
    host: { 'class': 'exl-snackbar' },
    imports: [AnimatedCheckmarkComponent, TranslateModule]
})
export class SuccessSnackbarComponent implements AfterContentInit {
    private snackBarRef = inject<MatSnackBarRef<SuccessSnackbarComponent>>(MatSnackBarRef);
    data = inject(MAT_SNACK_BAR_DATA);


    showAnimation = false;

    @HostListener('click', ['$event'])
    clickInside(event) {
        event.stopPropagation();
    }
  
    @HostListener('document:click')
    clickOutside() {
        this.close();
    }


    ngAfterContentInit(){
        setTimeout(() => {
            this.showAnimation = true;
        }, 0);
    }

    close(){
        this.snackBarRef.dismiss();
    }

}
