@if (icon) {
  <div class="type-icon">
    @if (alertType()) {
      <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
    }
  </div>
}

<div class="content">
  @if (title) {
    <h3 class="title">
      @if (titleUrl) {
        <a
          [href]="titleUrl"
          attr.aria-label="{{ 'research.aria.brief.display.open.url' | translate }}"
          >
          {{ title }}
        </a>
      }
      @if (!titleUrl) {
        {{ title }}
      }
    </h3>
  }

  <!-- display lines -->
  @for (line of lines(); track line) {
    <p class="line">{{ line }}</p>
  }

  <ng-content></ng-content>
</div>

@if (action()) {
  <div class="action">
    <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-right"></mat-icon>
  </div>
}
