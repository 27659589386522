import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositFreeTextComponent } from '../deposit-free-text/deposit-free-text.component';

@Component({
    selector: 'esp-deposit-methods',
    templateUrl: './deposit-methods.component.html',
    styleUrls: ['./deposit-methods.component.scss'],
    imports: [DepositFreeTextComponent, CustomTranslatePipe]
})
export class DepositMethodsComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private methodsDestroy = new Subject<void>();

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.methods.valueChanges
      .pipe(takeUntil(this.methodsDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.METHODS
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.METHODS
    );
  }

  ngOnDestroy() {
    if (!this.methodsDestroy.isStopped) {
      this.methodsDestroy.next();
      //unsubscribe from the subject itself
      this.methodsDestroy.unsubscribe();
    }
  }
}
