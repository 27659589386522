import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class RestUtilService {
    private http = inject(HttpClient);


    public postToServlet(url, data) {
        const headers = new HttpHeaders();
        let params = new HttpParams();
        headers.append(
            'Content-Type',
            'application/x-www-form-urlencoded; charset=utf-8'
        );
        const urlParams = Object.keys(data);
        urlParams.forEach((param) => {
            params = params.set(param, data[param]);
        });
        return this.http.post(url, data, { headers });
    }
}
