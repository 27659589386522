<div class="export-scope-item margin-bottom-sm">
  @if (computedScope() !== 'single') {
    <div class="small-caps">{{ labels.scope }}</div>
    <h2 class="semibold">
      @switch (computedScope()) {
        @case ('selected') {
          {{ labels.selected }}<span> ({{ selectedCount() }})</span>
        }
        @case ('all') {
          {{ labels.all }} <span> ({{ totalCount() }})</span>
        }
      }
    </h2>
  } @else if (asset()) {
    <div class="small-caps">{{ asset().type }}</div>
    <h2 class="semibold" [innerHTML]="asset().title | htmlSanitize"></h2>
  }
</div>

<div
  class="export-target-selector margin-bottom-xs exporter-buttons-group"
  role="group"
  [attr.aria-labelledby]="titleId()"
  aria-label="Export Asset"
>
  <span class="exporter-buttons-leading-text">{{ labels.to }}</span>
  <div class="overflow-hidden">
    <div
      mat-tab-nav-bar
      #selectors
      class="as-selector is-scaling hasnt-underline margin-left-sm"
      [mat-stretch-tabs]="false"
      [tabPanel]="tabPanel"
    >
      @for (target of exportTargets(); track target; let i = $index) {
        <button
          mat-tab-link
          [active]="(selectedExportTarget$ | async)?.value === target.value"
          [class.has-icon]="target.icon"
          (click)="setExportTarget(target)"
          class="export-target-button margin-right-xs"
        >
          @if (target.icon) {
            <mat-icon [svgIcon]="target.icon" muloSvgViewbox></mat-icon>
          }
          @if (target.name) {
            <span class="export-target-label">{{ target.name }}</span>
          }
        </button>
      }
    </div>
  </div>
</div>
@if (selectedExportTarget$ | async; as selectedExportTarget) {
  @if (selectedExportTarget.targetOptions || selectedExportTarget.preview) {
    <div class="options" @heightInAnimation @heightOutAnimation>
      @if (selectedExportTarget.targetOptions; as targetOptions) {
        <div class="options-container-wrapper" @heightInAnimation @heightOutAnimation>
          <div class="options-container">
            @for (field of targetOptions; track field; let i = $index) {
              <div [class.margin-right-md]="i != targetOptions.length - 1">
                <mat-form-field class="hasnt-errors semibold has-dynamic-width">
                  <mat-label>{{ field.label }}</mat-label>
                  @switch (field.type) {
                    @case ('select') {
                      <mat-select [value]="field.selected" (valueChange)="onOptionsChange(field, $event)">
                        @for (item of $any(field).items; track item.value) {
                          <mat-option [value]="item.value" class="semibold">
                            {{ item.label }}
                          </mat-option>
                        }
                      </mat-select>
                    }
                    @default {
                      <input
                        [type]="field.type"
                        matInput
                        [value]="field.value ?? ''"
                        (change)="onOptionsChange(field, $event)"
                        [placeholder]="field.placeholder ?? ' '"
                        [formControl]="getFieldControl(field)"
                        [autocomplete]="field.autocomplete"
                      />
                    }
                  }
                  <mat-error>{{ field.errorMessage }}</mat-error>
                </mat-form-field>
              </div>
            }
          </div>
          @if (actionSuccess$ | async; as msg) {
            @if (msg !== '') {
              <div cdkAriaLive="polite" class="target-action-success-msg" @widthInAnimation @opacityOutAnimation>
                <mulo-animated-checkmark [animate]="true"></mulo-animated-checkmark>
                {{ msg }}
              </div>
            }
          }
        </div>
      }
      <!-- Output preview container -->
      @if (selectedExportTarget.preview; as preview) {
        <div @heightInAnimation @heightOutAnimation>
          <mulo-copier
            display="wrapping"
            [copyLabel]="labels.copy"
            [copiedLabel]="labels.copied"
            [content]="outputPreview$ | async"
            [copyMethod]="['code', 'plain'].includes(outputStyle$ | async) ? 'plain' : 'rich'"
            [processing]="loadingPreview()"
            (copied)="onOutputCopied()"
          >
            <div class="output" [class.processing]="loadingPreview()">
              <pre
                #output
                class="output-content"
                [class.isnt-preformatted]="!['code', 'plain'].includes(outputStyle$ | async)"
                [innerHTML]="outputPreview$ | async"
              ></pre>
              @if (loadingPreview()) {
                <mat-progress-spinner class="spinner" mode="indeterminate" diameter="50"> </mat-progress-spinner>
              }
            </div>
          </mulo-copier>
          @if (selectedExportTarget?.afterPreviewNote; as note) {
            <div @heightInAnimation @heightOutAnimation class="overflow-hidden">
              <mulo-infobar
                class="margin-top-xs overflow-hidden"
                color="warn"
                icon="alert-outline"
                corners="rounded"
                appearance="slim"
              >
                {{ note }}
              </mulo-infobar>
            </div>
          }
        </div>
      }
    </div>
  }
}
<!-- Action button for when component is used not in a dialog context -->
@if (!inDialog()) {
  <div class="action-btn-wrap margin-top-xs">
    <span class="action-btn-spacer"></span>
    @for (action of targetActions$ | async; track action; let i = $index) {
      @if ({ ad: action.disabled$ | async }; as disabledObj) {
        @if (!action.url) {
          <button
            mat-button
            color="primary"
            [disabled]="disabledObj.ad || loadingPreview()"
            [class.is-large]="i === 0"
            (click)="onActionClick(action)"
          >
            {{ action.label }}
          </button>
        }
        @if (action.url) {
          <a
            mat-button
            color="primary"
            [disabled]="disabledObj.ad || loadingPreview()"
            [href]="action.url"
            [class.is-large]="i === 0"
            target="_blank"
          >
            {{ action.label }}
          </a>
        }
      }
    }
  </div>
}
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
