import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { UrlUtils } from '../shared/utils/url.utils';
import { ProfileService } from '../profile/profile.service';
import { Observable, map } from 'rxjs';
import { RouterService } from './router.service';

@Injectable()
export class ProfileGuard {
    private router = inject(Router);
    profileService = inject(ProfileService);
    private routerService = inject(RouterService);

    institutionCode = UrlUtils.getParam('institution');

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.hasActiveProfile(route.params.id);
    }

    hasActiveProfile(id): Observable<boolean> {
        return this.profileService.hasActiveProfile(id).pipe(
            map((res) => {
                const urlIdentifier = res as string;
                if (urlIdentifier === 'NULL') {
                    this.routerService.goToResearchersSearch();
                } else if (urlIdentifier !== id) {
                    this.routerService.goToProfile(urlIdentifier);
                }

                return true;
            }),
        );
    }
}
