import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  inject,
  input,
  output,
  viewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    MatDrawerContent,
    MatDrawerContainer,
    MatDrawer,
} from '@angular/material/sidenav';
import { CustomTranslatePipe } from '../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatAnchor } from '@angular/material/button';

@Component({
    selector: 'exl-navigable-text',
    templateUrl: './navigable-text.component.html',
    styleUrls: ['./navigable-text.component.scss'],
    host: { class: 'exl-navigable-text' },
    imports: [
        MatDrawerContainer,
        MatDrawer,
        MatButton,
        MatIcon,
        MatAnchor,
        MatDrawerContent,
        TranslateModule,
        CustomTranslatePipe,
    ],
})
export class NavigableTextComponent implements AfterViewInit {
    private elemRef = inject(ElementRef);
    private doc = inject(DOCUMENT);

    readonly html = input<string>(undefined);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() drawerOpened: boolean;
    readonly drawerClosed = output<boolean>();
    anchorsArray: Array<any> = new Array();
    anchorBaseName = 'anchor';

    readonly drawerContent = viewChild(MatDrawerContent);

    ngAfterViewInit() {
        this.enableFocusOnContentIfNeeded();

        const nodes = this.elemRef.nativeElement.querySelectorAll('h2');
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            node.setAttribute('id', this.anchorBaseName + i);
            this.anchorsArray.push(node.innerText);
        }
    }
    focusOnSection(event, index) {
        event.preventDefault();
        const content = this.doc.getElementsByTagName('mat-drawer-content')[0];
        content.scrollTop = this.doc.getElementById(
            this.anchorBaseName + index,
        ).offsetTop;
    }

    onDrawerClosed() {
        this.drawerClosed.emit(null);
    }

    private enableFocusOnContentIfNeeded() {
        const contentElement = this.drawerContent().getElementRef().nativeElement;
        // Check if has a scrollbar. Needed for keyboard users because there is no focusable element.
        if (contentElement.scrollHeight > contentElement.clientHeight) {
            contentElement.setAttribute('tabIndex', '0');
        }
    }
}
