<div>
  <div>
    @if (displayISSN()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfIssn')"
        class="is-short-input"
        >
        <input
          matInput
          [formControl]="depositFormDataService.isPartOfIssn"
                placeholder="{{
                    'research.deposit.searchable.fields.placeholder' | translate
                }}"
          />
        <mat-label>{{
          'research.status.issn' | customTranslate
        }}</mat-label>
        <mat-error>
          {{ 'research.status.issn.error' | customTranslate }}
        </mat-error>
        @if (isAutoPopulated('isPartOfIssn')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
        @if (searchIssnInProgress) {
          <mat-progress-bar
            mode="indeterminate"
            color="primary"
          ></mat-progress-bar>
        }
      </mat-form-field>
    }
    @if (displayISBN()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfIsbnForDisplay')"
        >
        <input
          matInput
          [formControl]="depositFormDataService.isPartOfIsbnForDisplay"
          />
        <mat-label>{{
          'research.status.isbn' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('isPartOfIsbnForDisplay')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
    @if (displayVolume()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfVolume')"
        class="is-short-input"
        >
        <input
          matInput
          [formControl]="depositFormDataService.isPartOfVolume"
          />
        <mat-label>{{
          'research.status.volume' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('isPartOfVolume')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
  </div>
  <div>
    @if (displayIssue()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfIssue')"
        class="is-short-input"
        >
        <input
          matInput
          [formControl]="depositFormDataService.isPartOfIssue"
          />
        <mat-label>{{
          'research.status.issue' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('isPartOfIssue')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayPages()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isPartOfPages')"
        class="is-short-input"
        >
        <input
          matInput
          [formControl]="depositFormDataService.isPartOfPages"
          />
        <mat-hint align="start">{{
          'research.status.page.hint' | customTranslate
        }}</mat-hint>
        <mat-label>{{
          'research.status.pages' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('isPartOfPages')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayPublisherName()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('publisher')"
        [muloInputExpander]="depositFormDataService.publisher.value"
        class="is-short-input"
        >
        <input matInput [formControl]="depositFormDataService.publisher" />
        <mat-label>{{
          'research.status.publisher' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('publisher')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
  </div>
</div>
