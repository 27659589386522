<exl-footer>
  <exl-centered-block-layout width="full">
    <div class="footer-frame">
      @if (displayLogo) {
        <div
          [class.has-links]="hasLinks"
          [class.has-many-links]="footerLinks.length > 2"
          [class.margin-right-md]="media.is('gt-sm') && hasLinks"
          class="footer-logo-link padding-sm"
          >
          <a
            [attr.aria-label]="'research.search.document.title' | translate: { value: institutionName }"
            [class.margin-right-md]="hasLinks"
            [href]="logoUrl$ | async"
            class="padding-bottom-md"
            >
            <mulo-brand [cdkAriaLive]="footerLogo"></mulo-brand>
          </a>
        </div>
      }
      @for (section of footerLinks; track section) {
        @if (section.arr.length) {
          <div class="links-section">
            @if (section.headline) {
              <h2 class="upper" [id]="'footerNav-' + section.headline.code">
                {{ 'esploro.research.conf.research.portal.label.prefix.' + section.headline.code | translate }}
              </h2>
            }
            <mat-nav-list
              [attr.aria-labelledby]="section.headline ? 'footerNav-' + section.headline?.code : null"
              >
              @for (link of section.arr; track link) {
                <a
                  [href]="link.url"
                  [target]="link.open === 'current' ? '_self' : '_blank'"
                  class="is-wrapping"
                  mat-list-item
                  >{{ 'esploro.research.conf.research.portal.label.prefix.' + link.code | translate }}</a
                  >
                }
              </mat-nav-list>
            </div>
          }
        }
        <div class="links-section">
          <h2 class="upper" id="footerNav-portalAndProfileIndexLinks">
            {{ 'esploro.research.conf.research.portal.label.prefix.portalAndProfileIndexLinks' | translate }}
          </h2>

          <mat-nav-list aria-labelledby="footerNav-portalAndProfileIndexLinks">
            @if (isProfileActivate) {
              <a
                mat-list-item
                queryParamsHandling="preserve"
                routerLink="/profiles"
                target="_self"
                >{{ 'esploro.research.conf.research.portal.label.prefix.profileIndexLink' | translate }}</a
                >
              }
              <a mat-list-item queryParamsHandling="preserve" routerLink="/output" target="_self">{{
                'esploro.research.conf.research.portal.label.prefix.portalIndexLink' | translate
              }}</a>
            </mat-nav-list>
          </div>
          <div class="padding-sm social-and-copyright">
            @if (socialMediaLinks.length) {
              <exl-footer-links class="margin-bottom-sm" role="none">
                <exl-footer-links-section [isHorizontalList]="true" maxWidth="100%" minWidth="100%">
                  <h2 class="cdk-visually-hidden" exl-footer-links-section-head>
                    {{ 'research.aria.footer.links.social.media' | translate: { value: institutionName } }}
                  </h2>
                  @for (link of socialMediaLinks; track link.code) {
                    <li>
                      <a
                        [href]="link.url"
                        [title]="link.code"
                        class="social-link"
                        mat-icon-button
                        target="_blank"
                        >
                        <mat-icon [svgIcon]="link.code.toLowerCase()"></mat-icon>
                      </a>
                    </li>
                  }
                </exl-footer-links-section>
              </exl-footer-links>
            }
            @if (displayCopyrights) {
              <p>{{ 'research.footer.rights' | translate }}</p>
            }
          </div>
        </div>
      </exl-centered-block-layout>
      @if (showPoweredBy) {
        <exl-centered-block-layout class="sub-footer padding-sm" width="none">
          <a [href]="ExlibrisLink" target="_blank" [innerHtml]="'research.footer.legal' | translate"></a>
        </exl-centered-block-layout>
      }
    </exl-footer>
