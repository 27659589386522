import { Injectable, inject } from '@angular/core';
import {DepositFormService} from '../deposit/deposit-form.service';
import {RestApiBase} from '../shared/configurations/rest-api-base.config';
import {RestUtilService} from './rest-util.service';
import {UrlUtils} from '../shared/utils/url.utils';


@Injectable()
export class LoginService {
    private depositFormService = inject(DepositFormService);
    private restUtilService = inject(RestUtilService);


    institutionCode: string;

    constructor(){
        this.institutionCode = UrlUtils.getParam('institution');
    }

    handleInternalLogin(username, password) {
        const data = {
            authenticationProfile: '',
            username: username,
            password: password,
            institution: this.institutionCode,
        };
        return this.restUtilService.postToServlet(RestApiBase.INTERNAL_LOGIN,data);
    }

    handleDecodeEncode(url, redirectUrl){
        redirectUrl = decodeURIComponent(decodeURIComponent(decodeURIComponent(redirectUrl)));
        redirectUrl = encodeURI(redirectUrl);
        redirectUrl = encodeURIComponent(redirectUrl);
        const backUrl = '&backUrl='+ redirectUrl;
        return  url + backUrl;
    }

}
