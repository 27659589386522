<div class="item-wrap" [attr.title]="label()" (mouseenter)="mouseOver = true" (mouseleave)="mouseOver = false">
  @if (hasCheckbox) {
    <mat-checkbox
      class="is-large"
      [class.is-excluded]="excluded"
      [checked]="checked"
      [disabled]="checkboxDisabled"
      [aria-label]="label()"
      [color]="excluded ? 'accent' : 'primary'"
      (change)="handleCheckedChange($event)"
    ></mat-checkbox>
  }
  <ng-content select="[slot='facet-content']"></ng-content>
  <ng-content select="mulo-facet-numerical"></ng-content>
  @if (!content) {
    <button mat-button (click)="handleButtonClick()" class="activate-button">
      @if (hasCheckbox) {
        <span class="label-spacer"></span>
      }
      <span class="label"
        ><span #buttonLabel class="label-inner" [id]="id() + '_label'">{{ label() }}</span
        >&nbsp;&nbsp;</span
        >
        <span class="count">{{ count }}</span>
      </button>
      <button
        mat-icon-button
        class="exclude-button is-small"
        [attr.aria-label]="excludeAriaLabel()"
        [attr.aria-describedby]="buttonLabel?.id"
        (click)="handleExcludeClick()"
        >
        <mat-icon
        [svgIcon]="
          !checked ? 'circle-off-outline' : !excluded ? 'checkbox-marked-off-outline' : 'checkbox-marked-outline'
        "
        ></mat-icon>
        <!-- [svgIcon]="checked && excluded ? 'checkbox-marked-outline' : (checked ? 'checkbox-marked-outline' : 'circle-off-outline' )" -->
      </button>
    }
  </div>
