import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { PlainTextInputComponent } from '../../../parts/plain-text-input/plain-text-input.component';

@Component({
    selector: 'esp-deposit-title',
    templateUrl: './deposit-title.component.html',
    styleUrls: ['./deposit-title.component.scss'],
    imports: [PlainTextInputComponent, CustomTranslatePipe]
})
export class DepositTitleComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private titleDestroy = new Subject<void>();
  readonly jump = input(undefined);

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.title.valueChanges
      .pipe(takeUntil(this.titleDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.TITLE
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.TITLE
    );
  }

  ngOnDestroy() {
    if (!this.titleDestroy.isStopped) {
      this.titleDestroy.next();
      //unsubscribe from the subject itself
      this.titleDestroy.unsubscribe();
    }
  }
}
