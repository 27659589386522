import {Directive, OnInit, OnDestroy, input} from '@angular/core';
import { UntypedFormControl, AbstractControl, Validators, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[exlRequiredInput]',
    standalone: true,
})
export class RequiredInputDirective implements OnInit, OnDestroy {

    readonly control = input<UntypedFormControl>(undefined, { alias: "exlRequiredInput" });
    readonly isRequired = input(true, { alias: "exlIsRequired" });

    private _originalValidators: ValidatorFn | null;

    set originalValidators(validator: ValidatorFn | null) {
        if (!this._originalValidators){
            this._originalValidators = validator;
        }
    }

    ngOnInit() {
        if (this.isRequired()){
            const control = this.control();
            if (control.validator) {
                const { validator } = control;
                this.originalValidators = validator;
                control.setValidators([Validators.required, this._originalValidators]);
                control.updateValueAndValidity();
            } else {
                control.setValidators([Validators.required]);
            }
        }
    }

    isRequiredControl = (control: AbstractControl): boolean => {
        const { validator } = control;
        if (validator) {
            const validation = validator(new UntypedFormControl());
            return validation !== null && validation.required === true;
        }
        return false;
    }

    ngOnDestroy(): void {
        if (this.isRequired()) {
            if (this._originalValidators){
                this.control().setValidators([this._originalValidators]);   // erase all current validators
            } else {
                this.control().clearValidators();
            }
            this.control().updateValueAndValidity();
        }
    }
}
