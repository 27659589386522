import {
  Directive,
  HostListener,
  HostBinding,
  inject,
  input,
  output,
} from '@angular/core';
import { FileDropService } from './file-drop.service';
import { ExtendedFile } from '../../models';

@Directive({
  selector: '[mulo-file-drop]',
  standalone: true,
})
export class FileDropDirective {
  private fileDropService = inject(FileDropService);

  readonly multiple = input<boolean>(true);
  readonly filesDroppedEmitter = output<File[]>();
  readonly filesInvalidEmitter = output<File[]>();
  readonly dragOverEmitter = output<boolean>();
  @HostBinding('class.is-drag-over') dragOver: boolean = false;
  @HostBinding('class.is-drag-leave') dragLeave: boolean = false;
  @HostBinding('class.is-drag-dropped') dragDrop: boolean = false;
  @HostBinding('class.files-added') filesAdded: boolean = false;

  delay = 2000;

  @HostListener('dragover', ['$event']) public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
    this.dragLeave = false;
    this.dragOverEmitter.emit(true);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragLeave = true;
    this.dragOverEmitter.emit(false);
    setTimeout(() => {
      this.dragOver = false;
    }, this.delay / 2);
    setTimeout(() => {
      this.dragLeave = false;
    }, this.delay);
  }

  @HostListener('drop', ['$event']) public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragDrop = true;
    this.dragOver = false;
    this.dragLeave = false;
    setTimeout(() => {
      this.dragDrop = false;
    }, this.delay);

    let filesList = event.dataTransfer.files;

    if (filesList.length > 0) {
      this.emitFiles(filesList);
    }
  }

  emitFiles(filesList) {
    this.filesAdded = true;
    this.filesDroppedEmitter.emit(filesList);
    // this.fileDropService.processFiles(files, this.allowed_extensions)
  }
}
