@if (options && options.length > 1) {
  <mat-form-field
    class="is-inline is-wrapping"
    [style.maxWidth]="maxWidth()"
    appearance="outline"
    >
    <mat-select [formControl]="_selectControl" class="italic semibold" [aria-label]="label()">
      @for (option of options; track option) {
        <mat-option [value]="option.value">{{ option.viewValue | translate }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
}

@if (!options || options.length === 1) {
  <div
    class="mulo-searchbar-static-label semibold italic"
    [style.maxWidth]="maxWidth()"
    >
    {{ options ? (options[0].viewValue | translate) : label() }}
  </div>
}
