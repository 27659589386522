import {
    Directive,
    HostListener,
    HostBinding,
    input,
    output,
} from '@angular/core';

@Directive({
    selector: '[exlFileUpload]',
    standalone: true,
})
export class FileUploadDirective {
    readonly allowed_extensions = input<Array<string>>([]);
    readonly multiple = input(false);
    readonly filesChangeEmitter = output<File[]>();
    readonly filesInvalidEmitter = output<File[]>();
    readonly dragOverEmitter = output<boolean>();
    readonly filesDroppedEmitter = output<number>();
    @HostBinding('class.is-drag-over') private dragOver = false;
    @HostBinding('class.is-drag-leave') private dragLeave = false;
    @HostBinding('class.is-drag-dropped') private dragDrop = false;
    @HostBinding('class.files-added') private filesAdded = false;

    delay = 2000;
    constructor() {}

    @HostListener('dragover', ['$event']) public onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.dragOver = true;
        this.dragLeave = false;
        this.dragOverEmitter.emit(true);
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();

        this.dragLeave = true;
        this.dragOverEmitter.emit(false);
        setTimeout(() => {
            this.dragOver = false;
        }, this.delay / 2);
        setTimeout(() => {
            this.dragLeave = false;
        }, this.delay);
    }

    @HostListener('drop', ['$event']) public onDrop(event) {
        event.preventDefault();
        event.stopPropagation();

        this.dragDrop = true;
        this.dragOver = false;
        this.dragLeave = false;
        setTimeout(() => {
            this.dragDrop = false;
        }, this.delay);

        const files = event.dataTransfer.files;

        if (files.length > 0) {
            this.emitFiles(files);
        }
    }

    emitFiles(files) {
        this.filesAdded = true;
        this.filesDroppedEmitter.emit(files);
    }
}
