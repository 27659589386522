import { Component, Input, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'mulo-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  host: {
    class: 'mulo-spinner',
    '[style.width.px]': 'diameter()',
    '[style.height.px]': 'diameter()',
  },
})
export class SpinnerComponent {
  readonly diameter = input<number>(80);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() colors: string | string[] = ['pink', 'cornsilk'];

  ngOnInit() {
    if (typeof this.colors === 'string') {
      this.colors = [this.colors];
    }
  }
}
