import { Injectable, inject } from '@angular/core';
import { SnackbarService } from '../../core/snackbar.service';
import { SuccessSnackbarComponent } from '../../parts/snackbars/success-snackbar/success-snackbar.component';
import { AmazonInfo } from '../../shared/interfaces/amazon-info.interface';
import { DepositFormDataService } from '../deposit-form-data.service';
import { DepositFormService } from '../deposit-form.service';

@Injectable({
    providedIn: 'root',
})
export class DepositFilesLinksService {
    private depositFormDataService = inject(DepositFormDataService);
    private depositFormService = inject(DepositFormService);
    private snackbarService = inject(SnackbarService);

    fileUploadRegex = new RegExp('/esploro/(.+)/');

    updateAmazonDetails() {
        this.depositFormService.getAmazonInfo().subscribe((data) => {
            const amazonInfo = data as AmazonInfo;
            this.depositFormService.amazonInfo = amazonInfo;
            const match = this.fileUploadRegex.exec(amazonInfo.uploadPath);
            if (match) {
                this.depositFormDataService.updateFilePath(match[1]);
            }
        });
    }

    uploadNewFilesAndLinks(mmsId: string) {
        const depositData = this.depositFormService.buildDepositData();
        this.depositFormDataService.mmsId.setValue(mmsId);

        const assetDetails = {
            mmsId: this.depositFormDataService.mmsId?.value,
            filePath: depositData.value.filePath,
            filesMetadata: depositData.value.filesMetadata,
            files: this.depositFormDataService.files?.value,
            links: this.depositFormDataService.links?.value,
        };

        this.depositFormService
            .submitFilesAndLinks(assetDetails)
            .subscribe((res) => {});
        this.success();
    }

    success() {
        setTimeout(() => {
            this.showSuccessMessage();
        }, 1500);
    }

    showSuccessMessage() {
        this.snackbarService.showComponent(
            SuccessSnackbarComponent,
            'research.output.list.upload.files.success',
            5000
        );
    }
}
