import { Injectable, inject } from '@angular/core';
import {UrlUtils} from '../shared/utils/url.utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../shared/configurations/rest-api-base.config';

@Injectable({
    providedIn: 'root'
})

export class ExtendedConfigurationService {
    private http = inject(HttpClient);


    institutionCode = UrlUtils.getParam('institution');

    getExtendedConfiguration(){
        const params = new HttpParams()
            .set('institution', this.institutionCode)
        return this.http.get(
            RestApiBase.EXTENDED_CONFIGURATION,
            { params: params }
        );
    }


}
