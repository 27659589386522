<mat-form-field
  class="is-comfortably-readable"
  [muloInputExpander]="searchTerm?.value || (placeholder() | customTranslate)"
  >
  <mat-label>{{ placeholder() | customTranslate }}</mat-label>
  <input matInput [formControl]="searchTerm" [matAutocomplete]="auto" />
  @if (searchInProgress) {
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  }
  <mat-autocomplete #auto="matAutocomplete" class="is-wide">
    <!-- no results -->
    @if (searchTerm.value && !searchInProgress && noResultsMessage && searchResult.length == 0) {
      <mat-option
        class="no-results-option"
        disabled
        >
        <span role="alert" [innerHTML]="noResultsMessage | customTranslate : { value: searchTerm.value }"></span>
      </mat-option>
    }

    <!-- results by search -->
    @if (searchResult) {
      @for (result of searchResult; track result; let i = $index) {
        <mat-option
          [value]=""
          (onSelectionChange)="onResultSelection(result, i)"
          [disabled]="searchInProgress"
          >
          <div class="autocomplete-option-multiline">
            @if (result.resourcetypeEsploro) {
              <small class="result-type small-caps semibold">{{
                getResourceType(result) | translate
              }}</small>
            }
            <span
              class="line-1 result-title semibold"
              [innerHTML]="getTitle(result) | highlight : searchTerm.value"
              >
            </span>
            @if (getCreatorOrContributor(result)) {
              <small class="line-2 result-authors">
                <span
                  [innerHTML]="getCreatorOrContributor(result) | lowercase | highlight : searchTerm.value"
                ></span>
              </small>
            }
            <small class="line-3 result-publication italics">
              @if (getPublicationTitle(result)) {
                <span [innerHTML]="getPublicationTitle(result) | highlight : searchTerm.value"></span>
                <span>&nbsp;</span>
              }
              @if (getPublicationDate(result)) {
                <span [innerHTML]="getPublicationDate(result) | highlight : searchTerm.value"></span>
              }
            </small>
          </div>
        </mat-option>
      }
    }
  </mat-autocomplete>
</mat-form-field>
