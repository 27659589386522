import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgComponentOutlet } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-content-dialog',
    templateUrl: './content-dialog.component.html',
    styleUrls: ['./content-dialog.component.scss'],
    imports: [
        MatDialogTitle,
        MatButton,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
        NgComponentOutlet,
        TranslateModule,
    ]
})
export class ContentDialogComponent implements OnInit {
    dialogRef = inject<MatDialogRef<ContentDialogComponent>>(MatDialogRef);
    data = inject(MAT_DIALOG_DATA);

    private contentDialogDestroy = new Subject<void>();

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.contentDialogDestroy.next();
        this.contentDialogDestroy.unsubscribe();
    }

    onDialogClose() {
        this.dialogRef.close();
    }

    onSubmit() {
        this.data.submit.next();
    }
}
