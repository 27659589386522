import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil, takeWhile } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import moment from 'moment';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { DatepickerChangeEvent } from '@exl-ng/mulo-common/lib/components/datepicker/datepicker.component';
import { CodeLabelEntry } from '../../../shared/interfaces/code-label-entry.interface';
import { CodeTableService } from '../../../core/code-table.service';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix, MatFormField, MatLabel } from '@angular/material/form-field';

import { DatepickerComponent } from '@exl-ng/mulo-common';

@Component({
    selector: 'esp-deposit-published-date',
    templateUrl: './deposit-published-date.component.html',
    styleUrls: ['./deposit-published-date.component.scss'],
    imports: [
        DatepickerComponent,
        MatPrefix,
        MatIcon,
        MatTooltip,
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        MatOption,
        TranslateModule,
        CustomTranslatePipe
    ]
})
export class DepositPublishedDateComponent implements OnInit, OnDestroy {
    depositFormDataService = inject(DepositFormDataService);
    private systemDateFormatService = inject(SystemDateFormatService);
    private codetableService = inject(CodeTableService);

    readonly autoPopulatedKey = input('DATE');
    readonly dateKey = input('date');

    currentDate: string;
    dateInput: string;
    displayDateFormat: string;

    seasons: CodeLabelEntry[];

    private componentDestroy = new Subject<void>();

    constructor() {
        const systemDateFormatService = this.systemDateFormatService;

        this.displayDateFormat = systemDateFormatService.getSystemDateFormat();
    }

    ngOnInit() {
        this.currentDate = moment().format(this.displayDateFormat);
        this.dateInput =
            this.depositFormDataService[this.dateKey()]?.value ?? null;
        this.depositFormDataService.mainForm
            .get(this.dateKey())
            .valueChanges.pipe(takeUntil(this.componentDestroy))
            .subscribe((date) => {
                if (
                    date &&
                    date !== this.dateInput &&
                    this.depositFormDataService.startPopulating
                ) {
                    this.dateInput =
                        this.depositFormDataService[this.dateKey()]?.value ??
                        null;
                }
            });
        this.setSeasons();
    }

    dateChange(change: DatepickerChangeEvent) {
        if (
            !this.depositFormDataService.startPopulating &&
            !(
                moment(this.dateInput).format(
                    this.systemDateFormatService.getSystemDateFormat()
                ) == change.stringValue || this.dateInput === change.stringValue
            )
        ) {
            this.depositFormDataService.removeKeyFromAutoPopulatedArray(
                AutoPopulateKeys[this.autoPopulatedKey()]
            );
        }
        //set date as YYYYMMDD
        let datePartial = '';
        datePartial += change.y ?? '';
        datePartial +=
            change.m !== null ? String(change.m + 1).padStart(2, '0') : '';
        datePartial += change.d ? String(change.d).padStart(2, '0') : '';
        this.depositFormDataService.updateYear(change.y || '');
        this.depositFormDataService.updateMonth(
            change.m !== null ? change.m + 1 : ''
        );
        this.depositFormDataService.updateDay(change.d || '');
        this.depositFormDataService.updateDate(datePartial);
        this.depositFormDataService.updateDatePublished(datePartial);
    }

    setSeasons() {
        this.codetableService
            .getCodeTable('EsploroSeasons')
            .subscribe((data) => {
                this.seasons = data as CodeLabelEntry[];
            });
    }

    get isDateAutoPopulated() {
        return this.depositFormDataService.isKeyAutoPopulated(
            AutoPopulateKeys[this.autoPopulatedKey()]
        );
    }

    ngOnDestroy() {
        if (!this.componentDestroy.isStopped) {
            this.componentDestroy.next();
            //unsubscribe from the subject itself
            this.componentDestroy.unsubscribe();
        }
    }
}
