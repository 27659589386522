<p class="title">{{ 'research.add.activity.title.description' | translate }}</p>

<form (ngSubmit)="submit()" [formGroup]="activityForm">
  <h3>{{ 'research.add.activity.name' | translate }}</h3>
  <p>{{ 'research.add.activity.name.description' | translate }}</p>
  <mat-form-field class="is-comfortably-readable" [muloInputExpander]="activityForm.value.name">
    <mat-label>{{ 'research.add.activity.name.placeholder' | translate }}</mat-label>
    <input matInput formControlName="name" type="text" #nameInput />
    <mat-error>
      @if (activityForm.controls.name.errors?.required) {
        <span
          [innerHTML]="'research.field.required.error.message' | customTranslate"
        ></span>
      } @else {
        {{ nameInput.value.length }}/4000
      }
    </mat-error>
  </mat-form-field>

  <h3>{{ 'research.add.activity.category' | translate }}</h3>
  <p>{{ 'research.add.activity.category.description' | translate }}</p>
  <mat-form-field class="is-comfortably-readable">
    <mat-label>{{ 'research.add.activity.category.placeholder' | translate }}</mat-label>
    <mat-select formControlName="categoryCode" (selectionChange)="resetTypeSelection($event)">
      @for (category of activityCategories$ | async; track category) {
        <mat-option [value]="category">
          {{ category | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <h3>{{ 'research.add.activity.type' | translate }}</h3>
  <p>{{ 'research.add.activity.type.description' | translate }}</p>
  <mat-form-field class="is-comfortably-readable">
    <mat-label>{{ 'research.add.activity.type.placeholder' | translate }}</mat-label>
    <mat-select formControlName="typeCode">
      @for (type of activityTypes$ | async | filterList : filterActivityType; track type) {
        <mat-option [value]="type">
          {{ type | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <button
    mulo-funky-button
    [disabled]="activityForm?.invalid"
    [gleam]="false"
    [jellyOnHover]="false"
    [ariaLabel]="'research.aria.deposit.submit' | translate"
    >
    @if (!loading) {
      <span class="margin-right-xs">
        {{ 'research.add.activity.dialog.button.submit' | translate }}</span
        >
      }
      @if (!loading) {
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="send"></mat-icon>
      }
      @if (loading) {
        <mat-spinner diameter="30" [@SpinnerIn]></mat-spinner>
      }
    </button>
  </form>
