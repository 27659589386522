import { Component, ElementRef, inject, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'exl-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    imports: [MatIconButton, MatDialogClose, MatIcon, MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, TranslateModule, CustomTranslatePipe]
})
export class ConfirmDialogComponent {
    data = inject(MAT_DIALOG_DATA);


    actionFocus = 'confirm';
    accentColor = 'accent';
    readonly confirm = viewChild<ElementRef>('confirm');
    readonly cancel = viewChild<ElementRef>('cancel');
    titleParam = {};
    messageParam = {};
    useCustomPipe = false;

    constructor() {
        if (this.data.actionFocus) {
            this.actionFocus = this.data.actionFocus;
        }
        if (this.data.titleParam){
            this.titleParam['value'] = this.data.titleParam;
        }
        if (this.data.param){
            this.messageParam['value0'] = this.data.param;
        }
        if (this.data.useCustomPipe){
            this.useCustomPipe = true;
        }
    }
}
