import { FocusMonitor, CdkAriaLive } from '@angular/cdk/a11y';
import {
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  AfterContentInit,
  DoCheck,
  AfterViewChecked,
  inject,
  input,
  output,
  viewChild,
  DestroyRef,
} from '@angular/core';
import {
  HeightInAnimation,
  HeightOutAnimation,
  ElementAsHeadingDirective,
} from '@exl-ng/mulo-common';
import { componentDestroyed } from '@exl-ng/mulo-core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  merge,
  takeUntil,
  tap,
} from 'rxjs';
import { FacetItemComponent } from '../facet-item/facet-item.component';
import { MatButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {
  takeUntilDestroyed,
  outputToObservable,
} from '@angular/core/rxjs-interop';

@Component({
  selector: 'mulo-facet-section',
  templateUrl: './facet-section.component.html',
  styleUrls: ['./facet-section.component.scss'],
  host: {
    class: 'mulo-facet-section',
    '[class.is-hovered]': '_isMouseOver',
    '[class.has-focus]': 'focused',
    '[class.is-selectable]': 'selectable',
    '[class.has-checkboxes]': 'hasCheckboxes',
    '[class.is-expanded]': 'expanded',
    '[class.has-selected-items]': 'selectedItemsCount > 0',
  },
  animations: [HeightInAnimation, HeightOutAnimation],
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    ElementAsHeadingDirective,
    CdkAriaLive,
    NgClass,
    MatButton,
  ],
})
export class FacetSectionComponent
  implements OnInit, OnDestroy, AfterContentInit, DoCheck, AfterViewChecked
{
  private elRef = inject(ElementRef);
  private focusMonitor = inject(FocusMonitor);

  /** Section title */
  readonly title = input<string>(undefined);
  readonly id = input<string>(undefined);
  readonly a11yHeaderLevel = input<number>(undefined);

  /** Expanded/collapsed state of the section */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input()
  get expanded(): boolean {
    return this._expanded;
  }
  set expanded(val: boolean) {
    this._expanded = val;
    // if (!this.selectable) {
    //   this.disableCheckboxes = true
    // }
  }
  private _expanded: boolean = true;

  /** Whether checkboxes within the section are selectable */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() get selectable(): boolean | null {
    return this._selectable;
  }
  set selectable(value: boolean | null) {
    this.disableCheckboxes = !value && (!this._isMouseOver || !this.focused);
    this._selectable = value;
  }
  private _selectable: boolean | null = null;

  /* Disable all checkboxes within the section */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() get disableCheckboxes(): boolean {
    return this._disableCheckboxes;
  }
  set disableCheckboxes(value: boolean) {
    this._disableCheckboxes = value;
    if (this.items) {
      this.items.toArray().forEach((item) => item.disableCheckbox(value));
    }
  }
  private _disableCheckboxes: boolean = false;

  // @Input() itemsRevealRatio: number = 4;
  readonly itemsReveal = input<boolean>(false);
  readonly itemsRevealLabel = input<string>(
    this.itemsReveal ? 'Show less' : 'Show all',
  );

  readonly headerHeightExpanded = input<string>('auto');
  readonly headerHeightCollapsed = input<string>('auto');

  /** Announce when actions display to notify screen reader users */
  readonly actionsDisplayAriaLabel = input('Apply checked options');

  /** Show/hide the section actions (apply, clear etc) */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() public get showSectionActions(): boolean {
    return this._showSectionActions;
  }
  public set showSectionActions(value: boolean) {
    this._showSectionActions = value;
    if (!value) {
      setTimeout(() => {
        this.setCheckedItemsLength();
      });
    }
  }
  private _showSectionActions: boolean = false;

  readonly opened = output<void>();
  readonly closed = output<void>();
  readonly itemsRevealChange = output<boolean>();
  readonly clearSelection = output<null>();
  readonly applySelection = output<null>();
  readonly showMore = output<boolean>();

  // TODO: Skipped for migration because:
  //  There are references to this query that cannot be migrated automatically.
  @ContentChildren(FacetItemComponent, { descendants: true })
  items: QueryList<FacetItemComponent>;
  readonly content = viewChild<ElementRef>('content');

  /** Whether section items has checkboxes */
  get hasCheckboxes() {
    return this.items ? this.items.find((item) => item.hasCheckbox) : false;
  }
  /** Observable informing when mouse is over section */
  _isMouseOver: boolean = false;
  private _mouseOver = new BehaviorSubject<boolean>(false);
  public mouseOver$ = this._mouseOver.asObservable().pipe(
    distinctUntilChanged(),
    tap((val) => {
      this._isMouseOver = val;
      if (!val) {
        this.focused = false;
      }
    }),
  );

  /** Observable informing when any of the elements within the section are in focus */
  public focused: boolean = false;
  sectionFocus$ = this.focusMonitor
    .monitor(this.elRef.nativeElement, true)
    .pipe(
      distinctUntilChanged(),
      tap((source) => {
        this.focused = source ? true : false;
      }),
    );

  // changeObserver = new MutationObserver((change) => this.onItemsChange(change));
  selectedItemsCount: number = 0;

  readonly showRevealButton = input<boolean>(false);
  sectionRevealedHeight: number | null = null;
  sectionRevealed: boolean = false;
  contentHeight: number;
  checkHeight = false;

  sectionHeight: number | null = null;
  sectionHeightAnimStart: number;
  sectionHeightAnimEnd: number;
  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    merge(this.sectionFocus$, this.mouseOver$)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe();
  }

  ngAfterContentInit() {
    if (this.items) {
      this.onItemsArrayChanges().subscribe((e) => {
        setTimeout(() => {
          this.sectionHeightAnimEnd = this.getSectionHeight();
          this.sectionHeight = this.sectionHeightAnimEnd;
          if (this.selectable != undefined && !this.selectable) {
            this.disableCheckboxes = true;
          }
        });
        setTimeout(() => {
          this.sectionHeight = null;
        }, 400);
      });

      this.onAnyItemChange().subscribe((change) => {
        this.setCheckedItemsLength();
      });
    }
  }

  ngDoCheck() {
    if (this.checkHeight) {
      this.sectionHeightAnimStart = this.getSectionHeight();
      this.sectionHeight = this.sectionHeightAnimStart;
      this.checkHeight = false;
    }
  }

  ngAfterViewChecked() {
    if (this.getSectionHeight() !== this.sectionHeight) {
      this.checkHeight = true;
    }
  }

  ngOnDestroy() {}

  onItemsArrayChanges() {
    return this.items.changes.pipe(takeUntil(componentDestroyed(this)));
  }

  onAnyItemChange() {
    let arr = [];
    this.items.forEach((item) =>
      arr.push(outputToObservable(item.checkedChange)),
    );
    return merge(...arr).pipe(takeUntilDestroyed(this.destroyRef));
  }

  setCheckedItemsLength() {
    if (!this.items) return;
    this.selectedItemsCount = this.items
      .toArray()
      .filter((item) => item.checked).length;
  }

  getSectionHeight() {
    return this.content().nativeElement.scrollHeight;
  }

  toggleSectionRevealed() {
    const state = this.sectionRevealed.toString();
    this.sectionRevealed = state === 'true' ? false : true;
  }

  handleOpened() {
    this.expanded = true;
    this.opened.emit();
  }

  handleClosed() {
    this.expanded = false;
    this.closed.emit();
  }

  handleClearSection(value: boolean) {
    this.showSectionActions = value;
  }

  mouseOver(val: boolean) {
    this._mouseOver.next(val);
  }

  onClearClicked() {
    this.clearSelection.emit(null);
  }

  onApplyClicked() {
    this.applySelection.emit(null);
  }

  onRevealClicked() {
    this.itemsRevealChange.emit(true);
  }
}
