import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'mulo-animated-checkmark',
    templateUrl: './animated-checkmark.component.html',
    styleUrls: ['./animated-checkmark.component.scss'],
    host: { class: 'mulo-animated-checkmark' },
    standalone: true,
})
export class AnimatedCheckmarkComponent implements OnInit {
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('class.is-animating') @Input() animate: boolean;

  constructor() {}

  ngOnInit() {}
}
