<content-loader
  viewBox="0 0 {{width()}} {{height()}} "
  class="content-loader"
  foregroundColor="#eeeeee"
  backgroundColor="#dddddd"
  [rtl]="rtl()"
>
  <svg:rect height="10" rx="2" ry="2" width="100" x="0" y="0"></svg:rect>
  <svg:rect height="95" rx="2" ry="2" width="70" x="0" y="20"></svg:rect>
  <svg:rect height="18" rx="2" ry="2" width="380" x="87" y="24"></svg:rect>
  <svg:rect height="18" rx="2" ry="2" width="415" x="87" y="59"></svg:rect>
  <svg:rect height="18" rx="2" ry="2" width="80" x="87" y="93"></svg:rect>
  <svg:rect height="10" rx="2" ry="2" width="397" x="0" y="128"></svg:rect>
  <svg:rect height="10" rx="2" ry="2" width="397" x="0" y="145"></svg:rect>
  <svg:rect height="56" rx="2" ry="2" width="437" x="0" y="169"></svg:rect>
  <svg:rect height="18" rx="2" ry="2" width="82" x="1" y="308"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="160" x="0" y="342"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="80" x="180" y="342"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="120" x="280" y="342"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="459" x="0" y="370"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="479" x="0" y="387"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="439" x="0" y="403"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="459" x="0" y="420"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="399" x="0" y="437"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="429" x="0" y="454"></svg:rect>
  <svg:rect height="9" rx="2" ry="2" width="259" x="0" y="470"></svg:rect>
</content-loader>
