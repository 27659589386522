@for (component of tab3(); track component) {
    @if (displayPeerReviewed(component)) {
        <esp-deposit-form-section
            sectionTitle="{{ 'research.peereview.title' | customTranslate }}"
            [required]="isRequired(component)"
        >
            <esp-deposit-peer-reviewed></esp-deposit-peer-reviewed>
        </esp-deposit-form-section>
    }
    @if (displayConferenceInformation(component)) {
        <esp-deposit-form-section
            sectionTitle="{{ 'research.conference.info' | customTranslate }}"
            [required]="isRequired(component)"
        >
            <esp-deposit-conference-information></esp-deposit-conference-information>
        </esp-deposit-form-section>
    }
    @if (displayEventInformation(component)) {
        <esp-deposit-form-section
            sectionTitle="{{ 'research.event.info' | customTranslate }}"
            [required]="isRequired(component)"
        >
            <esp-deposit-event-information></esp-deposit-event-information>
        </esp-deposit-form-section>
    }
    @if (displayPresentationInformation(component)) {
        <esp-deposit-form-section
            sectionTitle="{{ 'research.presentation.info' | customTranslate }}"
            [required]="isRequired(component)"
        >
            <esp-deposit-presentation-information></esp-deposit-presentation-information>
        </esp-deposit-form-section>
    }
    @if (displayStatus(component)) {
        <esp-deposit-status [required]="isRequired(component)"></esp-deposit-status>
    }
    @if (displayPublisher(component) && depositFormDataService.type.value) {
        <esp-deposit-form-section sectionTitle="{{ 'research.published.where' | customTranslate }}">
            <esp-deposit-publisher
                placeholder="{{ 'research.published.institution' | customTranslate }}"
            ></esp-deposit-publisher>
        </esp-deposit-form-section>
    }
    @if (displayDoi(component) && depositFormDataService.type.value) {
        <esp-deposit-form-section
            [required]="isRequired(component)"
            sectionTitle="{{ 'research.published.DOI' | customTranslate }}"
        >
            <esp-deposit-doi
                [assetType]="depositFormDataService.resourceType"
                [doi]="depositFormDataService.doi.value"
                [isAutoPopulated]="depositFormDataService.isKeyAutoPopulated('doi')"
                [isRegistered]="depositFormDataService.isRegisteredDOI.value"
                [isReserved]="depositFormDataService.isReservedDOI.value"
                (doiReserved)="depositFormDataService.handleDoiReserved($event)"
                (doiDismissed)="depositFormDataService.handleDoiDismissed()"
                (doiChanged)="depositFormDataService.updateDoi($event)"
                placeholder="research.published.enter"
            ></esp-deposit-doi>
        </esp-deposit-form-section>
    }
    @if (dispalyPublicationInformation(component) && isPublicationMoreInformation()) {
        <esp-deposit-form-section sectionTitle="{{ 'research.status.info' | customTranslate }}">
            <esp-deposit-publication-details-journal-article
                [status]="status.PUBLISHED"
            ></esp-deposit-publication-details-journal-article>
        </esp-deposit-form-section>
    }
    @if (displayDatePublished(component) && depositFormDataService.type.value) {
        <esp-deposit-form-section
            [required]="isRequired(component)"
            sectionTitle="{{ 'research.status.publish' | customTranslate }}"
        >
            <esp-deposit-published-date
                [autoPopulatedKey]="'DATE_PUBLISHED'"
                [dateKey]="'datePublished'"
            ></esp-deposit-published-date>
        </esp-deposit-form-section>
    }
    @if (displayDateCreated(component)) {
        <esp-deposit-form-section
            [required]="isRequired(component)"
            sectionTitle="{{ 'research.date.date.created' | customTranslate }}"
            description="{{ 'research.collected.subtitle' | customTranslate }}"
        >
            <esp-deposit-dates
                [range]="true"
                [dateHint]="false"
                [fromDate]="depositFormDataService.dateCreatedStart"
                [toDate]="depositFormDataService.dateCreatedEnd"
            >
            </esp-deposit-dates>
        </esp-deposit-form-section>
    }
    @if (displayDateCollected(component)) {
        <esp-deposit-form-section
            [required]="isRequired(component)"
            sectionTitle="{{ 'research.collected.title' | customTranslate }}"
            description="{{ 'research.collected.subtitle' | customTranslate }}"
        >
            <esp-deposit-dates
                [range]="true"
                [dateHint]="false"
                [fromDate]="depositFormDataService.dateCollectedStart"
                [toDate]="depositFormDataService.dateCollectedEnd"
            >
            </esp-deposit-dates>
        </esp-deposit-form-section>
    }
    @if (displayDatePresented(component)) {
        <esp-deposit-form-section
            [required]="isRequired(component)"
            sectionTitle="{{ 'research.conference.date.presented' | customTranslate }}"
        >
            <esp-deposit-dates [range]="false" [dateHint]="true" [fromDate]="depositFormDataService.datePresented">
            </esp-deposit-dates>
        </esp-deposit-form-section>
    }
    @if (displayLanguages(component)) {
        <esp-deposit-form-section
            sectionTitle="{{ 'research.language.title' | customTranslate }}"
            [required]="isRequired(component)"
        >
            <esp-deposit-language></esp-deposit-language>
        </esp-deposit-form-section>
    }
    @if (isDataset() && matchFirstCompUnderAdditionalData(component) && !depositStepThreeService.showAdditionalInfo()) {
        <esp-deposit-form-section class="is-unindented">
            <div>
                <button mat-button color="primary" (click)="depositStepThreeService.toggleShowAddtlInfo()">
                    {{ 'research.additional.add' | customTranslate }}
                </button>
            </div>
        </esp-deposit-form-section>
    }
    @if (depositStepThreeService.showAdditionalInfo()) {
        @if (displaySpatial(component)) {
            <esp-deposit-form-section
                sectionTitle="{{ 'research.geospatial.geospatial' | customTranslate }}"
                [required]="isRequired(component)"
            >
                <esp-deposit-spatial cdkTrapFocus="false" cdkTrapFocusAutoCapture></esp-deposit-spatial>
            </esp-deposit-form-section>
        }
        @if (displayMethods(component)) {
            <esp-deposit-form-section
                sectionTitle="{{ 'research.methods.methods' | customTranslate }}"
                [required]="isRequired(component)"
            >
                <esp-deposit-methods></esp-deposit-methods>
            </esp-deposit-form-section>
        }
        @if (displayCoverage(component)) {
            <esp-deposit-form-section
                sectionTitle="{{ 'research.coverage.coverage' | customTranslate }}"
                [required]="isRequired(component)"
            >
                <esp-deposit-coverage></esp-deposit-coverage>
            </esp-deposit-form-section>
        }
        @if (displayTechnicalInfo(component)) {
            <esp-deposit-form-section
                sectionTitle="{{ 'research.technical.technical' | customTranslate }}"
                [required]="isRequired(component)"
            >
                <esp-deposit-technical-information></esp-deposit-technical-information>
            </esp-deposit-form-section>
        }
    }
}
