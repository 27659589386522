import { Component, OnInit, inject, input, output } from '@angular/core';
import { DepositFormService } from '../../../deposit-form.service';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { SortUtils } from '../../../../shared/utils/sort.utils';
import { ConfigurationHandlerService } from '../../../../core/configuration-handler.service';
import { GrowInAnimation, GrowOutAnimation, ScaleInOutAnimation, ElementAsHeadingDirective, OrcidLinkComponent } from '@exl-ng/mulo-common';
import { MappingTable } from '../../../../shared/interfaces/mapping-table.interface';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AnnouncerService } from '../../../../core/announcer.service';
import { UntypedFormArray, FormsModule } from '@angular/forms';
import { DepositAuthorsService } from '../deposit-authors.service';
import { User } from '../../../../shared/interfaces/user.model';
import { DefaultTranslatePipe } from '../../../../shared/pipes/default-translate.pipe';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIcon } from '@angular/material/icon';
import { TransitionGroupItemDirective } from '../../../../shared/directives/transition-group/transition-group.directive';
import { MatList, MatListItem, MatListItemIcon, MatListItemTitle, MatListItemLine, MatListItemMeta } from '@angular/material/list';

@Component({
    selector: 'esp-authors-list',
    templateUrl: './authors-list.component.html',
    styleUrls: ['./authors-list.component.scss'],
    animations: [ScaleInOutAnimation, GrowInAnimation, GrowOutAnimation],
    host: { class: 'esp-authors-list' },
    imports: [
        MatList,
        MatListItem,
        TransitionGroupItemDirective,
        MatIcon,
        MatListItemIcon,
        MatListItemTitle,
        ElementAsHeadingDirective,
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        MatOption,
        MatListItemLine,
        OrcidLinkComponent,
        MatListItemMeta,
        MatIconButton,
        TranslateModule,
        CustomTranslatePipe,
        DefaultTranslatePipe
    ]
})
export class AuthorsListComponent implements OnInit {
    private depositFormService = inject(DepositFormService);
    depositFormDataService = inject(DepositFormDataService);
    private depositAuthorsService = inject(DepositAuthorsService);
    private configSvc = inject(ConfigurationHandlerService);
    private announcerService = inject(AnnouncerService);
    private translate = inject(TranslateService);

    readonly authors = input<UntypedFormArray>(undefined);
    readonly enterNewPersonChange = output();
    readonly editAuthor = output<User>();
    roles: string[] = [];
    rolesTranslations = [];

    trackByUserID = (index: number, author: any) => author.id;

    ngOnInit() {
        const resourceTypeTemplate =
            this.depositFormDataService.resourceTypeTemplate;
        const isDefaultForm = resourceTypeTemplate === DepositForm.DEFAULT_TYPE;

        const esploroContributorTypes = this.configSvc.getMappingTable(
            'EsploroContributorTypes',
        );
        const roleFilter = (row: MappingTable) =>
            row.enabled === 'true' &&
            this.relevantForResourceType(row.source1, isDefaultForm);
        this.roles =
            esploroContributorTypes
                ?.filter(roleFilter)
                .map((row) => row.target) || [];
        this.translateRolesAndSort();
    }

    relevantForResourceType(source1: string, isDefaultForm: boolean) {
        const category = this.depositFormDataService.category?.value;
        const isEtdType = this.depositFormDataService.type?.value?.startsWith(
            DepositForm.ETD_TYPE,
        );
        return (
            (isDefaultForm && !isEtdType && !this.isForEDTOnly(source1)) ||
            (isEtdType && this.isIncludeETD(source1)) ||
            source1.includes('ALL') ||
            (category && source1.includes(category))
        );
    }

    translateRolesAndSort() {
        if (this.roles?.length > 0) {
            this.translate.get(this.roles).subscribe((res) => {
                this.rolesTranslations = Object.entries(res);
                this.rolesTranslations.sort(SortUtils.labelSort);
            });
        }
    }

    isForEDTOnly(resourceTypes: string) {
        const resourceTypesArray = resourceTypes
            .split(',')
            .map((rt) => rt.trim());
        const isETDOnly = resourceTypesArray.every((s1) =>
            s1.startsWith(DepositForm.ETD_PREFIX),
        );
        return isETDOnly;
    }

    isIncludeETD(resourceTypes: string) {
        const resourceTypesArray = resourceTypes
            .split(',')
            .map((rt) => rt.trim());
        const isIncludeETD = resourceTypesArray.some((s1) =>
            s1.startsWith(DepositForm.ETD_PREFIX),
        );
        return isIncludeETD;
    }

    onAuthorRemove(index) {
        const authors = this.authors();
        const { value } = authors?.at(index);
        authors?.removeAt(index);
        this.announcerService.annonuceRemove(value?.name);
        this.depositAuthorsService.removeUserId(value.id);
    }

    onAuthorShift(index, direction) {
        const authors = this.authors();
        const removedItem = authors?.at(index);
        const newPos = direction === 'up' ? index - 1 : index + 1;
        authors?.removeAt(index);
        authors?.insert(newPos, removedItem);
    }

    isAutoPopulated(id) {
        return (
            this.depositFormDataService.authorsAutoPopulated.indexOf(id) !== -1
        );
    }

    changeRole(index, role) {
        // this.depositFormDataService.changeRole(index, role);
        const author = this.authors().at(index);
        author.patchValue({ role });
    }
}
