import { Component, inject, input, output, contentChildren } from '@angular/core';
import {
  GrowInAnimation,
  GrowOutAnimation,
} from '../../animations/grow.animation';
import { PortionListService, PortionMethod } from './portion-list.service';
import { PortionListItemComponent } from './portion-list-item/portion-list-item.component';
import { MatList } from '@angular/material/list';
import { PortionListToolbarComponent } from './portion-list-toolbar/portion-list-toolbar.component';


export interface Portion {
  label: string;
  portion?: number;
}

@Component({
    selector: 'mulo-portion-list',
    templateUrl: './portion-list.component.html',
    providers: [PortionListService],
    styleUrls: ['./portion-list.component.scss'],
    host: { class: 'mulo-portion-list' },
    animations: [GrowInAnimation, GrowOutAnimation],
    imports: [
        PortionListToolbarComponent,
        MatList
    ]
})
export class PortionListComponent {
  service = inject(PortionListService);

  readonly max = input(100);
  readonly min = input(0);
  readonly thumbLabel = input(true);
  /**
   * Array of labels to be used for the toolbar in order of appearance:
   * "Distribute", desc, equal, asc
   */
  readonly toolbarLabels = input<string[]>(undefined);
  readonly distribute = input<PortionMethod>(undefined);

  readonly distributePortion = output<PortionMethod>();

  readonly contentItems = contentChildren(PortionListItemComponent);

  list: any = [];
  subscription;

  get listItems() {
    return this.contentItems();
  }

  distributePortions(method: PortionMethod) {
    this.distributePortion.emit(method);
  }
}
