import {
  Component,
  effect,
  inject,
  Input,
  model,
  output,
  signal,
  viewChildren,
  input,
} from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { DisplayMode, MediaService } from '@exl-ng/mulo-core';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DropListModule } from '../drop-list';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export interface MultiLanguageObj<T> {
  [langKey: string]: T;
}

@Component({
  selector: 'mulo-editable-item-section',
  template: `<ng-content></ng-content>`,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    DropListModule,
  ],
})
export abstract class EditableItemSectionComponent<T = any, M = any> {
  readonly formGroupDirectives = viewChildren(FormGroupDirective);
  readonly displayMode = input<DisplayMode>(undefined);

  items = model<T[]>([]);
  a11yEditing = signal(false);
  a11yEditingParams = signal<{ startIdx: number; currIdx: number }>({
    startIdx: undefined,
    currIdx: undefined,
  });

  readonly itemLimit = model(5);
  readonly omitFields = input([]);
  readonly ariaLabel = input<string>(undefined);
  readonly ariaLabelledBy = input<string>(undefined);

  formMode = signal<DisplayMode>(undefined);
  form: UntypedFormGroup;
  formInitConfig: any;
  readonly itemInEdit = signal(-1);
  closeForm: boolean;
  submitInProgress = signal(false);

  selectedLanguage: string;
  languagesMap: MultiLanguageObj<M>;
  languagesKeys = signal<string[]>([]);
  readonly media = inject(MediaService);

  protected constructor() {
    effect(() => {
      if (this.a11yEditing() === false) {
        this.a11yEditingParams.set({
          startIdx: undefined,
          currIdx: undefined,
        });
      }
    });
  }

  setForm(index) {
    if (index === undefined || index === null) {
      this.itemInEdit.set(-1);
      this.form?.reset(this.formInitConfig);
      if (this.closeForm === false) {
        this.formMode.set('add');
      } else {
        this.formMode.set(null);
        this.closeForm = true;
      }
      this.formGroupDirectives()?.forEach((directive) => {
        if (directive.form === this.form) {
          directive.resetForm(this.formInitConfig);
        }
      });
    } else {
      this.formMode.set('edit');
      this.itemInEdit.set(index);
      this.form?.reset(
        { ...this.formInitConfig, ...this.items()[index] },
        { emitEvent: false },
      );
    }
    this.submitInProgress.set(false);
  }

  handleSubmit(andClose = true) {
    this.closeForm = andClose;
    this.handleFormClose();
  }

  handleAddAnother = (event: MouseEvent) => {
    event.preventDefault();
    this.handleSubmit(false);
  };

  handleFormClose() {
    this.setForm(null);
    this.formMode.set(null);
    this.submitInProgress.set(false);
  }

  handleFormOpen() {
    this.formMode.update((mode) => mode ?? 'add');
  }

  addItem(item: T) {
    this.resetItemLimit();
    this.items.update((prev) => [...prev, item]);
    this.setForm(null);
  }

  editItem(item: T, index: string | number) {
    this.items.update((prev) => {
      const items = [...prev];
      items[index] = item;
      return items;
    });

    this.setForm(null);
  }

  removeItem(index: number) {
    this.items.update((prev) => {
      const items = [...prev];
      items.splice(index, 1);
      return items;
    });

    this.setForm(null);
  }

  dropDraggedItem(item) {
    this.items.update((items) => {
      moveItemInArray(items, item.previousIndex, item.currentIndex);
      return items;
    });

    if (this.a11yEditing()) {
      let startIdx = this.a11yEditingParams().startIdx ?? item.previousIndex;
      this.a11yEditingParams.set({
        startIdx: startIdx,
        currIdx: item.currentIndex,
      });
    }
  }

  resetItemLimit() {
    this.itemLimit.set(undefined);
  }
}
