import {
  trigger,
  style,
  transition,
  animate,
  stagger,
  query,
} from '@angular/animations';
import * as easings from './easings';

const getParams = function (caller) {
  return '80ms';
};
export const SlideInChildrenStaggeredAnimation = trigger(
  'slideInChildrenStaggeredAnimation',
  [
    transition(
      '* => *',
      [
        query(
          '[animation-in-child]',
          style({
            opacity: 0,
            transform: 'translate{{ direction }}({{ distance }})',
          }),
          { optional: true },
        ),
        query(
          '[animation-in-child]',
          stagger('80ms', [
            animate(
              '{{ time }} {{ delay }} ' + easings.OutExpo,
              style({
                opacity: 1,
                transform: 'translate{{ direction }}(0)',
              }),
            ),
          ]),
          { optional: true },
        ),
      ],
      {
        params: {
          direction: 'Y',
          distance: '200%',
          time: '.8s',
          delay: '0ms', // don't confuse this with Angular's stagger
        },
      },
    ),
  ],
);
export const SlideOutChildrenStaggeredAnimation = trigger(
  'slideOutChildrenStaggeredAnimation',
  [
    transition(
      '* => *',
      [
        query(
          '[animation-out-child]',
          style({
            opacity: 1,
            transform: 'translate{{ direction }}(0)',
          }),
          { optional: true },
        ),
        query(
          '[animation-out-child]',
          stagger('80ms', [
            animate(
              '{{ time }} {{ delay }} ' + easings.OutExpo,
              style({
                opacity: 0,
                transform: 'translate{{ direction }}({{ distance }})',
              }),
            ),
          ]),
          { optional: true },
        ),
      ],
      {
        params: {
          direction: 'Y',
          distance: '200%',
          time: '.8s',
          delay: '0ms', // don't confuse this with Angular's stagger
        },
      },
    ),
  ],
);
