import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DepositFormComponent } from './deposit-form.component';
import { DepositStepperComponent } from './deposit-stepper/deposit-stepper.component';
import { DepositFormSectionComponent } from './deposit-form-section/deposit-form-section.component';
import { DepositTypeComponent } from './deposit-sections/deposit-type/deposit-type.component';
import { DepositFilesComponent } from './deposit-sections/deposit-files/deposit-files.component';
import { CitationsModule } from '../parts/citations/citations.module';
import { HandleFilesModule } from '../parts/handle-files/handle-files.module';
import { DepositTitleComponent } from './deposit-sections/deposit-title/deposit-title.component';
import { DepositKeywordsComponent } from './deposit-sections/deposit-keywords/deposit-keywords.component';
import { DepositFreetextKeywordsComponent } from './deposit-sections/deposit-freetext-keywords/deposit-freetext-keywords.component';
import { DepositDescriptionComponent } from './deposit-sections/deposit-description/deposit-description.component';
import { DepositPeerReviewedComponent } from './deposit-sections/deposit-peer-reviewed/deposit-peer-reviewed.component';
import { DepositPublicationStatusComponent } from './deposit-sections/deposit-publication-status/deposit-publication-status.component';
import { DepositPublicationDetailsJournalArticleComponent } from './deposit-sections/deposit-status-details/deposit-publication-details-journal-article/deposit-publication-details-journal-article.component';
import { DepositDatesComponent } from './deposit-sections/deposit-dates/deposit-dates.component';
import { DepositLanguageComponent } from './deposit-sections/deposit-language/deposit-language.component';
import { DepositStepThreeComponent } from './deposit-steps/deposit-step-three/deposit-step-three.component';
import { DepositStatusComponent } from './deposit-sections/deposit-status/deposit-status.component';
import { DepositGrantsComponent } from './deposit-sections/deposit-grants/deposit-grants.component';
import { DepositStepFourComponent } from './deposit-steps/deposit-step-four/deposit-step-four.component';
import { DepositSearchForService } from './deposit-sections/deposit-search-for/deposit-search-for.service';
import { DepositSelectedResearchComponent } from './deposit-sections/deposit-selected-research/deposit-selected-research.component';
import { DepositKeywordsService } from './deposit-sections/deposit-keywords/deposit-keywords.service';
import { DepositStepperBackButtonComponent } from './deposit-stepper/deposit-stepper-back-button/deposit-stepper-back-button.component';
import { DepositStepperNextButtonComponent } from './deposit-stepper/deposit-stepper-next-button/deposit-stepper-next-button.component';
import { DepositStepperSkipToEndButtonComponent } from './deposit-stepper/deposit-stepper-skip-to-end-button/deposit-stepper-skip-to-end-button.component';
import { DepositLastStepComponent } from './deposit-steps/deposit-last-step/deposit-last-step.component';
import { DepositPublicationDetailsService } from './deposit-sections/deposit-status-details/deposit-publication-details.service';
import { DepositLanguageService } from './deposit-sections/deposit-language/deposit-language.service';
import { LoginModule } from '../login/login.module';
import { DepositStepOneComponent } from './deposit-steps/deposit-step-one/deposit-step-one.component';
import { DepositStepTwoComponent } from './deposit-steps/deposit-step-two/deposit-step-two.component';
import { DepositSummaryComponent } from './deposit-sections/deposit-summary/deposit-summary.component';

import { BadgeModule } from '../bits/badge/badge.module';
import { FileProgressDialogComponent } from './deposit-sections/deposit-files/file-progress-dialog/file-progress-dialog.component';
import { DepositPublicationDetailsBookChapterComponent } from './deposit-sections/deposit-status-details/deposit-publication-details-book-chapter/deposit-publication-details-book-chapter.component';
import { DepositConferenceInformationComponent } from './deposit-sections/deposit-conference-information/deposit-conference-information.component';
import { DepositPublicationDetailsConferenceProceedingComponent } from './deposit-sections/deposit-status-details/deposit-publication-details-conference-proceeding/deposit-publication-details-conference-proceeding.component';

import { DepositFileMetadataComponent } from './deposit-sections/deposit-files/deposit-file-metadata/deposit-file-metadata.component';
import { DepositLinkMetadataComponent } from './deposit-sections/deposit-files/deposit-link-metadata/deposit-link-metadata.component';
import { DepositFreeTextComponent } from './deposit-sections/deposit-free-text/deposit-free-text.component';
import { DepositRadiosBooleanComponent } from './deposit-sections/deposit-radios-boolean/deposit-radios-boolean.component';
import { DepositDoiComponent } from './deposit-sections/deposit-doi/deposit-doi.component';
import { DateInputModule } from '../parts/date-input/date-input.module';
import { DepositPublisherComponent } from './deposit-sections/deposit-publisher/deposit-publisher.component';
import { DepositMethodsComponent } from './deposit-sections/deposit-methods/deposit-methods.component';
import { DepositCoverageComponent } from './deposit-sections/deposit-coverage/deposit-coverage.component';
import { DepositTechnicalInformationComponent } from './deposit-sections/deposit-technical-information/deposit-technical-information.component';
import { DepositSpatialComponent } from './deposit-sections/deposit-spatial/deposit-spatial.component';
import { DepositGrantsService } from './deposit-sections/deposit-grants/deposit-grants.service';
import { NewGrantComponent } from './deposit-sections/deposit-grants/new-grant/new-grant.component';

import { DepositRoutingModule } from './deposit-routing.module';
import { TransitionGroupComponent } from '../shared/directives/transition-group/transition-group.directive';
import { PlainTextInputModule } from '../parts/plain-text-input/plain-text-input.module';
import { DepositDoiService } from './deposit-sections/deposit-doi/deposit-doi.service';
import { DepositSubtitleComponent } from './deposit-sections/deposit-subtitle/deposit-subtitle.component';
import { DepositPublicationDetailsBookComponent } from './deposit-sections/deposit-status-details/deposit-publication-details-book/deposit-publication-details-book.component';
import { DepositEventInformationComponent } from './deposit-sections/deposit-event-information/deposit-event-information.component';
import { DepositPresentationInformationComponent } from './deposit-sections/deposit-presentation-information/deposit-presentation-information.component';
import { DepositAnzFieldsComponent } from './deposit-sections/deposit-anz-fields/deposit-anz-fields.component';
import { DepositAnzToaComponent } from './deposit-sections/deposit-anz-toa/deposit-anz-toa.component';
import { OnboardingModule } from '../parts/onboarding/onboarding.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
    CollectByFieldPipe,
    ContextFrameClosedViewDirective,
    ContextFrameComponent,
    DatepickerComponent,
    FunkyButtonComponent,
    InputExpanderDirective,
    LoaderComponent,
    LoadingSlateComponent,
    OrcidConnectComponent,
    OrcidLinkComponent,
    OrcidLogoComponent,
    PortionListComponent,
    PortionListItemComponent,
    PortionListToolbarComponent,
    RichTextEditorModule,
} from '@exl-ng/mulo-common';
import { DepositPublishedDateComponent } from './deposit-sections/deposit-published-date/deposit-published-date.component';
import { DepositFilesLinksDialogComponent } from './deposit-files-links-dialog/deposit-files-links-dialog.component';
import { DepositAuthorsModule } from './deposit-sections/deposit-authors/deposit-authors.module';
import { DepositSearchModule } from './deposit-sections/deposit-search-for/deposit-search.module';
import { ExternalOrganizationFieldComponent } from '../parts/external-organization-field/external-organization-field.component';
import { MatIconModule } from '@angular/material/icon';
import { DepositGrantsSearchComponent } from './deposit-sections/deposit-grants/deposit-grants-search/deposit-grants-search.component';
import { PickerComponent } from '../parts/menus/picker/picker.component';
import { PickerItemComponent } from '../parts/menus/picker/picker-item/picker-item.component';

@NgModule({
    imports: [
        DepositRoutingModule,
        // BrowserAnimationsModule,
        SharedModule,
        CitationsModule,
        HandleFilesModule,
        PickerComponent,
        PickerItemComponent,
        RichTextEditorModule,
        LoginModule,
        BadgeModule,
        DateInputModule,
        LoaderComponent,
        PlainTextInputModule,
        PortionListComponent,
        PortionListItemComponent,
        PortionListToolbarComponent,
        OnboardingModule,
        NgxMatSelectSearchModule,
        OrcidLinkComponent,
        OrcidLogoComponent,
        OrcidConnectComponent,
        DatepickerComponent,
        FunkyButtonComponent,
        DepositAuthorsModule,
        DepositSearchModule,
        ExternalOrganizationFieldComponent,
        LoadingSlateComponent,
        CollectByFieldPipe,
        MatIconModule,
        ContextFrameComponent,
        ContextFrameClosedViewDirective,
        InputExpanderDirective,
        DepositGrantsSearchComponent,
        DepositTypeComponent,
        DepositDoiComponent,
        DepositFormComponent,
        DepositStepperComponent,
        DepositStepOneComponent,
        DepositStepTwoComponent,
        DepositStepThreeComponent,
        DepositStepFourComponent,
        DepositLastStepComponent,
        DepositFormSectionComponent,
        DepositSelectedResearchComponent,
        DepositFilesComponent,
        DepositTitleComponent,
        DepositKeywordsComponent,
        DepositFreetextKeywordsComponent,
        DepositAnzFieldsComponent,
        DepositAnzToaComponent,
        DepositDescriptionComponent,
        DepositPeerReviewedComponent,
        DepositPublicationStatusComponent,
        DepositPublicationDetailsJournalArticleComponent,
        DepositPublicationDetailsBookChapterComponent,
        DepositPublicationDetailsConferenceProceedingComponent,
        DepositDatesComponent,
        DepositLanguageComponent,
        DepositStatusComponent,
        DepositGrantsComponent,
        DepositStepperBackButtonComponent,
        DepositStepperNextButtonComponent,
        DepositStepperSkipToEndButtonComponent,
        DepositSummaryComponent,
        FileProgressDialogComponent,
        DepositConferenceInformationComponent,
        DepositFileMetadataComponent,
        DepositLinkMetadataComponent,
        DepositFreeTextComponent,
        DepositRadiosBooleanComponent,
        DepositPublisherComponent,
        DepositPublishedDateComponent,
        DepositMethodsComponent,
        DepositCoverageComponent,
        DepositTechnicalInformationComponent,
        DepositSpatialComponent,
        NewGrantComponent,
        TransitionGroupComponent,
        DepositSubtitleComponent,
        DepositPublicationDetailsBookComponent,
        DepositEventInformationComponent,
        DepositPresentationInformationComponent,
        DepositFilesLinksDialogComponent,
    ],
    providers: [
        DepositSearchForService,
        DepositKeywordsService,
        DepositPublicationDetailsService,
        DepositLanguageService,
        DepositGrantsService,
        DepositDoiService,
    ],
    exports: [
        DepositFormComponent,
        DepositFilesComponent,
        DepositKeywordsComponent,
        DepositLanguageComponent,
        DepositTypeComponent,
        DepositDoiComponent,
    ],
})
export class DepositModule {}
