import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        // component: DepositFormComponent,
        redirectTo: 'etd',
        pathMatch: 'full',
    },
    {
        path: 'output',
        loadComponent: () =>
            import('./deposit-output-page/deposit-output-page.component').then(
                (m) => m.DepositOutputPageComponent,
            ),
    },
    {
        path: 'etd',
        loadChildren: () =>
            import('../student/student-deposit/student-deposit.module').then(
                (m) => m.StudentDepositModule,
            ),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DepositRoutingModule {}
