<div class="field-wrap">
  <mulo-datepicker
    (dateInputChange)="dateChange($event)"
    [dateInput]="dateInput"
        [error]="
            depositFormDataService.mainForm?.errors?.validDate ? ('research.status.error' | customTranslate) : null
        "
    [formFieldClass]="isDateAutoPopulated ? 'is-autopopulated' : ''"
    [hint]="'research.status.date.hint' | translate : { value0: currentDate }"
    [label]="'research.status.date' | customTranslate"
    >
    @if (isDateAutoPopulated) {
      <div class="mat-mdc-form-field-prefix" matPrefix>
        <mat-icon
          [matTooltip]="'research.auto.populate.tooltip' | customTranslate"
          svgIcon="auto-populate"
        ></mat-icon>
      </div>
    }
  </mulo-datepicker>
  <mat-form-field class="margin-left-sm">
    <mat-label>{{ 'research.status.season' | customTranslate }}</mat-label>
    <mat-select [formControl]="depositFormDataService.seasonPublished">
      <mat-option [value]="''">{{ 'research.settings.research.none' | customTranslate }}</mat-option>
      @for (season of seasons; track season) {
        <mat-option [value]="season.code">
          {{ season.code | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div></div>
</div>
