<mat-form-field [class]="formFieldClass()" [hideRequiredMarker]="hideRequiredMarker()">
  <mat-label>{{ label }}</mat-label>
  <input
    #inputField
    matInput
    [formControl]="dateInputCtrl"
    [required]="required()"
    (change)="dateInputChangeHandler($event)"
    [errorStateMatcher]="matcher"
    />
  <ng-content select="[matPrefix]"></ng-content>
  <mat-hint>{{ hint() }}</mat-hint>
  <mat-error>
    @if (dateInputCtrl.hasError('externalError')) {
      {{ error() }}
    }
    @if (dateInputCtrl.hasError('dateFormatInvalid')) {
      {{ dateFormatInvalidLabel() }}
    }
  </mat-error>
  <mat-datepicker-toggle [disabled]="disabled()" matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    startView="multi-year"
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event)"
    panelClass="datepicker__panel"
    >
    <input matInput [matDatepicker]="dp" [formControl]="dateCtrl" (dateChange)="dateChangeHandler($event)" />
  </mat-datepicker>
</mat-form-field>
