<div mat-dialog-title>
  <div class="title-wrap">
    <h1 class="beta semibold spacer">
      {{ 'research.flicense.title' | customTranslate }}
    </h1>
    <span>
      <a
        [attr.aria-label]="'research.aria.deposit.license.help' | translate"
        href="https://choosealicense.com/"
        target="_blank"
        >
        {{ 'research.flicense.help' | customTranslate }}
      </a>
    </span>
  </div>
  <div class="eta regular">
    <mat-checkbox (change)="checkboxChanged()" [(ngModel)]="applyToAll">{{
      'research.flicense.check' | customTranslate
    }}</mat-checkbox>
  </div>
</div>
<div mat-dialog-content>
  <mat-selection-list [multiple]="false">
    @for (group of licenses | groupBy : 'category'; track group) {
      <h3 mat-subheader>
        @for (key of group.key.split(','); track key; let last = $last) {
          {{ key | translate }}
          @if (!last) {
            ,
          }
        }
      </h3>
      <!--<h3 mat-subheader>{{group.key|translate}}</h3>-->
      @for (license of group.value; track license) {
        <mat-list-option
          (click)="selectLicense(license)"
          [class.is-selected]="license.title === data.selected"
          checkboxPosition
          class="is-wrapping is-paddingless"
          >
          <h4 mat-line>{{ license.title + '.name' | translate }} ({{ license.title }})</h4>
          <p mat-line>
            {{ license.title + '.description' | translate }}
          </p>
          @if (license.link) {
            <a
              (click)="showLicenseInfo($event)"
              [attr.aria-label]="'research.aria.deposit.license.info' | translate"
              [href]="license.link"
              class="license-info"
              color="primary"
              mat-icon-button
              matListItemAvatar
              target="_blank"
              >
              <mat-icon svgIcon="information-outline"></mat-icon>
            </a>
          }
        </mat-list-option>
      }
    }
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  @if (saveButton) {
    <button (click)="onSave()" class="is-large" color="accent" mat-button>
      {{ 'research.flicense.apply' | customTranslate }}
    </button>
  }
  <button mat-button mat-dialog-close="cancel">
    {{ 'research.flicense.cancel' | customTranslate }}
  </button>
</div>
