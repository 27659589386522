<mat-form-field
  [ngClass]="expanded() ? 'is-expanded' : 'is-auto-expanding'"
  [class.is-autopopulated]="autoPopulated()"
  [muloInputExpander]="input.value"
  class="is-comfortably-readable"
  >
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  @if (!multipleLines()) {
    <input
      matInput
      [formControl]="input"
      [placeholder]="placeholder()"
      #inputElement
      [exlFocus]="jump()"
      [attr.autocomplete]="autocompleteLabel()"
      />
  }
  @if (multipleLines()) {
    <textarea
      matInput
      class="hasnt-scrollbar"
      [formControl]="input"
      [placeholder]="placeholder()"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      [rows]="1"
      #inputElement
      [maxlength]="lengthLimit"
      [exlFocus]="jump()"
      [attr.autocomplete]="autocompleteLabel()"
      >
    </textarea>
  }

  @if (autoPopulated()) {
    <mat-icon
      matPrefix
      svgIcon="auto-populate"
      matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
      >
    </mat-icon>
  }
  <!--<exl-badge-auto-populated-->
  <!--*ngIf="autoPopulated"-->
  <!--tooltip="{{'research.auto.populate.tooltip' | customTranslate}}">-->
<!--</exl-badge-auto-populated>-->
@if (input.value) {
  <button
    mat-icon-button
    (click)="clearInput()"
    class="clear-button"
    attr.aria-label="{{ 'research.aria.deposit.clear.input' | translate }}"
    matSuffix
    >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
}
@if (lengthLimit && input.value) {
  <mat-hint align="end">{{ input.value.length }} / {{ lengthLimit }}</mat-hint>
}
<mat-error>
  <span [innerHTML]="'research.field.required.error.message' | customTranslate"></span>
</mat-error>
</mat-form-field>
