<span class="label">{{ label() }}</span>
<mat-form-field [floatLabel]="floatLabel" class="is-flexible is-inline">
  <mat-label class="cdk-visually-hidden">{{ label() }}</mat-label>
  <mat-select [value]="selected()" (valueChange)="onValueChange($event)" class="is-inline">
    @for (option of options(); track option) {
      <mat-option [value]="option.id">
        {{ option.value }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
