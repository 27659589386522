import {Component, OnInit, input} from '@angular/core';
import {TabComponentModel} from '../../../shared/interfaces/tab-component.model';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositGrantsComponent } from '../../deposit-sections/deposit-grants/deposit-grants.component';
import { DepositFormSectionComponent } from '../../deposit-form-section/deposit-form-section.component';


@Component({
    selector: 'esp-deposit-step-four',
    templateUrl: './deposit-step-four.component.html',
    styleUrls: ['./deposit-step-four.component.scss'],
    imports: [DepositFormSectionComponent, DepositGrantsComponent, CustomTranslatePipe]
})
export class DepositStepFourComponent implements OnInit {

    readonly tab4 = input(undefined);

    constructor() { }

    ngOnInit() {
    }

    displayGrants(comp: TabComponentModel){
        return comp.visible && this.identifyComponent(comp.field, 'grants');
    }

    identifyComponent(field:string, name:string) {
        return field === name;
    }

    isRequired(comp){
        return comp.mandatory;
    }

}
