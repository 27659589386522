<div #content class="content">
  <ng-content></ng-content>
</div>
@if (hideButtonOnExpand() ? expandable && !(expanded || expanding) : expandable) {
  <button
    mat-button
    class="expand-button"
    (click)="toggle()"
    [class.at-right]="buttonPosition() === 'right'"
    [class.at-left]="buttonPosition() === 'left'"
    [class.is-overlapping]="buttonOverlap() && !(expanded || expanding)"
    [class.has-icon]="buttonStyle() === 'full'"
    [class.mat-icon-button]="buttonStyle() === 'icon'"
    [color]="buttonColor()"
    [mulo-aria-describe]="descriptionLabel()"
    [disabled]="buttonDisabled()"
    >
    @if (buttonStyle() === 'full' || buttonStyle() === 'icon') {
      <mat-icon
        [svgIcon]="expanded || expanding ? buttonIcon.up : buttonIcon.down"
      ></mat-icon>
    }
    @if (buttonStyle() === 'full' || buttonStyle() === 'text') {
      {{ expanded || expanding ? collapseLabel() : expandLabel() }}
    }
  </button>
}
