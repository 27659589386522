@if (listItems && listItems.length > 1) {
  <mulo-portion-list-toolbar
    (clicked)="distributePortions($event)"
    @growInAnimation
    @growOutAnimation
    [labelDistribute]="toolbarLabels()[0]"
    [labelDistDesc]="toolbarLabels()[1]"
    [labelDistEq]="toolbarLabels()[2]"
    [labelDistAsc]="toolbarLabels()[3]"
  ></mulo-portion-list-toolbar>
}
<mat-list role="list" class="mulo-elevation-list padding-top-zero">
  <ng-content></ng-content>
</mat-list>
