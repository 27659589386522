import { NgModule } from '@angular/core';
import { FunkyButtonComponent } from '@exl-ng/mulo-common';
import { InternalOrganizationAutocompleteInputModule } from '../../parts/internal-organization-input/internal-organization-autocomplete-input.module';
import { SharedModule } from '../../shared/shared.module';
import { AddProjectComponent } from './add-project.component';

@NgModule({
    imports: [
        SharedModule,
        FunkyButtonComponent,
        InternalOrganizationAutocompleteInputModule,
        AddProjectComponent,
    ],
    exports: [AddProjectComponent],
})
export class AddProjectModule {}
