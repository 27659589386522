<mat-expansion-panel
  class="mat-elevation-z"
  [class.has-focus]="focused"
  [expanded]="expanded"
  (opened)="handleOpened()"
  (closed)="handleClosed()"
  (mouseenter)="mouseOver(true)"
  (mouseleave)="mouseOver(false)"
  >
  <mat-expansion-panel-header [expandedHeight]="headerHeightExpanded()" [collapsedHeight]="headerHeightCollapsed()">
    <mat-panel-title [id]="id() + '_title'" [muloElementAsHeading]="a11yHeaderLevel()">{{ title() }}</mat-panel-title>
  </mat-expansion-panel-header>
  @if (showSectionActions) {
    <div
      class="section-actions"
      [@heightInAnimation]="{ value: 'enter', params: { opacityFrom: 0 } }"
      [@heightOutAnimation]="{ value: 'leave', params: { opacityTo: 0 } }"
      >
      <span class="cdk-visually-hidden" cdkAriaLive="polite">{{ actionsDisplayAriaLabel() }}</span>
      <div class="action-wrap">
        <ng-content select="[slot='section-action']"></ng-content>
        <!-- <button
        mat-raised-button
        color="primary"
        class="margin-right-xs margin-left-sm margin-bottom-xs"
        (click)="onApplyClicked()"
        >
        Apply
      </button>
      <button
        mat-button
        color="primary"
        class="margin-right-xs margin-bottom-xs"
        (click)="onClearClicked()"
        >
        Clear
      </button> -->
    </div>
  </div>
}
<div
  class="items-wrapper"
  role="group"
  [attr.aria-labelledby]="id() + '_title'"
  [ngClass]="!itemsReveal() ? 'is-collapsed' : 'is-expanded'"
  [style.height.px]="sectionHeight"
  >
  <div #content class="items">
    <ng-content></ng-content>
  </div>
</div>
@if (showRevealButton()) {
  <div>
    <button
      mat-button
      (click)="onRevealClicked()"
      color="primary"
      [attr.aria-describedby]="id() + '_title'"
      [attr.aria-expanded]="itemsReveal()"
      >
      {{ itemsRevealLabel() }}
    </button>
  </div>
}
</mat-expansion-panel>
