import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { JwtUtilService } from './jwt-util.service';
import { AuthService } from './auth.service';
import { UrlUtils } from '../shared/utils/url.utils';
import { RouterService } from './router.service';

@Injectable()
export class AuthGuard {
    private jwtUtilService = inject(JwtUtilService);
    private router = inject(Router);
    private authService = inject(AuthService);
    private routerService = inject(RouterService);

    institutionCode: string;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const role = route.data?.role ?? 'Researcher';
        const url: string = window.location.href;
        return this.checkLogin(url, role);
    }

    checkLogin(url: string, role: string): boolean {
        if (this.jwtUtilService.isResearcherTypeLoggedIn()) {
            return true;
        } else if (
            role === 'User' &&
            this.jwtUtilService.isUserTypeLoggedIn()
        ) {
            return true;
        }
        if (this.jwtUtilService.isUserTypeLoggedIn()) {
            this.routerService.goToHomepage();
            return true;
        }
        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;
        this.authService.redirectRoute = window.location.pathname.replace(
            '/esploro',
            '',
        );
        const queryParams = { institution: this.institutionCode };
        this.router.navigate(['/login'], { queryParams });
        return false;
    }
}
