import { Directive, ElementRef, Renderer2, OnDestroy, Input, inject, input } from '@angular/core';
import {
  FocusMonitor,
  FocusableOption,
  FocusKeyManager,
  AriaDescriber,
  FocusOrigin,
} from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

type State = 'Save' | 'Edit';

/**
 * Quill editor accessibility directive.
 * - Addition of aria-labels for toolbar buttons.
 * - Focus monitoring.
 * - Arrow keys navigation.
 */
@Directive({
    selector: '[muloRichTextA11y]',
    exportAs: 'muloRichTextA11y',
    host: {
        class: 'remove-content-label',
    },
    standalone: true,
})
export class RichTextA11yDirective implements OnDestroy {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private focusMonitor = inject(FocusMonitor);
  private translate = inject(TranslateService);
  private ariaDescriber = inject(AriaDescriber);

  private linkPopupState: State = 'Edit';

  keyManager: FocusKeyManager<QuillButton>;
  monitoringElements: HTMLElement[];
  listeners = [];
  private tooltipLinkContainerLabel = new Subject<{
    containerLabel: string;
    buttonState: State;
  }>();

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input('aria-label') ariaLabel: string;

  /**
   * place holder input use the placeholder input of the quill-text editor component
   */
  readonly placeholder = input<string>(undefined);

  readonly required = input<boolean>(undefined);

  constructor() {
    this.monitoringElements = new Array<HTMLElement>();
  }

  ngOnDestroy() {
    this.tooltipLinkContainerLabel.complete();
    this.monitoringElements.forEach((element) =>
      this.focusMonitor.stopMonitoring(element)
    );
    this.listeners.forEach((unlisten) => unlisten());
    this.monitoringElements = null;
    this.listeners = null;
  }

  // Run the functions when the quill editor created finished
  // Bind this function to the `onEditorCreated` output of quill-editor
  onEditorCreated(event) {
    this.toolbarAccessibilityAddition();
    this.contenteditableAccessibilityAddition();
  }

  toolbarAccessibilityAddition() {
    const quillButtons: HTMLElement[] = this.getQuerySelectorAll(
      'button, [role=button]'
    );
    quillButtons.forEach(this.monitorToolbarButtons);
    quillButtons.forEach(this.setAriaLabel);

    this.setHeaderSelectAriaLabel(quillButtons);

    this.supportArrowKeysNavigation(quillButtons);

    const toolbar = this.getQuerySelector('div.ql-toolbar');
    this.listen(toolbar, 'keydown', this.keyDown);
    this.renderer.setAttribute(toolbar, 'role', 'toolbar');

    this.monitor(toolbar, true).subscribe((origin) => {
      if (!origin) {
        // settimeout because of the expanded buttons (e.g. heading). When collapsed children elements are hidden.
        setTimeout(() => {
          this.preventFocusLoss();
        }, 60);
      }
    });

    this.linkPopupToolbarA11y();
  }

  /**
   * * Add a11y to link popup.
   *  When user add a link to desctiption (contenteditable element), he can edit and save the link address.
   *  The popup that enable it change the DOM via css, and is not accessible.
   *  This function add the labels and the focus to the relevant elements,
   *  The Labels are hard-coded becuase we are not change the visible labels for now.
   */
  linkPopupToolbarA11y() {
    const addButtonLabel = (button: HTMLElement, label: string) => {
      const content = this.renderer.createText(label);
      this.renderer.appendChild(button, content);
    };

    const saveEditButton = this.getQuerySelector('a.ql-action');
    this.linkButtonPopupA11y(saveEditButton);
    addButtonLabel(saveEditButton, 'Edit');

    const removeButton = this.getQuerySelector('a.ql-remove');
    this.linkButtonPopupA11y(removeButton);
    addButtonLabel(removeButton, 'Remove');

    const link = this.getQuerySelector('a.ql-preview');
    this.listen(link, 'keydown.space', (event$: KeyboardEvent) =>
      this.clickLinkButton(event$, link)
    );

    const inputLink = this.getQuerySelector('input[data-formula]');
    this.renderer.setAttribute(inputLink, 'aria-label', 'Enter link:');

    // Save state have auto focus on the input field
    this.listen(inputLink, 'focus', () => {
      this.tooltipLinkContainerLabel.next({
        containerLabel: 'Edit link:',
        buttonState: 'Save',
      });
    });

    this.linkTooltipFocusTrap(link, removeButton, false);
    this.linkTooltipFocusTrap(inputLink, saveEditButton, true);
  }

  /**
   * Focus Trap for link pop-up elements.
   * ? using the focusTrap cdk isn't possible because it include some dynamic hidden elements. Handle it
   */
  private linkTooltipFocusTrap(
    firstElement: HTMLElement,
    lastElement: HTMLElement,
    stateCondition: boolean
  ) {
    this.listen(lastElement, 'keydown.Tab', (event: KeyboardEvent) => {
      if (!stateCondition || this.linkPopupState === 'Save') {
        event.preventDefault();
        firstElement.focus();
      }
    });

    this.listen(firstElement, 'keydown', (event: KeyboardEvent) => {
      if (event.code === 'Tab' && event.shiftKey) {
        event.preventDefault();
        lastElement.focus();
      }
    });
  }

  linkButtonPopupA11y(button: HTMLElement) {
    this.renderer.setAttribute(button, 'tabindex', '0');
    this.renderer.setAttribute(button, 'role', 'button');

    this.listen(button, 'keydown.enter', (event$: KeyboardEvent) =>
      this.clickLinkButton(event$, button)
    );
    this.listen(button, 'keydown.space', (event$: KeyboardEvent) =>
      this.clickLinkButton(event$, button)
    );
    this.monitor(button);
  }

  clickLinkButton(event: KeyboardEvent, button: HTMLElement) {
    // prevent event from input
    event.preventDefault();
    event.stopPropagation();
    button.click();
  }

  contenteditableAccessibilityAddition() {
    const inputDiv = this.getQuerySelector(
      'div.ql-editor[contenteditable=true]'
    );
    const inputFocus = this.monitor(inputDiv);
    if (!this.ariaLabel) {
      this.translate
        .get('research.aria.richtext.input.label')
        .subscribe((label) => (this.ariaLabel = label));
    }
    this.renderer.setAttribute(inputDiv, 'aria-label', this.ariaLabel);
    if (this.placeholder()) {
      const label = this.createAlikeMatLabel();
      this.renderer.setAttribute(inputDiv, 'aria-labelledby', label.id);
      this.listen(label, 'click', () => inputDiv.focus());
      if (this.required()) {
        this.renderer.setAttribute(inputDiv, 'aria-required', 'true');
        this.renderer.setAttribute(inputDiv, 'aria-describedby', 'ql-error');
      }
    }

    inputDiv.setAttribute('role', 'textbox');
    inputDiv.setAttribute('aria-multiline', 'true');
    this.handleLinkToolbarClose(inputDiv);
  }

  private createAlikeMatLabel(): HTMLElement {
    const inputContainer = this.getQuerySelector('div.ql-container');
    const label = this.renderer.createElement('label');
    this.renderer.setAttribute(label, 'id', 'mulo-mat-label-quill');
    this.renderer.addClass(label, 'mulo-mat-label');

    const text = this.renderer.createText(this.placeholder());
    this.renderer.appendChild(label, text);

    if (this.required()) {
      const asterisk = this.renderer.createElement('span');
      this.renderer.setAttribute(asterisk, 'aria-hidden', 'true');
      this.renderer.addClass(asterisk, 'mark-required');
      const asteriskText = this.renderer.createText(' *');

      this.renderer.appendChild(asterisk, asteriskText);
      this.renderer.appendChild(label, asterisk);
    }
    // this.renderer.appendChild(span, label);

    this.renderer.insertBefore(
      inputContainer,
      label,
      inputContainer?.firstChild
    );
    return label;
  }

  /**
   * Hide pop-up on Esc or out of input focus
   * */
  private handleLinkToolbarClose(inputDiv: HTMLElement) {
    const inputDivWrapper = inputDiv.parentElement as HTMLElement;
    const linkTooltipContainer =
      inputDivWrapper.lastElementChild as HTMLElement; //this.getQuerySelector('div.ql-tooltip');

    const hideLinkTootltip = () =>
      this.renderer.addClass(linkTooltipContainer, 'ql-hidden');
    this.listen(
      linkTooltipContainer,
      'keydown.Escape',
      (event: KeyboardEvent) => {
        event.stopPropagation();
        event.preventDefault();
        hideLinkTootltip();
        inputDiv.focus();
      }
    );

    this.handleTooltipLinkLabels(linkTooltipContainer);

    this.monitor(inputDivWrapper, true).subscribe((origin) => {
      if (!origin) {
        hideLinkTootltip();
      }
    });
  }

  handleTooltipLinkLabels(linkTooltipContainer: HTMLElement) {
    const span = this.renderer.createElement('span');
    this.renderer.addClass(span, 'link-label');
    const text = this.renderer.createText('Visit URL:');
    const actionButton = linkTooltipContainer.querySelector('.ql-action');

    this.renderer.appendChild(span, text);
    this.renderer.insertBefore(
      linkTooltipContainer,
      span,
      linkTooltipContainer.firstChild
    );

    this.tooltipLinkContainerLabel.subscribe((labels) => {
      this.renderer.setProperty(span, 'textContent', labels.containerLabel);
      this.renderer.setProperty(
        actionButton,
        'textContent',
        labels.buttonState
      );
      this.linkPopupState = labels.buttonState;
    });

    this.monitor(linkTooltipContainer, true).subscribe((origin) => {
      if (!origin) {
        this.tooltipLinkContainerLabel.next({
          containerLabel: 'Visit URL:',
          buttonState: 'Edit',
        });
      }
    });
  }

  preventFocusLoss() {
    if (!this.keyManager.activeItem || this.keyManager.activeItem.hidden) {
      this.keyManager.updateActiveItem(0);
    }
    this.keyManager.activeItem.tabindex = 0;
  }

  keyDown = (event: KeyboardEvent) => {
    if (event.code === 'End') {
      this.keyManager.setLastItemActive();
    } else if (event.code === 'Home') {
      this.keyManager.setFirstItemActive();
    } else {
      this.keyManager.onKeydown(event);
    }
  };

  getQuerySelectorAll(queryText: string): HTMLElement[] {
    return this.elementRef.nativeElement.querySelectorAll(queryText);
  }

  private getQuerySelector(queryText: string): HTMLElement {
    return this.elementRef.nativeElement.querySelector(queryText);
  }

  /**
   * * Event Listener
   *  Using the renderer.listen function
   *  Save the listener to unlisten on destroy
   */
  private listen = (
    element: HTMLElement,
    event: string,
    func: (event?: KeyboardEvent) => void
  ) => {
    const listen = this.renderer.listen(element, event, func);
    this.listeners.push(listen);
  };

  /**
   * Monitoring buttons
   * Save elements to stop monitoring on destroy.
   * By defulat set the cdk-focused and cdk-{origin}-focused classes on focus,
   * and remove the classes on blur. The origin can be 'mouse'/'keyboard'/'program'.
   */
  private monitor = (
    element: HTMLElement,
    checkChildren?: boolean
  ): Observable<FocusOrigin> => {
    this.monitoringElements.push(element);
    return this.focusMonitor.monitor(element, checkChildren);
  };

  /**
   * Monitoring every button in the quill toolbar.
   * Set tabindex to 0/-1 depends on focus/blur
   *
   * @param element to monitor
   */
  private monitorToolbarButtons = (element: HTMLElement) => {
    this.monitor(element).subscribe((origin) => {
      element.tabIndex = !origin ? -1 : 0;
    });
  };

  private supportArrowKeysNavigation(quillButtons: HTMLElement[]) {
    const qb: QuillButton[] = [...quillButtons].map(
      (button) => new QuillButton(button)
    );

    this.keyManager = new FocusKeyManager(qb)
      .skipPredicate((item) => item.hidden)
      .withWrap()
      .withHorizontalOrientation('ltr');

    this.preventFocusLoss();
  }

  private setAriaLabel = (element: HTMLElement) => {
    const ariaLabel = this.getLabel(element);
    if (ariaLabel) {
      this.renderer.setAttribute(element, 'aria-label', ariaLabel);
    }
  };

  private getLabel(element: Element) {
    let value = '';
    switch ('button') {
      // <button>
      case element.tagName.toLowerCase():
        value = element.getAttribute('value');
        break;
      // <span role=button>
      case element.getAttribute('role').toLowerCase():
        value = element.getAttribute('data-value');
        if (!value) {
          if (element.closest('[class^="ql-align"]')) {
            value = element.hasAttribute('aria-controls') ? 'Align' : 'Left';
          } else if (element.closest('[class^="ql-picker-options"]')) {
            value = 'normal';
          }
        }
        break;
      // Not a button. Don't set aria-label.
      default:
        return '';
    }

    if (!value) {
      value = element.className.substring(
        'ql-'.length,
        element.className.length
      );
    }
    this.translate
      .get(`research.aria.richtext.toolbar.${value.toLowerCase()}`)
      .subscribe((label) => {
        value = label ? label : value;
      });
    return value;
  }

  setHeaderSelectAriaLabel(quillButtons: NodeList | HTMLElement[]) {
    const headerSelect = [].find.call(quillButtons, (button: HTMLElement) =>
      button.parentElement.classList.contains('ql-header')
    );
    const headerOptions = [].filter.call(quillButtons, (button: HTMLElement) =>
      button.parentElement?.parentElement.classList.contains('ql-header')
    );
    this.setHeaderSelectChangelistener(headerSelect, headerOptions);
  }

  setHeaderSelectChangelistener(
    element: HTMLElement,
    headerOptions: HTMLElement[]
  ) {
    if (!element || !(headerOptions?.length > 0)) {
      return;
    }

    // set the initial value, set only once
    const oldLabel = element.getAttribute('aria-label');
    const label = this.translate.instant(
      'research.aria.richtext.toolbar.normal'
    );
    this.renderer.setAttribute(element, 'aria-label', label);
    this.ariaDescriber.describe(element, oldLabel);

    // inner function to listen heading click buttons
    const clickButtonListeners = (button: HTMLElement, event: string) => {
      this.listen(button, event, () => {
        if (element.hasAttribute('data-value')) {
          const dataValue = element.getAttribute('data-value');
          const label = this.translate.instant(
            `research.aria.richtext.toolbar.${dataValue}`
          );
          this.renderer.setAttribute(element, 'aria-label', label);
        } else {
          const label = this.translate.instant(
            'research.aria.richtext.toolbar.normal'
          );
          this.renderer.setAttribute(element, 'aria-label', label);
        }
      });
    };

    // Check changes on click
    headerOptions.forEach((buttonOption) => {
      clickButtonListeners(buttonOption, 'click');
      clickButtonListeners(buttonOption, 'keydown.enter');
    });
  }
}

/**
 * Class for FocusKeyManager items
 * Every item must have the focus function.
 */
class QuillButton implements FocusableOption {
  constructor(private el: HTMLElement) {
    el.tabIndex = -1;
  }

  /**
   * Skipping collapsed buttons in the quill toolbar.
   */
  get hidden(): boolean {
    return (
      this.el.parentElement.hasAttribute('aria-hidden') &&
      this.el.parentElement.getAttribute('aria-hidden') === 'true'
    );
  }

  set tabindex(index: number) {
    // wait focus/blur to change and set the tabindex.
    setTimeout(() => {
      this.el.tabIndex = index;
    }, 20);
  }

  focus() {
    if (!!this.el['focus']) {
      this.el.focus();
    }
  }
}
