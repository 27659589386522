import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlUtils } from '../../../shared/utils/url.utils';
import { RestApiBase } from '../../../shared/configurations/rest-api-base.config';
import { JwtUtilService } from '../../../core/jwt-util.service';
import { Grant } from '../../../shared/interfaces/grant.model';

@Injectable()
export class DepositGrantsService {
    private http = inject(HttpClient);
    private jwtUtilService = inject(JwtUtilService);

    params: HttpParams;
    private _lastChosenExternalOrganzation = '';

    constructor() {
        const institutionCode = UrlUtils.getParam('institution');
        this.params = new HttpParams().set('institution', institutionCode);
    }

    getAllGrants() {
        return this.http.get(RestApiBase.GRANT_LIST, {params: this.params});
    }

    getSuggestedGrants() {
        const userId = this.jwtUtilService.getUserID();
        return this.http.get(RestApiBase.SUGGESTED_GRANT_FOR_USER + '/' + userId, {params: this.params});
    }

    addNewGrant(newGrant: Grant) {
        return this.http.post(RestApiBase.ADD_GRANT, newGrant, {params: this.params, responseType: 'text'});
    }

    searchExternalGrants(grantName: string) {
        this.params = new HttpParams().set('grantName', grantName);
        return this.http.get(RestApiBase.SEARCH_EXTERNAL_GRANTS, {params: this.params});
    }

    enrichAndAddGrantFromPivot(grantName: string) {
        const institutionCode = UrlUtils.getParam('institution');
        const params = new HttpParams().set('institution', institutionCode)
            .set('grantName', grantName);
        return this.http.get(RestApiBase.ENRICH_AND_ADD_GRANT_FROM_PIVOT, {params: params});
    }



    get lastChosenExternalOrganization(): string {
        return this._lastChosenExternalOrganzation;
    }

    set lastChosenExternalOrganization(value: string) {
        this._lastChosenExternalOrganzation = value;
    }


}
