import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { UserMenuComponent } from './user-menu.component';
import { UserMenuPanelComponent } from './user-menu-panel/user-menu-panel.component';
import { UserMenuButtonComponent } from './user-menu-button/user-menu-button.component';
import { RouterModule } from '@angular/router';
import {
    MatBottomSheetModule,
    MatBottomSheetRef,
    MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { AvatarComponent } from '@exl-ng/mulo-common';

@NgModule({
    imports: [
        SharedModule,
        AvatarComponent,
        RouterModule,
        MatBottomSheetModule,
        UserMenuComponent,
        UserMenuPanelComponent,
        UserMenuButtonComponent,
    ],
    providers: [
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
        { provide: MatBottomSheetRef, useValue: {} },
    ],
    exports: [UserMenuComponent],
})
export class UserMenuModule {}
