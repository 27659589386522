import { Directive, NgModule, ElementRef, inject, input, output } from '@angular/core';

@Directive({
    selector: '[muloInView]',
    standalone: true,
})
export class InViewDirective {
  private el = inject(ElementRef);

  readonly threshold = input<number | string>(1, { alias: "muloInViewThreshold" });
  readonly offset = input<string>('0px', { alias: "muloInViewOffset" });

  readonly visible = output<boolean | boolean[]>({ alias: 'muloInViewVisible' });

  private observer: IntersectionObserver | undefined;

  ngAfterViewInit() {
    const threshold = this.threshold();
    const thr =
      typeof threshold === 'string'
        ? parseFloat(threshold)
        : threshold;
    const options: IntersectionObserverInit = {
      rootMargin: this.offset(),
      threshold: thr,
    };
    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        this.visible.emit(
          entries.length === 1
            ? entries[0].intersectionRatio === 1
            : entries.map((entry) => entry.intersectionRatio === 1)
        );
      },
      options
    );

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}


