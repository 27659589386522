import { Component, Input, OnChanges, OnInit, SimpleChanges, inject, input, output, viewChild } from '@angular/core';
import { DepositDoiService } from './deposit-doi.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { TranslateService } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { SharedModule } from '../../../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { AbstractControl, FormControl } from '@angular/forms';
import { validateDoiFormat } from '../../../shared/validators/doi.validator';

@Component({
    selector: 'esp-deposit-doi',
    templateUrl: './deposit-doi.component.html',
    styleUrls: ['./deposit-doi.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
    imports: [SharedModule, MatFormFieldModule, MatIcon]
})
export class DepositDoiComponent implements OnInit, OnChanges {
    private doiSvc = inject(DepositDoiService);
    private xl8Svc = inject(TranslateService);

    readonly placeholder = input<string>(undefined);
    readonly hint = input<string>(undefined);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() doi: string;
    readonly assetType = input<string>(undefined);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() isReserved = false;
    readonly isReservable = input(true);
    // TODO: Skipped for migration because:
    //  Your application code writes to the input. This prevents migration.
    @Input() isRegistered = false;
    readonly isAutoPopulated = input(false);
    readonly doiChanged = output<string>();
    readonly doiReserved = output<string>();
    readonly doiDismissed = output<void>();
    readonly dismissBtn = viewChild<MatButton>('dismissBtn');
    readonly reserveBtn = viewChild<MatButton>('reserveBtn');
    haveDoiConfigurationPerAssetType = false;
    reserveDoiInProcess = false;
    translatedResourceType = {};
    readonly doiControl = input<AbstractControl>(new FormControl('', [
    validateDoiFormat,
]));

    constructor() {
        const doiControl = this.doiControl();
        if (doiControl) {
            doiControl.valueChanges.subscribe((value) => {
                this.doi = value;
                this.doiChanged.emit(value);
            });
        }
    }

    get isReservedDoiButtonVisible() {
        return (
            this.isReservable() &&
            this.haveDoiConfigurationPerAssetType &&
            !this.doi
        );
    }

    ngOnInit() {
        this.checkIfhaveDoiConfigurationPerAssetType();
        this.setTranslatedResourceType();
    }

    ngOnChanges(changes: SimpleChanges) {
        const doi = changes['doi'];
        if (doi) {
            const doiControl = this.doiControl();
            if (doi.firstChange) {
                this.doiControl().reset(doi.currentValue, { emitEvent: false });
            } else if (doi.currentValue !== doiControl.value) {
                doiControl.patchValue(doi.currentValue);
            }
        }
    }

    checkIfhaveDoiConfigurationPerAssetType() {
        this.doiSvc.haveReservedDoiButton(this.assetType()).subscribe({
            next: (response) => {
                this.haveDoiConfigurationPerAssetType = response;
            },
            error: (error) => {
                console.error(error);
                this.haveDoiConfigurationPerAssetType = false;
            },
        });
    }

    setTranslatedResourceType() {
        const resourceType = this.assetType() || '_';
        this.xl8Svc.get(resourceType).subscribe((translation: String) => {
            this.translatedResourceType['0'] = translation.toLowerCase();
        });
    }

    requestReserveDoi() {
        this.reserveDoiInProcess = true;
        this.doiSvc.getNewReserveDoi(this.assetType()).subscribe((newDoi) => {
            this.doiControl().patchValue(newDoi);
            this.isReserved = true;
            this.doiReserved.emit(newDoi);
            this.reserveDoiInProcess = false;
            setTimeout(() => this.dismissBtn().focus());
        });
    }

    dismissDoi() {
        this.doiControl().reset();
        this.isRegistered = false;
        this.isReserved = false;
        this.doiDismissed.emit();
        setTimeout(() => this.reserveBtn().focus());
    }
}
