<dl class="dictionary is-{{alignLabels()}}-aligned as-{{display()}}">
  @if (items) {
    @for (key of keys(items); track key) {
      @if (parseValue(key); as value) {
        <div mulo-dictionary-item
          [label]="key"
          [labelAlign]="alignLabels()"
          >
          <span slot="content" [innerHTML]="value"></span>
        </div>
      }
    }
  }
  <ng-content></ng-content>
</dl>
