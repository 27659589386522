<div class="drag-grip"></div>

@if (hidden()) {
  <div class="hidden-indicator"></div>
}
@if (extension() && useListIcon()) {
  <span class="item-list-icon" matListItemIcon>
    <mat-icon [svgIcon]="type() | fileTypeIcon: true : extension()"></mat-icon>
    <span>{{ extension() }}</span>
  </span>
}
<div class="mdc-list-item__content">
  @if (hidden() && hiddenLabel()) {
    <mulo-status-tag [description]="hiddenDescription()">{{ hiddenLabel() }}</mulo-status-tag>
  }
  <ng-content select="[matListItemTitle]"></ng-content>
  <ng-content select="[mat-line], [matLine], [matListItemLine]"></ng-content>

  <ng-template matMenuContent #actionsBlock>
    <ng-content select="mulo-action-list-item-action"></ng-content>
    @if (removable()) {
      <mulo-action-list-item-action
        type="icon-btn"
        [icon]="media.is('gt-sm') ? 'close' : null"
        [labels]="[fileRemoveTip()]"
        (actionEvent)="onRemove($event, index())"
      ></mulo-action-list-item-action>
    }
  </ng-template>

  <div class="list-item-wrap">
    <div class="list-item-header">
      <!-- list number -->
      @if (index()) {
        <span class="item-index">{{ index() }}. </span>
      }
      <span class="item-name">
        <!-- files -->
        <ng-content select="[slot='file-info']"></ng-content>
        @if (!fileInfoTemplate()) {
          @if (baseName() ?? name(); as itemName) {
            <span class="item-basename semibold">
              @if (url()) {
                <a [href]="url()" [target]="urlTarget()" [matTooltip]="itemName">{{ itemName }}</a>
              } @else {
                <span [matTooltip]="itemName">{{ itemName }}</span>
              }
            </span>
          }
          @if (extension() && !useListIcon()) {
            <span class="item-ext item-header-field">
              <mat-icon [svgIcon]="type() | fileTypeIcon: true : extension()"></mat-icon>
              <span>{{ extension() }}</span>
            </span>
          }
          @if (date()) {
            <span class="item-header-field">{{ date() }}</span>
          }
          @if (size()) {
            <span class="item-size item-header-field">{{ size() | fileSize }}</span>
          }
        }
      </span>

      <span class="item-actions">
        <ng-content select="[mulo-file-action]"></ng-content>

        <ng-container *mediaIf="'gt-sm'">
          <ng-container *ngTemplateOutlet="actionsBlock"></ng-container>
        </ng-container>
        @if (progress()) {
          <span class="progress-count">
            @if (progress() < 100) {
              <mat-icon svgIcon="upload-arrow"></mat-icon>
              <span class="semibold">{{ progress() | percent }}</span>
            } @else {
              <mat-icon svgIcon="check" [matTooltip]="doneUploadMsg()" class="color-positive"></mat-icon>
            }
          </span>
          @if (progress() >= 100) {
            <a mat-icon-button [href]="'#'" [matTooltip]="filePreviewTip()" target="_blank">
              <mat-icon svgIcon="eye-outline"></mat-icon>
            </a>
          }
        }
      </span>
      @if (progress() && progress() < 100) {
        <mat-progress-bar [bufferValue]="0" mode="buffer" [value]="progress()"> </mat-progress-bar>
      }
    </div>
    <div class="list-item-content" #content>
      <!-- content that appears in the opened drawer -->
      <ng-content></ng-content>
    </div>
  </div>
</div>

<ng-container *mediaIf="'lt-md'">
  @if (actions().length > 0 || removable()) {
    <button mat-icon-button [matMenuTriggerFor]="actionsMenu" [attr.aria-label]="moreBtnLabel()">
      <mat-icon svgIcon="dots-vertical"></mat-icon>
    </button>
  }
  <mat-menu #actionsMenu="matMenu" xPosition="before">
    <ng-container *ngTemplateOutlet="actionsBlock"></ng-container>
  </mat-menu>
</ng-container>
<ng-content select="[matListItemMeta]"></ng-content>

<ng-content select="mat-divider"></ng-content>

<!--
  Strong focus indicator element. MDC uses the `::before` pseudo element for the default
  focus/hover/selected state, so we need a separate element.
-->
<div class="mat-mdc-focus-indicator"></div>
