import { Component, Input, input } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'mulo-keyword-item',
    templateUrl: './keyword-item.component.html',
    styleUrls: ['./keyword-item.component.scss'],
    host: {
        class: 'mulo-keyword-item',
        '[class.as-block]': 'this.flavor === "block"',
    },
    imports: [
        MatIcon,
        SvgViewboxDirective,
        NgTemplateOutlet
    ]
})
export class KeywordItemComponent {
  /** Optional URL to make the keyword a link */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() url: string;
  /** Show/hide the icon */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() showIcon: boolean = false;
  /** Style of the keyword */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() flavor: 'block' | 'plain' = 'plain';

  /** The icon to display next the keyword, if displayed */
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set icon(val) {
    this._icon = val;
  }
  get icon(): string | 'default' | 'none' {
    return this._icon === 'default' ? this._defaultIcon : this._icon;
  }
  readonly rtlMirrorIcon = input<boolean>(undefined);

  _icon = 'default';
  _defaultIcon = 'tag';

  constructor() {}

  setFlavor(val) {
    this.flavor = val;
  }
}
