import {
  AfterContentInit,
  Component,
  contentChildren,
  effect,
  ElementRef,
  InjectionToken,
  input,
  NgZone,
  inject,
  TemplateRef,
  computed,
} from '@angular/core';
import { MatListItem } from '@angular/material/list';
import { ActionListItemActionComponent } from './action-list-item-action/action-list-item-action.component';
import { Platform } from '@angular/cdk/platform';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MediaIfDirective, MediaService } from '@exl-ng/mulo-core';
import {
  MatMenu,
  MatMenuContent,
  MatMenuTrigger,
} from '@angular/material/menu';
import { CdkObserveContent } from '@angular/cdk/observers';
import { StatusTagComponent } from '../status-tag';
import { NgTemplateOutlet } from '@angular/common';

/**
 * An interface and InjectionToken to prevent Circular dependency of ActionListItemComponent and ActionListItemActionComponent
 * Becuase parent component ActionListItemComponent hold contentChildren of child ActionListItemActionComponent
 * and ActionListItemActionComponent inject ActionListItemComponent
 */
export interface MuloActionListItem {}

export const MULO_ACTION_LIST_ITEM = new InjectionToken<MuloActionListItem>(
  'MULO_ACTION_LIST_ITEM',
);

@Component({
  selector: 'mulo-action-list-item',
  templateUrl: './action-list-item.component.html',
  styleUrls: ['./action-list-item.component.scss'],
  host: {
    '[class.is-hidden]': 'hidden()',
    '[class.is-hover]': 'showOnHover()',
  },
  providers: [
    {
      provide: MULO_ACTION_LIST_ITEM,
      useExisting: ActionListItemComponent,
    },
  ],
  imports: [
    StatusTagComponent,
    CdkObserveContent,
    MatMenuContent,
    MediaIfDirective,
    NgTemplateOutlet,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
  ],
})
export class ActionListItemComponent
  extends MatListItem
  implements MuloActionListItem, AfterContentInit
{
  media = inject(MediaService);

  hidden = input(false);
  hiddenLabel = input('Hidden');
  hiddenDescription = input('');
  showOnHover = input(false);
  moreBtnLabel = input('More Options');
  actions = contentChildren(ActionListItemActionComponent);
  mainActionCount = computed(
    () => this.actions().filter((action) => !action.keepVisible()).length,
  );
  useMenu = input(this.media.is('lt-md'));

  updateItemLines = super._updateItemLines;

  constructor() {
    const _element = inject<ElementRef<HTMLElement>>(ElementRef);
    const _ngZone = inject(NgZone);
    const _platform = inject(Platform);

    super(_element, _ngZone, null, _platform);

    effect(() => {
      const hidden = this.hidden();
      if (hidden !== undefined) {
        this.recalcLinesForHidden();
      }
    });
  }

  ngAfterContentInit() {
    // Disable the item if all action buttons disabled
    this.disabled =
      this.disabled ||
      (this.actions()?.length > 0 &&
        !this.actions().some((action) => !action.isDisabled()));
    this.recalcLinesForHidden();
    this._lines.changes.subscribe(() => this.recalcLinesForHidden());
    this._titles.changes.subscribe(() => this.recalcLinesForHidden());
  }

  recalcLinesForHidden() {
    if (this.hidden() === true) {
      this.lines = this.inferLinesFromContent() + 1;
    } else {
      this.lines = this.inferLinesFromContent();
    }
  }

  private inferLinesFromContent() {
    let numOfLines = this._titles?.length + this._lines?.length;
    if (this._hasUnscopedTextContent) {
      numOfLines += 1;
    }
    return numOfLines;
  }
}
