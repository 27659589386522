import {Component, OnInit, Input, Directive, HostListener, HostBinding, output} from '@angular/core';

@Component({
    selector: 'exl-person-brief',
    templateUrl: './person-brief.component.html',
    styleUrls: ['./person-brief.component.scss'],
    host: { 'class': 'exl-person-brief' },
    standalone: true,
})
export class PersonBriefComponent implements OnInit {

    /* Click output (Event emitter)  */
    readonly clicked = output();
    /* Click event listener for clicks inside the component */
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.is-hoverable') @Input() hoverable = true;
    @HostListener('click', ['$event']) onclick(el) { this.handleClick(el); }


    constructor() { }

    ngOnInit() {
    }

    handleClick(el){
        const selection = window.getSelection();
        if (selection.toString().length === 0) {
            this.clicked.emit(null);
        } else {
            // Add click exceptions here (i.e when clicking on tags - don't emit the click)
        }
    }

}

/* Atribute directive to transclude the title into the person brief component */
@Directive({
    selector: '[exlPersonBriefTitle]',
    host: { 'class': 'exlPersonBriefTitle' },
    standalone: true
})
export class PersonBriefTitleDirective {
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.hasnt-color') @Input() titleAsLink = true;
}
