import {
    Component,
    Input,
    HostBinding,
    OnInit,
    input,
    output as output_1,
    output,
} from '@angular/core';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { RichTextEditorComponent } from '@exl-ng/mulo-common';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';

@Component({
    selector: 'esp-deposit-free-text',
    templateUrl: './deposit-free-text.component.html',
    styleUrls: ['./deposit-free-text.component.scss'],
    imports: [
        BadgeAutoPopulatedComponent,
        RichTextEditorComponent,
        CustomTranslatePipe,
    ],
})
export class DepositFreeTextComponent implements OnInit {
    readonly freeText = input(undefined);
    readonly placeholder = input(undefined);
    readonly output = output();
    @HostBinding('class.has-content') content;
    // TODO: Skipped for migration because:
    //  This input is used in combination with `@HostBinding` and migrating would
    //  break.
    @HostBinding('class.is-autoPopulated') @Input() autoPopulated = false;

    constructor() {}

    ngOnInit() {
        this.content = this.freeText().value;
    }

    onEditorContentChange(editorContent) {
        this.content = editorContent;
        //  this.output.emit(editorContent);
    }
}
