@if (isInternalLogin) {
  <exl-centered-block-layout>
    <div class="header">
      <mulo-brand></mulo-brand>
      <h1 class="title">{{ 'research.login.title' | translate }}</h1>
    </div>
  </exl-centered-block-layout>
  <exl-centered-block-layout width="none">
    <exl-login-box></exl-login-box>
  </exl-centered-block-layout>
}
