<ng-template #mainBtnContent>
  <mat-icon svgIcon="language" muloSvgViewbox></mat-icon>
  @if (selectedLanguage; as language) {
    @if (mode == 'full') {
      {{ language | translate }}&nbsp;({{ language | uppercase }})
    } @else {
      <span class="selected-lang">{{ language | uppercase }}</span>
      <mat-icon svgIcon="menu-down" class="menu-icon"></mat-icon>
    }
  }
  <span class="cdk-visually-hidden"> {{ ariaLabel() }}</span>
</ng-template>
@if (useDialog()) {
  @if (mode !== 'icon') {
    <button
      mat-button
      [class.has-icon]="mode == 'full'"
      [class.has-small-icon]="mode == 'full'"
      (click)="handleClick()"
      >
      <ng-container *ngTemplateOutlet="mainBtnContent"></ng-container>
    </button>
  } @else {
    <button mat-icon-button (click)="handleClick()" [attr.aria-label]="ariaLabel()">
      <mat-icon svgIcon="language" muloSvgViewbox></mat-icon>
    </button>
  }
} @else {
  @if (mode !== 'icon') {
    <button
      mat-button
      [matMenuTriggerFor]="languagesMenu"
      [class.has-icon]="mode == 'full'"
      [class.has-small-icon]="mode == 'full'"
      >
      <div class="btn-content">
        <ng-container *ngTemplateOutlet="mainBtnContent"></ng-container>
      </div>
    </button>
  } @else {
    <button mat-icon-button [attr.aria-label]="ariaLabel()" [matMenuTriggerFor]="languagesMenu">
      <mat-icon svgIcon="language" muloSvgViewbox></mat-icon>
    </button>
  }
  <mat-menu #languagesMenu="matMenu" xPosition="before">
    <div class="list-label">{{ langListLabel() }}</div>
    @for (lang of languages; track lang) {
      <button
        mat-menu-item
        (click)="handleLangChange(lang)"
        [attr.aria-current]="selectedLanguage === lang ? true : null"
        [class.selected-button]="selectedLanguage === lang"
        >
        <mat-icon [svgIcon]="selectedLanguage === lang ? 'radiobox-marked' : 'radiobox-blank'"></mat-icon>
        <span>{{ lang | translate }}&nbsp;·&nbsp;{{ lang | uppercase }}</span>
      </button>
    }
  </mat-menu>
}
