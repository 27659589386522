import {
  Component,
  OnInit,
  Input,
  HostBinding,
  input,
  output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

import { ChatBubbleComponent } from '@exl-ng/mulo-common';

@Component({
    selector: 'exl-citation-brief-notification',
    templateUrl: './citation-brief-notification.component.html',
    styleUrls: ['./citation-brief-notification.component.scss'],
    host: { class: 'exl-citation-brief-notification' },
    imports: [ChatBubbleComponent, MatButton, MatIcon, TranslateModule],
})
export class CitationBriefNotificationComponent implements OnInit {
    // TODO: Skipped for migration because:
    //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
    //  and migrating would break narrowing currently.
    @Input() notification: any;
    readonly color = input<'accent' | 'primary' | 'default'>('accent');
    readonly icon = input('alert-decagram');
    readonly time = input(new Date());
    readonly actions = input<Array<any>>(new Array());

    readonly action = output<any>();

    @HostBinding('class') get colorClass() {
        return 'exl-' + this.color();
    }

    constructor() {}

    ngOnInit() {}

    onActionClick(action) {
        this.action.emit(action);
    }
}
