import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { finalize, map, Observable, tap } from 'rxjs';
import { aliasMap, MediaQueryAlias } from '../models/media-query-alias.model';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private breakpointObserver = inject(BreakpointObserver);
  private rendererFactory = inject(RendererFactory2);
  private _document = inject<Document>(DOCUMENT);

  private renderer: Renderer2;
  private _currentlyActive: MediaQueryAlias[];

  constructor() {
    const rendererFactory = this.rendererFactory;

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private setDocumentMediaClasses(newClasses: MediaQueryAlias[]) {
    const el = this._document.documentElement;
    this.clearDocumentMediaClasses().then((res) => {
      newClasses.forEach((str) => {
        const classStr = `--${str}`;
        this.renderer.addClass(el, classStr);
      });
    });
  }

  public observe(): Observable<MediaQueryAlias[]> {
    return this.breakpointObserver.observe(Object.values(aliasMap)).pipe(
      map((bpState) =>
        Object.keys(aliasMap).filter((s) => bpState.breakpoints[aliasMap[s]]),
      ),
      finalize(() => this.clearDocumentMediaClasses()),
      tap((res: MediaQueryAlias[]) => {
        this.setDocumentMediaClasses(res);
        this._currentlyActive = res;
      }),
    );
  }

  public clearDocumentMediaClasses(): Promise<null> {
    const el = this._document.documentElement;
    const elClasses = el.getAttribute('class');
    const prevClasses = elClasses ? elClasses.split(' ') : [];
    const regex = /--/;
    return new Promise<null>((resolve) => {
      prevClasses.forEach((cls) => {
        if (cls.match(regex)) this.renderer.removeClass(el, cls);
      });
      resolve(null);
    });
  }

  /** Shorthand for isActive (boolean getter) */
  public is(bp: MediaQueryAlias | MediaQueryAlias[]) {
    const query =
      typeof bp === 'string'
        ? aliasMap[bp as MediaQueryAlias]
        : (bp as MediaQueryAlias[])?.map((s) => aliasMap[s]);
    return this.breakpointObserver.isMatched(query);
  }
}
