import {
  Component,
  computed,
  ElementRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChatBubbleComponent } from '../chat-bubble/chat-bubble.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'mulo-chat-entry',
  templateUrl: './chat-entry.component.html',
  styleUrls: ['./chat-entry.component.scss'],
  host: {
    '[class.mulo-chat-entry]': 'true',
    '[class]': 'classes()',
  },
  imports: [NgClass, ChatBubbleComponent, TranslateModule],
})
export class ChatEntryComponent {
  private elRef = inject(ElementRef);

  readonly content = input(undefined);
  readonly time = input(undefined);
  readonly sender = input(undefined);
  readonly senderTitle = input(undefined);
  readonly color = input<'accent' | 'primary'>('accent');
  readonly align = input<'left' | 'right'>('left');
  readonly index = input(undefined);
  readonly reasonPrefix = input('Return reason:');
  classList = signal<string>(undefined);

  classes = computed(() =>
    [
      this.classList,
      'mulo-' + this.color(),
      'align-' + this.align(),
      'from-' + this.sender(),
      'chat-bubble-' + this.index(),
    ]
      .filter(Boolean)
      .join(' '),
  );

  constructor() {
    const elRef = this.elRef;

    this.classList.set(elRef.nativeElement.className);
  }
}
