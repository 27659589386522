import { Injectable, inject } from '@angular/core';
import { MatDateFormats } from '@angular/material/core';
import { SystemDateFormatService } from '@exl-ng/mulo-core';

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    private systemDateFormatService = inject(SystemDateFormatService);

    date = 'YYYY/MM/DD';

    constructor() {
        this.date = this.systemDateFormatService.getSystemDateFormat();
    }

    getFormat(): string {
        return this.date;
    }

    getDateFormat() {
        const CUSTOM_DATE_FORMAT: MatDateFormats = {
            parse: {
                dateInput: [this.systemDateFormatService.getSystemDateFormat()],
            },
            display: {
                dateInput: this.systemDateFormatService.getSystemDateFormat(),
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        };

        return CUSTOM_DATE_FORMAT;
    }
}
