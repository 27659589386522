import { NgModule } from '@angular/core';
import {
    ContextFrameClosedViewDirective,
    ContextFrameComponent,
    OrcidConnectComponent,
    OrcidLinkComponent,
    OrcidLogoComponent,
} from '@exl-ng/mulo-common';
import { SharedModule } from '../../../shared/shared.module';
import { ExternalOrganizationFieldComponent } from '../../../parts/external-organization-field/external-organization-field.component';
import { AuthorsListComponent } from './authors-list/authors-list.component';
import { DepositAuthorsComponent } from './deposit-authors.component';
import { DepositAuthorsService } from './deposit-authors.service';
import { NewAuthorComponent } from './new-author/new-author.component';
import { MultipleAffiliationsComponent } from '../../../parts/multiple-affiliations/multiple-affiliations.component';
import { EditAuthorComponent } from './edit-author/edit-author.component';

@NgModule({
    imports: [
        SharedModule,
        OrcidLinkComponent,
        OrcidLogoComponent,
        OrcidConnectComponent,
        ExternalOrganizationFieldComponent,
        ContextFrameComponent,
        ContextFrameClosedViewDirective,
        MultipleAffiliationsComponent,
        EditAuthorComponent,
        DepositAuthorsComponent,
        AuthorsListComponent,
        NewAuthorComponent,
    ],
    providers: [DepositAuthorsService],
    exports: [DepositAuthorsComponent],
})
export class DepositAuthorsModule {}
