import { Component, OnInit, ElementRef, inject, input } from '@angular/core';

@Component({
    selector: 'mulo-horizontaller-item',
    templateUrl: './horizontaller-item.component.html',
    styleUrls: ['./horizontaller-item.component.scss'],
    host: {
        class: 'mulo-horizontaller-item',
        '[style.maxWidth.px]': 'maxWidth()',
        '[style.minWidth.px]': 'minWidth()',
    },
    standalone: true,
})
export class HorizontallerItemComponent implements OnInit {
  elem = inject(ElementRef);

  readonly maxWidth = input<number>(320);
  readonly minWidth = input<number>(180);

  ngOnInit(): void {}
}
