<mulo-context-frame
  (close)="handleFormClose()"
  [isOpen]="enterNewGrant"
  class="margin-top-sm"
  [closeBtnAriaLabel]="'research.aria.deposit.grants.add.close' | translate"
  >
  @if (enterNewGrant) {
    <esp-new-grant [(allGrants)]="allGrants" [(enterNewGrant)]="enterNewGrant"> </esp-new-grant>
  }

  <ng-container slot="closed-view">
    @if (!enterNewGrant) {
      <esp-deposit-grants-search
        #grantsSearch
        [showAddBtn]="true"
        (addBtnClick)="enterNewGrant = true"
        (grantSelect)="addGrant($event)"
      ></esp-deposit-grants-search>
    }
  </ng-container>
</mulo-context-frame>
@if (depositFormDataService.grants.value.length) {
  <mat-list class="grants-list mulo-elevation-list" role="list">
    @for (grant of depositFormDataService.grants.value; track grant; let i = $index; let isFirst = $first; let isLast = $last) {
      @if (grant) {
        <mat-list-item
          @growInAnimation
          @growOutAnimation
          exlDescriptionA11y="grant-{{ i }}"
          role="listitem"
          >
          <mat-icon matListItemIcon svgIcon="trophy"></mat-icon>
          <span exlDescription matListItemTitle>
            @if (grant.grantId) {
              <span class="grant-name semibold"
                >{{ grant.grantName }} ({{ grant.grantId }})</span
                >
              }
              @if (!grant.grantId && !grant.funderAgency) {
                <span class="grant-name semibold">{{
                  grant.funderCode | defaultTranslate : { default: grant.funderName }
                }}</span>
              }
              @if (!grant.grantId && grant.funderAgency) {
                <span class="grant-name semibold"
                  >{{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}
                  ,
                  {{ grant.funderAgencyCode | defaultTranslate : { default: grant.funderAgency } }}</span
                  >
                }
              </span>
              @if (grant.grantId && !grant.funderAgency) {
                <span matListItemLine>
                  <span>{{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}</span>
                </span>
              }
              @if (grant.grantId && grant.funderAgency) {
                <span matListItemLine>
                  <span
                    >{{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}
                    ,
                    {{ grant.funderAgencyCode | defaultTranslate : { default: grant.funderAgency } }}</span
                    >
                  </span>
                }
                <div class="btn-wrap" matListItemMeta>
                  <button
                    (click)="onGrantRemove(i)"
                    [attr.aria-label]="'research.aria.icon.button.remove' | translate"
                    mat-icon-button
                    type="button"
                    >
                    <mat-icon svgIcon="close"></mat-icon>
                  </button>
                </div>
              </mat-list-item>
            }
          }
        </mat-list>
      }
