import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnInit, TemplateRef, inject, input, viewChild } from '@angular/core';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { HorizontallerComponent } from '../horizontaller';
import { HeightInAnimation, HeightOutAnimation } from '../../animations';
import { take } from 'rxjs';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { HorizontallerItemComponent } from '../horizontaller/horizontaller-item/horizontaller-item.component';

import { HorizontallerComponent as HorizontallerComponent_1 } from '../horizontaller/horizontaller.component';

export interface Image {
  src: string;
  alt?: string;
  caption?: string;
}

@Component({
    selector: 'mulo-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss'],
    animations: [HeightInAnimation, HeightOutAnimation],
    imports: [
        HorizontallerComponent_1,
        HorizontallerItemComponent,
        MatIconButton,
        MatDialogClose,
        MatIcon,
        SvgViewboxDirective
    ]
})
export class ImageGalleryComponent implements OnInit, AfterViewInit {
  private cdr = inject(ChangeDetectorRef);
  dialog = inject(MatDialog);

  readonly horizontaller = viewChild(HorizontallerComponent);
  readonly dialogTmplRef = viewChild<TemplateRef<any>>('dialogTmpl');

  readonly images = input<Image[]>([]);
  readonly imgFullBtnAriaLabel = input('Open image in full-screen view');
  readonly scrollLeftBtnAriaLabel = input('Previous image');
  readonly scrollRightBtnAriaLabel = input('Next image');
  readonly dialogAriaLabel = input('Image full-screen view');
  readonly closeBtnAriaLabel = input('Close full-screen view');
  readonly rtl = input(false);
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('style.--gallery-max-height')
  @Input()
  maxHeight;
  // TODO: Skipped for migration because:
  //  This input is used in combination with `@HostBinding` and migrating would
  //  break.
  @HostBinding('style.--hover-scale')
  @Input()
  hoverScale = 1.125;

  _imagesToLoad = [];
  loading;
  currentFullImgIdx;

  ngOnInit(): void {
    this._imagesToLoad = Array.from(this.images());
    this.loading = this._imagesToLoad.length > 0;
  }

  ngAfterViewInit() {
    this.cdr.markForCheck();
  }

  imgLoaded(imgSrc: string) {
    this._imagesToLoad = this._imagesToLoad.filter((_) => _.src !== imgSrc);
    setTimeout(() => {
      this.loading = this._imagesToLoad.length > 0;
      this.cdr.markForCheck();
    });
  }

  openDialog(imgIdx) {
    this.currentFullImgIdx = imgIdx;
    const dialogRef = this.dialog.open(this.dialogTmplRef(), {
      ariaLabel: this.dialogAriaLabel(),
      panelClass: 'mulo-image-gallery-dialog',
      maxHeight: 750,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        this.currentFullImgIdx = undefined;
      });
  }
}
