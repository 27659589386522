import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'htmlTrust', standalone: true })
export class HtmlTrustPipe implements PipeTransform {
  private sanitizer = inject(DomSanitizer);


  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html.toString());
  }
}
