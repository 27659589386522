import { Component, OnInit, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'mulo-visibility-switch',
    templateUrl: './visibility-switch.component.html',
    styleUrls: ['./visibility-switch.component.scss'],
    imports: [
        MatIconButton,
        MatTooltip,
        MatIcon,
    ]
})
export class VisibilitySwitchComponent implements OnInit {
  readonly active = input<boolean>(true);
  readonly disabled = input(false);
  readonly toolTipText = input('Show/hide this');
  readonly visibleLabel = input('Visible');
  readonly hiddenLabel = input('Hidden');
  readonly changed = output();

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.changed.emit(null);
  }
}
