<div>
  <div>
    @if (displayISBN()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('isbnForDisplay')"
        [muloInputExpander]="depositFormDataService.isbnForDisplay.value"
        >
        <input
          matInput
          [formControl]="depositFormDataService.isbnForDisplay"
          />
        <mat-label>{{
          'research.status.isbn' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('isbnForDisplay')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayPublisherName()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('publisher')"
        [muloInputExpander]="depositFormDataService.publisher.value"
        class="is-short-input"
        >
        <input matInput [formControl]="depositFormDataService.publisher" />
        <mat-label>{{
          'research.status.publisher' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('publisher')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayPublicationPlace()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('publicationPlace')"
        class="is-short-input"
            [muloInputExpander]="
                depositFormDataService.publicationPlaceForDisplay.value
            "
        >
        <input
          matInput
                [formControl]="
                    depositFormDataService.publicationPlaceForDisplay
                "
          />
        <mat-label>{{
          'research.status.publicationPlace' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('publicationPlace')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
  </div>
  <div>
    @if (displayPages()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('pages')"
        class="is-short-input"
        >
        <input matInput [formControl]="depositFormDataService.pages" />
        <mat-label>{{
          'research.status.pages' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('pages')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displayEdition()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('edition')"
        class="is-short-input"
        [muloInputExpander]="depositFormDataService.edition.value"
        >
        <input matInput [formControl]="depositFormDataService.edition" />
        <mat-label>{{
          'research.status.edition' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('edition')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }

    @if (displaySeries()) {
      <mat-form-field
        [class.is-autopopulated]="isAutoPopulated('series')"
        class="is-short-input"
        [muloInputExpander]="depositFormDataService.series.value"
        >
        <input matInput [formControl]="depositFormDataService.series" />
        <mat-label>{{
          'research.status.series' | customTranslate
        }}</mat-label>
        @if (isAutoPopulated('series')) {
          <mat-icon
            matPrefix
            svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
          ></mat-icon>
        }
      </mat-form-field>
    }
  </div>
</div>
