<button mat-button>
  @if (hasProxy) {
    <mulo-avatar
      class="proxy-avatar"
      [image]="proxyAvatar()"
      [initials]="proxyInitials()"
      [description]="proxyName() + 'profile image'"
      [size]="30"
      [radius]="4"
      >
    </mulo-avatar>
    <!-- <mat-icon svgIcon="menu-down" class="proxy-avatar-arrow"></mat-icon> -->
    <!-- <mat-icon svgIcon="arrow-right-bold" class="proxy-link"></mat-icon> -->
  }
  <mulo-avatar
    [image]="avatar()"
    [initials]="userInitials()"
    [description]="displayName() + 'profile image'"
    [size]="hasProxy ? 24 : 30"
    [radius]="4"
    [shape]="hasProxy ? 'square' : 'square'"
  ></mulo-avatar>
  @if (showArrow()) {
    <mat-icon svgIcon="menu-down" class="menu-icon"></mat-icon>
  }
</button>
