import { Component, Input, OnChanges, SimpleChanges, inject, input, output } from '@angular/core';
import { LangChangeEvent, TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageSelectDialogComponent } from '../language-select/language-select.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton, MatIconButton } from '@angular/material/button';
import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'mulo-language-select-button',
    templateUrl: './language-select-button.component.html',
    styleUrls: ['./language-select-button.component.scss'],
    imports: [
        MatIcon,
        SvgViewboxDirective,
        MatButton,
        NgTemplateOutlet,
        MatIconButton,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        UpperCasePipe,
        TranslateModule
    ]
})
export class LanguageSelectButtonComponent implements OnChanges {
  private translate = inject(TranslateService);
  private dialog = inject(MatDialog);

  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() mode: 'full' | 'compact' | 'icon' = 'full';
  readonly useDialog = input(false);
  readonly ariaLabel = input('Open language menu');
  readonly langListLabel = input('Display language');
  readonly cancelLabel = input(undefined);
  readonly click = output<null>();
  readonly languageChange = output<string>();
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() languages;
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() selectedLanguage;
  dialogRef: MatDialogRef<LanguageSelectDialogComponent>;

  constructor() {
    const translate = this.translate;

    this.languages = translate.getLangs();
    this.selectedLanguage = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['languages'] && changes['languages'].currentValue) {
      this.translate.addLangs(changes['languages'].currentValue);
    }
  }

  /**
   * @internal
   */
  handleClick() {
    if (this.useDialog()) {
      this.dialogRef = this.dialog.open(LanguageSelectDialogComponent, {
        data: {
          langListLabel: this.langListLabel(),
          cancelLabel: this.cancelLabel(),
        },
      });
    }
  }

  handleLangChange(lang: string) {
    this.translate.use(lang);
    this.languageChange.emit(lang);
  }
}
