import {
  Component,
  HostBinding,
  Input,
  OnInit,
  input,
  output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatError } from '@angular/material/form-field';
import { KeyValuePipe } from '@angular/common';
import { RichTextA11yDirective } from './rich-text-a11y.directive';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'mulo-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  host: { class: 'mulo-rich-text-editor' },
  imports: [
    QuillEditorComponent,
    RichTextA11yDirective,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    KeyValuePipe,
  ],
})
export class RichTextEditorComponent implements OnInit {
  readonly ariaLabel = input<string>(undefined);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() editorContent = new UntypedFormControl();
  readonly autoPopulated = input(false);
  readonly sneakyToolbar = input(false);
  readonly placeholder = input('Enter text, or paste content');
  readonly disabled = input(false);
  readonly showOnlyH3Heading = input(false);
  readonly required = input<boolean>(undefined);
  readonly requiredError = input('This field is required');
  @HostBinding('class.has-content') content;
  readonly changedContent = output<ContentChange>();

  editorTheme = 'snow'; // "snow" (toolbar) or "bubble" (inline)
  defaultModule = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', { script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }, { direction: 'rtl' }],
      ['link'],
      ['clean'],
    ],
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: () => true,
        },
      },
    },
  };

  constructor() {}

  ngOnInit() {
    if (this.showOnlyH3Heading()) {
      this.defaultModule.toolbar[0] = [{ header: [3, false] }];
    }
  }

  emitChangedContent(event) {
    this.changedContent.emit(event);
  }
}
