import { Component, OnInit, input } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';

type Width = 'auto' | 'full' | 'none' | number | string;
@Component({
    selector: 'mulo-centered-block-layout',
    templateUrl: './centered-block-layout.component.html',
    styleUrls: ['./centered-block-layout.component.scss'],
    host: { class: 'mulo-centered-block-layout' },
    imports: [NgClass, NgStyle]
})
export class CenteredBlockLayoutComponent implements OnInit {
  readonly width = input<Width>('auto');

  constructor() {}

  ngOnInit() {}

  get isNumber() {
    return typeof this.width() === 'number';
  }

  get widthClass() {
    return this.isNumber ? `is-custom-width` : `is-${this.width()}-width`;
  }

  get customWidth() {
    return this.isNumber ? { width: `${this.width()}px` } : null;
  }
}
