import { Injectable, inject } from '@angular/core';
import { ResearcherAssetStatus } from '../shared/configurations/researcher-asset-status.constant';
import { Communication } from '../shared/interfaces/communication.interface';
import { NotificationData, ResearcherNotification } from '../shared/interfaces/researcher-notification.model';
import { Notification } from '../shared/configurations/notification.constant';
//import { ChatDialogComponent } from '../parts/chat/chat-dialog/chat-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { JwtUtilService } from './jwt-util.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlUtils } from '../shared/utils/url.utils';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { I18nService } from './i18n.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private matDialog = inject(MatDialog);
    private jwtUtilService = inject(JwtUtilService);
    private i18nService = inject(I18nService);
    private http = inject(HttpClient);

    institutionCode: string;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    setNoteData(notes: Communication[], status: string) {
        const noteData = new NotificationData();
        noteData.index = 0;
        noteData.notifications = [];
        let index = 0;
        notes.forEach(note => {
            const userName = this.jwtUtilService.getUserName(true);

            if (this.showMessageFromAdmin(note, userName) || this.showMessageFromResearcher(note, userName)) {
                const newNote = this.convertNoteToResearcherNotification(note, status);
                if (!this.messageNotEmpty(newNote)) {
                    return;
                }
                if (newNote.showNotification && !noteData.currNote) {
                    this.setCurrentNote(noteData, index, newNote);
                }
                noteData.notifications.push(newNote);
                index++;
            }
        });
        return noteData;
    }

    showMessageFromAdmin(note: Communication, userName: string) {
        const sentToThisUser = note.messages[0]?.sentTo.find(user => user === userName ||
            user === this.jwtUtilService.getCurrentUserForProxy()?.userPrimaryID) !== undefined;
        return note?.messages[0].creatorType === 'ADMIN' && (sentToThisUser ||
            !['CONTACT', 'RESEARCHER_REVISION'].some(type => type === note?.action));
    }

    showMessageFromResearcher(note: Communication, userName: string) {
        return (note.action !== 'RESEARCHER_REVISION' || note.messages[0]?.createdBy === userName) &&
            (note?.messages[0].owner === userName ||
            note?.messages[0].owner === this.jwtUtilService.getCurrentUserForProxy()?.userPrimaryID);
    }

    messageNotEmpty(notification: ResearcherNotification) {
        return notification.body || notification.contentTitle || notification.subject;
    }

    setCurrentNote(noteData: NotificationData, index, newNote: ResearcherNotification) {
        noteData.currNote = newNote;
        noteData.index = index;
    }

    convertNoteToResearcherNotification(note: Communication, status: string) {
        const notification = new ResearcherNotification();
        notification.uuid = note?.uuid;
        notification.reason = note.reason;
        notification.body = note.messages[0]?.body;
        notification.subject = note.subject;
        const readByUser = note.messages[0]?.readBy.find(user => user === this.jwtUtilService.getUserName(true) ||
            user === this.jwtUtilService.getCurrentUserForProxy()?.userPrimaryID) !== undefined;
        notification.showNotification = note.messages[0]?.creatorType === 'ADMIN' && !readByUser &&
            note?.uuid !== undefined;
        this.convertNoteToChatEntry(note, notification);
        switch (note.action.toLowerCase()) {
            case Notification.RETURN_ֹNOTIFICATION:
                if (status === ResearcherAssetStatus.RETURNED) {
                    notification.contentTitle = 'research.output.list.note.return.text';
                } else {
                    notification.showNotification = false;
                }

                break;
            case Notification.APPROVED_NOTIFICATION:
                notification.contentTitle = 'research.output.list.note.approve.text';
                break;

        }
        return notification;
    }

    convertNoteToChatEntry(note: Communication, notification: ResearcherNotification) {
        const createdDate = note.messages[0]?.createdDate;
        let time;
        if (createdDate.year && createdDate.month && createdDate.dayOfMonth){
             time = new Date(Date.UTC(createdDate.year, createdDate.month, createdDate.dayOfMonth,
                createdDate.hourOfDay, createdDate.minute, createdDate.second));
        } else {
            time = new Date(createdDate);
        }
        notification.time = time;
        this.setNoteOrigion(note, notification);
    }

    setNoteOrigion(note: Communication, notification: ResearcherNotification) {
        let origionTitle = 'research.output.list.message.window.';

        if (note.messages[0]?.creatorType === 'ADMIN' ||
            (!note.messages[0].creatorType && note.action !== 'CONTACT')) {
            origionTitle += 'admin';
            notification.origin = 'other';
        } else {
            if (note.messages[0]?.creatorType === 'PROXY' && !this.jwtUtilService.getCurrentUserForProxy()) {
                origionTitle += 'for.';
            }
            origionTitle += 'you';
            notification.origin = 'self';
        }
        notification.originTitle = origionTitle;
    }

    readCommunicationMessage(uuId: string, mmsId: string) {
        const params = new HttpParams().set('institution', this.institutionCode);
        return this.http.put(RestApiBase.READ_COMMUNICATION_MESSAGE_BY_UUID, {
            uuId: uuId, mmsId: mmsId,
            userName: this.jwtUtilService.getUserName(true)
        },
            { params: params });
    }

    readAllCommunicationMessage(mmsId: string) {
        const params = new HttpParams().set('institution', this.institutionCode);
        return this.http.put(RestApiBase.READ_ALL_COMMUNICATION_MESSAGE, {
            mmsId: mmsId,
            userName: this.jwtUtilService.getUserName(true)
        },
            { params: params });
    }

    addCommunicationMessage(message: string, mmsId: string) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        params = params.set('mmsId', mmsId);

        const body = {
            mmsId: mmsId,
            userName: this.jwtUtilService.getUserName(true),
            ownerId: this.jwtUtilService.getOwnerID(),
            communicationBody: message
        };
        return this.http.post(RestApiBase.ADD_COMMUNICATION_MESSAGE, body, { params: params, responseType: 'text' });
    }

    addResearcherToAssetRequest(mmsId: string) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        params = params.set('mmsId', mmsId);

        // this translated message is only to enable customization to the label, and should be translated with default language
        const bodyMessageWithDefaultLanguageForAlma = this.i18nService.defaultLangInstant('research.selected.brief.body.text.display.in.alma',
                                    {displayName: this.jwtUtilService.getUserDisplayName()});

        const body = {
            mmsId: mmsId,
            userName: this.jwtUtilService.getUserName(true),
            ownerId: this.jwtUtilService.getOwnerID(),
            communicationBody: bodyMessageWithDefaultLanguageForAlma
        };
        return this.http.post(RestApiBase.ADD_RESEARCHER_TO_ASSET, body, { params: params, responseType: 'text' });
    }
}
