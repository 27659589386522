import { Component, OnInit, inject, input, output } from '@angular/core';
import { TabComponentModel } from '../../../shared/interfaces/tab-component.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DepositFormService } from '../../deposit-form.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { JwtUtilService } from '../../../core/jwt-util.service';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DepositFilesComponent } from '../../deposit-sections/deposit-files/deposit-files.component';
import { DepositSearchForComponent } from '../../deposit-sections/deposit-search-for/deposit-search-for.component';
import { DepositSelectedResearchComponent } from '../../deposit-sections/deposit-selected-research/deposit-selected-research.component';

import { DepositTypeComponent } from '../../deposit-sections/deposit-type/deposit-type.component';
import { OnboardingDirective } from '../../../parts/onboarding/onboarding.directive';
import { DepositFormSectionComponent } from '../../deposit-form-section/deposit-form-section.component';

@Component({
    selector: 'esp-deposit-step-one',
    templateUrl: './deposit-step-one.component.html',
    styleUrls: ['./deposit-step-one.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
    imports: [
        DepositFormSectionComponent,
        OnboardingDirective,
        DepositTypeComponent,
        DepositSelectedResearchComponent,
        DepositSearchForComponent,
        DepositFilesComponent,
        TranslateModule,
        CustomTranslatePipe,
    ],
})
export class DepositStepOneComponent implements OnInit {
    private depositFormService = inject(DepositFormService);
    private depositFormDataService = inject(DepositFormDataService);
    private jwtUtilService = inject(JwtUtilService);

    readonly tab1 = input(undefined);
    readonly jumpToFiles = input(undefined);
    readonly assetTypeSelectedEmitter = output();
    readonly assetTypeClearedEmitter = output();
    readonly closeDeposit = output<any>();

    config: any;
    showOnboarding = true;
    allowMultipleFiles = true;
    selectedResearch = null;
    isTypeSelected = false;
    userId: string;

    constructor() {
        this.userId = this.jwtUtilService.getUserID();
    }

    ngOnInit() {
        if (this.depositFormDataService.isDraft()) {
            this.showOnboarding = false;
        }
    }

    displaySearchFor(comp?: TabComponentModel) {
        return !this.depositFormDataService.isReservedOrRegisteredDoi();
    }

    displayFileUploader(comp: TabComponentModel) {
        return comp.field === 'file_uploader';
    }

    onResearchSelection(event) {
        this.selectedResearch = event;
    }

    onRemoveSelectedWork() {
        this.selectedResearch = '';
        this.depositFormDataService.enableFormEnrichment();
    }

    isRequired(comp) {
        return comp.mandatory;
    }

    onTypeSelection(value) {
        this.isTypeSelected = true;
        const index = value.indexOf('.');
        if (index !== -1) {
            const category = value.substr(0, index);
            if (
                this.depositFormDataService.category.value &&
                this.depositFormDataService.category.value !== category
            ) {
                this.clearReservedRegisterDoi();
            }
            this.depositFormDataService.updateCategory(category);
        }
        this.depositFormDataService.updateType(value);

        this.assetTypeSelectedEmitter.emit();
    }
    clearReservedRegisterDoi() {
        if (this.depositFormDataService.isReservedOrRegisteredDoi()) {
            this.depositFormDataService.updateDoi('');
            this.depositFormDataService.updateCleanReservedRegisterDoi(true);
            this.depositFormDataService.updateIsRegisteredDOI(false);
            this.depositFormDataService.updateIsReservedDOI(false);
            this.depositFormDataService.updateResearcherReservedDoi(false);
        }
    }

    onClearSelection() {
        this.isTypeSelected = false;
        this.assetTypeClearedEmitter.emit();
    }

    getSearchForTitle(component?) {
        if (!this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.search.title';
        } else if (this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.identify.title';
        }
    }

    getSearchForDescription(component?) {
        if (!this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.search.subtitle';
        } else if (this.selectedResearch && this.displaySearchFor(component)) {
            return 'research.search.selected.asset.subtitle';
        }
    }
}
