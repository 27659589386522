import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  input
} from '@angular/core';

@Component({
    selector: 'mulo-floating-toolbar',
    templateUrl: './floating-toolbar.component.html',
    styleUrls: ['./floating-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-floating-toolbar',
        '[class.has-background]': 'background() === "opaque"',
        '[class.has-shadow]': 'shadow()',
        '[class.is-disabled]': 'disabled()',
    },
    standalone: true,
})
export class FloatingToolbarComponent implements OnInit {

  readonly shadow = input<boolean>(false);
  readonly background = input<'transparent' | 'opaque'>('opaque');
  readonly disabled = input<boolean>(false);

  constructor() {}

  ngOnInit() {}
}
