import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'esp-deposit-peer-reviewed',
    templateUrl: './deposit-peer-reviewed.component.html',
    styleUrls: ['./deposit-peer-reviewed.component.scss'],
    imports: [
        MatRadioGroup,
        FormsModule,
        ReactiveFormsModule,
        BadgeAutoPopulatedComponent,
        MatRadioButton,
        CustomTranslatePipe
    ]
})
export class DepositPeerReviewedComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private peerReviewedDestroy = new Subject<void>();

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.peerReviewed.valueChanges
      .pipe(takeUntil(this.peerReviewedDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PEER_REVIEWED
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.PEER_REVIEWED
    );
  }

  ngOnDestroy() {
    if (!this.peerReviewedDestroy.isStopped) {
      this.peerReviewedDestroy.next();
      //unsubscribe from the subject itself
      this.peerReviewedDestroy.unsubscribe();
    }
  }
}
