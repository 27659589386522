@if (data.actions.close) {
  <button
    [attr.aria-label]="'research.aria.dialog.close.dialog' | translate"
    class="dialog-close-button"
    mat-dialog-close="cancel"
    mat-icon-button
    >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
}
<span class="title-wrap">
  @if (viewInit && !drawerOpened) {
    <button
      (click)="openDrawer()"
      [attr.aria-label]="'research.aria.dialog.show.navigation.links' | translate"
      [exlFocus]="!drawerOpened"
      class="margin-right-xs heading-button"
      mat-icon-button
      >
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
  }
  <h1 mat-dialog-title>
    <span>{{ data.title }}</span>
  </h1>
</span>
@if (data.body) {
  <div mat-dialog-content>
    <exl-navigable-text
      (drawerClosed)="onDrawerClosed()"
      [drawerOpened]="drawerOpened"
      [html]="data.body"
    ></exl-navigable-text>
  </div>
}
<div mat-dialog-actions>
  @if (data.actions.discard) {
    <button
      #discard
      [attr.cdkFocusInitial]="actionFocus === 'discard' || null"
      [class.is-large]="actionFocus === 'discard'"
      [color]="actionFocus === 'discard' ? accentColor : null"
      mat-button
      mat-dialog-close="discard"
      >
      {{ data.actions.discard }}
    </button>
  }
  <span class="spacer"></span>
  @if (data.actions.confirm) {
    <button
      #confirm
      [attr.cdkFocusInitial]="actionFocus === 'confirm' || null"
      [class.is-large]="actionFocus === 'confirm'"
      [color]="actionFocus === 'confirm' ? accentColor : null"
      mat-button
      mat-dialog-close="ok"
      >
      {{ data.actions.confirm | customTranslate }}
    </button>
  }
  @if (data.actions.cancel) {
    <button
      #cancel
      [attr.cdkFocusInitial]="actionFocus === 'cancel' || null"
      [class.is-large]="actionFocus === 'cancel'"
      [color]="actionFocus === 'cancel' ? accentColor : null"
      mat-button
      mat-dialog-close="cancel"
      >
      {{ data.actions.cancel | customTranslate }}
    </button>
  }
</div>
