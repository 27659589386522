import {
  Directive,
  ElementRef,
  HostBinding,
  NgModule,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
  HostAttributeToken,
  input,
  output
} from '@angular/core';

@Directive({
  selector: '[muloMultipleEvents]',
  standalone: true,
})
export class MultipleEventsDirective implements OnInit, OnDestroy {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  readonly muloMultipleEvents = output();
  readonly eventOrigins = input('keydown.enter,keydown.space');
  @HostBinding() tabIndex: number;
  private unlisteners = [];

  constructor() {
    const tabIndex = inject(new HostAttributeToken('tabindex'), {
      optional: true,
    });

    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }

  ngOnInit() {
    this.eventListInit();
  }

  eventListInit() {
    if (!this.muloMultipleEvents) {
      return;
    }
    const eventList = this.eventOrigins()?.split(/[ ,|]/);
    eventList?.forEach((origin) => {
      this.unlisteners.push(
        // the listen function returns An "unlisten" function for disposing of this handler.
        this.renderer.listen(
          this.elementRef.nativeElement,
          origin,
          this.keyFunc,
        ),
      );
    });
  }

  keyFunc = (event) => {
    this.muloMultipleEvents.emit(event);
  };

  ngOnDestroy(): void {
    this.unlisteners?.forEach((unlisten) => unlisten());
  }
}
