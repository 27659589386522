import {
  ChangeDetectorRef,
  Component,
  input,
  computed,
  model,
  signal,
  effect,
  inject,
} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY, finalize, take } from 'rxjs';
import { FileUtils, MediaIfDirective } from '@exl-ng/mulo-core';
import { CommonModule } from '@angular/common';
import { FileTypeIconPipe, UrlTrustPipe } from '../../pipes';
import { LoadingSlateComponent } from '../loading-slate';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StlModelViewerModule } from 'angular-stl-model-viewer';

@Component({
  selector: 'mulo-arcview',
  templateUrl: './arcview.component.html',
  styleUrls: ['./arcview.component.scss'],
  imports: [
    CommonModule,
    UrlTrustPipe,
    FileTypeIconPipe,
    LoadingSlateComponent,
    PdfViewerModule,
    StlModelViewerModule,
    MediaIfDirective,
  ],
})
export class ArcviewComponent {
  private http = inject(HttpClient);
  private cdr = inject(ChangeDetectorRef);

  src = model('');
  type = model('');
  alt = input('');
  unsupportedMsg = input('This format is not supported by the viewer');
  errorMsg = input('An unexpected error has occurred');
  ariaLabelledBy = input<string>();
  ariaLabel = input<string>();
  loading = model(false);
  loadingText = input<string>();
  viewHtmlAsCode = input(false);

  srcContent = signal<string>(null);
  reloadTimeout = signal(null);
  reloadTries = 5;

  constructor() {
    effect(() => {
      const shortType = FileUtils.getFileProfile('', this.type());

      if (['pdf', 'text', 'code'].includes(shortType)) {
        this.loading.set(true);
        this.srcContent.set(null);
        this.http
          .get(this.src(), { responseType: 'text' })
          .pipe(
            take(1),
            finalize(() => this.loading.set(false)),
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              return EMPTY;
            }),
          )
          .subscribe((res) => this.srcContent.set(res));
      }
    });
  }

  officeSrc = computed(
    () =>
      'https://view.officeapps.live.com/op/embed.aspx?src=' +
      encodeURIComponent(this.src()),
  );

  gDocsSrc = computed(
    () =>
      'https://docs.google.com/gview?embedded=true&url=' +
      encodeURIComponent(this.src()),
  );

  handleGdocsLoad(ev: Event) {
    // this is all needed because of blank 204 responses returning from google
    const iframe = ev.target as HTMLIFrameElement;
    if (iframe?.contentDocument?.body?.innerHTML === '') {
      this.loading.set(true);
      this.reloadTries--;
      if (this.reloadTries > 0) {
        console.info(
          'Google Docs viewer content empty, reloading in 5 seconds if not resolved',
        );
        const src = this.src();
        this.reloadTimeout.set(
          setTimeout(() => {
            console.error('not resolved, reloading');
            this.src.set(null);
            this.cdr.detectChanges();
            this.src.set(src);
            this.cdr.detectChanges();
          }, 5000),
        );
      } else {
        // if it doesn't resolve after 5 tries, consider it an error
        this.type.set('example/error');
      }
    } else {
      clearTimeout(this.reloadTimeout());
      this.loading.set(false);
    }
  }

  handleUnsupported(ev) {
    console.error('unsupported', ev);
    this.type.set('example/unsupported');
    clearTimeout(this.reloadTimeout());
    this.loading.set(false);
  }

  handleError(ev) {
    console.error('error', ev);
    this.type.set('example/error');
    clearTimeout(this.reloadTimeout());
    this.loading.set(false);
  }
}
