<mat-card appearance="outlined">
  <form #loginForm>
    <div class="login-form">
      @if (error) {
        <div style="color:red" class="margin-bottom-small">{{errorMessage | translate}}</div>
      }
      <mat-form-field class="is-auto-filling">
        <input matInput
          name="user"
          type="text"
          required
          [(ngModel)]="user"
          #name="ngModel">
        <mat-label>{{'research.login.username' | translate}}</mat-label>

        @if (name?.errors?.required) {
          <mat-error>
            {{'research.login.username.error' | translate}}
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field class="is-auto-filling">
        <input matInput
          required
          type="password"
          name="password"
          [(ngModel)]="password"
          #pass="ngModel">
        <mat-label>{{'research.login.password' | translate}}</mat-label>
        @if (pass?.errors?.required) {
          <mat-error>
            {{'research.login.password.error' | translate}}
          </mat-error>
        }
      </mat-form-field>
      <button mat-raised-button
        type="submit"
        color="primary"
        class="submit-button is-large"
        [class.is-processing]="submitInProgress"
        attr.aria-label="{{'research.aria.sign.in' | translate}}"
        (click)="doSignIn()">
        @if (!submitInProgress) {
          {{'research.login.sign.in' | translate}}
        }
        @if (submitInProgress) {
          <mat-spinner diameter="30"></mat-spinner>
        }
      </button>
      @if (resetPwUrl && showResetPwUrl) {
        <a
          class="margin-top-xs"
          color="primary"
          role="button"
          mat-button
          [href]="resetPwUrl"
          attr.aria-label="{{ 'research.aria.reset.pw.link' | translate }}"
          >
          {{ 'research.reset.password.link' | translate }}
        </a>
      }
    </div>
  </form>
</mat-card>
