import { Injectable, Renderer2, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AltmetricService {
  private http = inject(HttpClient);
  private document = inject(DOCUMENT);
  private window = this.document.defaultView;

  srcScript = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
  url = 'https://api.altmetric.com/v1/doi/';

  /* properties for Altmetric badge as is  */
  private renderer = inject(Renderer2);

  loadScript(doi?: string) {
    const id = 'altmetric';
    const scriptElem = document.getElementById(id);

    if (scriptElem) {
      scriptElem.remove();
      let doiElem: HTMLElement;
      if (doi) {
        const scripts = document.querySelectorAll(`script[src*="${doi}"]`);
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
          scripts[i].parentNode.removeChild(scripts[i]);
        }
        doiElem = document.getElementById(doi);
      }

      if (this.window['_altmetric_embed_init']) {
        this.window['_altmetric_embed_init'](doiElem);
      }
    }
    const newScriptElem = this.renderer.createElement('script');
    newScriptElem.type = 'text/javascript';
    newScriptElem.src = this.srcScript;
    newScriptElem.id = id;
    this.renderer.appendChild(this.document.body, newScriptElem);
  }

  searchDOI(doi): Observable<any> {
    return this.fetchImage(this.url + doi);
  }

  fetchImage(url): Observable<any> {
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error(
      `Altmetric API returned ${error.status}. ` + `Reason: ${error.error}`,
    );
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
