import { AfterViewInit, ChangeDetectorRef, Directive, EmbeddedViewRef, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';

@Directive({
    selector: '[muloSearchbarAdvRoot]',
    exportAs: 'muloSearchbarAdvRoot',
    standalone: true,
})
export class SearchbarAdvRootDirective implements OnInit, AfterViewInit {
  private _vcr = inject(ViewContainerRef);
  private templateRef = inject<TemplateRef<any>>(TemplateRef);
  private changeRef = inject(ChangeDetectorRef);

  parentHeight = 0;
  advSearch: EmbeddedViewRef<any>;
  isOpen = false;

  ngOnInit() {
    this._vcr.createEmbeddedView(this.templateRef);
  }
  ngAfterViewInit() {
    this.parentHeight =
      this._vcr.element?.nativeElement?.parentElement?.scrollHeight;
  }

  openAdvSearch(content: TemplateRef<any>) {
    this.advSearch = this._vcr.createEmbeddedView(content);
    this.isOpen = true;
    this.changeRef.markForCheck();
  }

  closeAdvSearch() {
    this.advSearch?.destroy();
    this.isOpen = false;
    this.changeRef.markForCheck();
  }
}
