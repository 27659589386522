import { Component, OnInit, Input, inject, input } from '@angular/core';
import { SharerLabels, ShareTarget } from '../share.model';
import { ShareService } from '../share.service';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { HtmlTrustPipe } from '../../../pipes/html-trust.pipe';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MultipleEventsDirective } from '../../../directives/multiple-events.directive';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { CopierComponent } from '../../copier/copier.component';
import { DOCUMENT } from '@angular/common';

const defaultLabels: SharerLabels = {
  copy: 'Copy',
  copied: 'Copied!',
};
@Component({
    selector: 'mulo-sharer',
    templateUrl: './sharer.component.html',
    styleUrls: ['./sharer.component.scss'],
    imports: [
        CopierComponent,
        MatAnchor,
        MultipleEventsDirective,
        MatIcon,
        SvgViewboxDirective,
        HtmlTrustPipe,
    ]
})
export class SharerComponent implements OnInit {
  private service = inject(ShareService);
  private document = inject<Document>(DOCUMENT);
  private _window = this.document.defaultView;

  /** Whether the asset share component is loaded in a dialog context */
  readonly inDialog = input(false);
  /** static labels (for translation) */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() labels: SharerLabels;
  /** Array of targets to share to */
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() shareTargets: ShareTarget[];
  /** The data to be sent to the share popup (url, title, text) */
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() shareData: ShareData;
  /** open the share session as a popup or as a new tab */
  readonly openAs = input<'popup' | 'tab'>('popup');
  /** Optional popup params */
  readonly popupParams = input(`location=0,menubar=0,toolbar=0,personalbar=0,status=0,scrollbars=1,resizable=1`);
  /** the id name of the popup window. Used to ensure any new popup replaces the previously opened one, instead of opening multiple popup windows. */
  readonly shareWindowId = input('social');

  ngOnInit(): void {
    this.labels = { ...defaultLabels, ...this.labels };
    // check if the browser handles the Web Share API
    const nativeCompatible = this._window?.navigator?.share;
    if (!nativeCompatible) {
      // filter out native share if not compatible
      this.shareTargets = this.shareTargets.filter((t) => t.key !== 'native');
    }

    this.shareTargets.forEach((t, i) => {
      const service = this.service.shareServices.find((s) => s.key === t.key);
      if (service) {
        this.shareTargets[i] = { ...service, ...t };
      }
      if (this.shareTargets[i].url) {
        const urlObj = this.service.urlParse(
          this.shareTargets[i].url,
          this.shareData,
        );
        this.shareTargets[i].url = urlObj.href;
      }
    });
  }

  /** Calculate popup window size */
  popupSize() {
    const screen = this._window.screen;
    const sw = screen.availWidth || 1024;
    const sh = screen.availHeight || 700;
    const pw = Math.min(600, sw - 40);
    const ph = Math.min(600, sh - 40);
    const px = Math.floor((sw - pw) / 2);
    const py = Math.floor((sh - ph) / 2);

    return `width=${pw},height=${ph},left=${px},top=${py}`;
  }

  onLinkCopied() {
    this.service.onLinkCopied();
  }

  openPopupShare(url): void {
    const popup = this._window.open(
      url,
      this.shareWindowId(),
      `${this.popupSize()}, ${this.popupParams()}`,
    );

    if (popup) popup.focus();
  }

  openNativeShare(event) {
    event.preventDefault();
    this._window.navigator.share(this.shareData);
  }
}

@Component({
    selector: 'mulo-share-dialog',
    templateUrl: './share-dialog.component.html',
    styleUrls: ['./share-dialog.component.scss'],
    imports: [
        MatIconButton,
        MatIcon,
        SvgViewboxDirective,
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        SharerComponent,
    ]
})
export class ShareDialogComponent {
  dialogRef = inject<MatDialogRef<ShareDialogComponent>>(MatDialogRef);
  data = inject(MAT_DIALOG_DATA);

  dialogTitle = 'Share';

  close() {
    this.dialogRef.close();
  }
}
