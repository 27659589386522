import { Injectable, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { UrlUtils } from '../shared/utils/url.utils';
import { Profile } from '../shared/configurations/profile.constant';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { RouterService } from './router.service';
import { I18nService } from '../core/i18n.service';

@Injectable()
export class EditionGuard {
    private router = inject(Router);
    private routerService = inject(RouterService);
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private i18nService = inject(I18nService);

    institutionCode = UrlUtils.getParam('institution');

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isEditionAdvanced(route.params.id);
    }

    isEditionAdvanced(id): boolean {
        if (this.configurationHandlerService.isEsploroEditionAdvanced()) {
            return true;
        }
        const discoveryURL = this.getDiscoveryMainPageURL(this.institutionCode);
        window.open(discoveryURL, '_self');
    }

    getDiscoveryMainPageURL(institutionCode: string) {
        let discoveryURL = Profile.DISCOVERY_MAIN_PAGE_URL;
        discoveryURL = discoveryURL.replace(
            '{{institution_code}}',
            institutionCode,
        );
        discoveryURL = discoveryURL.replace(
            '{{lang}}',
            this.i18nService.getLanguage(),
        );
        return discoveryURL;
    }
}
